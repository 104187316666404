import { useTranslation } from 'react-i18next';
import { confirmDelete, formatCurrency, uuid } from '../../utils';
import AuditingTable from '../table/AuditingTable';
import { OnEditProps, TableColumnDefs } from '../table/Table';
import styled from 'styled-components';

const Container = styled.div`
  .additional-info {
    font-style: italic;
    color: ${p => p.theme.color.grey400};
    text-align: right;
  }
`;

export function formatNumber(num: any) {
  return num.toLocaleString('fi-FI', {
    minimumFractionDigits: 1,
    maximumFractionDigits: 2,
  });
}

const sectionKey: AuditingSectionKey = 'financialInstitutions';

export const LoanConfimationsTableColumn = ({
  formState,
  patchFormState,
}: FormFieldProps<FinancialInstitutionsForm>) => {
  const { t } = useTranslation();

  if (!formState) return null;

  const tBase = `auditing:form.${sectionKey}.creditorsTable`;

  const data = formState.loanConfirmations ?? [];

  function handleEdit<T>({
    itemId,
    rowIndex,
    columnId,
    value,
  }: OnEditProps<T>) {
    const patch = formState.loanConfirmations.map((row, index) => {
      const idMatch = itemId !== undefined && itemId === row.id;
      const rowIndexMatch = itemId === undefined && rowIndex === index;
      if (idMatch || rowIndexMatch) {
        const updatedRow = { ...row, [columnId]: value };
        if (columnId === 'generalLedger' || columnId === 'balance') {
          let generalLedgerValue = updatedRow.generalLedger || 0;
          let balanceValue = updatedRow.balance || 0;
          let minus = generalLedgerValue - balanceValue || 0;
          updatedRow.minus = minus;
          // FormatNumber
          formatNumber(updatedRow.minus);
        }
        return updatedRow;
      }
      return row;
    });
    const newFormState = { ...formState, loanConfirmations: patch };
    patchFormState(newFormState);
  }

  let tableColumns: TableColumnDefs<LoanConfirmation> = [
    {
      accessorKey: 'label',
      header: () => t(`${tBase}.loan`),
      className: 'text-left width-middle',
      placeholder: 'XXXX',
      onEdit: (editProps: OnEditProps<string>) => {
        handleEdit<string>(editProps);
      },
    },
    {
      accessorKey: 'generalLedger',
      header: () => t(`${tBase}.generalLedger`) + ' €',
      className: 'text-center width-middle',
      onNumberEdit: (editProps: OnEditProps<number>) =>
        handleEdit<number>(editProps),
      cell: ({ cell }) => <div>{cell.row.original.generalLedger} €</div>,
    },
    {
      accessorKey: 'balance',
      header: () => t(`${tBase}.balance`) + ' €',
      className: 'text-center width-middle',
      onNumberEdit: (editProps: OnEditProps<number>) =>
        handleEdit<number>(editProps),
      cell: ({ cell }) => <div>{cell.row.original.balance} €</div>,
    },
    {
      accessorKey: 'minus',
      header: () => t(`${tBase}.minus`),
      className: 'text-center width-middle',
      accessorFn: (row: any) => {
        return formatCurrency(row.minus);
      },
      cell: ({ cell }) => <div>{formatNumber(cell.row.original.minus)} €</div>,
    },
    {
      accessorKey: 'other',
      header: () => t(`${tBase}.other`),
      className: 'text-center width-middle',
      onEdit: (editProps: OnEditProps<string>) => handleEdit<string>(editProps),
    },
  ];

  const handleAddNewRow = () => {
    patchFormState({
      ...formState,
      loanConfirmations: [
        ...(formState.loanConfirmations ?? []),
        {
          label: '',
          id: uuid(), //Math.floor(Math.random()*100).toString(),
          generalLedger: 0,
          balance: 0,
          minus: 0,
          other: '',
        },
      ],
    });
  };

  const handleDeleteRow = ({ id, label }: LoanConfirmation) => {
    if (confirmDelete(label ?? '')) {
      const patch = (formState.loanConfirmations ?? []).filter(
        row => row.id !== id
      );
      patchFormState({ ...formState, loanConfirmations: patch });
    }
  };

  return (
    <>
      <AuditingTable<LoanConfirmation>
        sectionKey={sectionKey}
        title="Lainojen vahvistukset:"
        onAddNewRow={handleAddNewRow}
        data={data}
        columns={tableColumns}
        onRowDelete={handleDeleteRow}
        showGlobalFilter={false}
        disableSort
        variant="default"
      />
      <Container>
        <div className="additional-info">
          Erotus: Saldo pääkirjanpidossa - Saldo vahvistuksella
        </div>
      </Container>
    </>
  );
};

// Pääomalainojen täsmäytys velkakirjoille
export const ReconciliationTableColumn = ({
  formState,
  patchFormState,
}: FormFieldProps<FinancialInstitutionsForm>) => {
  const { t } = useTranslation();

  if (!formState) return null;

  const tBase = `auditing:form.${sectionKey}.reconciliationTable`;

  const data = formState.reconciliationTables ?? [];

  function handleEdit<T>({
    itemId,
    rowIndex,
    columnId,
    value,
  }: OnEditProps<T>) {
    const patch = formState.reconciliationTables.map((row, index) => {
      const idMatch = itemId !== undefined && itemId === row.id;
      const rowIndexMatch = itemId === undefined && rowIndex === index;
      if (idMatch || rowIndexMatch) {
        const updatedRow = { ...row, [columnId]: value };
        if (
          columnId === 'generalLedger' ||
          columnId === 'balance' ||
          columnId === 'shortPerYear' ||
          columnId === 'shortMade'
        ) {
          let generalLedgerValue = updatedRow.generalLedger || 0;
          let balanceValue = updatedRow.balance || 0;
          let shortPerYearValue = updatedRow.shortPerYear || 0;
          let shortMadeValue = updatedRow.shortMade || 0;
          let minus =
            generalLedgerValue -
              balanceValue -
              shortPerYearValue * shortMadeValue || 0;
          updatedRow.minus = minus;
          // FormatNumber
          formatNumber(updatedRow.minus);
        }
        return updatedRow;
      }
      return row;
    });
    const newFormState = { ...formState, reconciliationTables: patch };
    patchFormState(newFormState);
  }

  function formatNumber(num: any) {
    return num.toLocaleString('fi-FI', {
      minimumFractionDigits: 1,
      maximumFractionDigits: 2,
    });
  }

  let tableColumns: TableColumnDefs<ReconciliationTable> = [
    {
      accessorKey: 'label',
      header: () => t(`${tBase}.loan`),
      className: 'text-left width-middle',
      placeholder: 'XXXX',
      onEdit: (editProps: OnEditProps<string>) => {
        handleEdit<string>(editProps);
      },
    },
    {
      accessorKey: 'generalLedger',
      header: () => t(`${tBase}.generalLedger`) + ' €',
      className: 'text-center width-middle',
      onNumberEdit: (editProps: OnEditProps<number>) =>
        handleEdit<number>(editProps),
      cell: ({ cell }) => <div>{cell.row.original.generalLedger} €</div>,
    },
    {
      accessorKey: 'balance',
      header: () => t(`${tBase}.balance`) + ' €',
      className: 'text-center width-middle',
      onNumberEdit: (editProps: OnEditProps<number>) =>
        handleEdit<number>(editProps),
      cell: ({ cell }) => <div>{cell.row.original.balance} €</div>,
    },
    {
      accessorKey: 'shortPerYear',
      header: () => t(`${tBase}.shortPerYear`) + ' €',
      className: 'text-center width-middle',
      onNumberEdit: (editProps: OnEditProps<number>) =>
        handleEdit<number>(editProps),
      cell: ({ cell }) => <div>{cell.row.original.shortPerYear} €</div>,
    },
    {
      accessorKey: 'shortMade',
      header: () => t(`${tBase}.shortMade`) + ' KPL',
      className: 'text-center width-middle',
      onNumberEdit: (editProps: OnEditProps<number>) =>
        handleEdit<number>(editProps),
      cell: ({ cell }) => <div>{cell.row.original.shortMade} €</div>,
    },
    {
      accessorKey: 'minus',
      header: () => t(`${tBase}.minus`),
      className: 'text-center width-middle',
      accessorFn: (row: any) => {
        return formatCurrency(row.minus);
      },
      cell: ({ cell }) => <div>{formatNumber(cell.row.original.minus)} €</div>,
    },
    {
      accessorKey: 'other',
      header: () => t(`${tBase}.other`),
      className: 'text-center width-middle',
      onEdit: (editProps: OnEditProps<string>) => handleEdit<string>(editProps),
    },
  ];

  const handleAddNewRow = () => {
    patchFormState({
      ...formState,
      reconciliationTables: [
        ...(formState.reconciliationTables ?? []),
        {
          label: '',
          id: uuid(), //Math.floor(Math.random()*100).toString(),
          generalLedger: 0,
          shortPerYear: 0,
          shortMade: 0,
          balance: 0,
          minus: 0,
          other: '',
        },
      ],
    });
  };

  const handleDeleteRow = ({ id, label }: ReconciliationTable) => {
    if (confirmDelete(label ?? '')) {
      const patch = (formState.reconciliationTables ?? []).filter(
        row => row.id !== id
      );
      patchFormState({ ...formState, reconciliationTables: patch });
    }
  };

  return (
    <>
      <AuditingTable<ReconciliationTable>
        sectionKey={sectionKey}
        title="Lainojen täsmäytys velkakirjoille:"
        onAddNewRow={handleAddNewRow}
        data={data}
        columns={tableColumns}
        onRowDelete={handleDeleteRow}
        showGlobalFilter={false}
        disableSort
        variant="default"
      />
      <Container>
        <div className="additional-info">
          Erotus: Saldo pääkirjalla - Saldo velkakirjalla - Lyhennys € per erä *
          Lyhennyksiä tehty{' '}
        </div>
      </Container>
    </>
  );
};

// Lainojen luokittelun tarkastus
export const InspectionTableColumn = ({
  formState,
  patchFormState,
}: FormFieldProps<FinancialInstitutionsForm>) => {
  const { t } = useTranslation();

  if (!formState) return null;

  const tBase = `auditing:form.${sectionKey}.inspectionTable`;

  const data = formState.inspectionClassifications ?? [];

  function handleEdit<T>({
    itemId,
    rowIndex,
    columnId,
    value,
  }: OnEditProps<T>) {
    const patch = formState.inspectionClassifications.map((row, index) => {
      const idMatch = itemId !== undefined && itemId === row.id;
      const rowIndexMatch = itemId === undefined && rowIndex === index;
      if (idMatch || rowIndexMatch) {
        const updatedRow = { ...row, [columnId]: value };
        if (columnId === 'ShortTermGeneralLedger' || columnId === 'ShortTerm') {
          let ShortTermGeneralLedgerValue =
            updatedRow.ShortTermGeneralLedger || 0;
          let ShortTermValue = updatedRow.ShortTerm || 0;
          let minus = ShortTermGeneralLedgerValue - ShortTermValue || 0;
          updatedRow.minus = minus;
          // FormatNumber
          formatNumber(updatedRow.minus);
        }
        return updatedRow;
      }
      return row;
    });
    const newFormState = { ...formState, inspectionClassifications: patch };
    patchFormState(newFormState);
  }

  function formatNumber(num: any) {
    return num.toLocaleString('fi-FI', {
      minimumFractionDigits: 1,
      maximumFractionDigits: 2,
    });
  }

  let tableColumns: TableColumnDefs<InspectionClassification> = [
    {
      accessorKey: 'label',
      header: () => t(`${tBase}.loan`),
      className: 'text-left width-middle',
      placeholder: 'XXXX',
      onEdit: (editProps: OnEditProps<string>) => {
        handleEdit<string>(editProps);
      },
    },
    {
      accessorKey: 'ShortTermGeneralLedger',
      header: () => t(`${tBase}.ShortTermGeneralLedger`) + ' €',
      className: 'text-center width-middle',
      onNumberEdit: (editProps: OnEditProps<number>) =>
        handleEdit<number>(editProps),
      cell: ({ cell }) => (
        <div>{cell.row.original.ShortTermGeneralLedger} €</div>
      ),
    },
    {
      accessorKey: 'ShortTerm',
      header: () => t(`${tBase}.ShortTerm`) + ' €',
      className: 'text-center width-middle',
      onNumberEdit: (editProps: OnEditProps<number>) =>
        handleEdit<number>(editProps),
      cell: ({ cell }) => <div>{cell.row.original.ShortTerm} €</div>,
    },
    {
      accessorKey: 'minus',
      header: () => t(`${tBase}.minus`),
      className: 'text-center width-middle',
      accessorFn: (row: any) => {
        return formatCurrency(row.minus);
      },
      cell: ({ cell }) => <div>{formatNumber(cell.row.original.minus)} €</div>,
    },
    {
      accessorKey: 'backgroundMaterial',
      header: () => t(`${tBase}.backgroundMaterial`),
      className: 'text-center width-middle',
      onEdit: (editProps: OnEditProps<string>) => handleEdit<string>(editProps),
    },
    {
      accessorKey: 'other',
      header: () => t(`${tBase}.other`),
      className: 'text-center width-middle',
      onEdit: (editProps: OnEditProps<string>) => handleEdit<string>(editProps),
    },
  ];

  const handleAddNewRow = () => {
    patchFormState({
      ...formState,
      inspectionClassifications: [
        ...(formState.inspectionClassifications ?? []),
        {
          label: '',
          id: uuid(), //Math.floor(Math.random()*100).toString(),
          ShortTermGeneralLedger: 0,
          ShortTerm: 0,
          minus: 0,
          backgroundMaterial: '',
          other: '',
        },
      ],
    });
  };

  const handleDeleteRow = ({ id, label }: InspectionClassification) => {
    if (confirmDelete(label ?? '')) {
      const patch = (formState.inspectionClassifications ?? []).filter(
        row => row.id !== id
      );
      patchFormState({ ...formState, inspectionClassifications: patch });
    }
  };

  return (
    <>
      <AuditingTable<InspectionClassification>
        sectionKey={sectionKey}
        title="Lainojen luokittelun tarkastus:"
        onAddNewRow={handleAddNewRow}
        data={data}
        columns={tableColumns}
        onRowDelete={handleDeleteRow}
        showGlobalFilter={false}
        disableSort
        variant="default"
      />
      <Container>
        <div className="additional-info">
          Erotus: Saldo pääkirjanpidossa - Lyhytaikainen osuus{' '}
        </div>
      </Container>
    </>
  );
};

// Lainojen tarkastus
export const LoanBalanceTableColumn = ({
  formState,
  patchFormState,
}: FormFieldProps<FinancialInstitutionsForm>) => {
  const { t } = useTranslation();

  if (!formState) return null;

  const tBase = `auditing:form.${sectionKey}.loanBalanceTable`;

  const data = formState.loanBalances ?? [];

  function handleEdit<T>({
    itemId,
    rowIndex,
    columnId,
    value,
  }: OnEditProps<T>) {
    const patch = formState.loanBalances.map((row, index) => {
      const idMatch = itemId !== undefined && itemId === row.id;
      const rowIndexMatch = itemId === undefined && rowIndex === index;
      if (idMatch || rowIndexMatch) {
        const updatedRow = { ...row, [columnId]: value };
        if (
          columnId === 'generalLedger' ||
          columnId === 'backgroundMaterialEUR'
        ) {
          let generalLedgerValue = updatedRow.generalLedger || 0;
          let backgroundMaterialEURValue =
            updatedRow.backgroundMaterialEUR || 0;
          let minus = generalLedgerValue - backgroundMaterialEURValue || 0;
          updatedRow.minus = minus;
          // FormatNumber
          formatNumber(updatedRow.minus);
        }
        return updatedRow;
      }
      return row;
    });
    const newFormState = { ...formState, loanBalances: patch };
    patchFormState(newFormState);
  }

  function formatNumber(num: any) {
    return num.toLocaleString('fi-FI', {
      minimumFractionDigits: 1,
      maximumFractionDigits: 2,
    });
  }

  let tableColumns: TableColumnDefs<LoanBalances> = [
    {
      accessorKey: 'label',
      header: () => t(`${tBase}.loan`),
      className: 'text-left width-middle',
      placeholder: 'XXXX',
      onEdit: (editProps: OnEditProps<string>) => {
        handleEdit<string>(editProps);
      },
    },
    {
      accessorKey: 'generalLedger',
      header: () => t(`${tBase}.generalLedger`) + ' €',
      className: 'text-center width-middle',
      onNumberEdit: (editProps: OnEditProps<number>) =>
        handleEdit<number>(editProps),
      cell: ({ cell }) => <div>{cell.row.original.generalLedger} €</div>,
    },
    {
      accessorKey: 'backgroundMaterialEUR',
      header: () => t(`${tBase}.backgroundMaterialEUR`) + ' €',
      className: 'text-center width-middle',
      onNumberEdit: (editProps: OnEditProps<number>) =>
        handleEdit<number>(editProps),
      cell: ({ cell }) => (
        <div>{cell.row.original.backgroundMaterialEUR} €</div>
      ),
    },
    {
      accessorKey: 'minus',
      header: () => t(`${tBase}.minus`),
      className: 'text-center width-middle',
      accessorFn: (row: any) => {
        return formatCurrency(row.minus);
      },
      cell: ({ cell }) => <div>{formatNumber(cell.row.original.minus)} €</div>,
    },
    {
      accessorKey: 'backgroundMaterial',
      header: () => t(`${tBase}.backgroundMaterial`),
      className: 'text-center width-middle',
      onEdit: (editProps: OnEditProps<string>) => handleEdit<string>(editProps),
    },
    {
      accessorKey: 'other',
      header: () => t(`${tBase}.other`),
      className: 'text-center width-middle',
      onEdit: (editProps: OnEditProps<string>) => handleEdit<string>(editProps),
    },
  ];

  const handleAddNewRow = () => {
    patchFormState({
      ...formState,
      loanBalances: [
        ...(formState.loanBalances ?? []),
        {
          label: '',
          id: uuid(), //Math.floor(Math.random()*100).toString(),
          generalLedger: 0,
          backgroundMaterialEUR: 0,
          minus: 0,
          backgroundMaterial: '',
          other: '',
        },
      ],
    });
  };

  const handleDeleteRow = ({ id, label }: LoanBalances) => {
    if (confirmDelete(label ?? '')) {
      const patch = (formState.loanBalances ?? []).filter(row => row.id !== id);
      patchFormState({ ...formState, loanBalances: patch });
    }
  };

  return (
    <>
      <AuditingTable<LoanBalances>
        sectionKey={sectionKey}
        title="Lainojen tarkastus:"
        onAddNewRow={handleAddNewRow}
        data={data}
        columns={tableColumns}
        onRowDelete={handleDeleteRow}
        showGlobalFilter={false}
        disableSort
        variant="default"
      />
      <Container>
        <div className="additional-info">
          Erotus: Saldo pääkirjanpidossa - Saldo taustamateriaalilla{' '}
        </div>
      </Container>
    </>
  );
};
