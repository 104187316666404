import { FormFieldBuilder } from '../FormFieldBuilder';

type SectionFormType = IndependenceForm;

const sectionKey: AuditingSectionKey = 'independence';

const formBuilder = new FormFieldBuilder<SectionFormType>({ sectionKey });

const defaultFormState: DefaultFormState<SectionFormType> = {
  isMattersThatRisksIndependency: null,
  mattersThatRisksIndependency: '',
  isMattersThatAffectIncapacity: null,
  mattersThatAffectIncapacity: '',
  isOfferedServicesThatRisksIndependency: null,
  offeredServicesThatRisksIndependency: '',
  isThreatsThatRisksAuditing: null,
  threatsThatRisksAuditing: '',
  isConfirmedThatDataIsCorrect: null,
  isDetectedMattersThatRisksIndependency: null,
  detectedMattersThatRisksIndependency: '',
};

const formFields: FormFields<SectionFormType> = ({ formState }) => [
  formBuilder.boolean({
    accessor: 'isMattersThatRisksIndependency',
    options: ['no', 'yes'],
    detailsAccessor: 'mattersThatRisksIndependency',
    detailsHidden: !formState.isMattersThatRisksIndependency,
  }),

  formBuilder.boolean({
    accessor: 'isMattersThatAffectIncapacity',
    options: ['no', 'yes'],
    detailsAccessor: 'mattersThatAffectIncapacity',
    detailsHidden: !formState.isMattersThatAffectIncapacity,
  }),

  formBuilder.boolean({
    accessor: 'isOfferedServicesThatRisksIndependency',
    options: ['no', 'yes'],
    detailsAccessor: 'offeredServicesThatRisksIndependency',
    detailsHidden: !formState.isOfferedServicesThatRisksIndependency,
  }),

  formBuilder.boolean({
    accessor: 'isThreatsThatRisksAuditing',
    options: ['no', 'yes'],
    detailsAccessor: 'threatsThatRisksAuditing',
    detailsHidden: !formState.isThreatsThatRisksAuditing,
  }),

  formBuilder.boolean({
    accessor: 'isConfirmedThatDataIsCorrect',
  }),

  formBuilder.boolean({
    accessor: 'isDetectedMattersThatRisksIndependency',
    options: ['notDetected', 'detected'],
    detailsAccessor: 'detectedMattersThatRisksIndependency',
    detailsHidden: !formState.isDetectedMattersThatRisksIndependency,
  }),
];

const sectionDefinition: AuditingSectionDefinition<SectionFormType> = {
  sectionKey,
  defaultFormState,
  formFields,
};

export default sectionDefinition;
