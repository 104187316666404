import { observer } from 'mobx-react-lite';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useStore } from '../../store/RootContext';
import { isValidBusinessId } from '../../utils';
import Divider from '../Divider';
import { Button, Label, TextInput } from '../inputs';

interface CompanyFormProps {
  company: Partial<Company>;
  onSubmit: (formData: Omit<Company, 'id'>) => void;
  disabledFields?: (keyof Omit<Company, 'id'>)[];
  children?: React.ReactNode;
}

const CompanyForm: React.FC<CompanyFormProps> = observer(
  ({ company, onSubmit, disabledFields, children }) => {
    const { t } = useTranslation(['common', 'action']);

    const {
      commonStore: { fetchFromYtj },
    } = useStore();

    const [name, setName] = useState(company.name ?? '');
    const [businessId, setBusinessId] = useState(company.businessId ?? '');
    const [streetAddress, setStreetAddress] = useState(
      company.streetAddress ?? ''
    );
    const [postalCode, setPostalCode] = useState(company.postalCode ?? '');
    const [city, setCity] = useState(company.city ?? '');
    const [country, setCountry] = useState(company.country ?? '');
    const [industry, setIndustry] = useState(company.industry ?? '');
    const [contactName, setContactName] = useState(company.contactName ?? '');
    const [phone, setPhone] = useState(company.contactPhone ?? '');
    const [email, setEmail] = useState(company.contactEmail ?? '');

    const [ytjCompany, setYtjCompany] = useState<YtjCompany>();
    const ytjBusinessIdRef = useRef<string>();

    const collectFormData = (): Omit<Company, 'id'> => ({
      name,
      businessId,
      streetAddress,
      postalCode,
      city,
      country,
      industry,
      contactName: contactName,
      contactPhone: phone,
      contactEmail: email,
    });

    // Initialize form data whenever company changes
    useEffect(() => {
      setName(company.name ?? '');
      setBusinessId(company.businessId ?? '');
      setStreetAddress(company.streetAddress ?? '');
      setPostalCode(company.postalCode ?? '');
      setCity(company.city ?? '');
      setCountry(company.country ?? '');
      setIndustry(company.industry ?? '');
      setContactName(company.contactName ?? '');
      setPhone(company.contactPhone ?? '');
      setEmail(company.contactEmail ?? '');
    }, [company]);

    /**
     * Sets new value, if current value is empty
     *
     * @param setter setState action
     * @param currentValue current state value
     * @param newValue (possible) new state value
     */
    function replaceEmptyState<T extends string | number | undefined>(
      setter: React.Dispatch<React.SetStateAction<T>>,
      currentValue: T,
      newValue?: T
    ) {
      const isString = typeof currentValue === 'string';
      const isEmpty = isString ? !currentValue.trim().length : !currentValue;
      if (isEmpty && newValue) setter(newValue);
    }

    /**
     * Fill form data from YTJ (The Business Information System)
     */
    const fillFormWithYtjData = useCallback(
      (ytj: YtjCompany) => {
        replaceEmptyState<string>(setName, name, ytj.name);
        replaceEmptyState<string>(
          setStreetAddress,
          streetAddress,
          ytj.streetAddress
        );
        replaceEmptyState<string>(setPostalCode, postalCode, ytj.postalCode);
        replaceEmptyState<string>(setCity, city, ytj.city);
        replaceEmptyState<string>(setCountry, country, ytj.country);
        replaceEmptyState<string>(setIndustry, industry, ytj.industry.name);
        replaceEmptyState<string>(setContactName, contactName, ytj.contactName);
        replaceEmptyState<string>(setPhone, phone, ytj.contactPhone);
        replaceEmptyState<string>(setEmail, email, ytj.contactEmail);
      },
      [
        city,
        contactName,
        country,
        industry,
        email,
        name,
        phone,
        postalCode,
        streetAddress,
      ]
    );

    // Fill empty fields from YTJ data whenever YTJ company changes
    useEffect(() => {
      const ytjCompanyChanged =
        ytjBusinessIdRef.current !== ytjCompany?.businessId;

      if (ytjCompany && ytjCompanyChanged) {
        ytjBusinessIdRef.current = ytjCompany.businessId;
        fillFormWithYtjData(ytjCompany);
      }
    }, [fillFormWithYtjData, ytjCompany]);

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      event.stopPropagation();
      onSubmit(collectFormData());
    };

    const handleFetchFromYtj = async () => {
      if (isValidBusinessId(businessId)) {
        if (!ytjCompany || ytjCompany.businessId !== businessId) {
          const ytjData = await fetchFromYtj({ businessId });
          if (ytjData) setYtjCompany(ytjData);
        } else {
          fillFormWithYtjData(ytjCompany);
        }
      }
    };

    return (
      <form onSubmit={handleSubmit}>
        <div className="flex-row flex-row-gap">
          <div className="flex-1">
            <TextInput
              label={t('common:label.businessId')}
              value={businessId}
              setValue={setBusinessId}
              required
              autoFocus
              disabled={disabledFields?.includes('businessId')}
              fullWidth
            />
          </div>

          <div className="flex-1">
            <Label disabled={!isValidBusinessId(businessId)}>
              {t('common:label.fetchFromYTJ')}:
            </Label>
            <Button
              text={t('action:fetchFromYTJ')}
              onClick={handleFetchFromYtj}
              variant="outlined"
              disabled={!isValidBusinessId(businessId)}
              fullWidth
            />
          </div>

          <div className="flex-1"></div>
        </div>

        <div className="flex-row flex-row-gap">
          <div className="flex-1">
            <TextInput
              label={t('common:label.name')}
              value={name}
              setValue={setName}
              required
              disabled={disabledFields?.includes('name')}
              fullWidth
            />
          </div>

          <div className="flex-1">
            <TextInput
              label={t('common:label.country')}
              value={country}
              setValue={setCountry}
              required
              disabled={disabledFields?.includes('country')}
              fullWidth
            />
          </div>

          <div className="flex-1">
            <TextInput
              label={t('common:label.industry')}
              value={industry}
              setValue={setIndustry}
              disabled={disabledFields?.includes('industry')}
              fullWidth
            />
          </div>
        </div>

        <div className="flex-row flex-row-gap">
          <div className="flex-1">
            <TextInput
              label={t('common:label.streetAddress')}
              value={streetAddress}
              setValue={setStreetAddress}
              required
              disabled={disabledFields?.includes('streetAddress')}
              fullWidth
            />
          </div>

          <div className="flex-1">
            <TextInput
              label={t('common:label.postalCode')}
              value={postalCode}
              setValue={setPostalCode}
              required
              disabled={disabledFields?.includes('postalCode')}
              fullWidth
            />
          </div>

          <div className="flex-1">
            <TextInput
              label={t('common:label.city')}
              value={city}
              setValue={setCity}
              required
              disabled={disabledFields?.includes('city')}
              fullWidth
            />
          </div>
        </div>

        <Divider size="md" />

        <div className="flex-row flex-row-gap">
          <div className="flex-1">
            <TextInput
              label={t('common:label.contactName')}
              value={contactName}
              setValue={setContactName}
              disabled={disabledFields?.includes('contactEmail')}
              fullWidth
            />
          </div>

          <div className="flex-1">
            <TextInput
              type="email"
              label={t('common:label.email')}
              value={email}
              setValue={setEmail}
              disabled={disabledFields?.includes('contactEmail')}
              fullWidth
            />
          </div>

          <div className="flex-1">
            <TextInput
              label={t('common:label.phone')}
              value={phone}
              setValue={setPhone}
              disabled={disabledFields?.includes('contactPhone')}
              fullWidth
            />
          </div>
        </div>

        {children && (
          <>
            <Divider size="md" />
            <div className="flex-row flex-row-gap">{children}</div>
          </>
        )}

        <Divider size="lg" />

        <Button text={t('action:save')} type="submit" />
      </form>
    );
  }
);

export default CompanyForm;
