import { useStore } from '../../../store/RootContext';
import { Button } from '../../../components/inputs';

export function CopyRisksData() {
  const {
    auditingStore: {
      copyIncomeStatementAndBalanceToScoping,
      isAuditingSectionFinished,
    },
  } = useStore();

  const hideButton = isAuditingSectionFinished(
    'incomeStatementAndBalanceScoping'
  );

  const handleCopyToScopingClick = () => {
    copyIncomeStatementAndBalanceToScoping();
  };

  return (
    <>
      {!hideButton && (
        <Button
          onClick={handleCopyToScopingClick}
          text="Kopio riskit kaudenaikaisesta tarkastuksesta"
        />
      )}
    </>
  );
}

export function CopyAccountMapData() {
  const {
    auditingStore: { copyAccountMapToScoping, isAuditingSectionFinished },
  } = useStore();

  const hideButton = isAuditingSectionFinished('accountMapScoping');

  return (
    <>
      {!hideButton && (
        <Button
          onClick={copyAccountMapToScoping}
          text="Kopio tilikartta tilikaudenaikaisesta tarkastusosiosta."
        />
      )}
    </>
  );
}
