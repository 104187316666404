import { uuid } from '../../../utils';

export enum InquiredIfRegulationsHaveBeenCompliedWith {
  complied = 'complied',
  notComplied = 'notComplied',
}

export enum InquiredWhetherAnyAbusesAreKnown {
  notKnown = 'notKnown',
  known = 'known',
}

export enum InquiredAbilityToContinue {
  noReasonToDoubt = 'noReasonToDoubt',
  noAssessmentHasBeenMade = 'noAssessmentHasBeenMade',
  reasonToDoubt = 'reasonToDoubt',
}

export enum DetectedEventsToDoubtAbilityToContinue {
  noReasonToDoubt = 'noReasonToDoubt',
  reasonToDoubt = 'reasonToDoubt',
}

export const makeClosureProcedure = (
  procedure: Partial<CompletedClosureProcedure>
) => ({
  id: uuid(),
  nameKey: undefined,
  customName: '',
  completed: false,
  ...procedure,
});

export const defaultClosureProcedures = (): CompletedClosureProcedure[] => {
  return [
    'formedUnderstandingOfTheProcedures',
    'inquiredIfEventsAfterFinancialStatementDate',
    'wentThroughTheMinutesOfTheMeetings',
    'readTheLatestInterimFinancialStatement',
    'wentThroughTheLatestReports',
  ].map(key => makeClosureProcedure({ nameKey: key }));
};
