import { Api } from '../RootApi';
import { ModuleBaseApi } from './ModuleBaseApi';

const URL = {
  base: 'customerCompanies',
};

export class CustomerCompanyApi extends ModuleBaseApi<CustomerCompany> {
  constructor(rootApi: Api) {
    super(rootApi, URL.base);
  }
}

// Get all customer companies
export async function getAllCustomerCompanies(
  rootApi: Api
): Promise<CustomerCompany[]> {
  const response = await new CustomerCompanyApi(rootApi).getAllEntities();
  if (response instanceof Object) {
    return response.data;
  } else {
    throw new Error('Customer companies not found');
  }
}

// Get single customer company
export async function getSingleCustomerCompany(
  rootApi: Api,
  id: string
): Promise<CustomerCompany> {
  const response = await new CustomerCompanyApi(rootApi).getOneEntity({
    id: Number(id),
  });
  if (response instanceof Object) {
    return response.data;
  } else {
    throw new Error('Customer company not found');
  }
}
