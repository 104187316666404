import { displayAuditingProcedures } from '../../../components/auditing/AuditingProcedureItem2';
import AuditingJson from '../../../i18n/locales/fi/auditing.json';
import { FormFieldBuilder } from '../FormFieldBuilder';
import {
  ManagementTable,
  ManagementTableTwo,
  ReviewOfContractsTable,
} from './managementUtils';

type SectionFormType = ManagementForm;

const sectionKey: AuditingSectionKey = 'management';
//const batch = FinancialStatementBatch['G-management'];
const batchString = 'G-management';
const vocab = AuditingJson.form.management;

export const tBase = `auditing:form.${sectionKey}`;

const formBuilder = new FormFieldBuilder<SectionFormType>({ sectionKey });

const defaultFormState: DefaultFormState<SectionFormType> = () => {
  return {
    // publicNetworkDocuments
    municipalStrategyUpToDate: null,
    municipalStrategy: '',
    municipalStrategySource: '',
    administrativeRule: '',
    administrativeRuleSource: '',
    administrativeRuleUpToDate: null,
    budget: '',
    budgetSource: '',
    budgetUpToDate: null,
    financialStatement: '',
    financialStatementSource: '',
    financialStatementUpToDate: null,
    auditBoardEvaluationReport: '',
    auditBoardEvaluationReportSource: '',
    auditBoardEvaluationReportUpToDate: null,
    auditReport: '',
    auditReportSource: '',
    auditReportUpToDate: null,
    municipalCooperationAgreements: '',
    municipalCooperationAgreementsSource: '',
    municipalCooperationAgreementsUpToDate: null,
    groupInstruction: '',
    groupInstructionReportource: '',
    groupInstructionReportUpToDate: null,
    boardCommitments: '',
    boardCommitmentsSource: '',
    boardCommitmentsUpToDate: null,
    boardRewardJustifications: '',
    boardRewardJustificationsSource: '',
    boardRewardJustificationsUpToDate: null,
    serviceFees: '',
    serviceFeesSource: '',
    serviceFeesUpToDate: null,

    programmeOfMeasures: '',
    programmeOfMeasuresSource: '',
    programmeOfMeasuresUpToDate: null,

    administrativeRuleMeetsRequirements: '',
    administrativeRuleMeetsRequirementsSource: '',
    administrativeRuleMeetsRequirementsUpToDate: null,

    // budget
    budgetAndMunicipalStrategyInLine: '',
    budgetAndMunicipalStrategyInLineSource: '',
    budgetAndMunicipalStrategyInLineUpToDate: null,
    isBudgetRealistic: '',
    isBudgetRealisticSource: '',
    isBudgetRealisticUpToDate: null,
    budgetAndChangesProperlyApproved: '',
    budgetAndChangesProperlyApprovedSource: '',
    budgetAndChangesProperlyApprovedUpToDate: null,

    // internalInstructions
    councilGuidelinesForInternalInstructions: '',
    councilGuidelinesForInternalInstructionsSource: '',
    councilGuidelinesForInternalInstructionsUpToDate: null,
    internalInstructionsMeetCouncilGuidelines: '',
    internalInstructionsMeetCouncilGuidelinesSource: '',
    internalInstructionsMeetCouncilGuidelinesUpToDate: null,
    internalInstructionsUpToDate: '',
    internalInstructionsUpToDateSource: '',
    internalInstructionsUpToDateUpToDate: null,
    currentPracticesAreValid: '',
    currentPracticesAreValidSource: '',
    currentPracticesAreValidUpToDate: null,

    // groupInstruction
    planningOfBudgetAndInvestments: '',
    planningOfBudgetAndInvestmentsSource: '',
    planningOfBudgetAndInvestmentsUpToDate: null,
    groupSupervisionAndReporting: '',
    groupSupervisionAndReportingSource: '',
    groupSupervisionAndReportingUpToDate: null,
    informingAndInformationAccess: '',
    informingAndInformationAccessSource: '',
    informingAndInformationAccessUpToDate: null,
    obligationToGetMunicipalitysOpinionBeforeDecisions: '',
    obligationToGetMunicipalitysOpinionBeforeDecisionsSource: '',
    obligationToGetMunicipalitysOpinionBeforeDecisionsUpToDate: null,
    groupsInternalServices: '',
    groupsInternalServicesSource: '',
    groupsInternalServicesUpToDate: null,
    subsidiaryBoardCompositionAndAppointment: '',
    subsidiaryBoardCompositionAndAppointmentSource: '',
    subsidiaryBoardCompositionAndAppointmentUpToDate: null,
    subsidiaryGoodManagementAndLeadership: '',
    subsidiaryGoodManagementAndLeadershipSource: '',
    subsidiaryGoodManagementAndLeadershipUpToDate: null,

    // boardMeetingMinutes
    boardMeetingMinutesChecked: null,

    // councilMeetingMinutes
    councilMeetingMinutesChecked: null,
    previousYearFinancialStatementConfirmed: '',
    previousYearFinancialStatementConfirmedSource: '',
    previousYearFinancialStatementConfirmedUpToDate: null,
    nextYearBudgetApproved: '',
    nextYearBudgetApprovedSource: '',
    nextYearBudgetApprovedUpToDate: null,
    handlingOfBudgetChanges: '',
    handlingOfBudgetChangesSource: '',
    handlingOfBudgetChangesUpToDate: null,
    isBudgetReviewedEveryQuarter: '',
    isBudgetReviewedEveryQuarterSource: '',
    isBudgetReviewedEveryQuarterUpToDate: null,
    affiliationNotices: '',
    affiliationNoticesSource: '',
    affiliationNoticesUpToDate: null,
    evaluationReport: '',
    evaluationReportSource: '',
    evaluationReportUpToDate: null,

    // reviewOfPriorityBoards
    reviewOfPriorityBoardsNotes: [],
    reviewOfPriorityBoardsChecked: null,

    // reviewOfPriorityOfficials
    reviewOfPriorityOfficialsNotes: [],
    reviewOfPriorityOfficialsChecked: null,

    // tables
    municipalStrategyDetails: [],
    administrativeRuleDetails: [],
    internalInstructionsDetails: [],
    groupInstructionDetails: [],
    councilMeetingMinutesNotes: [],
    boardMeetingMinutesNotes: [],

    // overall assessment
    overallAssessmentComments: '',

    tradeRegister: '',
    tradeRegisterNo: '',
    tradeRegisterComments: '',

    generalMeeting: '',
    generalMeetingNo: 'kuvaus, milloin pidetty?',
    generalMeetingComments: '',

    ordinaryGeneralMeeting: '',
    ordinaryGeneralMeetingNo: '',
    ordinaryGeneralMeetingComments: '',
    generalMeetingHasLeft: '',

    theArticlesOfAssociation: '',
    theArticlesOfAssociationComment: '',
    theArticlesOfAssociationYes: '',
    theArticlesOfAssociationNo:
      'Anna lisätietoja ja kuvaa, miltä osin yhtiöjärjestyksen vaatimuksia ei ole noudatettu',

    proceedings: '',
    proceedingsComments:
      'Anna lisätietoja ja kuvaa, miltä osin yhtiöjärjestyksen vaatimuksia ei ole noudatettu',
    moreProceedings: '',
    moreProceedingsComments: 'Anna lisätietoja',

    reviewOfMinutes: '',
    reviewOfMinutesReference:
      'viittaa tiedostoon tai kopio esimerksi tiedoston Sharepoint linkki / internet osoite',
    reviewOfMinutesComments: '',
    reviewOfMinutesMoreComments: '',
    reviewOfMinutesData: [
      {
        id: '1',
        pvm: '',
        kokous: '',
        keskeiset: '',
      },
    ],

    generalMeetingHasDecided: '',
    generalMeetingHasDecidedNo: '',
    generalMeetingHasDecidedComments: 'Anna lisätietoja',

    proceedingsTwo: '',
    proceedingsTwoComments: 'Anna lisätietoja',
    moreProceedingsTwo: '',
    moreProceedingsTwoComments: 'Anna lisätietoja',

    reviewOfMinutesTwo: '',
    reviewOfMinutesReferenceTwo:
      'viittaa tiedostoon tai kopio esimerksi tiedoston Sharepoint linkki / internet osoite',
    reviewOfMinutesCommentsTwo: '',
    reviewOfMinutesDataTwo: [
      {
        id: '1',
        pvm: '',
        kokous: '',
        keskeiset: '',
      },
    ],
    reviewOfMinutesMoreCommentsTwo: '',

    theBoardHasImplemented: '',
    theBoardHasImplementedComments: '',

    theBoardHasDecided: '',
    theBoardHasDecidedNo: '',
    theBoardHasDecidedComments: '',
    theBoardHasDecidedMore: '',

    theCompanyHasValid: '',
    theCompanyHasValidComments: '',
    theCompanyHasValidNo: '',
    theComapanyHasValidNoComments: '',

    reviewOfContractsBoolean: null,
    reviewOfContracts: '',
    reviewOfContractsInfo:
      'yhtiöllä on:\n\n-vuokrasopimuksia, jotka on käyty läpi ja dokumentoitu KB tarkastusosiossa\n\n- lainasopimuksia, jotka on käyty läpi ja dokumentoitu S1 lainojen tarkastusosiossa\n\n- pääomalainasopimuksia, jotka on dokumentoitu R2 osioo\n\n- Lainasaamisia, joiden sopimukset on dokumentoitu saamisten tarkastusosioon Q3',
    reviewOfContractsComments: '',
    reviewOfContractsData: [
      {
        id: '1',
        sopimus: '',
        kuvaus: '',
        viite: '',
      },
    ],

    basedOnTheManagement: '',
    basedOnTheInspection: '',
    basedOntTheManagementNo: '',
    basedOntTheInspectionNo: '',
    basedOnTheManagementComments: '',
    basedOnTheInspectionComments: '',
  };
};

const formFields: FormFields<SectionFormType> = ({
  store,
  auditing,
  formState,
}) => [
  formBuilder.group({
    items: [
      formBuilder.text({ text: vocab.administrationAudit }),
      formBuilder.text({ text: vocab.administrationAudit1 }),
      formBuilder.text({ text: vocab.administrationAudit2 }),
      formBuilder.text({ text: vocab.administrationAudit3 }),
    ],
  }),
  formBuilder.group({
    items: [
      formBuilder.subtitle({ title: 'Johtopäätökset' }),
      formBuilder.checkboxGroup({
        accessor: 'basedOnTheManagement',
        options: Object.keys(vocab.basedOnTheManagementOptions),
      }),
      ...(formState.basedOnTheManagement?.two
        ? [
            formBuilder.textArea({
              accessor: 'basedOntTheManagementNo',
              plainLabel: 'Lisätietoa',
            }),
          ]
        : []),
      ...(formState.basedOnTheManagement?.three
        ? [
            formBuilder.textArea({
              accessor: 'basedOnTheManagementComments',
              plainLabel: 'Kommentti',
            }),
          ]
        : []),
      formBuilder.checkboxGroup({
        accessor: 'basedOnTheInspection',
        options: Object.keys(vocab.basedOnTheInspectionOptions),
      }),
      ...(formState.basedOnTheInspection?.two
        ? [
            formBuilder.textArea({
              accessor: 'basedOntTheInspectionNo',
              plainLabel: 'Lisätietoa',
            }),
          ]
        : []),
      ...(formState.basedOnTheInspection?.three
        ? [
            formBuilder.textArea({
              accessor: 'basedOnTheInspectionComments',
              plainLabel: 'Kommentti',
            }),
          ]
        : []),
    ],
  }),

  ...displayAuditingProcedures(store, batchString, formBuilder, [
    {
      // G4.1 Kaupparekisteritiedot
      accordionId: 'internalInstructions',
      hide: ['auditingReference', 'auditingProcedureGoals'],
      upperElements: [
        formBuilder.checkboxGroup({
          accessor: 'tradeRegister',
          options: Object.keys(vocab.tradeRegisterOptions),
        }),
        formBuilder.text({
          text: 'Perustiedot yhteisöstä (mm. kaupparekisteritiedot, yhtiöjärjestys, osakassopimukset, muiden rekisterin tiedot) ja sen kannalta relevanteista säädöksistä on dokumentoi kohtaan "yhteisön perustiedot ja tuntemistiedot". Rahanpesulain edellyttämät tiedot on dokumentoitu osioon "asiakkaan todentaminen ja riskiarvion dokumentoiti". Sisäinen valvota ja riskien hallinta on dokumentoitu osiohin "sisäinen valvonta ja toimintaympäristä ja "riskit"',
        }),
        ...(formState.tradeRegister?.two
          ? [
              formBuilder.textArea({
                accessor: 'tradeRegisterNo',
                plainLabel: 'Lisätietoa',
              }),
            ]
          : []),
        ...(formState.tradeRegister?.three
          ? [
              formBuilder.textArea({
                accessor: 'tradeRegisterComments',
                plainLabel: 'Kommentti',
              }),
            ]
          : []),
      ],
    },
    {
      accordionId: 'groupInstruction',
      hide: ['auditingReference', 'auditingProcedureGoals', 'auditingComments'],
      lowerElements: [
        formBuilder.group({
          items: [
            formBuilder.checkboxGroup({
              accessor: 'generalMeeting',
              options: Object.keys(vocab.generalMeetingOptions),
            }),
            ...(formState.generalMeeting?.two
              ? [
                  formBuilder.textArea({
                    accessor: 'generalMeetingNo',
                    plainLabel: 'Lisätietoa',
                  }),
                ]
              : []),
            ...(formState.generalMeeting?.three
              ? [
                  formBuilder.textArea({
                    accessor: 'generalMeetingComments',
                    plainLabel: 'Kommentti',
                  }),
                ]
              : []),

            formBuilder.checkboxGroup({
              accessor: 'ordinaryGeneralMeeting',
              options: Object.keys(vocab.ordinaryGeneralMeetingOptions),
            }),
            ...(formState.ordinaryGeneralMeeting?.two
              ? [
                  formBuilder.checkboxGroup({
                    accessor: 'generalMeetingHasLeft',
                    options: Object.keys(vocab.generalMeetingHasLeftOptions),
                  }),
                ]
              : []),
            ...(formState.ordinaryGeneralMeeting?.three
              ? [
                  formBuilder.textArea({
                    accessor: 'ordinaryGeneralMeetingComments',
                    plainLabel: 'Kommentti',
                  }),
                ]
              : []),

            formBuilder.checkboxGroup({
              accessor: 'theArticlesOfAssociation',
              options: Object.keys(vocab.theArticlesOfAssociationOptions),
            }),
            ...(formState.theArticlesOfAssociation?.one
              ? [
                  formBuilder.checkboxGroup({
                    accessor: 'theArticlesOfAssociationYes',
                    options: Object.keys(
                      vocab.theArticlesOfAssociationYesOptions
                    ),
                  }),
                ]
              : []),
            ...(formState.theArticlesOfAssociationYes?.two
              ? [
                  formBuilder.textArea({
                    accessor: 'theArticlesOfAssociationNo',
                    plainLabel: 'Lisätietoa',
                  }),
                ]
              : []),
            ...(formState.theArticlesOfAssociationYes?.three
              ? [
                  formBuilder.textArea({
                    accessor: 'theArticlesOfAssociationComment',
                    plainLabel: 'Kommentti',
                  }),
                ]
              : []),

            formBuilder.checkboxGroup({
              accessor: 'proceedings',
              options: Object.keys(vocab.proceedingsOptions),
            }),
            ...(formState.proceedings?.two
              ? [
                  formBuilder.textArea({
                    accessor: 'proceedingsComments',
                    plainLabel: 'Lisätietoa',
                  }),
                ]
              : []),
            formBuilder.checkboxGroup({
              accessor: 'moreProceedings',
              options: Object.keys(vocab.moreProceedingsOptions),
            }),
            ...(formState.moreProceedings?.two
              ? [
                  formBuilder.textArea({
                    accessor: 'moreProceedingsComments',
                    plainLabel: 'Lisätietoa',
                  }),
                ]
              : []),

            formBuilder.checkboxGroup({
              accessor: 'reviewOfMinutes',
              options: Object.keys(vocab.reviewOfMinutesOptions),
            }),
            ...(formState.reviewOfMinutes?.two
              ? [
                  formBuilder.textArea({
                    accessor: 'reviewOfMinutesComments',
                    plainLabel: 'Kommentti',
                  }),
                ]
              : []),
            ...(formState.reviewOfMinutes?.three
              ? [
                  formBuilder.textArea({
                    accessor: 'reviewOfMinutesReference',
                    plainLabel: 'Viittaus',
                  }),
                ]
              : []),
            ...(formState.reviewOfMinutes?.four
              ? [formBuilder.custom(props => <ManagementTable {...props} />)]
              : []),
            ...(formState.reviewOfMinutes?.five
              ? [
                  formBuilder.textArea({
                    accessor: 'reviewOfMinutesMoreComments',
                    plainLabel: 'Kommentti',
                  }),
                ]
              : []),

            formBuilder.checkboxGroup({
              accessor: 'generalMeetingHasDecided',
              options: Object.keys(vocab.generalMeetingHasDecidedOptions),
            }),
            ...(formState.generalMeetingHasDecided?.two
              ? [
                  formBuilder.checkboxGroup({
                    accessor: 'generalMeetingHasDecidedNo',
                    options: Object.keys(
                      vocab.generalMeetingHasDecidedNoOptions
                    ),
                  }),
                  formBuilder.textArea({
                    accessor: 'generalMeetingHasDecidedComments',
                    plainLabel: 'Lisätietoa',
                  }),
                ]
              : []),
          ],
        }),
      ],
    },
    {
      accordionId: 'boardMeetingMinutes',
      hide: ['auditingReference', 'auditingProcedureGoals', 'auditingComments'],
      lowerElements: [
        formBuilder.group({
          items: [
            formBuilder.checkboxGroup({
              accessor: 'proceedingsTwo',
              options: Object.keys(vocab.proceedingsTwoOptions),
            }),
            ...(formState.proceedingsTwo?.two
              ? [
                  formBuilder.textArea({
                    accessor: 'proceedingsTwoComments',
                    plainLabel: 'Lisätietoa',
                  }),
                ]
              : []),
            formBuilder.checkboxGroup({
              accessor: 'moreProceedingsTwo',
              options: Object.keys(vocab.moreProceedingsTwoOptions),
            }),
            ...(formState.moreProceedingsTwo?.two
              ? [
                  formBuilder.textArea({
                    accessor: 'moreProceedingsTwoComments',
                    plainLabel: 'Lisätietoa',
                  }),
                ]
              : []),

            formBuilder.checkboxGroup({
              accessor: 'reviewOfMinutesTwo',
              options: Object.keys(vocab.reviewOfMinutesTwoOptions),
            }),
            ...(formState.reviewOfMinutesTwo?.two
              ? [
                  formBuilder.textArea({
                    accessor: 'reviewOfMinutesCommentsTwo',
                    plainLabel: 'Kommentti',
                  }),
                ]
              : []),
            ...(formState.reviewOfMinutesTwo?.three
              ? [
                  formBuilder.textArea({
                    accessor: 'reviewOfMinutesReferenceTwo',
                    plainLabel: 'Lisätietoa',
                  }),
                ]
              : []),
            ...(formState.reviewOfMinutesTwo?.four
              ? [formBuilder.custom(props => <ManagementTableTwo {...props} />)]
              : []),
            ...(formState.reviewOfMinutesTwo?.five
              ? [
                  formBuilder.textArea({
                    accessor: 'reviewOfMinutesMoreCommentsTwo',
                    plainLabel: 'Kommentti',
                  }),
                ]
              : []),

            formBuilder.checkboxGroup({
              accessor: 'theBoardHasImplemented',
              options: Object.keys(vocab.theBoardHasImplementedOptions),
            }),
            ...(formState.theBoardHasImplemented?.four
              ? [
                  formBuilder.textArea({
                    accessor: 'theBoardHasImplementedComments',
                    plainLabel: 'Lisätietoa',
                  }),
                ]
              : []),

            formBuilder.checkboxGroup({
              accessor: 'theBoardHasDecided',
              options: Object.keys(vocab.theBoardHasDecidedOptions),
              showContextInfo: 'bottom-right',
            }),
            ...(formState.theBoardHasDecided?.two
              ? [
                  formBuilder.checkboxGroup({
                    accessor: 'theBoardHasDecidedNo',
                    options: Object.keys(vocab.theBoardHasDecidedNoOptions),
                  }),
                  formBuilder.textArea({
                    accessor: 'theBoardHasDecidedComments',
                    plainLabel: 'Lisätietoa',
                  }),
                ]
              : []),
            ...(formState.theBoardHasDecided?.three
              ? [
                  formBuilder.textArea({
                    accessor: 'theBoardHasDecidedMore',
                    plainLabel: 'Kommentti',
                  }),
                ]
              : []),
          ],
        }),
      ],
    },
    {
      accordionId: 'insurancePolicies',
      hide: ['auditingReference', 'auditingProcedureGoals', 'auditingComments'],
      lowerElements: [
        formBuilder.checkboxGroup({
          accessor: 'theCompanyHasValid',
          options: Object.keys(vocab.theCompanyHasValidOptions),
        }),
        ...(formState.theCompanyHasValid?.three
          ? [
              formBuilder.textArea({
                accessor: 'theCompanyHasValidComments',
                plainLabel: 'Kommentti',
              }),
            ]
          : []),
        ...(formState.theCompanyHasValid?.two
          ? [
              formBuilder.checkboxGroup({
                accessor: 'theCompanyHasValidNo',
                options: Object.keys(vocab.theCompanyHasValidNoOptions),
              }),
              formBuilder.textArea({
                accessor: 'theComapanyHasValidNoComments',
                plainLabel: 'Lisätietoa',
              }),
            ]
          : []),
      ],
    },
    {
      accordionId: 'affiliationRegister',
      hide: ['auditingReference', 'auditingProcedureGoals', 'auditingComments'],
      lowerElements: [
        formBuilder.group({
          items: [
            formBuilder.boolean({
              accessor: 'reviewOfContractsBoolean',
              plainLabel: ' ',
              showContextInfo: 'bottom-right',
            }),
            formBuilder.checkboxGroup({
              accessor: 'reviewOfContracts',
              options: Object.keys(vocab.reviewOfContractsOptions),
            }),
            ...(formState.reviewOfContracts?.one
              ? [
                  formBuilder.custom(props => (
                    <ReviewOfContractsTable {...props} />
                  )),
                ]
              : []),
            ...(formState.reviewOfContracts?.two
              ? [
                  formBuilder.textArea({
                    accessor: 'reviewOfContractsInfo',
                    plainLabel: 'Lisätietoa',
                  }),
                ]
              : []),
            ...(formState.reviewOfContracts?.three
              ? [
                  formBuilder.textArea({
                    accessor: 'reviewOfContractsComments',
                    plainLabel: 'Kommentti',
                  }),
                ]
              : []),
          ],
        }),
      ],
    },
  ]),

  formBuilder.accordionGroup({
    titleKey: 'overallAssessment',
    items: [
      formBuilder.textArea({
        accessor: 'overallAssessmentComments',
        showContextInfo: 'top-right',
      }),
    ],
  }),
];

const section: AuditingSectionDefinition<SectionFormType> = {
  sectionKey,
  defaultFormState,
  formFields,
  //depending: ['detailedPlan'],
};

export default section;
