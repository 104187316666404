export const round = (value: number, precision = 100) => {
  // 1000 = 3 decimals / 100 = 2 decimals / etc.
  return Math.round((value + Number.EPSILON) * precision) / precision;
};

export const getPercent = (value?: number | null, percent?: number | null) => {
  return value !== undefined && value !== null
    ? round(value * ((percent ?? 0) / 100))
    : undefined;
};

export const isInRange = (
  number: number,
  range: [number | undefined, number | undefined]
) => {
  const [min, max] = range;
  return (
    min !== undefined && number >= min && max !== undefined && number <= max
  );
};

export const divide = (
  dividend: number | undefined,
  divisor: number | undefined,
) => {
  return dividend !== undefined && divisor !== undefined && divisor !== 0
    ? dividend / divisor
    : 0;
};
