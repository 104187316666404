import { Breakpoints } from './theme.breakpoints';

export const fontSize = (sm: number, md: number) => {
  return `
    font-size: ${md}rem;
    @media (max-width: ${Breakpoints.Mobile}px) {
      font-size: ${sm}rem;
    }
  `;
};

export const headingSize = (level: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6') => {
  switch (level) {
    case 'h1':
      return fontSize(2, 2.375);
    case 'h2':
      return fontSize(1.75, 2);
    case 'h3':
      return fontSize(1.5, 1.75);
    case 'h4':
      return fontSize(1.25, 1.5);
    case 'h5':
      return fontSize(1.125, 1.25);
    case 'h6':
      return fontSize(1, 1.125);
  }
};
