import styled from 'styled-components';

interface Props {
  noBorder?: boolean;
  size?: 'sm' | 'md' | 'lg' | 'xl' | 'xxl';
}

const Divider = styled.hr<Props>`
  margin: ${p => p.theme.spacing[p.size ?? 'md']} 0;
  border-width: ${p => (p.noBorder ? 0 : 1)}px;
  border-color: ${p => p.theme.color.grey100};
  border-style: solid;
`;

export default Divider;
