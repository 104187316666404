import { changeLanguage } from 'i18next';
import { makeAutoObservable, observable, runInAction } from 'mobx';
import { Api } from '../../api/RootApi';
import { t } from '../../i18n';
import { DEFAULT_LANGUAGE, getWindowDimensions, uuid } from '../../utils';
import { RootStore } from '../RootStore';

export class AppStore {
  rootStore: RootStore;
  api: Api;

  /**
   * Observables
   */
  language: LanguageCode = DEFAULT_LANGUAGE;
  windowDimensions = getWindowDimensions();
  pendingTasks: PendingTask[] = [];
  componentStates: ComponentStates = {};

  constructor(rootStore: RootStore, api: Api, language?: LanguageCode) {
    makeAutoObservable(this, {
      rootStore: false,
      api: false,
      windowDimensions: observable.struct,
    });

    this.api = api;
    this.rootStore = rootStore;
    this.setLanguage(language);

    window.onresize = () => {
      runInAction(() => {
        this.windowDimensions = getWindowDimensions();
      });
    };
  }

  /**
   * Setters
   */
  setLanguage = (language: LanguageCode = DEFAULT_LANGUAGE) => {
    this.language = language;
    changeLanguage(language);
  };

  addPendingTask = (
    taskType: PendingTaskType,
    taskName?: PendingTaskName,
    isSilent?: boolean
  ) => {
    const notificationKey = taskType.split('-')[0];
    const message = t(`notification:${notificationKey}.pending`);

    const taskId = isSilent
      ? uuid()
      : this.rootStore.notificationStore.pendingNotification(message);

    this.pendingTasks.push({
      id: taskId,
      name: taskName,
      type: taskType,
    });
    return taskId;
  };

  finishPendingTask = (
    taskId: PendingTaskId,
    taskResult: PendingTaskResult
  ) => {
    const task = this.pendingTasks.find(({ id }) => id === taskId);
    this.pendingTasks = this.pendingTasks.filter(({ id }) => id !== taskId);

    const notificationKey = task?.type.split('-')[0] ?? 'processing';
    const message = t(`notification:${notificationKey}.${taskResult}`);

    return this.rootStore.notificationStore.finishPendingNotification(
      message,
      taskId,
      taskResult
    );
  };

  setComponentState = (state: ComponentState) => {
    this.componentStates = { ...this.componentStates, [state.key]: state };
  };

  /**
   * Computeds
   */
  private tasksOfType = (...types: PendingTaskType[]) =>
    this.pendingTasks.filter(({ type }) => types.includes(type));

  taskAlreadyPending = (taskName?: PendingTaskName) =>
    !!taskName && !!this.pendingTasks.find(({ name }) => name === taskName);

  get appState() {
    return {
      isProcessing: !!this.tasksOfType('processing', 'login', 'logout').length,
      isFetching: !!this.tasksOfType('fetching').length,
      isSaving: !!this.tasksOfType('saving').length,
    };
  }

  getComponentState = (key: string) => {
    return this.componentStates[key];
  };

  /**
   * Actions
   */
}
