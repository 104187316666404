import { AuditingProcedureResult } from '../auditing/auditingProceduresUtils';

const hasShortcomings = ({ auditingResult }: AuditingProcedure) =>
  auditingResult &&
  [AuditingProcedureResult.shortcomings].includes(auditingResult);

const fixedFilter = (row: AuditingProcedure) =>
  hasShortcomings(row) && row.fixed;

const notFixedFilter = (row: AuditingProcedure) =>
  hasShortcomings(row) && !row.fixed;

const notStartedFilter = (row: AuditingProcedure) =>
  [
    null,
    AuditingProcedureResult.notStarted,
    AuditingProcedureResult.noEvidenceReceived,
  ].includes(row.auditingResult);

export const filterAuditingProcedures = (
  data: AuditingProcedure[],
  type: ErrorsImpactType
) => {
  switch (type) {
    case 'fixed':
      return data.filter(fixedFilter);
    case 'notFixed':
      return data.filter(notFixedFilter);
    case 'notStarted':
      return data.filter(notStartedFilter);
    default:
      return data;
  }
};
