import { DateTime } from 'luxon';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Divider from '../components/Divider';
import {
  Button,
  Checkbox,
  Radio,
  Select,
  TextInput,
} from '../components/inputs';
import Wrapper from '../components/layout/Wrapper';
import { useStore } from '../store/RootContext';
import { sortCompanies, useFetchCustomerCompanies } from '../utils';
import { AuditingTemplate, financialYear } from '../utils/auditing';

const NEW_AUDITING_FORM = {
  startDate: DateTime.local().minus({ year: 1 }).startOf('year').toISO(),
  endDate: DateTime.local().minus({ year: 1 }).endOf('year').toISO(),
  template: AuditingTemplate.private,
  copyPreviousAuditing: true,
};

enum AuditingOption {
  select = 'select_existing_auditing',
  create = 'create_new_auditing',
}

export const AuditingSelection: React.FC = observer(() => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const {
    authStore: { userAuditFirmId },
    customerCompanyStore,
    auditingStore,
    notificationStore: { notify },
  } = useStore();

  const [customerCompany, setCustomerCompany] = useState<
    CustomerCompany | undefined
  >(auditingStore.selectedEntity?.customerCompany);

  const [auditingOption, setAuditingOption] = useState<AuditingOption>(
    AuditingOption.select
  );

  const [auditing, setAuditing] = useState<Auditing | undefined>(
    auditingStore.selectedEntity
  );

  const [newAuditing, setNewAuditing] = useState(NEW_AUDITING_FORM);

  const isCurrentAuditorOfCompany =
    customerCompany?.auditFirmId === userAuditFirmId;

  const selectingAuditing = auditingOption === AuditingOption.select;
  const creatingAuditing = auditingOption === AuditingOption.create;

  const companyIdRef = useRef<number>();

  useFetchCustomerCompanies();

  // Initialize auditing whenever company changes
  useEffect(() => {
    const companyChangedAndNotAuditingInStore =
      companyIdRef.current !== customerCompany?.id &&
      !auditingStore.selectedEntity;

    const notMatchingStoreCompany =
      customerCompany?.id !== auditingStore.selectedEntity?.customerCompany?.id;

    if (companyChangedAndNotAuditingInStore || notMatchingStoreCompany) {
      companyIdRef.current = customerCompany?.id;
      const auditings = customerCompanyStore.getAuditings(customerCompany?.id);
      setAuditing(auditings.length ? auditings[0] : undefined);
    }
  }, [auditingStore.selectedEntity, customerCompany, customerCompanyStore]);

  const auditingFrontPage = (auditingId: number) =>
    auditingStore.getLinkToAuditingSection({
      sectionKey: 'auditingFrontPage',
      auditingId,
    });

  const handleSelectAuditing = () => {
    if (auditing) {
      // Clear previous auditing entity from store before opening a new one
      auditingStore.setEntity(undefined);
      navigate(auditingFrontPage(auditing.id));
    }
  };

  const handleCreateAuditing = async () => {
    if (customerCompany && newAuditing && userAuditFirmId) {
      const auditing: Omit<Auditing, 'id'> = {
        ...newAuditing,
        status: 'started',
        customerCompanyId: customerCompany.id,
        auditFirmId: userAuditFirmId,
      };

      const { success, data } = await auditingStore.addEntity({
        data: auditing,
      });

      await customerCompanyStore.getEntities();

      if (success && data) {
        navigate(auditingFrontPage(data.id));
      }
    } else {
      notify(t('notification:error.createAuditing'), 'error');
    }
  };

  const handleAuditingDateChange =
    (target: 'startDate' | 'endDate') =>
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const newDate = event.currentTarget.value;
      setNewAuditing(prev => ({
        ...prev,
        endDate: target === 'endDate' ? newDate : prev.endDate,
        startDate: target === 'startDate' ? newDate : prev.startDate,
      }));
    };

  const handleAuditingTemplateChange = (
    template: React.SetStateAction<AuditingTemplate | undefined>
  ) => {
    if (template && typeof template === 'string') {
      setNewAuditing(prev => ({
        ...prev,
        template,
      }));
    }
  };

  const getAuditingDisplayValue = (auditing?: Auditing) => {
    const status =
      auditing?.status === 'started'
        ? ` (${t(`auditing:status.${auditing?.status}`)})`
        : '';
    return `${financialYear(auditing)}${status}`;
  };

  return (
    <Wrapper width="sm">
      <Select<CustomerCompany>
        autocomplete
        label={t('common:label.customerCompany')}
        options={customerCompanyStore.allEntities?.sort(sortCompanies) ?? []}
        value={customerCompany}
        setValue={setCustomerCompany}
        displayValue={(company?: CustomerCompany) => company?.name ?? ''}
        fullWidth
      />

      <Divider noBorder size="md" />

      <Radio
        value="select_existing_auditing"
        setValue={setAuditingOption}
        label={t('auditing:chooseFinancialYear')}
        checked={selectingAuditing}
        disabled={!customerCompany}
      />

      <div className="flex-row justify-start flex-row-gap">
        <div style={{ width: '20px' }}></div>
        <Select<Auditing>
          label={t('auditing:financialYear')}
          options={customerCompanyStore.getAuditings(customerCompany?.id)}
          value={auditing}
          setValue={setAuditing}
          displayValue={getAuditingDisplayValue}
          disabled={!customerCompany || !selectingAuditing}
          nullable
        />
      </div>

      <Divider noBorder />

      {isCurrentAuditorOfCompany && (
        <Radio
          value="create_new_auditing"
          setValue={setAuditingOption}
          label={t('auditing:newFinancialYear')}
          checked={creatingAuditing}
          disabled={!customerCompany}
        />
      )}

      {isCurrentAuditorOfCompany && creatingAuditing && (
        <>
          <div className="flex-row justify-start flex-row-gap">
            <div style={{ width: '20px' }}></div>
            <TextInput
              type="date"
              label={t('auditing:startDate')}
              value={newAuditing.startDate}
              onChange={handleAuditingDateChange('startDate')}
            />

            <TextInput
              type="date"
              label={t('auditing:endDate')}
              value={newAuditing.endDate}
              onChange={handleAuditingDateChange('endDate')}
            />

            <Select<AuditingTemplate>
              autocomplete
              label={t('auditing:template.label')}
              options={Object.values(AuditingTemplate)}
              value={newAuditing.template}
              setValue={handleAuditingTemplateChange}
              displayValue={(template?: AuditingTemplate) =>
                t(`auditing:template.${template}`) ?? ''
              }
            />
          </div>
          <div className="flex-row justify-start flex-row-gap">
            <div style={{ width: '20px' }}></div>
            <Checkbox
              checked={!!newAuditing.copyPreviousAuditing}
              onChange={event => {
                const { checked } = event.currentTarget;
                setNewAuditing(prev => ({
                  ...prev,
                  copyPreviousAuditing: checked,
                }));
              }}
              label={t('auditing:copyPreviousAuditing')}
            />
          </div>
        </>
      )}

      <Divider noBorder />

      {selectingAuditing && (
        <Button
          text={t('action:select')}
          disabled={!customerCompany || !auditing || !selectingAuditing}
          onClick={handleSelectAuditing}
        />
      )}

      {creatingAuditing && (
        <Button
          text={t('action:addNewAuditing')}
          disabled={!customerCompany || !creatingAuditing}
          onClick={handleCreateAuditing}
        />
      )}
    </Wrapper>
  );
});

export default AuditingSelection;
