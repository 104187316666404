import { Accept } from 'react-dropzone';

export enum FileType {
  csv = 'text/csv',
  xls = 'application/vnd.ms-excel',
  xlsx = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
}

export const getAcceptedFileTypes = (accept?: AcceptFileType[]) => {
  if (!accept?.length) return undefined;

  const acceptedFileTypes: Accept = {};

  if (accept.includes('.csv')) acceptedFileTypes[FileType.csv] = ['.csv'];
  if (accept.includes('.xls')) acceptedFileTypes[FileType.xls] = ['.xls'];
  if (accept.includes('.xlsx')) acceptedFileTypes[FileType.xlsx] = ['.xlsx'];

  return acceptedFileTypes;
};
