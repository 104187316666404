import { useTranslation } from 'react-i18next';
import AuditingTable from '../table/AuditingTable';
import { OnEditProps, TableColumnDefs } from '../table/Table';

type FormType = MandateAuditorForm;

type TableItemType = {
  id: string;
  label: string;
  value: string;
  source: string;
  upToDate?: boolean | null;
};

type DataKey = keyof MandateCompanyInformation;

const sectionKey: AuditingSectionKey = 'MandateAuditor';
const formFieldKey: keyof FormType = 'companyInformation';

interface Props extends FormFieldProps<FormType> {
  visibleData: DataKey[];
}

export const MandateCompanyInformation = ({
  visibleData,
  formState,
  patchFormState,
}: Props) => {
  const { t } = useTranslation();

  const companyData = formState[formFieldKey];

  if (!companyData) return null;

  const tBase = `auditing:form.${sectionKey}.${formFieldKey}`;

  const data: TableItemType[] = visibleData.map(key => {
    const upToDateKey = `${key}UpToDate` as DataKey;
    const sourceKey = `${key}Source` as DataKey;
    const isDisabled = key !== 'selectedAuditor';
    return {
      id: key,
      label: t(`${tBase}.label.${key}`),
      value: `${companyData[key] ?? ''}`,
      upToDate:
        typeof companyData[upToDateKey] === 'boolean'
          ? !!companyData[upToDateKey]
          : null,
      source: companyData[sourceKey] ? `${companyData[sourceKey]}` : '',
      disabled: isDisabled,
    };
  });

  const getFieldKey = (itemId: DataKey, columnId: string) => {
    const isSourceField = columnId === 'source';
    const isUpToDateField = columnId === 'upToDate';

    const makeKey = (key: DataKey) => {
      if (isSourceField) return `${key}Source`;
      if (isUpToDateField) return `${key}UpToDate`;
      return key;
    };

    return makeKey(itemId);
  };

  function handleEdit<T>({ itemId, columnId, value }: OnEditProps<T>) {
    const fieldKey = getFieldKey(itemId as DataKey, columnId);
    const patch = {
      ...formState.companyInformation,
      [fieldKey]: value,
    };
    patchFormState({
      ...formState,
      [formFieldKey]: patch,
    });
  }

  const tableColumns: TableColumnDefs<TableItemType> = [
    {
      accessorKey: 'label',
      header: () => '',
      className: 'width-min nowrap text-bold',
    },
    {
      accessorKey: 'value',
      header: () => t(`${tBase}.info`),
      className: 'width-max',
      onEdit: (editProps: OnEditProps<string>) => handleEdit<string>(editProps),
    },
    {
      accessorKey: 'source',
      header: () => t(`${tBase}.Date`),
      className: 'width-min nowrap',
      onEdit: (editProps: OnEditProps<string>) => handleEdit<string>(editProps),
      hidden: (cell: TableItemType) => cell.id !== 'selectedAuditor',
      placeholder: 'XX.YY.ZZZZ',
    },
    /*     {
      accessorKey: 'upToDate',
      className: 'width-min',
      header: () => t(`${tBase}.upToDate`),
      contextInfoProps: {
        position: 'top-left',
        text: t(`${tBase}.upToDateInfo`),
      },
      showRemark: (item: TableItemType) =>
        typeof item.upToDate === 'boolean' ? !item.upToDate : false,
      selectProps: {
        options: ['false', 'true'],
        placeholder: 'N/A',
        clearable: true,
        displayValue: (option: string) =>
          t(`auditing:form.permanentData.upToDateOptions.${option}`),
        setValue: (editProps: OnEditProps<string>) => {
          const value = editProps.value;
          handleEdit<boolean | null>({
            ...editProps,
            value: value === 'true' ? true : value === 'false' ? false : null,
          });
        },
      },
      hidden: (cell: TableItemType) => cell.id !== 'selectedAuditor',
    }, */
  ];

  return (
    <AuditingTable<TableItemType>
      sectionKey={sectionKey}
      data={data}
      columns={tableColumns}
      showGlobalFilter={false}
      disableSort
    />
  );
};
