import AccountMapScopingResetButton from '../../../components/scoping/AccountMapScoping.ResetButton';
import AccountMapTable from '../../../components/scoping/AccountMapScoping.Table';
import { AuditingTemplate } from '../../../utils';
import { generateAccountMap } from './accountMapUtilsScoping';
import { FormFieldBuilder } from '../FormFieldBuilder';
import { CopyAccountMapData } from './copyFunctions';
import InstructionBox from '../../../components/InstructionBox';
import { t } from '../../../i18n';

type SectionFormType = AccountMapScopingForm;

const sectionKey: AuditingSectionKey = 'accountMapScoping';

export const tBase = `auditing:form.${sectionKey}`;

const formBuilder = new FormFieldBuilder<SectionFormType>({ sectionKey });

const defaultFormState: DefaultFormState<SectionFormType> = ({ auditing }) => ({
  accountMapScoping: generateAccountMap(
    auditing.template ?? AuditingTemplate.private
  ),
  questionMark: '',
});

const accountMapKeys: (keyof AccountMapScoping)[] = [
  'incomeStatementAccountMap',
  'balanceSheetAssetsAccountMap',
  'balanceSheetLiabilitiesAccountMap',
];

const formFields: FormFields<SectionFormType> = [
  formBuilder.custom(
    <InstructionBox
      buttonText='Katso ohje!'
      title='Ohje'>
      <div style={{whiteSpace: 'pre-line'}}>
        {t('auditing:form.accountMapScoping.questionMarkInfo')}
      </div>
    </InstructionBox>
  ),

  formBuilder.custom(props => <CopyAccountMapData />),

  formBuilder.group({
    items: [
      formBuilder.custom(props => <AccountMapScopingResetButton {...props} />),
      formBuilder.text({ textKey: 'infoText' }),
    ],
  }),

  ...accountMapKeys.map(key =>
    formBuilder.accordionGroup({
      open: true,
      titleKey: key,
      items: [
        formBuilder.custom(props => (
          <AccountMapTable targetKey={key} {...props} />
        )),
      ],
    })
  ),
];

const section: AuditingSectionDefinition<SectionFormType> = {
  sectionKey,
  defaultFormState,
  formFields,
};

export default section;
