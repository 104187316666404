import React from 'react';
import { useTranslation } from 'react-i18next';
import Modal from './Modal';
import PasswordResetForm from './forms/PasswordResetForm';

interface Props {
  token: string;
}

const PasswordResetModal: React.FC<Props> = ({ token }) => {
  const { t } = useTranslation(['login', 'action']);

  return (
    <Modal
      title={t('login:resetPassword')}
      description={t('login:resetPasswordDescription')}
      isInitiallyOpen
    >
      {({ closeModal }) => (
        <PasswordResetForm token={token} onSubmit={closeModal} />
      )}
    </Modal>
  );
};

export default PasswordResetModal;
