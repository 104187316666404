import AuditingTable from '../../../components/table/AuditingTable';
import { OnEditProps, TableColumnDefs } from '../../../components/table/Table';
import { uuid } from '../../../utils';

type VisibleData = {
  [key: string]: (keyof ManagementForm)[];
};

export const emptyDetailedInformationItem = (
  props: {
    value?: string;
    page?: string;
  } = {}
): DetailedInformationItem => ({
  id: uuid(),
  value: props.value ?? '',
  page: props.page ?? '',
});

export const emptyEssentialNotesItem = (
  props: {
    minutes?: string;
    date?: string;
    section?: string;
    notes?: string;
    reference?: string;
    mustBeNoted?: boolean;
  } = {}
): EssentialNotesItem => ({
  id: uuid(),
  minutes: props.minutes ?? '',
  date: props.date ?? '',
  section: props.section ?? '',
  notes: props.notes ?? '',
  reference: props.reference ?? '',
  mustBeNoted: props.mustBeNoted ?? false,
});

export const visibleData: VisibleData = {
  publicNetworkDocuments: [
    'municipalStrategy',
    'administrativeRule',
    'budget',
    'financialStatement',
    'auditBoardEvaluationReport',
    'auditReport',
    'municipalCooperationAgreements',
    'groupInstruction',
    'boardCommitments',
    'boardRewardJustifications',
    'serviceFees',
  ],
  municipalStrategy: ['programmeOfMeasures'],
  administrativeRule: ['administrativeRuleMeetsRequirements'],
  budget: [
    'budgetAndMunicipalStrategyInLine',
    'isBudgetRealistic',
    'budgetAndChangesProperlyApproved',
  ],
  internalInstructions: [
    'councilGuidelinesForInternalInstructions',
    'internalInstructionsMeetCouncilGuidelines',
    'internalInstructionsUpToDate',
    'currentPracticesAreValid',
  ],
  groupInstruction: [
    'planningOfBudgetAndInvestments',
    'groupSupervisionAndReporting',
    'informingAndInformationAccess',
    'obligationToGetMunicipalitysOpinionBeforeDecisions',
    'groupsInternalServices',
    'subsidiaryBoardCompositionAndAppointment',
    'subsidiaryGoodManagementAndLeadership',
  ],
  boardMeetingMinutes: [],
  councilMeetingMinutes: [
    'previousYearFinancialStatementConfirmed',
    'nextYearBudgetApproved',
    'handlingOfBudgetChanges',
    'isBudgetReviewedEveryQuarter',
    'affiliationNotices',
    'evaluationReport',
  ],
  reviewOfPriorityBoards: [],
  reviewOfPriorityOfficials: [],
  insurancePolicies: [],
  affiliationRegister: [],
  auditOfRelatedPartyTransactions: [],
};

const sectionKey: AuditingSectionKey = 'management';
export const ManagementTable = ({
  formState,
  patchFormState,
}: FormFieldProps<ManagementForm>) => {
  if (!formState) return null;

  const data = formState.reviewOfMinutesData;

  function handleEdit<T>({
    itemId,
    rowIndex,
    columnId,
    value,
  }: OnEditProps<T>) {
    const patch = data.map((item: any, index: number) => {
      const idMatch = itemId !== undefined && itemId === item.id;
      const rowIndexMatch = itemId === undefined && rowIndex === index;
      if (idMatch || rowIndexMatch) {
        const updatedRow = { ...item, [columnId]: value };
        return updatedRow;
      }
      return item;
    });

    const newFormState = {
      ...formState,
      reviewOfMinutesData: patch,
    };
    patchFormState(newFormState);
  }

  const handleAddNewRow = () => {
    patchFormState({
      ...formState,
      reviewOfMinutesData: [
        ...data,
        {
          id: uuid(),
          pvm: '',
          kokous: '',
          keskeiset: '',
        },
      ],
    });
  };

  const tableColumns: TableColumnDefs<any> = [
    {
      accessorKey: 'pvm',
      header: 'PVM',
      className: 'nowrap text-bold width-min',
      onEdit: (editProps: OnEditProps<string>) => {
        handleEdit<string>(editProps);
      },
    },
    {
      accessorKey: 'kokous',
      header: 'Kokousnro',
      className: 'nowrap text-bold width-min',
      onEdit: (editProps: OnEditProps<string>) => {
        handleEdit<string>(editProps);
      },
    },
    {
      accessorKey: 'keskeiset',
      header: 'Keskeiset päätökset',
      className: 'nowrap text-bold width-min',
      onEdit: (editProps: OnEditProps<string>) => {
        handleEdit<string>(editProps);
      },
    },
  ];

  return (
    <AuditingTable
      sectionKey={sectionKey}
      columns={tableColumns}
      data={data}
      onAddNewRow={handleAddNewRow}
      showGlobalFilter={false}
    />
  );
};
export const ManagementTableTwo = ({
  formState,
  patchFormState,
}: FormFieldProps<ManagementForm>) => {
  if (!formState) return null;

  const data = formState.reviewOfMinutesDataTwo;

  function handleEdit<T>({
    itemId,
    rowIndex,
    columnId,
    value,
  }: OnEditProps<T>) {
    const patch = data.map((item: any, index: number) => {
      const idMatch = itemId !== undefined && itemId === item.id;
      const rowIndexMatch = itemId === undefined && rowIndex === index;
      if (idMatch || rowIndexMatch) {
        const updatedRow = { ...item, [columnId]: value };
        return updatedRow;
      }
      return item;
    });

    const newFormState = {
      ...formState,
      reviewOfMinutesDataTwo: patch,
    };
    patchFormState(newFormState);
  }

  const handleAddNewRow = () => {
    patchFormState({
      ...formState,
      reviewOfMinutesDataTwo: [
        ...data,
        {
          id: uuid(),
          pvm: '',
          kokous: '',
          keskeiset: '',
        },
      ],
    });
  };

  const tableColumns: TableColumnDefs<any> = [
    {
      accessorKey: 'pvm',
      header: 'PVM',
      className: 'nowrap text-bold width-min',
      onEdit: (editProps: OnEditProps<string>) => {
        handleEdit<string>(editProps);
      },
    },
    {
      accessorKey: 'kokous',
      header: 'Kokousnro',
      className: 'nowrap text-bold width-min',
      onEdit: (editProps: OnEditProps<string>) => {
        handleEdit<string>(editProps);
      },
    },
    {
      accessorKey: 'keskeiset',
      header: 'Keskeiset päätökset',
      className: 'nowrap text-bold width-min',
      onEdit: (editProps: OnEditProps<string>) => {
        handleEdit<string>(editProps);
      },
    },
  ];

  return (
    <AuditingTable
      sectionKey={sectionKey}
      columns={tableColumns}
      data={data}
      onAddNewRow={handleAddNewRow}
      showGlobalFilter={false}
    />
  );
};
export const ReviewOfContractsTable = ({
  formState,
  patchFormState,
}: FormFieldProps<ManagementForm>) => {
  if (!formState) return null;

  const data = formState.reviewOfContractsData;

  function handleEdit<T>({
    itemId,
    rowIndex,
    columnId,
    value,
  }: OnEditProps<T>) {
    const patch = data.map((item: any, index: number) => {
      const idMatch = itemId !== undefined && itemId === item.id;
      const rowIndexMatch = itemId === undefined && rowIndex === index;
      if (idMatch || rowIndexMatch) {
        const updatedRow = { ...item, [columnId]: value };
        return updatedRow;
      }
      return item;
    });

    const newFormState = {
      ...formState,
      reviewOfContractsData: patch,
    };
    patchFormState(newFormState);
  }

  const handleAddNewRow = () => {
    patchFormState({
      ...formState,
      reviewOfContractsData: [
        ...data,
        {
          id: uuid(),
          pvm: '',
          kokous: '',
          keskeiset: '',
        },
      ],
    });
  };

  const tableColumns: TableColumnDefs<any> = [
    {
      accessorKey: 'sopimus',
      header: 'Sopimus',
      className: 'nowrap text-bold width-min',
      onEdit: (editProps: OnEditProps<string>) => {
        handleEdit<string>(editProps);
      },
    },
    {
      accessorKey: 'kuvaus',
      header: 'Kuvaus',
      className: 'nowrap text-bold width-min',
      onEdit: (editProps: OnEditProps<string>) => {
        handleEdit<string>(editProps);
      },
    },
    {
      accessorKey: 'viite',
      header: 'Viite',
      className: 'nowrap text-bold width-min',
      onEdit: (editProps: OnEditProps<string>) => {
        handleEdit<string>(editProps);
      },
    },
  ];

  return (
    <AuditingTable
      sectionKey={sectionKey}
      columns={tableColumns}
      data={data}
      onAddNewRow={handleAddNewRow}
      showGlobalFilter={false}
    />
  );
};
