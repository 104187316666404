import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { formatDateTime, isDate } from '../../utils';
import Icon from '../Icon';
import { Button } from '../inputs';
import Table, { TableColumnDefs } from '../table/Table';

const Container = styled.div`
  position: relative;
  > button {
    position: absolute;
    right: 0;
    top: -${p => p.theme.spacing.xl};
  }

  .additional-info {
    margin-top: ${p => p.theme.spacing.lg};
    font-style: italic;
    color: ${p => p.theme.color.grey400};
    text-align: right;
  }
`;

type FormType = PermanentDataForm;

interface TableItemType {
  key: string;
  label: string;
}

interface RegisterTableItemType extends TableItemType {
  value: boolean | null;
  date: string | null;
}

interface OtherDataTableItemType extends TableItemType {
  value: string;
}

const sectionKey: AuditingSectionKey = 'permanentData';

interface Props extends FormFieldProps<FormType> {
  visibleData?: string[];
}

export const TaxRegisterInformation = ({
  visibleData,
  formState,
  patchFormState,
  store,
}: Props) => {
  const { t } = useTranslation();

  const { auditingStore } = store;

  const { auditingLocked, isAuditingSectionFinished } = auditingStore;

  const disabled = auditingLocked || isAuditingSectionFinished(sectionKey);

  if (!formState) return null;

  const tBase = `auditing:form.${sectionKey}`;

  type ValueKey = keyof FormType;
  type DateKey = keyof FormType;

  const registerDataKeys: [ValueKey, DateKey][] = [
    ['tradeRegister', 'tradeRegisterDate'],
    ['taxAdministrationInformation', 'taxAdministrationInformationDate'],
    ['advanceCollectionRegister', 'advanceCollectionRegisterDate'],
    ['liableForVATOnBusiness', 'liableForVATOnBusinessDate'],
    ['liableForVATOnProperty', 'liableForVATOnPropertyDate'],
    ['employerRegister', 'employerRegisterDate'],
  ];

  const filteredDataKeys: [ValueKey, DateKey][] = registerDataKeys.filter(
    keys => visibleData?.includes(keys[0])
  );

  const registerData: RegisterTableItemType[] = filteredDataKeys.map(
    ([key, dateKey]) => ({
      key,
      label: t(`${tBase}.${key}`),
      value: typeof formState[key] === 'boolean' ? !!formState[key] : null,
      date: formState[dateKey] ? `${formState[dateKey]}` : null,
    })
  );

  const data: OtherDataTableItemType[] = [
    {
      key: 'legalSituation',
      label: t(`${tBase}.legalSituation`),
      value: formState['legalSituation']
        ? t(`${tBase}.legalSituationOptions.${formState['legalSituation']}`)
        : '-',
    },
  ];

  const registerTableColumns: TableColumnDefs<RegisterTableItemType> = [
    {
      accessorKey: 'label',
      header: () => t(`${tBase}.register`),
    },
    {
      id: 'value',
      accessorFn: row =>
        typeof row.value === 'boolean'
          ? t(`${tBase}.inRegisterOptions.${row.value}`)
          : '-',
      header: () => t(`${tBase}.status`),
      showRemark: (row: RegisterTableItemType) =>
        auditingStore.isMarkedAsRemark(sectionKey, row.key),
      className: 'width-min nowrap',
    },
    {
      id: 'date',
      accessorFn: row =>
        row.date && isDate(row.date)
          ? formatDateTime(row.date, { format: 'D' })
          : '-',
      header: () => t(`${tBase}.date`),
      className: 'width-min nowrap text-center',
    },
  ];

  const dataColumns: TableColumnDefs<OtherDataTableItemType> = [
    {
      accessorKey: 'label',
      className: 'width-half',
    },
    {
      accessorKey: 'value',
      showRemark: (row: OtherDataTableItemType) =>
        auditingStore.isMarkedAsRemark(sectionKey, row.key),
      className: 'width-half text-right',
    },
  ];

  const handleUpdateData = async () => {
    const taxRegisterInformation =
      await auditingStore.fetchTaxRegisterInformation();

    if (taxRegisterInformation) {
      patchFormState({ ...formState, ...taxRegisterInformation });
    }
  };

  const getAdditionalInfoText = () => {
    const date =
      formState.taxRegisterFetchedDate &&
      formatDateTime(formState.taxRegisterFetchedDate);
    const lastUpdatedText = date ? t('common:lastUpdated', { date }) : '';
    return `${t('common:dataFetchedFromPRH')} ${lastUpdatedText}`;
  };

  return (
    <Container>
      {!disabled && (
        <Button
          text={t('action:updateData')}
          icon={<Icon type="Refresh" />}
          variant="link"
          color="primary"
          onClick={handleUpdateData}
        />
      )}

      <Table<RegisterTableItemType>
        data={registerData}
        columns={registerTableColumns}
        showGlobalFilter={false}
        disableSort
        variant="plain"
      />

      <Table<OtherDataTableItemType>
        data={data}
        columns={dataColumns}
        showGlobalFilter={false}
        disableSort
        variant="plain"
        hideHeader
      />

      <div className="additional-info">{getAdditionalInfoText()}</div>
    </Container>
  );
};
