import { t } from '../../../i18n';
import { AuditingTemplate } from '../../../utils';

interface Mapping {
  auditingProcedureGoals?: string;
  auditingComments?: string;
  auditingReference?: string;
}

interface actionKeyMapping {
  private: Mapping;
  public?: Mapping;
}

const forms: Record<string, actionKeyMapping> = {
  processMappingWtAndMainAccountingPrinciples: {
    private: {
      auditingProcedureGoals: t(
        'auditing:form.turnOver.analyticAuditingProcedureGoals'
      ),
    }
  },
  reviewOfIndividualTransactions: {
    private: {
      auditingProcedureGoals: t(
        'auditing:form.turnOver.reviewOfIndividualTransactionsGoals'
      ),
    }
  },
  matchingOfSalesToInvoicing: {
    private: {
      auditingProcedureGoals: t(
        'auditing:form.turnOver.inspectionConclusionInvoicingGoals'
      ),
    }
  },
  reviewOfRevenueRecognition: {
    private: {
      auditingProcedureGoals: t(
        'auditing:form.turnOver.reviewOfRevenueRecognitionGoals'
      ),
    }
  },
  /* J2 - Myyntisaamiset */
  /* Myyntireskontran täsmäytys taseelle */
  accountsReceivableReconciliation: {
    private: {
      auditingProcedureGoals: t(
        'auditing:form.accountsReceivable.balanceSheetReconciliationGoals'
      ),
    },
    public: {
      auditingProcedureGoals: t(
        'auditing:form.accountsReceivable.publicSectorBalanceSheetReconciliationGoals'
      ),
      auditingReference: t(
        'auditing:form.accountsReceivable.referenceJ2Value'
      )
    }
  },
  /* Myyntireskontran matemaattinen oikeellisuus */
  mathematicalAccuracy: {
    private: {
      auditingProcedureGoals: t(
        'auditing:form.accountsReceivable.reconciliationMathematicalAccuracyGoals'
      ),
      auditingComments: t(
        'auditing:form.accountsReceivable.reconciliationMathematicalAccuracyComments'
      ),
    },
    public: {
      auditingReference: t(
        'auditing:form.accountsReceivable.publicSectorMathematicalAccuracyReferenceValue'
      ),
      auditingProcedureGoals: t(
        'auditing:form.accountsReceivable.publicSectorReconciliationMathematicalAccuracyGoals'
      ),
      auditingComments: t(
        'auditing:form.accountsReceivable.publicSectorReconciliationMathematicalAccuracyComments'
      )
    }
  },
  /* Myyntisaamisten läpikäynti */
  recheckingAccountsReceivable: {
    private: {
      auditingProcedureGoals: t(
        'auditing:form.accountsReceivable.recheckingAccountsReceivableGoals'
      ),
    },
    public: {
      auditingReference: t(
        'auditing:form.accountsReceivable.publicSectorRecheckingAccountsReceivableReference'
      ),
      auditingProcedureGoals: t(
        'auditing:form.accountsReceivable.recheckingAccountsReceivableGoals'
      )
    }
  },
  /* Alkaneen kauden hyvityslaskut */
  payments: {
    private: {
      auditingProcedureGoals: t('auditing:form.accountsReceivable.paymentsGoals'),
    },
    public: {
      auditingReference: t('auditing:form.accountsReceivable.referenceFileJ1_3Value')
    }
  },
  /* Myyntisaamisten olemassaolo: saldovahvistukset */
  balanceConfirmation: {
    private: {
      auditingProcedureGoals: t(
        'auditing:form.accountsReceivable.balanceConfirmationGoals'
      ),
    },
    public: {
      auditingProcedureGoals: t(
        'auditing:form.accountsReceivable.balanceConfirmationGoals'
      ),
    }
  },
  /* Myyntisaamisten maksuun tarkastus */
  paymentConfirmation: {
    private: {
      auditingProcedureGoals: t(
        'auditing:form.accountsReceivable.paymentConfirmationGoals'
      ),
    },
    public: {
      auditingProcedureGoals: t(
        'auditing:form.accountsReceivable.paymentConfirmationGoals'
      ),
    }
  },
  /* Myyntisaamisten luokittelu */
  classificationAndPresentation: {
    private: {
      auditingProcedureGoals: t(
        'auditing:form.accountsReceivable.classificationAndPresentationGoals'
      ),
    },
    public: {
      auditingProcedureGoals: t(
        'auditing:form.accountsReceivable.classificationAndPresentationGoals'
      ),
      auditingReference: t('auditing:form.accountsReceivable.referenceEgJ2Value')
    }
  },
  /* Kirjanpidolliset arviot */
  /*
  accountingEstimates: {
    private: {
      auditingProcedureGoals: t(
        'auditing:form.accountsReceivable.accountingEstimatesGoals'
      ),
    }
  }*/
  /* K2 - Ostovelat */
  /* Ostoreskontran täsmäytys taseelle */
  reconciliationOfThePurchase: {
    private: {
      auditingProcedureGoals: t(
        'auditing:form.tradeCreditors.reconciliationOfThePurchaseGoals'
      ),
    },
  },
  /* Ostoreskontran matemaattinen oikeellisuus */
  mathematicalCorrectness: {
    private: {
      auditingProcedureGoals: t(
        'auditing:form.tradeCreditors.mathematicalCorrectnessGoals'
      ),
      auditingComments: t(
        'auditing:form.tradeCreditors.mathematicalCorrectnessComments'
      ),
    }
  },
  /* Ostovelkojen läpikäynti */
  reviewOfAccounts: {
    private: {
      auditingProcedureGoals: t(
        'auditing:form.tradeCreditors.reviewOfAccountsGoals'
      ),
    }
  },
  /* Kulujen katko - alkaneen kauden tapahtumat*/
  breakInExpenses: {
    private: {
      auditingProcedureGoals: t(
        'auditing:form.tradeCreditors.breakInExpensesGoals'
      ),
    }
  },
  /* Kulujen katko - Tilikauden viimeisen kuukauden tapahtumat */
  breakInExpensesEvents: {
    private: {
      auditingProcedureGoals: t(
        'auditing:form.tradeCreditors.breakInExpensesEventsGoals'
      ),
    }
  },
  /* Ostovelat: saldovahvistukset */
  existenceOfAccounts: {
    private: {
      auditingProcedureGoals: t(
        'auditing:form.tradeCreditors.existenceOfAccountsGoals'
      ),
    }
  },
  /* Ostovelkojen luokittelu */
  classification: {
    private: {
      auditingProcedureGoals: t(
        'auditing:form.tradeCreditors.classificationGoals'
      ),
    }
  },
  /* K1 - Materiaalit ja palvelut*/
  /* Ostojen analyyttinen tarkastus */
  analyticalInspectionOfPurchases: {
    private: {
      auditingProcedureGoals: t(
        'auditing:form.materialsAndServices.analyticalInspectionOfPurchasesGoals'
      ),
    }
  },
  /* Kateanalyysi */
  marginAnalysis: {
    private: {
      auditingProcedureGoals: t(
        'auditing:form.materialsAndServices.marginAnalysisGoals'
      ),
    }
  },
  /* Ostojen katkon tarkastus */
  inspectionOfInterruption: {
    private: {
      auditingProcedureGoals: t(
        'auditing:form.materialsAndServices.inspectionOfInterruptionGoals'
      ),
    }
  },
  /* Ostojen aineistotarkastus */
  materialInspection: {
    private: {
      auditingProcedureGoals: t(
        'auditing:form.materialsAndServices.materialInspectionGoals'
      ),
    }
  },
  /* KB - Liiketoiminnan muut kulut */
  /* Muiden kulujen analyyttinen tarkastus */
  analyticalReview: {
    private: {
      auditingProcedureGoals: t(
        'auditing:form.otherOperatingExpenses.analyticalReviewGoals'
      ),
    }
  },
  /* Muiden kulujen katko */
  interruptionExpenses: {
    private: {
      auditingProcedureGoals: t(
        'auditing:form.otherOperatingExpenses.interruptionExpensesGoals'
      ),
    }
  },
  /* Lakikulut */
  legalFees: {
    private: {
      auditingProcedureGoals: t(
        'auditing:form.otherOperatingExpenses.legalFeesGoals'
      ),
    }
  },
  /* JB - Liiketoiminnan muut tuotot */
  /* Yksittäisten tapahtumien tarkastus */
  individualTransactionInspection: {
    private: {
      auditingProcedureGoals: t(
        'auditing:form.otherOperatingIncome.individualTransactionInspectionGoals'
      ),
    }
  },
  /* Katkon tarkastus */
  breakCheck: {
    private: {
      auditingProcedureGoals: t(
        'auditing:form.otherOperatingIncome.breakCheckGoalsValue'
      ),
    }
  },
  /* N - Verot */
  /* Edellisen tilikauden verojen ja verotuspäätöksen tarkastaminen */
  lastSeasonTaxesCheck: {
    private: {
      auditingProcedureGoals: t(
        'auditing:form.taxes.lastSeasonTaxesCheckGoalsValue'
      ),
    }
  },
  /* Tilikauden tuloverojen (ml. ennakkoverojen) tarkastus */
  checkingOfTaxes: {
    private: {
      auditingProcedureGoals: t('auditing:form.taxes.checkingOfTaxesGoalsValue'),
    }
  },
  /* O1 - Käyttöomaisuus */
  /* Käyttöomaisuuskirjanpidon täsmäytys pääkirjanpitoon */
  accountingReconciliation: {
    private: {
      auditingProcedureGoals: t(
        'auditing:form.fixedAssets.accountingReconciliationGoalsValue'
      ),
    }
  },
  /* Poistojen tarkastus */
  verifyingRemovals: {
    private: {
      auditingProcedureGoals: t(
        'auditing:form.fixedAssets.verifyingRemovalsGoalsValue'
      ),
    }
  },
  /* Lisäysten ja vähennysten tarkastus */
  checkingAdditionsAndSubtractions: {
    private: {
      auditingProcedureGoals: t(
        'auditing:form.fixedAssets.checkingAdditionsAndSubtractionsGoalsValue'
      ),
    }
  },
  /* Lisäysten tarkastus */
  additionsCheck: {
    private: {
      auditingProcedureGoals: t(
        'auditing:form.fixedAssets.additionsCheckGoalsValue'
      ),
    }
  },
  /* Vähennysten tarkastus */
  removalsCheck: {
    private: {
      auditingProcedureGoals: t(
        'auditing:form.fixedAssets.removalsCheckGoalsValue'
      ),
    }
  },
  /* Poistoerojen tarkastus */
  removedDifferencesCheck: {
    private: {
      auditingProcedureGoals: t(
        'auditing:form.fixedAssets.removedDifferencesCheckGoalsValue'
      ),
    }
  },
  /* Käyttöomaisuuden arvonalentumisen tarkastus */
  decreasedValueOfFixedAssetsCheck: {
    private: {
      auditingProcedureGoals: t(
        'auditing:form.fixedAssets.decreasedValueOfFixedAssetsCheckGoalsValue'
      ),
    }
  },
  /* Käyttöomaisuuden arvostus */
  fixedAssetsAppreciation: {
    private: {
      auditingProcedureGoals: t(
        'auditing:form.fixedAssets.fixedAssetsAppreciationGoalsValue'
      ),
    }
  },
  /* O4 - Rahoitusomaisuus */
  /* Rahoitusomaisuuden tarkastus - täsmäytys saldovahvistukselle */
  fundingAppreciation: {
    private: {
      auditingProcedureGoals: t(
        'auditing:form.funding.fundingAppreciationGoalsValue'
      ),
    }
  },
  /* Rahoitusomaisuuden tarkastus (jos markkina-arvoa ei käytettävissä mm. listaamattomat) */
  fundingInspection: {
    private: {
      auditingProcedureGoals: t(
        'auditing:form.funding.fundingInspectionGoalsValue'
      ),
    }
  },
  /* Rahoitusomaisuuden luokittelun tarkastus */
  fundingClassificationInspection: {
    private: {
      auditingProcedureGoals: t(
        'auditing:form.funding.fundingClassificationInspectionGoalsValue'
      ),
    }
  },
  /* Rahoitusomaisuuden arvostus */
  liquidAssetsValuation: {
    private: {
      auditingProcedureGoals: t(
        'auditing:form.funding.liquidAssetsValuationGoalsValue'
      ),
    }
  },
  /* Q1 - Muut saamiset ja siirtosaamiset */
  /* Muiden saamisten ja siirtosaamisten tarkastus */
  inspectionOtherReceivables: {
    private: {
      auditingProcedureGoals: t(
        'auditing:form.otherReceivables.inspectionOtherReceivablesGoalsValue'
      ),
    }
  },
  /* Muiden saamisten tarkastus*/
  verificationOfReceivables: {
    private: {
      auditingProcedureGoals: t(
        'auditing:form.otherReceivables.verificationOfReceivablesGoalsValue'
      ),
    }
  },
  /* Siirtosaamisten tarkastus */
  checkingMovedReceivables: {
    private: {
      auditingProcedureGoals: t(
        'auditing:form.otherReceivables.checkingMovedReceivablesGoalsValue'
      ),
    }
  },
  /* Q2 - Rahat ja pankkisaamiset */
  /* Täsmäytys tiliotteille */
  accountReconciliation: {
    private: {
      auditingProcedureGoals: t(
        'auditing:form.cashEquivalents.accountReconciliationGoalsValues'
      ),
    }
  },
  /* Saldojen tarkistus suoraan pankkiohjelmasta */
  balanceConfirmationThroughBank: {
    private: {
      auditingProcedureGoals: t(
        'auditing:form.cashEquivalents.balanceConfirmationThroughBankGoalsValue'
      ),
    }
  },
  /* Debet ja kredit vientien täsmäytys tiliotteille */
  debetAndCreditConfirmation: {
    private: {
      auditingProcedureGoals: t(
        'auditing:form.cashEquivalents.debetAndCreditConfirmationGoalsValue'
      ),
    }
  },
  /* Käteiskassan tarkistus */
  cashCounterConfirmation: {
    private: {
      auditingProcedureGoals: t(
        'auditing:form.cashEquivalents.cashCounterConfirmationGoalsValue'
      ),
    }
  },
  /* Yksittäisten / manuaalisten maksujen tarkastus */
  manualPaymentsConfirmation: {
    private: {
      auditingProcedureGoals: t(
        'auditing:form.cashEquivalents.manualPaymentsConfirmationGoalsValue'
      ),
    }
  },
  /* Rahavarojen luokittelu */
  cashFlowClassificationLUO: {
    private: {
      auditingProcedureGoals: t(
        'auditing:form.cashEquivalents.cashFlowClassificationLUOGoalsValue'
      ),
    }
  },
  /* R1 - oma pääoma */
  /* Oman pääoman tarkastus */
  equityAudit: {
    private: {
      auditingReference: t('auditing:form.ownCapital.equityAuditReferenceValue'),
      auditingProcedureGoals: t('auditing:form.ownCapital.equityAuditGoalsValue'),
    }
  },
  /* Arvio voitonjakoehdotuksen asianmukaisuudesta */
  assessmentOfTheAppropriateness: {
    private: {
      auditingProcedureGoals: t(
        'auditing:form.ownCapital.assessmentOfTheAppropriatenessGoalsValue'
      ),
    }
  },
  /* T1 - Muut velat ja siirtovelat */
  /* Muihin velkoihin sisältyvien lainojen tarkastus */
  inspectionOfLoans: {
    private: {
      auditingProcedureGoals: t(
        'auditing:form.otherLiabilities.inspectionOfLoansGoalsValue'
      ),
    }
  },
  /* Muiden velkojen ja siirtovelkojen tarkastus */
  checkingOtherDebts: {
    private: {
      auditingProcedureGoals: t(
        'auditing:form.otherLiabilities.checkingOtherDebtsGoalsValue'
      ),
    }
  },
  /* Muiden velkojen tarkastus */
  auditOfOtherDebts: {
    private: {
      auditingProcedureGoals: t(
        'auditing:form.otherLiabilities.auditOfOtherDebtsGoalsValue'
      ),
    }
  },
  /* Siirtovelkojen tarkastus */
  auditOfAccruedLiabilities: {
    private: {
      auditingProcedureGoals: t(
        'auditing:form.otherLiabilities.auditOfAccruedLiabilitiesGoalsValue'
      ),
    }
  },
  /* Velkojen luokittelun tarkastus */
  inspectionOfTheClassification: {
    private: {
      auditingProcedureGoals: t(
        'auditing:form.otherLiabilities.inspectionOfTheClassificationGoalsValue'
      ),
    }
  },
  /* S1 - Lainat rahoituslaitoksilta */
  /* Pääomalainojen täsmäytys saldovahvistuksille */
  loanReconciliationForBalance: {
    private: {
      auditingProcedureGoals: t(
        'auditing:form.financialInstitutions.loanReconciliationForBalanceGoalsValue'
      ),
    }
  },
  /* Pääomalainojen täsmäytys velkakirjoille */
  reconciliationOfLoans: {
    private: {
      auditingProcedureGoals: t(
        'auditing:form.financialInstitutions.reconciliationOfLoansGoalsValue'
      ),
    }
  },
  /* Lainojen tarkastus */
  inspectionOfLoanBalance: {
    private: {
      auditingProcedureGoals: t(
        'auditing:form.financialInstitutions.inspectionOfLoanBalanceGoalsValue'
      ),
    }
  },
  /* Lainojen luokittelun tarkastus */
  inspectionOfLoanClassification: {
    private: {
      auditingProcedureGoals: t(
        'auditing:form.financialInstitutions.inspectionOfLoanClassificationGoalsValue'
      ),
    }
  },
  /* Lainasopimusten ja -ehtojen läpikäynti */
  reviewingLoanAgreements: {
    private: {
      auditingProcedureGoals: t(
        'auditing:form.financialInstitutions.reviewingLoanAgreementsGoalsValue'
      ),
    }
  },
  /* Kovenanttien tarkastus */
  inspectionOfCovenants: {
    private: {
      auditingProcedureGoals: t(
        'auditing:form.financialInstitutions.inspectionOfCovenantsGoalsValue'
      ),
    }
  },
  /* XK - Rahoituskulut */
  /* Rahoituskulujen tarkastus */
  "examinationOfFinancialExpenses": {
    private: {
      auditingProcedureGoals: t('auditing:form.financialExpenses.examinationOfFinancialExpensesGoalsValue')
    }
  },
  /* Korkokulujen täsmäytys saldovahvistukselle */
  "reconciliationOfInterestExpenses": {
    private: {
      auditingProcedureGoals: t('auditing:form.financialExpenses.reconciliationOfInterestExpensesGoalsValue')
    }
  },
  /* Korkokulujen analyyttinen tarkastus */
  "analyticalExamination": {
    private: {
      auditingProcedureGoals: t('auditing:form.financialExpenses.analyticalExaminationGoalsValue')
    }
  },
  /* Rahoituskulujen analyyttinen tarkastus */
  "analyticalExaminationFinancialExpenses": {
    private: {
      auditingProcedureGoals: t('auditing:form.financialExpenses.analyticalExaminationFinancialExpensesGoalsValue')
    }
  },
  /* Rahoituskulujen täsmäytys taustamateriaalille */
  "analyticalExaminationOfFinancialExpenses": {
    private: {
      auditingProcedureGoals: t('auditing:form.financialExpenses.analyticalExaminationOfFinancialExpensesGoalsValue')
    }
  },
  /* Pysyvien vastaavien arvonalentumisten tarkastus */
  "examinationOfPermanentPriceDecrease": {
    private: {
      auditingProcedureGoals: t('auditing:form.financialExpenses.examinationOfPermanentPriceDecreaseGoalsValue')
    }
  },
  /* Vaihtuvien vastaavien rahoitusarvopapereiden arvonalentumisten tarkastus */
  "examinationOfChangingAssets": {
    private: {
      auditingProcedureGoals: t('auditing:form.financialExpenses.examinationOfChangingAssetsGoalsValue')
    }
  },
  /* XJ - Rahoitustuotot */
  /* Rahoitustuottojen tarkistus */
  "auditFinancialIncome": {
    private: {
      auditingProcedureGoals: t('auditing:form.financialIncome.auditFinancialIncomeGoalsValue')
    }
  },
  /* Osinkotuottojen tarkistus */
  "checkingDividendIncome": {
    private: {
      auditingProcedureGoals: t('auditing:form.financialIncome.checkingDividendIncomeGoalsValue')
    }
  },
  /* Rahoitustuottojen analyyttinen tarkastus */
  "reconciliationOfFinancialExpenses": {
    private: {
      auditingProcedureGoals: t('auditing:form.financialIncome.reconciliationOfFinancialExpensesGoalsValue')
    }
  },
  /* Pysyvistä vastaavista saatujen tuottojen tarkastus */
  "examinationOfImpairments": {
    private: {
      auditingProcedureGoals: t('auditing:form.financialIncome.examinationOfImpairmentsGoalsValue')
    }
  },
  /* V - Varaukset */
  /* Pakollisten varausten tarkastus */
  "mandatoryReservationCheck": {
    private: {
      auditingProcedureGoals: t('auditing:form.reserves.mandatoryReservationCheckGoalsValue')
    }
  },
  /* Varausten tarkastus - saldovahvistukset */
  "checkOfReservation": {
    private: {
      auditingProcedureGoals: t('auditing:form.reserves.checkOfReservationGoalsValue')
    }
  },
  /* P1 - Vaihto-omaisuus */
  /* Varastolistan täsmäytys kirjanpitoon */
  "reconciliationOfInventory": {
    private: {
      auditingProcedureGoals: t('auditing:form.warehouses.reconciliationOfInventoryGoalsValue')
    }
  },
  /* Varaston muutoksen täsmäytys tuloksen ja taseen välillä */
  "inventoryChangeReconciliation": {
    private: {
      auditingProcedureGoals: t('auditing:form.warehouses.inventoryChangeReconciliationGoalsValue')
    }
  },
  /* Varaston arvostus: hankintamenon testaus */
  "costTesting": {
    private: {
      auditingProcedureGoals: t('auditing:form.warehouses.costTestingGoalsValue')
    }
  },
  /* Varaston arvostus: varastonarvon vertaaminen myyntihintaan */
  "comparingTheInventory": {
    private: {
      auditingProcedureGoals: t('auditing:form.warehouses.comparingTheInventoryGoalsValue')
    }
  },
  /* Data-analyysi */
  "dataAnalysis": {
    private: {
      auditingProcedureGoals: t('auditing:form.warehouses.dataAnalysisGoalsValue')
    }
  },
  /* Varaston olemassaolon tarkastus: inventaariin osallistuminen */
  "participationInInventory": {
    private: {
      auditingProcedureGoals: t('auditing:form.warehouses.participationInInventoryGoalsValue')
    }
  },
  /* Varaston kierron tarkastuminen */
  "checkingStock": {
    private: {
      auditingProcedureGoals: t('auditing:form.warehouses.checkingStockGoalsValue')
    }
  },
  /* L - Henkilöstökulut */
  /* Palkkojen analyyttinen tarkastelu */
  "analyticalReviewOfWages": {
    private: {
      auditingProcedureGoals: t('auditing:form.personelCosts.analyticalReviewOfWagesGoalsValue')
    }
  },
  /* Palkkojen analyyttinen tarkastus */
  "analyticalAuditOfWages": {
    private: {
      auditingProcedureGoals: t('auditing:form.personelCosts.analyticalAuditOfWagesGoalsValue')
    }
  },
  /* Sivukulujen analyyttinen tarkastus */
  "analyticalExaminationOfSideCosts": {
    private: {
      auditingProcedureGoals: t('auditing:form.personelCosts.analyticalExaminationOfSideCostsGoalsValue')
    }
  },
  /* Palkkojen täsmäytys tulorekisterin yhteenvetoon */
  "analyticalAuditOfVacationPayAccruals": {
    private: {
      auditingProcedureGoals: t('auditing:form.personelCosts.analyticalAuditOfVacationPayAccrualsGoalsValue')
    }
  },
  /* Lomapalkkavelan täsmäytys ja analyyttinen tarkastus */
  "reconciliationOfSalaries": {
    private: {
      auditingProcedureGoals: t('auditing:form.personelCosts.reconciliationOfSalariesGoalsValue')
    }
  },
  /* Henkilöstökulujaksotusten tarkastus */
  "inspectionOfPersonnelExpenseAccurals": {
    private: {
      auditingProcedureGoals: t('auditing:form.personelCosts.inspectionOfPersonnelExpenseAccuralsGoalsValue')
    }
  },
  /* Johdon palkkojen tarkastus */
  "managementSalaryAudit": {
    private: {
      auditingProcedureGoals: t('auditing:form.personelCosts.managementSalaryAuditGoalsValue')
    }
  }
};

export const mapActionKeyToFormStrings = (
  actionKey?: string,
  template?: AuditingTemplate,
): Record<string, string> => {
  const defaultForms: Record<string, string> = {
    auditingReference: t(
      'auditing:form.auditingProcedures.auditingReferenceValue'
    ),
    auditingComments: t(
      'auditing:form.auditingProcedures.auditingCommentsValue'
    ),
    auditingProcedureGoals: t(
      'auditing:form.auditingProcedures.auditingProcedureGoalsValue'
    ),
  };

  let matchedFormStrings: Mapping | undefined = {};
  Object.keys(forms).forEach(key => {
    if (actionKey === key) {
      // Default to private template if undefined;
      matchedFormStrings = forms[key][template ?? AuditingTemplate.private];
    }
  });

  /* Return defaults, if something matches override the default .*/
  return { ...defaultForms, ...matchedFormStrings };
};
