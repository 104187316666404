import React from 'react';
import styled from 'styled-components';
import Icon from './Icon';

const SpinnerContainer = styled.div`
  display: flex;
  flex: auto;
  justify-content: center;
  align-items: center;

  &.is-full-screen-loader {
    z-index: 9;
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
  }
`;

const StyledSpinner = styled.div`
  animation: rotating 1.5s linear infinite;

  @keyframes rotating {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
`;

interface Props {
  id?: string;
  isFullScreen?: boolean;
  size?: 'sm' | 'md' | 'lg';
  negativeColor?: boolean;
}

const Spinner: React.FC<Props> = ({
  id,
  isFullScreen = false,
  size = 'md',
  negativeColor,
}) => {
  const isFullScreenClass = isFullScreen ? 'is-full-screen-loader' : '';
  const spinnerSize =
    size === 'lg' || isFullScreen ? 64 : size === 'sm' ? 14 : 32;

  return (
    <SpinnerContainer id={id} data-testid={id} className={isFullScreenClass}>
      <StyledSpinner>
        <Icon
          type="Hourglass"
          size={spinnerSize}
          color={negativeColor ? 'background' : undefined}
        />
      </StyledSpinner>
    </SpinnerContainer>
  );
};

export default Spinner;
