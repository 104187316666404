import { createGlobalStyle } from 'styled-components';
import { Colors } from './theme.colors';
import { Fonts } from './theme.fonts';
import { headingSize, fontSize } from './theme.utils';

export const GlobalStyle = createGlobalStyle`
  html {
    font-size: 16px;
    box-sizing: border-box;
  }

  *, *:before, *:after {
    box-sizing: inherit;
  }

  *:focus {
    outline-color: ${Colors.secondary};
    :-moz-focusring {
      outline: solid ${Colors.secondary};
    }
  }
  input:focus {
    border-color: ${Colors.secondary};
  }
  input[type="checkbox"]:focus + label::before {
    outline: ${Colors.secondary} auto 1px;
  }

  label {
    white-space: pre-line;
  }
  
  body {
    margin: 0;
    font-family: ${Fonts.Main};
    background-color: ${Colors.background};
    color: ${Colors.text};
    ${fontSize(0.875, 1)};
  }

  a, .button-variant__link {
    color: ${Colors.text};
    text-decoration: underline;
    :hover {
      text-decoration: none;
    }
  }

  ul, ol {
    list-style-type: none;
    padding: 0;
  }

  button {
    padding: 0;
    background-color: transparent;
    border: none;
    cursor: pointer;
  }

  button, input, textarea {
    font-size: inherit;
    font-family: inherit;
  }
  
  h1, h2, h3, h4, h5, h6 {
    color: ${Colors.heading};
    font-family: ${Fonts.Secondary};
    margin: 0;
    margin-bottom: ${p => p.theme.spacing.md};
  }

  h2, h3, h4, h5, h6 {
    margin-top: ${p => p.theme.spacing.xl};
  }

  h1 {
    ${headingSize('h1')};
    margin-top: ${p => p.theme.spacing.md};
  }
  h2 {
    ${headingSize('h2')};
  }
  h3 {
    ${headingSize('h3')};
  }
  h4 {
    ${headingSize('h4')};
  }
  h5 {
    ${headingSize('h5')};
  }
  h6 {
    ${headingSize('h6')};
  }

  p {
    margin: ${p => p.theme.spacing.md} 0;
  }

  .flex-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .flex-row-gap {
    > *:not(:first-child) {
      margin-left: ${p => p.theme.spacing.md};
    }
  }
  .flex-1 { flex: 1; }
  .justify-start { justify-content: start; }
  .justify-end { justify-content: end; }
  .justify-evenly { justify-content: evenly; }
  .align-start {align-items: start; }
  .align-end { align-items: end; }
  .flex-row-reverse { flex-direction: row-reverse; }
  .flex-wrap { flex-wrap: wrap; }

  .text-left { text-align: left; }
  .text-center { text-align: center; }
  .text-right { text-align: right; }

  .text-normal { font-weight: normal; }
  .text-bold { font-weight: bold; }
  .text-italic { font-style: italic; }
  .text-accent { color: ${p => p.theme.color.accent}; }
  .text-uppercase { text-transform: uppercase; }
  .nowrap { white-space: nowrap; }

  .pull-right { margin-left: ${p => p.theme.spacing.lg}; }

  .is-spinning {
    animation-name: spin;
    animation-duration: 1200ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  }

  @media print {
    .no-print { display: none; }
  }

  @keyframes spin {
    from { transform: rotate(0deg); }
    to { transform: rotate(360deg); }
  }

  @keyframes slideFromTop {
    0% { transform: translateY(-110vh); }
    100% { transform: translateX(0); }
  }
`;
