import { AuditFindingsTable } from '../../../components/auditing/Errors.AuditFindings';
import ErrorsImpact from '../../../components/auditing/Errors.ImpactTable';
import {
  defaultAuditingProcedures,
  transformRemarkToAuditingProcedure,
} from '../auditing/auditingProceduresUtils';
import { SpreadSheet } from '../auditing/reUsables/SpreadSheet';
import { FormFieldBuilder } from '../FormFieldBuilder';
import AuditingJson from '../../../i18n/locales/fi/auditing.json';

/**
 * Please note:
 * This section doesn't have its own form, since it's manipulating form data defined for auditingProcedures section!
 */

// NOTE: This section is using other section's form
type SectionFormType = AuditingProceduresForm;

const sectionKey: AuditingSectionKey = 'errors';
const formSectionKey: AuditingSectionKey = 'auditingProcedures';

export const tBase = `auditing:form.${sectionKey}`;

const formBuilder = new FormFieldBuilder<SectionFormType>({ sectionKey });

const jsonVocab = AuditingJson.form.errors;

const defaultFormState: DefaultFormState<SectionFormType> = ({ store }) => ({
  auditingProcedures: [
    ...store.auditingStore
      .getAuditingRemarks()
      .map(transformRemarkToAuditingProcedure),
    ...defaultAuditingProcedures(store),
  ],
  shouldUpdateAuditPlanDueToErrors: null,
  shouldUpdateAuditPlanDueToErrorsDetails: '',

  useSheet: false,
  spreadsheet: [
    {
      cellData: [],
    },
  ],
  natureOfCorrected: '',
  fixedAloneOrTogether: '',
  fixedComments:
    'Korjaamattomien virheellisyyksien arviointiin voidaan sisällyttää esimerkiksi tieto siitä, onko olennaisuusrajat ylitetty ja miten korjaamattomat virheet vaikuttavat esimerkiksi tunnuslukuihin',
});

const formFields: FormFields<SectionFormType> = ({ formState, auditing }) => [
  formBuilder.accordionGroup({
    titleKey: 'table.notFixed',
    open: true,
    items: [
      formBuilder.custom(props => (
        <ErrorsImpact type="notFixed" auditing={auditing} {...props} />
      )),
    ],
  }),

  formBuilder.accordionGroup({
    titleKey: 'table.fixed',
    open: true,
    items: [
      formBuilder.custom(props => (
        <ErrorsImpact type="fixed" auditing={auditing} {...props} />
      )),
      formBuilder.checkboxGroup({
        accessor: 'fixedAloneOrTogether',
        plainLabel:
          'Ovatko korjaamattomat virheet yksinään tai erikseen olennaisia?',
        options: ['false', 'true'],
      }),
      formBuilder.textArea({
        accessor: 'fixedComments',
        plainLabel: 'Esitä johtopäätökset ja perustelut',
      }),
    ],
  }),

  formBuilder.accordionGroup({
    titleKey: 'table.auditFindings',
    open: true,
    items: [formBuilder.custom(props => <AuditFindingsTable {...props} />)],
  }),

  formBuilder.accordionGroup({
    titleKey: 'table.notStarted',
    open: true,
    items: [
      formBuilder.custom(props => (
        <ErrorsImpact type="notStarted" auditing={auditing} {...props} />
      )),
    ],
  }),

  formBuilder.group({
    items: [
      formBuilder.boolean({ accessor: 'useSheet' }),
      formBuilder.custom(props =>
        formState.useSheet ? <SpreadSheet id="spreadsheet" {...props} /> : null
      ),
    ],
  }),

  formBuilder.group({
    items: [
      formBuilder.boolean({
        accessor: 'shouldUpdateAuditPlanDueToErrors',
        options: ['no', 'yes'],
        detailsAccessor: 'shouldUpdateAuditPlanDueToErrorsDetails',
        detailsHidden: !formState.shouldUpdateAuditPlanDueToErrors,
      }),

      formBuilder.checkboxGroup({
        accessor: 'natureOfCorrected',
        plainLabel:
          'Korjattujen ja korjaamattomien virheiden luonne tai niiden yhteismäärä',
        options: Object.keys(jsonVocab.natureOfCorrectedOptions),
      }),
    ],
  }),
];

const section: AuditingSectionDefinition<SectionFormType> = {
  sectionKey,
  formSectionKey,
  defaultFormState,
  formFields,
  depending: [],
};

export default section;
