import { getWindowDimensions } from '../../../../utils';
import { FormFieldBuilder } from '../../FormFieldBuilder';
import { SpreadSheet } from '../reUsables/SpreadSheet';

type SectionFormType = OwnCapitalSheetForm;

const sectionKey: AuditingSectionKey = 'ownCapitalSheet';

export const tBase = `auditing:form.${sectionKey}`;

const formBuilder = new FormFieldBuilder<SectionFormType>({ sectionKey });

const defaultFormState: DefaultFormState<SectionFormType> = ({ auditing }) => ({
  spreadsheet1: [
    {
      cellData: [],
    },
  ],
});

const formFields: FormFields<SectionFormType> = ({ formState }) => [
  formBuilder.custom(props => (
    <SpreadSheet
      customHeight={getWindowDimensions().height}
      id="spreadsheet1"
      {...props}
    />
  )),
];

const section: AuditingSectionDefinition<SectionFormType> = {
  sectionKey,
  defaultFormState,
  formFields,
};

export default section;
