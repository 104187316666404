import { Role } from './constants';

const byRole =
  (role: Role) =>
  ({ roles }: Route) => {
    return !roles?.length || roles.includes(role);
  };

export const getUserRoutes = (routes: Route[], role?: Role) => {
  if (!role) return [];
  return routes.filter(byRole(role));
};
