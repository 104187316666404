import { useTranslation } from 'react-i18next';
import { formatCurrency } from '../../utils';
import AuditingTable from '../table/AuditingTable';
import { OnEditProps } from '../table/Table';

const sectionKey: AuditingSectionKey = 'materiality';

type Props = FormFieldProps<MaterialityForm> & {
  readonly?: boolean;
};

export const MaterialitiesTable = ({
  formState,
  patchFormState,
  store,
  readonly,
}: Props) => {
  const { t } = useTranslation();

  const {
    auditingStore: { materialityComputeds },
  } = store;

  if (!formState) return null;

  const tBase = `auditing:form.${sectionKey}`;

  const handleEdit = ({ itemId, value }: OnEditProps<number>) => {
    const updateValue = (
      targetRowId:
        | 'materialityPercent'
        | 'workingMaterialityPercent'
        | 'singleErrorMaterialityPercent'
    ) => {
      if (targetRowId === itemId) return value;
      else return formState[targetRowId];
    };

    patchFormState({
      ...formState,
      materialityPercent: updateValue('materialityPercent'),
      workingMaterialityPercent: updateValue('workingMaterialityPercent'),
      singleErrorMaterialityPercent: updateValue(
        'singleErrorMaterialityPercent'
      ),
    });
  };

  const tableColumns = [
    {
      accessorKey: 'name',
      header: () => '',
    },
    {
      accessorKey: 'materialityPercent',
      header: () => t(`${tBase}.materialityPercent`),
      className: 'text-center width-min nowrap',
      onNumberEdit: readonly ? undefined : handleEdit,
    },
    {
      accessorKey: 'materiality',
      header: () => t(`${tBase}.materialityValue`),
      className: 'text-center width-min nowrap',
    },
  ];

  const readonlyColumns = tableColumns.filter(
    ({ accessorKey }) => accessorKey !== 'materialityPercent'
  );

  const materialities = materialityComputeds(formState);

  const rows = [
    {
      id: 'materialityPercent',
      name: t(`${tBase}.materiality`),
      materialityPercent: formState.materialityPercent,
      materiality: formatCurrency(materialities.materiality),
    },
    {
      id: 'workingMaterialityPercent',
      name: t(`${tBase}.workingMateriality`),
      materialityPercent: formState.workingMaterialityPercent,
      materiality: formatCurrency(materialities.workingMateriality),
    },
    {
      id: 'singleErrorMaterialityPercent',
      name: t(`${tBase}.singleErrorMateriality`),
      materialityPercent: formState.singleErrorMaterialityPercent,
      materiality: formatCurrency(materialities.singleErrorMateriality),
    },
  ];

  return (
    <AuditingTable
      sectionKey={sectionKey}
      title={t(`${tBase}.materialities`)}
      hideHeader={readonly}
      data={rows}
      columns={readonly ? readonlyColumns : tableColumns}
      showGlobalFilter={false}
      disableSort
    />
  );
};

export const MaterialitiesSecondTable = ({
  formState,
  patchFormState,
  store,
  readonly,
}: Props) => {
  const { t } = useTranslation();

  const {
    auditingStore: { materialityComputeds },
  } = store;

  if (!formState) return null;

  const tBase = `auditing:form.${sectionKey}`;

  const handleEdit = ({ itemId, value }: OnEditProps<number>) => {
    const updateValue = (
      targetRowId:
        | 'materialitySecondPercent'
        | 'workingMaterialitySecondPercent'
        | 'singleErrorMaterialitySecondPercent'
    ) => {
      if (targetRowId === itemId) return value;
      else return formState[targetRowId];
    };

    patchFormState({
      ...formState,
      materialitySecondPercent: updateValue('materialitySecondPercent'),
      workingMaterialitySecondPercent: updateValue(
        'workingMaterialitySecondPercent'
      ),
      singleErrorMaterialitySecondPercent: updateValue(
        'singleErrorMaterialitySecondPercent'
      ),
    });
  };

  const tableColumns = [
    {
      accessorKey: 'name',
      header: () => '',
    },
    {
      accessorKey: 'materialitySecondPercent',
      header: () => t(`${tBase}.materialityPercent`),
      className: 'text-center width-min nowrap',
      onNumberEdit: readonly ? undefined : handleEdit,
    },
    {
      accessorKey: 'materialitySecond',
      header: () => t(`${tBase}.materialityValue`),
      className: 'text-center width-min nowrap',
    },
  ];

  const readonlyColumns = tableColumns.filter(
    ({ accessorKey }) => accessorKey !== 'materialitySecondPercent'
  );

  const materialities = materialityComputeds(formState);

  const rows = [
    {
      id: 'materialitySecondPercent',
      name: t(`${tBase}.materiality`),
      materialitySecondPercent: formState.materialitySecondPercent,
      materialitySecond: formatCurrency(materialities.materialitySecond),
    },
    {
      id: 'workingMaterialitySecondPercent',
      name: t(`${tBase}.workingMateriality`),
      materialitySecondPercent: formState.workingMaterialitySecondPercent,
      materialitySecond: formatCurrency(materialities.workingMaterialitySecond),
    },
    {
      id: 'singleErrorMaterialitySecondPercent',
      name: t(`${tBase}.singleErrorMateriality`),
      materialitySecondPercent: formState.singleErrorMaterialitySecondPercent,
      materialitySecond: formatCurrency(
        materialities.singleErrorMaterialitySecond
      ),
    },
  ];

  return (
    <AuditingTable
      sectionKey={sectionKey}
      title={t(`${tBase}.materialities`)}
      hideHeader={readonly}
      data={rows}
      columns={readonly ? readonlyColumns : tableColumns}
      showGlobalFilter={false}
      disableSort
    />
  );
};

export const MaterialitiesThirdTable = ({
  formState,
  patchFormState,
  store,
  readonly,
}: Props) => {
  const { t } = useTranslation();

  const {
    auditingStore: { materialityComputeds },
  } = store;

  if (!formState) return null;

  const tBase = `auditing:form.${sectionKey}`;

  const handleEdit = ({ itemId, value }: OnEditProps<number>) => {
    const updateValue = (
      targetRowId:
        | 'materialityThirdPercent'
        | 'workingMaterialityThirdPercent'
        | 'singleErrorMaterialityThirdPercent'
    ) => {
      if (targetRowId === itemId) return value;
      else return formState[targetRowId];
    };

    patchFormState({
      ...formState,
      materialityThirdPercent: updateValue('materialityThirdPercent'),
      workingMaterialityThirdPercent: updateValue(
        'workingMaterialityThirdPercent'
      ),
      singleErrorMaterialityThirdPercent: updateValue(
        'singleErrorMaterialityThirdPercent'
      ),
    });
  };

  const tableColumns = [
    {
      accessorKey: 'name',
      header: () => '',
    },
    {
      accessorKey: 'materialityThirdPercent',
      header: () => t(`${tBase}.materialityPercent`),
      className: 'text-center width-min nowrap',
      onNumberEdit: readonly ? undefined : handleEdit,
    },
    {
      accessorKey: 'materialityThird',
      header: () => t(`${tBase}.materialityValue`),
      className: 'text-center width-min nowrap',
    },
  ];

  const readonlyColumns = tableColumns.filter(
    ({ accessorKey }) => accessorKey !== 'materialityThirdPercent'
  );

  const materialities = materialityComputeds(formState);

  const rows = [
    {
      id: 'materialityThirdPercent',
      name: t(`${tBase}.materiality`),
      materialityThirdPercent: formState.materialityThirdPercent,
      materialityThird: formatCurrency(materialities.materialityThird),
    },
    {
      id: 'workingMaterialityThirdPercent',
      name: t(`${tBase}.workingMateriality`),
      materialityThirdPercent: formState.workingMaterialityThirdPercent,
      materialityThird: formatCurrency(materialities.workingMaterialityThird),
    },
    {
      id: 'singleErrorMaterialityThirdPercent',
      name: t(`${tBase}.singleErrorMateriality`),
      materialityThirdPercent: formState.singleErrorMaterialityThirdPercent,
      materialityThird: formatCurrency(
        materialities.singleErrorMaterialityThird
      ),
    },
  ];

  return (
    <AuditingTable
      sectionKey={sectionKey}
      title={t(`${tBase}.materialities`)}
      hideHeader={readonly}
      data={rows}
      columns={readonly ? readonlyColumns : tableColumns}
      showGlobalFilter={false}
      disableSort
    />
  );
};
