import React from 'react';
import styled from 'styled-components';
import { quantityLabel } from '../../utils';
import Icon, { IconColor, IconType } from '../Icon';

export const DegreeOfReadinessSectionContainer = styled.div`
  margin-top: ${p => p.theme.spacing.md};
  margin-right: ${p => p.theme.spacing.xxl};
  margin-bottom: ${p => p.theme.spacing.lg};
  padding-left: ${p => p.theme.spacing.md};
  min-width: 180px;

  border-left: 1px solid ${p => p.theme.color.grey100};

  h3 {
    margin-top: 0;
  }

  a,
  button {
    ${p => p.theme.font.size[14]};
    color: ${p => p.theme.color.secondary};
  }

  .row-item {
    display: flex;
    align-items: center;
    margin: ${p => p.theme.spacing.md} 0;
    ${p => p.theme.font.size[14]};
    white-space: nowrap;
    svg {
      margin-right: ${p => p.theme.spacing.md};
    }
    .dimmed {
      opacity: 0.25;
    }
  }
`;

interface StatusRow {
  icon: IconType;
  color?: IconColor;
  label: string;
  dimmed?: boolean;
}

interface Props {
  title: string;
  statusInformation: AuditingStatusInformation;
}

const StatusSection: React.FC<Props> = ({ title, statusInformation }) => {
  const getLabel = (key: AuditingSectionStatus) =>
    quantityLabel(
      statusInformation[key],
      `auditing:statusInformation.${key}Quantity`
    );

  const defaultColor: IconColor = 'grey200';

  const getColor = (count: number, color: IconColor) =>
    count ? color : defaultColor;

  const statusRows: StatusRow[] = [
    {
      icon: 'Approved',
      color: getColor(statusInformation.approved, 'success'),
      label: getLabel('approved'),
      dimmed: statusInformation.approved === 0,
    },
    {
      icon: 'Check',
      color: getColor(statusInformation.finished, 'success'),
      label: getLabel('finished'),
      dimmed: statusInformation.finished === 0,
    },
    {
      icon: 'Hourglass',
      color: getColor(statusInformation.started, 'primary'),
      label: getLabel('started'),
      dimmed: statusInformation.started === 0,
    },
    {
      icon: 'Minus',
      color: defaultColor,
      label: getLabel('not_started'),
      dimmed: statusInformation.not_started === 0,
    },
  ];

  return (
    <DegreeOfReadinessSectionContainer>
      <h3>{title}</h3>

      {statusRows.map(({ icon, color, label, dimmed }) => (
        <div key={label} className="row-item">
          <Icon type={icon} color={color} />
          <span className={dimmed ? 'dimmed' : ''}>{label}</span>
        </div>
      ))}
    </DegreeOfReadinessSectionContainer>
  );
};

export default StatusSection;
