import { action, makeObservable } from 'mobx';
import { Api } from '../../api/RootApi';
import BaseStore from '../BaseStore';
import { RootStore } from '../RootStore';

export class CommonStore extends BaseStore {
  constructor(rootStore: RootStore, api: Api, token?: string) {
    super(rootStore, api);

    makeObservable(this, {
      fetchFromYtj: action,
    });
  }

  fetchFromYtj = async (params: Api.Payload.FetchFromYtj) => {
    const response = await this.defaultAction<YtjCompany>({
      taskName: 'GET_YTJ_DATA',
      taskType: 'fetching',
      apiRequest: () => this.api.common.fetchFromYtj(params),
    });
    if (response.success) return response.data;
  };
}
