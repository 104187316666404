import { formatDateTime, localizedDate } from './date';
import {tabSettings} from '../projectConfig.js'
export enum AuditingTemplate {
  private = 'private',
  public = 'public',
}

type OmittedSections = {
  [AuditingTemplate.private]: AuditingSectionKey[];
  [AuditingTemplate.public]: AuditingSectionKey[];
};

/**
 * OMMITTED_SECTIONS: (
 * Public = Julkishallinto
 * Private = Osakeyhtiö
 * )
 */
// List what to show depending on the template
export const OMITTED_SECTIONS: OmittedSections = Object.freeze({
  [AuditingTemplate.private]: [
    'governmentTransfersAndTaxRevenues',
  ],
  [AuditingTemplate.public]: [
    //'tradeCreditors',
  ],
});

export const sortAuditings = (a: Auditing, b: Auditing) => {
  return (
    localizedDate(b.startDate).toMillis() -
    localizedDate(a.startDate).toMillis()
  );
};

export const financialYear = (auditing?: Auditing) => {
  if (!auditing) return '';

  const format = 'D';
  const startDate = formatDateTime(auditing.startDate, { format });
  const endDate = formatDateTime(auditing.endDate, { format });
  return `${startDate}-${endDate}`;
};

export const getClassNameForFinancialStatementRow = (
  headingLevel?: AccountRowHeadingLevel
) =>
  headingLevel === 1
    ? 'text-bold text-uppercase'
    : headingLevel && headingLevel >= 2
    ? 'text-bold'
    : 'pull-right';

/**
 * Parses a comma separated list of account ranges.
 *
 * @param accountRanges A comma separated list of account ranges, e.g. "1000-1999,3000,4000-4499,6000"
 * @returns An array of account ranges, e.g. [[1000, 1999], [3000, 3000], [4000, 4499], [6000, 6000]]
 */
const parseAccountRanges = (accountRanges: string): number[][] =>
  accountRanges
    // Strip everything except numbers, commas and dashes
    .replace(/[^0-9,-]/g, '')
    // Split into ranges
    .split(',')
    // Split each range into start and end (end is optional)
    .map(item => item.split('-').map(Number));

/**
 * Sums up the current and prior year values of a list of grouped general ledger items.
 * @param generalLedgerItems A list of grouped general ledger items
 * @returns An object containing the summed up current and prior year values
 */
const sumCurrentAndPriorYearValues = (
  generalLedgerItems: GroupedGeneralLedgerItem[]
) =>
  generalLedgerItems.reduce(
    (acc, { currentYear, priorYear }) => ({
      currentYear: acc.currentYear + (currentYear ?? 0),
      priorYear: acc.priorYear + (priorYear ?? 0),
    }),
    { currentYear: 0, priorYear: 0 }
  );

export const getSummedAccountItems = (
  groupedGeneralLedger: GroupedGeneralLedger | undefined | null,
  accountMappings: AccountMappings
): SummedAccountItem[] => {
  return Object.keys(accountMappings).map(key => {
    const accountRanges = parseAccountRanges(accountMappings[key]);

    // Filter out all rows that don't match the account range
    const matchingAccountRows = (groupedGeneralLedger ?? []).filter(
      ({ account }) => {
        return accountRanges.some(([start, end]) => {
          if (start && end) return account >= start && account <= end;
          if (start) return account === start;
          return false;
        });
      }
    );

    // Sum up the current and prior year values
    const { currentYear, priorYear } =
      sumCurrentAndPriorYearValues(matchingAccountRows);

    return {
      id: key,
      accountKey: key,
      accountName: key,
      currentYear,
      priorYear,
      accountRange: accountMappings[key],
    };
  });
};

export const getAccountMappingValues = (
  groupedGeneralLedger: GroupedGeneralLedger | undefined | null,
  accountMappings: AccountMappings
): AccountMappingValues => {
  const accountMappingValues = getSummedAccountItems(
    groupedGeneralLedger,
    accountMappings
  );

  return accountMappingValues.reduce(
    (acc, { accountKey, currentYear, priorYear }) => ({
      ...acc,
      [accountKey]: {
        cy: currentYear ?? 0,
        py: priorYear ?? 0,
      },
    }),
    {}
  );
};

/**
 * Helper function to hide or show 'scoping' auditing route based on internalControlAndOperatingEnvironment's 'isInterimAudit' boolean.
 * false = hide, true = show
 */
export function shouldShowAnalyzesRoute(isInterimAudit: any): boolean {
  return isInterimAudit !== false;
}

/**
 * Helper for getting 'isInterimAudit' boolean value from internalControlAndOperatingEnvironment.
 * @param auditing any
 * @returns boolean
 */
export function getIsInterimAudit(isInterimAudit: any): boolean {
  return isInterimAudit;
}

/**
 * Determines the correct dependency based on the 'isInterimAudit' value.
 * @param isInterimAudit boolean
 * @param interimSections (keyof AuditingSections)[]
 * @param notInterimSections (keyof AuditingSections)[]
 * @returns (keyof AuditingSections)[]
 */
export function getDependingSections(
  isInterimAudit: boolean,
  interimSections: (keyof AuditingSections)[],
  notInterimSections: (keyof AuditingSections)[]
): (keyof AuditingSections)[] {
  return isInterimAudit ? interimSections : notInterimSections;
}

/**
 * Helper function to hide or show tabs in detailedplan.
 * @param auditing any
 * @param batchString any
 * @param category Array<string>
 * @returns true if the tab is chosen in detailedplan, otherwise false
 */
export function isTabChosenInDetailedPlan(auditing: any, batchString: any, category: Array<string>) {
  var partsToAlwaysDisplay = tabSettings.partsToDisplayDespiteDetailedPlan;

  // Here we ensure that if in G-management innerCircle is chosen in detailedplan, we will display innerCircleTab,
  // and if it is not chosen, we will not display innerCircleTab
  if(hidePartBasedOnDetailedPlan(auditing, "innerCircle" ,"G-management") === false){
    !partsToAlwaysDisplay.includes("innerCircleTab") && partsToAlwaysDisplay.push("innerCircleTab")
  }else if (partsToAlwaysDisplay.includes("innerCircleTab")){
    partsToAlwaysDisplay = partsToAlwaysDisplay.filter((item:any) => item !== "innerCircleTab")
  }

  // Here we ensure that if in F-financialStatement manualExports is chosen in detailedplan, we will display innerCircleTab,
  // and if it is not chosen, we will not display innerCircleTab
  if(hidePartBasedOnDetailedPlan(auditing, "manualExports" ,"F-financialStatement") === false){
    !partsToAlwaysDisplay.includes("manualExports") && partsToAlwaysDisplay.push("manualExports")
  }else if (partsToAlwaysDisplay.includes("manualExports")){
    partsToAlwaysDisplay = partsToAlwaysDisplay.filter((item:any) => item !== "manualExports")
  }
  // F9-continuityOfOperation
  if(hidePartBasedOnDetailedPlan(auditing, "continuityOfOperation" ,"F-financialStatement") === false){
    !partsToAlwaysDisplay.includes("continuityOfOperation") && partsToAlwaysDisplay.push("continuityOfOperation")
  }else if (partsToAlwaysDisplay.includes("continuityOfOperation")){
    partsToAlwaysDisplay = partsToAlwaysDisplay.filter((item) => item !== "continuityOfOperation")
  }
  // F1-assignmentsFirstTime
  // Add ""...auditing?.permanentData?.form.isFirstAuditing" to hide this part if it is not the first auditing
  // eslint-disable-next-line
  if(hidePartBasedOnDetailedPlan(auditing, "assignmentsFirstTime" ,"F-financialStatement") === false || auditing?.permanentData?.form.isFirstAuditing !== null && auditing?.permanentData?.form.isFirstAuditing !== undefined) {
    !partsToAlwaysDisplay.includes("assignmentsFirstTime") && partsToAlwaysDisplay.push("assignmentsFirstTime")
  } else if (partsToAlwaysDisplay.includes("assignmentsFirstTime")) {
    partsToAlwaysDisplay = partsToAlwaysDisplay.filter((item) => item !== "assignmentsFirstTime")
  }

  /*
    If in detailedplan, tp or period checkboxes are true, display this
    and if in detailedplan, the part has been removed, dont display anything
  */
  
  if(category.includes("auditing") && !tabSettings.displayAllParts){

    const hasOwnProperty = auditing.hasOwnProperty("incomeStatementAndBalance") && auditing.hasOwnProperty("auditingProcedures");
    const hasOwnPropertyScoping = auditing.hasOwnProperty("incomeStatementAndBalanceScoping") && auditing.hasOwnProperty("auditingProcedures");

    if((auditing && hasOwnProperty) || (auditing && hasOwnPropertyScoping)) {

      if(partsToAlwaysDisplay.includes(batchString)){
        return true
      } else {// if everything is set up, and the part is not in partsToAlwaysDisplay
        
        var betterlist: any[] = []

        if(auditing?.incomeStatementAndBalance?.form?.hasOwnProperty("incomeStatement")){
          betterlist = [...betterlist, ...[
            ...auditing.incomeStatementAndBalance.form.incomeStatement, 
            ...auditing.incomeStatementAndBalance.form.balanceAssets, 
            ...auditing.incomeStatementAndBalance.form.balanceLiabilities
          ]]
        }

        if(auditing?.incomeStatementAndBalanceScoping?.form?.hasOwnProperty("incomeStatement")){
          betterlist = [...betterlist, ...[
            ...auditing.incomeStatementAndBalanceScoping.form.incomeStatement, 
            ...auditing.incomeStatementAndBalanceScoping.form.balanceAssets, 
            ...auditing.incomeStatementAndBalanceScoping.form.balanceLiabilities
          ]]
        }
        const riskContainingObjectsArray = betterlist.filter((item:any) => item.hasOwnProperty("risk") && (item.risk === "significant" || item.risk === "limitedRisk"))
          const riskIsSignificant = riskContainingObjectsArray.find((object) => object.hasOwnProperty("classKey") && object.classKey.includes("-") ? object.classKey.split("-")[1].toLowerCase() === batchString.toLowerCase() : false) !== undefined
      
            const list = []
            if(auditing.auditingProcedures.hasOwnProperty("form") && auditing.auditingProcedures.form.hasOwnProperty("auditingProcedures")){
              list.push(...auditing.auditingProcedures.form.auditingProcedures.filter((object: { batch: string; }) => object.batch.includes("-") ? object.batch.split("-")[1].toLowerCase() === batchString.toLowerCase() : false))
            }

            const auditingProcedures = list;
      
            var shouldDisplay = false;
            for(var i=0; i< auditingProcedures.length; i++){
              if(auditingProcedures[i].tp === true || auditingProcedures[i].period === true){
                shouldDisplay = true
              }
            }
            return riskIsSignificant && shouldDisplay
      }
    } else return false
  }else return true
}

/**
 * Helper function to hide or show parts in/of ItAndProcesses.
 * @param auditing any
 * @param target any
 * @returns true to show in a auditing part, otherwise show in ItAndProcesses.
 */
export function hidePartBasedOnItAndProcesses(auditing: any, target: any) {
  /*
    If in itAndProcesses, toDocument is true, we display the part. otherwise we hide it
    The part is also hidden if the user removes the part from itAndProcesses.
    example: if target = "purchases", we display this part in K1-materialsAndServices batch
  */
    if (auditing.hasOwnProperty("itAndProcesses") && auditing.itAndProcesses.form.hasOwnProperty("businessProcesses")) {
      const businessProcesses = auditing.itAndProcesses.form.businessProcesses.filter((item: { kind: BusinessProcess; }) => item.kind === target);
      const thisItem = businessProcesses.find((item: { kind: BusinessProcess; }) => item.kind === target);

      if (thisItem && thisItem.toDocument === true) {
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
}

export function hidePartBasedOnDetailedPlan(auditing: any, target: any, batchString: string) {
  if (!auditing) return true;
  /*
    If in detailedplan, tp or period checkboxes are true, we display the part. otherwise we hide it
    The part is also hidden if the user removes the part from detailedplan.
  */
  if(auditing.hasOwnProperty("auditingProcedures") && auditing.auditingProcedures.form.hasOwnProperty("auditingProcedures")){
    const listOfAuditingProcedures = []
    const auditingProcedures = auditing.auditingProcedures.form.auditingProcedures.filter((item: { batch: string; }) => item.batch === batchString)
    for(var i=0; i<auditingProcedures.length; i++){
      listOfAuditingProcedures.push(auditingProcedures[i].actionKey)
    }

    const thisItem = auditingProcedures.find((item: { actionKey: any; }) => item.actionKey === target)

    const tpOrPeriodIsTrue = thisItem === undefined ? false : (
      thisItem.tp === true ? true : 
      thisItem.period === true ? true : false
    )

    if(tpOrPeriodIsTrue){
      return false
    }else return true
  } else return true
}

export function hidePartBasedOnUseSheetSelect(auditing: any, batchString: any){
  if(auditing.hasOwnProperty(batchString) && auditing[batchString].hasOwnProperty("form") && auditing[batchString].form.hasOwnProperty("useSheetTab")){
    return auditing[batchString].form.useSheetTab;
  }else return false
}

/**
 * Function to create a field identifier.
 * @param batchString string
 * @param one string
 * @param two string
 * @returns string
 */
export function createFieldIdentifier(batchString: string, one: string, two: string){
  return (batchString + one + two) as any;
}