import { observer } from 'mobx-react-lite';
import React, { PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { useStore } from '../../store/RootContext';
import { AuditingProcedureResult } from '../../views/auditingSections/auditing/auditingProceduresUtils';
import { Select, TextInputModal } from '../inputs';

const tBase = 'auditing:form.auditingProcedures';

const Container = styled.section`
  > * {
    :not(:last-child) {
      margin-bottom: ${p => p.theme.spacing.lg};
    }
  }
`;

interface Props extends PropsWithChildren {
  auditingProcedure: AuditingProcedure;
}

const AuditingProcedureItem: React.FC<Props> = observer(
  ({ auditingProcedure, children }) => {
    const { t } = useTranslation();

    const {
      auditingStore: { auditing, getAuditingSection, editEntity },
    } = useStore();

    const handleSave = (newAuditingProcedure: AuditingProcedure) => {
      const section = getAuditingSection('auditingProcedures');

      if (!auditing?.id || !section) return;

      const auditingProcedures =
        section as AuditingSection<AuditingProceduresForm>;

      const auditingProceduresBatch: AuditingProcedure[] =
        auditingProcedures.form.auditingProcedures.map(procedure =>
          procedure.id === newAuditingProcedure.id
            ? newAuditingProcedure
            : procedure
        );

      const auditingPatch: Partial<Auditing> = {
        auditingProcedures: {
          ...auditingProcedures,
          form: {
            ...auditingProcedures.form,
            auditingProcedures: auditingProceduresBatch,
          },
        },
      };

      editEntity({ id: auditing.id, data: auditingPatch });
    };

    const handleSelectChange =
      (key: keyof AuditingProcedure) =>
      (value?: React.SetStateAction<AuditingProcedureResult | undefined>) => {
        if (typeof value === 'string') {
          handleSave({ ...auditingProcedure, [key]: value });
        }
      };

    const handleTextChange =
      (key: keyof AuditingProcedure) => (newValue: string) => {
        handleSave({ ...auditingProcedure, [key]: newValue });
      };

    const renderTextInputModal = (
      key: keyof AuditingProcedure,
      { showContextInfo }: { showContextInfo?: boolean } = {}
    ) => (
      <TextInputModal
        editOnlyInModal
        modalTitle={t(
          `auditing:auditingProcedureAction.${auditingProcedure.batch}.${auditingProcedure.actionKey}`
        )}
        label={t(`${tBase}.${key}`)}
        inputLabel={t(`${tBase}.${key}`)}
        value={auditingProcedure[key] ? `${auditingProcedure[key]}` : ''}
        placeholder={t(`${tBase}.${key}Placeholder`)}
        contextInfo={
          showContextInfo ? { text: t(`${tBase}.${key}Info`) } : undefined
        }
        onChange={handleTextChange(key)}
      />
    );

    return (
      <Container>
        <Select<AuditingProcedureResult>
          fullWidth
          label={t(`${tBase}.auditingResult`)}
          value={auditingProcedure.auditingResult ?? undefined}
          options={Object.values(AuditingProcedureResult)}
          displayValue={option => t(`${tBase}.auditingResultOptions.${option}`)}
          setValue={handleSelectChange('auditingResult')}
        />

        {/* {renderTextInputModal('auditingProcedurePerceptions')} */}
        {renderTextInputModal('auditingComments')}
        {renderTextInputModal('auditingProcedureGoals')}
        {renderTextInputModal(
          'auditingProcedureNatureAndScopeAndExpectations',
          { showContextInfo: true }
        )}
        {renderTextInputModal('auditingReference')}

        {children}
      </Container>
    );
  }
);

export default AuditingProcedureItem;
