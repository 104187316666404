import { useTranslation } from 'react-i18next';
import { confirmDelete } from '../../utils';
import { emptySeparateMateriality } from '../../views/auditingSections/planning/materialityUtils';
import AuditingTable from '../table/AuditingTable';
import { OnEditProps } from '../table/Table';

const sectionKey: AuditingSectionKey = 'materiality';

export const SeparateMaterialities = ({
  formState,
  patchFormState,
}: FormFieldProps<MaterialityForm>) => {
  const { t } = useTranslation();

  if (!formState) return null;

  const tBase = `auditing:form.${sectionKey}.separateMaterialities`;

  const data = formState.separateMaterialities ?? [];

  const patchSeparateMaterialities = (patch: SeparateMateriality[]) => {
    patchFormState({
      ...formState,
      separateMaterialities: patch,
    });
  };

  function handleEdit<T>({
    itemId,
    rowIndex,
    columnId,
    value,
  }: OnEditProps<T>) {
    const patch = (formState.separateMaterialities ?? []).map((row, index) => {
      const idMatch = itemId !== undefined && itemId === row.id;
      const rowIndexMatch = itemId === undefined && rowIndex === index;
      if (idMatch || rowIndexMatch) return { ...row, [columnId]: value };
      return row;
    });
    patchSeparateMaterialities(patch);
  }

  const tableColumns = [
    {
      accessorKey: 'name',
      className: 'width-max',
      header: () => t(`${tBase}.name`),
      onEdit: (editProps: OnEditProps<string>) => {
        handleEdit<string>(editProps);
      },
    },
    {
      accessorKey: 'methodOrBasis',
      header: () => t(`${tBase}.methodOrBasis`),
      onEdit: (editProps: OnEditProps<string>) => {
        handleEdit<string>(editProps);
      },
    },
    {
      accessorKey: 'materiality',
      header: () => t(`${tBase}.materiality`),
      onNumberEdit: (editProps: OnEditProps<number>) => {
        handleEdit<number>(editProps);
      },
      className: 'nowrap',
    },
    {
      accessorKey: 'workingMateriality',
      header: () => t(`${tBase}.workingMateriality`),
      onNumberEdit: (editProps: OnEditProps<number>) => {
        handleEdit<number>(editProps);
      },
      className: 'nowrap',
    },
    {
      accessorKey: 'singleErrorMateriality',
      header: () => t(`${tBase}.singleErrorMateriality`),
      onNumberEdit: (editProps: OnEditProps<number>) => {
        handleEdit<number>(editProps);
      },
      className: 'nowrap',
    },
    {
      accessorKey: 'description',
      header: () => t(`${tBase}.description`),
      onEdit: (editProps: OnEditProps<string>) => {
        handleEdit<string>(editProps);
      },
    },
  ];

  const handleAddNewRow = () => {
    patchFormState({
      ...formState,
      separateMaterialities: [
        ...(formState.separateMaterialities ?? []),
        emptySeparateMateriality(),
      ],
    });
  };

  const handleDeleteRow = ({ id, name }: SeparateMateriality) => {
    if (confirmDelete(name)) {
      const patch = (formState.separateMaterialities ?? []).filter(
        row => row.id !== id
      );
      patchSeparateMaterialities(patch);
    }
  };

  return (
    <AuditingTable<SeparateMateriality>
      sectionKey={sectionKey}
      onAddNewRow={handleAddNewRow}
      title={t(`${tBase}.title`)}
      data={data}
      columns={tableColumns}
      onRowDelete={handleDeleteRow}
      showGlobalFilter={false}
      disableSort
    />
  );
};

export const SeparateSecondMaterialities = ({
  formState,
  patchFormState,
}: FormFieldProps<MaterialityForm>) => {
  const { t } = useTranslation();

  if (!formState) return null;

  const tBase = `auditing:form.${sectionKey}.separateMaterialities`;

  const data = formState.separateSecondMaterialities ?? [];

  const patchSeparateMaterialities = (patch: SeparateSecondMateriality[]) => {
    patchFormState({
      ...formState,
      separateSecondMaterialities: patch,
    });
  };

  function handleEdit<T>({
    itemId,
    rowIndex,
    columnId,
    value,
  }: OnEditProps<T>) {
    const patch = (formState.separateSecondMaterialities ?? []).map(
      (row, index) => {
        const idMatch = itemId !== undefined && itemId === row.id;
        const rowIndexMatch = itemId === undefined && rowIndex === index;
        if (idMatch || rowIndexMatch) return { ...row, [columnId]: value };
        return row;
      }
    );
    patchSeparateMaterialities(patch);
  }

  const tableColumns = [
    {
      accessorKey: 'name',
      className: 'width-max',
      header: () => t(`${tBase}.name`),
      onEdit: (editProps: OnEditProps<string>) => {
        handleEdit<string>(editProps);
      },
    },
    {
      accessorKey: 'methodOrBasis',
      header: () => t(`${tBase}.methodOrBasis`),
      onEdit: (editProps: OnEditProps<string>) => {
        handleEdit<string>(editProps);
      },
    },
    {
      accessorKey: 'materiality',
      header: () => t(`${tBase}.materiality`),
      onNumberEdit: (editProps: OnEditProps<number>) => {
        handleEdit<number>(editProps);
      },
      className: 'nowrap',
    },
    {
      accessorKey: 'workingMateriality',
      header: () => t(`${tBase}.workingMateriality`),
      onNumberEdit: (editProps: OnEditProps<number>) => {
        handleEdit<number>(editProps);
      },
      className: 'nowrap',
    },
    {
      accessorKey: 'singleErrorMateriality',
      header: () => t(`${tBase}.singleErrorMateriality`),
      onNumberEdit: (editProps: OnEditProps<number>) => {
        handleEdit<number>(editProps);
      },
      className: 'nowrap',
    },
    {
      accessorKey: 'description',
      header: () => t(`${tBase}.description`),
      onEdit: (editProps: OnEditProps<string>) => {
        handleEdit<string>(editProps);
      },
    },
  ];

  const handleAddNewRow = () => {
    patchFormState({
      ...formState,
      separateSecondMaterialities: [
        ...(formState.separateSecondMaterialities ?? []),
        emptySeparateMateriality(),
      ],
    });
  };

  const handleDeleteRow = ({ id, name }: SeparateSecondMateriality) => {
    if (confirmDelete(name)) {
      const patch = (formState.separateSecondMaterialities ?? []).filter(
        row => row.id !== id
      );
      patchSeparateMaterialities(patch);
    }
  };

  return (
    <AuditingTable<SeparateSecondMateriality>
      sectionKey={sectionKey}
      onAddNewRow={handleAddNewRow}
      title={t(`${tBase}.title`)}
      data={data}
      columns={tableColumns}
      onRowDelete={handleDeleteRow}
      showGlobalFilter={false}
      disableSort
    />
  );
};

export const SeparateThirdMaterialities = ({
  formState,
  patchFormState,
}: FormFieldProps<MaterialityForm>) => {
  const { t } = useTranslation();

  if (!formState) return null;

  const tBase = `auditing:form.${sectionKey}.separateMaterialities`;

  const data = formState.separateThirdMaterialities ?? [];

  const patchSeparateMaterialities = (patch: SeparateThirdMateriality[]) => {
    patchFormState({
      ...formState,
      separateThirdMaterialities: patch,
    });
  };

  function handleEdit<T>({
    itemId,
    rowIndex,
    columnId,
    value,
  }: OnEditProps<T>) {
    const patch = (formState.separateThirdMaterialities ?? []).map(
      (row, index) => {
        const idMatch = itemId !== undefined && itemId === row.id;
        const rowIndexMatch = itemId === undefined && rowIndex === index;
        if (idMatch || rowIndexMatch) return { ...row, [columnId]: value };
        return row;
      }
    );
    patchSeparateMaterialities(patch);
  }

  const tableColumns = [
    {
      accessorKey: 'name',
      className: 'width-max',
      header: () => t(`${tBase}.name`),
      onEdit: (editProps: OnEditProps<string>) => {
        handleEdit<string>(editProps);
      },
    },
    {
      accessorKey: 'methodOrBasis',
      header: () => t(`${tBase}.methodOrBasis`),
      onEdit: (editProps: OnEditProps<string>) => {
        handleEdit<string>(editProps);
      },
    },
    {
      accessorKey: 'materiality',
      header: () => t(`${tBase}.materiality`),
      onNumberEdit: (editProps: OnEditProps<number>) => {
        handleEdit<number>(editProps);
      },
      className: 'nowrap',
    },
    {
      accessorKey: 'workingMateriality',
      header: () => t(`${tBase}.workingMateriality`),
      onNumberEdit: (editProps: OnEditProps<number>) => {
        handleEdit<number>(editProps);
      },
      className: 'nowrap',
    },
    {
      accessorKey: 'singleErrorMateriality',
      header: () => t(`${tBase}.singleErrorMateriality`),
      onNumberEdit: (editProps: OnEditProps<number>) => {
        handleEdit<number>(editProps);
      },
      className: 'nowrap',
    },
    {
      accessorKey: 'description',
      header: () => t(`${tBase}.description`),
      onEdit: (editProps: OnEditProps<string>) => {
        handleEdit<string>(editProps);
      },
    },
  ];

  const handleAddNewRow = () => {
    patchFormState({
      ...formState,
      separateThirdMaterialities: [
        ...(formState.separateThirdMaterialities ?? []),
        emptySeparateMateriality(),
      ],
    });
  };

  const handleDeleteRow = ({ id, name }: SeparateThirdMateriality) => {
    if (confirmDelete(name)) {
      const patch = (formState.separateThirdMaterialities ?? []).filter(
        row => row.id !== id
      );
      patchSeparateMaterialities(patch);
    }
  };

  return (
    <AuditingTable<SeparateThirdMateriality>
      sectionKey={sectionKey}
      onAddNewRow={handleAddNewRow}
      title={t(`${tBase}.title`)}
      data={data}
      columns={tableColumns}
      onRowDelete={handleDeleteRow}
      showGlobalFilter={false}
      disableSort
    />
  );
};
