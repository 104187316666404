import { useTranslation } from 'react-i18next';
import { confirmDelete } from '../../utils';
import { emptyInnerCircleParty } from '../../views/auditingSections/planning/risksUtils';
import AuditingTable from '../table/AuditingTable';
import { OnEditProps, TableColumnDefs } from '../table/Table';

const sectionKey: AuditingSectionKey = 'risks';

const tBase = `auditing:form.${sectionKey}.innerCircleParties`;

export const InnerCircleParties = ({
  formState,
  patchFormState,
}: FormFieldProps<RisksForm>) => {
  const { t } = useTranslation();

  if (!formState) return null;

  const data = formState.innerCircleParties ?? [];

  function handleEdit<T>({
    itemId,
    rowIndex,
    columnId,
    value,
  }: OnEditProps<T>) {
    const patch = formState.innerCircleParties.map((row, index) => {
      const idMatch = itemId !== undefined && itemId === row.id;
      const rowIndexMatch = itemId === undefined && rowIndex === index;
      if (idMatch || rowIndexMatch) return { ...row, [columnId]: value };
      return row;
    });
    patchFormState({ ...formState, innerCircleParties: patch });
  }

  const composeTextInputColumn = (
    key: 'name' | 'basisForBelonging' | 'transaction' | 'basisForTransaction'
  ) => ({
    accessorKey: key,
    header: () => t(`${tBase}.${key}`),
    onEdit: (editProps: OnEditProps<string>) => {
      handleEdit<string>(editProps);
    },
  });

  const tableColumns: TableColumnDefs<InnerCircleParty> = [
    composeTextInputColumn('name'),
    composeTextInputColumn('basisForBelonging'),
    composeTextInputColumn('transaction'),
    composeTextInputColumn('basisForTransaction'),
  ];

  const handleAddNewRow = () => {
    patchFormState({
      ...formState,
      innerCircleParties: [
        ...(formState.innerCircleParties ?? []),
        emptyInnerCircleParty(),
      ],
    });
  };

  const handleDeleteRow = ({ id, name }: InnerCircleParty) => {
    if (confirmDelete(name ?? '')) {
      const patch = (formState.innerCircleParties ?? []).filter(
        row => row.id !== id
      );
      patchFormState({ ...formState, innerCircleParties: patch });
    }
  };

  return (
    <AuditingTable<InnerCircleParty>
      sectionKey={sectionKey}
      onAddNewRow={handleAddNewRow}
      data={data}
      columns={tableColumns}
      onRowDelete={handleDeleteRow}
      showGlobalFilter={false}
      disableSort
    />
  );
};
