import { AuditingTemplate } from '../../../utils';
import accountMapPublicSector from '../planning/accountMapPublicSector';
import accountMapPrivateSector from '../planning/accountMapPrivateSector';

/**
 * Generate account map based on a given template.
 *
 * @param template Auditing template, e.g. 'private' (sector) or 'public' (sector)
 * @returns account map
 */
export const generateAccountMap = (template: AuditingTemplate): AccountMap => {
  switch (template) {
    case AuditingTemplate.private:
      return accountMapPrivateSector;
    case AuditingTemplate.public:
      return accountMapPublicSector;
  }
};

// Flatten account map
export const getFlatAccountMap = (accountMap: AccountMap): AccountMapRow[] =>
  Object.values(accountMap).reduce((acc, curr) => [...acc, ...curr], []);

// Filter account rows based on accountKey
export const getAllAccountMapRowsMatchingClassKey = (
  accountMap: AccountMap,
  batch: AccountClassKey
) =>
  getFlatAccountMap(accountMap).filter(account => account.classKey === batch);

export const getAccountMapRow = (
  accountMap: AccountMap,
  accountKey: AccountKey
) => {
  return getFlatAccountMap(accountMap).find(({ key }) => key === accountKey);
};

export const getAccountRangeFromAccountMap =
  (auditing?: Auditing) => (accountKey: AccountKey) => {
    const accountMap =
      auditing?.accountMap?.form.accountMap ?? auditing?.template
        ? generateAccountMap(auditing.template)
        : undefined;

    if (!accountMap) return '';
    const accountRow = getAccountMapRow(accountMap, accountKey);
    return `${accountRow?.start}-${accountRow?.end}`;
  };
