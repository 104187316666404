// eslint-disable-next-line
import { TableColumnDefs } from '../../../../components/table/Table';
import AuditingTable from '../../../../components/table/AuditingTable';
import { useEffect, useState } from 'react';

interface Props {
  formState: any;
  patchFormState: Function;
  title?: string;
  headerText?: string;
}

interface QuadrupleTableProps {
  formState: any;
  patchFormState: Function;
  title?: string;
  headers?: Array<string>;
  id: string;
}

const TableColumn: React.FC<Props> = ({
  formState,
  patchFormState,
  title,
  headerText,
}) => {
  /*
    Using this function requires you to create in the formstate a variable called
    tableColumnData,
    this contains all the left and right fields of the tableColumn.

    like this: 
    tableColumnData: [
      {
        right: 'rightText',
        left: 'leftText'
      },
      {
        right: 'rightText2',
        left: 'leftText2'
      }
    ]
  */

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  type TableColumnType = {
    right: string;
    left: string;
  };

  useEffect(() => {
    formState && setData(formState.tableColumnData);
  }, [formState]);

  function handleEditField(input: any) {
    const newFormstate = { ...formState };
    var editedItem = newFormstate.tableColumnData[input.rowIndex];

    newFormstate.tableColumnData[input.rowIndex] = {
      right: input.columnId === 'right' ? input.value : editedItem.right,
      left: input.columnId === 'left' ? input.value : editedItem.left,
    };

    patchFormState({
      ...formState,
      tableColumnData: newFormstate.tableColumnData,
    });
  }

  function handleAddField() {
    patchFormState({
      ...formState,
      tableColumnData: [
        ...formState.tableColumnData,
        {
          right: '',
          left: '',
        },
      ],
    });
  }

  function handleRemoveRow(data: any) {
    setLoading(true);
    var newDataList = [];
    for (var i = 0; i < formState.tableColumnData.length; i++) {
      if (
        formState.tableColumnData[i].right === data.right &&
        formState.tableColumnData[i].left === data.left
      ) {
      } else {
        newDataList.push(formState.tableColumnData[i]);
      }
    }

    patchFormState({
      ...formState,
      tableColumnData: newDataList,
    });
    setTimeout(() => setLoading(false), 1);
  }

  const tableColumns: TableColumnDefs<TableColumnType> = [
    {
      accessorKey: 'left',
      className: 'width-half',
      header: () => null,
      onEdit: handleEditField,
    },
    {
      accessorKey: 'right',
      className: 'width-half',
      header: headerText ? () => <span>{headerText}</span> : () => null,
      onEdit: handleEditField,
    },
  ];

  return !loading ? (
    <AuditingTable<TableColumnType>
      data={data}
      title={title}
      columns={tableColumns}
      showGlobalFilter={false}
      disableSort
      onAddNewRow={handleAddField}
      onRowDelete={handleRemoveRow}
    />
  ) : null;
};

const SecondTableColumn: React.FC<Props> = ({
  formState,
  patchFormState,
  title,
  headerText,
}) => {
  type TableColumnType = {
    right: string;
    left: string;
  };
  /*
    Using this function requires you to create in the formstate a variable called
    tableColumnData,
    this contains all the left and right fields of the tableColumn.

    like this: 
    secondTableColumnData: [
      {
        right: 'rightText',
        left: 'leftText'
      },
      {
        right: 'rightText2',
        left: 'leftText2'
      }
    ]
  */

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    formState && setData(formState.secondTableColumnData);
  }, [formState]);

  function handleEditField(input: any) {
    const newFormstate = { ...formState };
    var editedItem = newFormstate.secondTableColumnData[input.rowIndex];

    newFormstate.secondTableColumnData[input.rowIndex] = {
      right: input.columnId === 'right' ? input.value : editedItem.right,
      left: input.columnId === 'left' ? input.value : editedItem.left,
    };

    patchFormState({
      ...formState,
      secondTableColumnData: newFormstate.secondTableColumnData,
    });
  }

  function handleAddField() {
    patchFormState({
      ...formState,
      secondTableColumnData: [
        ...formState.secondTableColumnData,
        {
          right: '',
          left: '',
        },
      ],
    });
  }

  function handleRemoveRow(data: any) {
    setLoading(true);
    var newDataList = [];
    for (var i = 0; i < formState.secondTableColumnData.length; i++) {
      if (
        formState.secondTableColumnData[i].right === data.right &&
        formState.secondTableColumnData[i].left === data.left
      ) {
      } else {
        newDataList.push(formState.secondTableColumnData[i]);
      }
    }

    patchFormState({
      ...formState,
      secondTableColumnData: newDataList,
    });
    setTimeout(() => setLoading(false), 1);
  }

  const tableColumns: TableColumnDefs<TableColumnType> = [
    {
      accessorKey: 'left',
      className: 'width-half',
      header: () => null,
      onEdit: handleEditField,
    },
    {
      accessorKey: 'right',
      className: 'width-half',
      header: headerText ? () => <span>{headerText}</span> : () => null,
      onEdit: handleEditField,
    },
  ];

  return !loading ? (
    <AuditingTable<TableColumnType>
      data={data}
      title={title}
      columns={tableColumns}
      showGlobalFilter={false}
      disableSort
      onAddNewRow={handleAddField}
      onRowDelete={handleRemoveRow}
    />
  ) : null;
};

const TableColumnWithFiveRows: React.FC<QuadrupleTableProps> = ({
  formState,
  patchFormState,
  title,
  headers,
  id,
}) => {
  type TableColumnType = {
    one: string;
    two: string;
    three: string;
    four: number;
    five: string;
  };
  /*
    Using this function requires you to create in the formstate a variable called
    tableColumnData,
    this contains all the three and two fields of the tableColumn.

    like this: 
    tableColumnWithFiveRowsData: [
      {
        one: 'rightText',
        two: 'rightText',
        three: 'leftText',
        four: 0,
        five: 'fivetext'
      },
      {
        one: 'rightText',
        two: 'rightText',
        three: 'leftText',
        four: 0,
        five: 'fivetext'
      },
    ]
  */

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    formState && setData(formState.tableColumnWithFiveRowsData[id]);
    // eslint-disable-next-line
  }, [formState]);

  function handleEditField(input: any) {
    const newFormstate = { ...formState };
    newFormstate.tableColumnWithFiveRowsData[id][input.rowIndex][
      input.columnId
    ] = input.value;

    patchFormState({
      ...formState,
      tableColumnWithFiveRowsData: {
        ...formState.tableColumnWithFiveRowsData,
        [id]: newFormstate.tableColumnWithFiveRowsData[id],
      },
    });
  }

  function handleAddField() {
    patchFormState({
      ...formState,
      tableColumnWithFiveRowsData: {
        ...formState.tableColumnWithFiveRowsData,
        [id]: [
          ...formState.tableColumnWithFiveRowsData[id],
          {
            one: '',
            two: '',
            three: '',
            four: 0,
            five: '',
          },
        ],
      },
    });
  }

  function handleRemoveRow(data: any) {
    setLoading(true);
    var newDataList = [...formState.tableColumnWithFiveRowsData[id]].filter(
      item =>
        !(
          item.one === data.one &&
          item.two === data.two &&
          item.three === data.three &&
          item.four === data.four &&
          item.five === data.five
        )
    );

    patchFormState({
      ...formState,
      tableColumnWithFiveRowsData: {
        ...formState.tableColumnWithFiveRowsData,
        [id]: newDataList,
      },
    });
    setTimeout(() => setLoading(false), 1);
  }

  const tableColumns: TableColumnDefs<TableColumnType> = [
    {
      accessorKey: 'one',
      header: () => (headers !== undefined ? headers[0] : null),
      className: 'width-half nowrap',
      onEdit: handleEditField,
    },
    {
      accessorKey: 'two',
      header: () => (headers !== undefined ? headers[1] : null),
      className: 'width-half nowrap',
      onEdit: handleEditField,
    },
    {
      accessorKey: 'three',
      header: () => (headers !== undefined ? headers[2] : null),
      className: 'width-half nowrap',
      onEdit: handleEditField,
    },
    {
      accessorKey: 'four',
      header: () => (headers !== undefined ? headers[3] : null),
      className: 'width-half nowrap',
      onNumberEdit: handleEditField,
    },
    {
      accessorKey: 'five',
      header: () => (headers !== undefined ? headers[4] : null),
      className: 'width-half nowrap',
      onEdit: handleEditField,
    },
  ];
  return !loading ? (
    <AuditingTable<TableColumnType>
      data={data}
      title={title}
      columns={tableColumns}
      showGlobalFilter={false}
      disableSort
      onAddNewRow={handleAddField}
      onRowDelete={handleRemoveRow}
    />
  ) : null;
};

export { TableColumn, SecondTableColumn, TableColumnWithFiveRows };
