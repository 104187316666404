import { observer } from "mobx-react-lite";
import styled from "styled-components";
import Logo from "../components/Logo";
import { useStore } from "../store/RootContext";
import React, { useEffect } from "react";

const SittingContainer = styled.div`
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1000;
    padding: 50px;
    border-radius: 12px;
    text-align: center;
    justify-content: center;
    align-items: center;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    background-color: rgba(255, 255, 255, 1);

    form {
    width: 400px;

        > div {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            margin: ${p => p.theme.spacing.lg} 0;
        }

        button {
            margin-left: 5px;
            margin-right: 5px;
            background-color: #00a6ff;
            width: 65%;
            height: 50px;
            border-radius: 20px;
            color: #fff;
            border: 1px solid #ccc;
            outline: none;
            
            &:focus {
                border-color: #007bff;
            }
        }

        p {
            margin-bottom: ${p => p.theme.spacing.md};
        }
    }
`;

interface SittingProps {
    resetTimer: () => void;
    setShowSittingPopup: (value: boolean) => void;
}

const Sitting: React.FC<SittingProps> = observer(({ resetTimer, setShowSittingPopup }) => {
    const { authStore: { logout }} = useStore();

    // Automatic logout after 30 minutes, if user doesn't respond to the popup.
    useEffect(() => {
        const logoutTimer = setTimeout(() => {
          setShowSittingPopup(false); // Close the popup
          logout(); // Logout the user
        }, 1800000); // 30 minutes
    
        return () => {
          clearTimeout(logoutTimer); // Clear the timer when the component unmounts
        };
    }, [logout, setShowSittingPopup]);

    function handleLogout() {
        setShowSittingPopup(false);
        logout();
    }

    return (
        <>
        <div style={{zIndex: '1001', position: 'fixed', backgroundColor: 'rgba(255, 255, 255, 0.5)', width: '100vw', height: '100vh'}}>
            <SittingContainer>
                <form>
                    <Logo height={95} />

                    <p>Näyttäisi siltä, että et ole tehnyt mitään vähän aikaan.</p>
                    <p>Haluatko jatkaa istuntoasi?</p>
                    <p><i>Kirjaudutaan ulos automaattisesti, 30 minuutin kuluttua.</i></p>
                    <hr style={{marginBottom: '0'}}></hr>
                    <div>
                        <button onClick={handleLogout}>Kirjaudu ulos</button>
                        <button onClick={resetTimer}>Jatka</button>
                    </div>
                </form>
            </SittingContainer>
        </div>
        </>
    );
});

export default Sitting;