import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useStore } from '../../store/RootContext';
import { PASSWORD_LENGTH, validatePassword } from '../../utils';
import { Button, TextInput } from '../inputs';

interface Props {
  onSubmit: (params: Partial<User & { currentPassword: string }>) => void;
}

const PasswordChangeForm: React.FC<Props> = ({ onSubmit }) => {
  const { t } = useTranslation(['myProfile', 'action']);

  const {
    notificationStore: { notify },
    authStore: { setVerified },
  } = useStore();

  const [currentPassword, setCurrentPassword] = useState('');
  const [password, setPassword] = useState('');
  const [passwordConfirm, setPasswordConfirm] = useState('');

  const clearForm = () => {
    setCurrentPassword('');
    setPassword('');
    setPasswordConfirm('');
  };

  const handleResetPassword = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    event.stopPropagation();

    const validateMessages = validatePassword(password, passwordConfirm);

    if (validateMessages) {
      validateMessages.forEach(message => notify(message, 'error'));
    } else {
      setVerified(true);
      onSubmit({ password, currentPassword });
      clearForm();
    }
  };

  return (
    <form onSubmit={handleResetPassword}>
      <TextInput
        type="password"
        label={t('myProfile:currentPassword')}
        value={currentPassword}
        setValue={setCurrentPassword}
        autoFocus
        required
        fullWidth
      />

      <div className="flex-row flex-row-gap">
        <div className="flex-1">
          <TextInput
            type="password"
            label={t('myProfile:newPassword')}
            value={password}
            setValue={setPassword}
            required
            fullWidth
            min={PASSWORD_LENGTH}
          />
        </div>
        <div className="flex-1">
          <TextInput
            type="password"
            label={t('myProfile:newPasswordConfirm')}
            value={passwordConfirm}
            setValue={setPasswordConfirm}
            required
            fullWidth
            min={PASSWORD_LENGTH}
          />
        </div>
      </div>
      <Button text={t('action:save')} type="submit" />
    </form>
  );
};

export default PasswordChangeForm;
