import auditingFrontPage from './auditingFrontPage';
// Essentials imports
import MandateAuditor from './essentials/MandateAuditor';
import communityInformation from './essentials/communityInformation';
import CustomerVerification from './essentials/customerVerification';
import inspectionIndependence from './essentials/inspectionIndependence';
import EvaluationAssignment from './essentials/evaluationAssignment';
// Inspection imports

// Analyzes imports
import accountMap from './analyzes/accountMap';
import generalLedger from './analyzes/generalLedger';
import indicators from './analyzes/indicators';
// Scoping imports
import generalLedgerScoping from './scoping/generalLedgerScoping';
import accountMapScoping from './scoping/accountMapScoping';
import incomeStatementAndBalanceScoping from './scoping/incomeStatementAndBalanceScoping';
// Planning imports
import permanentData from './planning/permanentData';
import incomeStatementAndBalance from './analyzes/incomeStatementAndBalance';
import independence from './planning/independence';
import auditingPlanning from './planning/auditingPlanning';
import internalControlAndOperatingEnvironment from './planning/internalControlAndOperatingEnvironment';
import itAndProcesses from './planning/itAndProcesses';
import risks from './planning/risks';
import materiality from './planning/materiality';
import detailedPlan from './scoping/detailedPlan';
// Auditing imports
import reservesSheet from './auditing/sheets/reservesSheet';
import financialExpensesSheet from './auditing/sheets/financialExpensesSheet';
import financialIncomeSheet from './auditing/sheets/financialIncomeSheet';
import advancePaymentsSheet from './auditing/sheets/advancePaymentsSheet';
import otherLiabilitiesSheet from './auditing/sheets/otherLiabilitiesSheet';
import financialInstitutionsSheet from './auditing/sheets/financialInstitutionsSheet';
import capitalLoansSheet from './auditing/sheets/capitalLoansSheet';
import ownCapitalSheet from './auditing/sheets/ownCapitalSheet';
import loanReceivablesSheet from './auditing/sheets/loanReceivablesSheet';
import cashEquivalentsSheet from './auditing/sheets/cashEquivalentsSheet';
import otherReceivablesSheet from './auditing/sheets/otherReceivablesSheet';
import warehousesSheet from './auditing/sheets/warehousesSheet';
import fundingSheet from './auditing/sheets/fundingSheet';
import fixedAssetsSheet from './auditing/sheets/fixedAssetsSheet';
import taxesSheet from './auditing/sheets/taxesSheet';
import otherOperatingExpensesSheet from './auditing/sheets/otherOperatingExpensesSheet';
import tradeCreditorsSheet from './auditing/sheets/tradeCreditorsSheet';
import materialsAndServicesSheet from './auditing/sheets/materialsAndServicesSheet';
import otherOperatingIncomeSheet from './auditing/sheets/otherOperatingIncomeSheet';
import accountsReceivableSheet from './auditing/sheets/accountsReceivableSheet';
import turnOverSheet from './auditing/sheets/turnOverSheet';
import investmentsSheet from './auditing/sheets/investmentsSheet';
import investments from './auditing/investments';
import warehouses from './auditing/warehouses';
import ownCapital from './auditing/ownCapital';
import presentationAndContent from './auditing/presentationAndContent';
import capitalLoans from './auditing/capitalLoans';
import taxes from './auditing/taxes';
import financialExpenses from './auditing/financialExpenses';
import financialIncome from './auditing/financialIncome';
import fixedAssets from './auditing/fixedAssets';
import assignmentsFirstTime from './auditing/assignmentsFirstTime';
import innerCircleTab from './auditing/innerCircleTab';
import continuityOfOperation from './auditing/continuityOfOperation';
import manualExports from './auditing/manualExports';
import advancePayments from './auditing/advancePayments';
import turnOver from './auditing/turnOver';
import financialInstitutions from './auditing/financialInstitutions';
import loanReceivables from './auditing/loanReceivables';
import otherReceivables from './auditing/otherReceivables';
import cashEquivalents from './auditing/cashEquivalents';
import accountsReceivable from './auditing/accountsReceivable';
import funding from './auditing/funding';
import otherOperatingIncome from './auditing/otherOperatingIncome';
import otherOperatingExpenses from './auditing/otherOperatingExpenses';
import materialsAndServices from './auditing/materialsAndServices';
import otherLiabilities from './auditing/otherLiabilities';
import tradeCreditors from './auditing/tradeCreditors';
import auditingProcedures from './auditing/auditingProcedures';
import personelCosts from './auditing/personelCosts';
import governmentTransfersAndTaxRevenues from './auditing/governmentTransfersAndTaxRevenues';
import management from './auditing/management';
import reserves from './auditing/reserves';
// Reporting imports
import reporting from './reporting/reporting';
import errors from './reporting/errors';
import closure from './reporting/closure';
import communicationAndReporting from './reporting/communicationAndReporting';
import { InstructionsPage } from '../InstructionsPage';
import { FeedBackPage } from '../FeebackPage';
const InstructionsRenderer = (
  sectionKey: AuditingSectionKey
): AuditingSectionDefinition<unknown> => ({
  sectionKey,
  formFields: [
    {
      type: 'custom',
      render: <InstructionsPage />,
    },
  ],
  defaultFormState: {},
});
const FeebackRenderer = (
  sectionKey: AuditingSectionKey
): AuditingSectionDefinition<unknown> => ({
  sectionKey,
  formFields: [
    {
      type: 'custom',
      render: <FeedBackPage />,
    },
  ],
  defaultFormState: {},
});

const AuditingSections = {
  auditingFrontPage,

  //Essentials definitions
  MandateAuditor,
  communityInformation,
  CustomerVerification,
  inspectionIndependence,
  EvaluationAssignment,

  // Inspection definitions

  // Analyzes definitions

  // Scoping definitions
  generalLedgerScoping,
  accountMapScoping,
  incomeStatementAndBalanceScoping,

  // Planning definitions
  indicators,
  permanentData,
  accountMap,
  generalLedger,
  incomeStatementAndBalance,
  independence,
  auditingPlanning,
  internalControlAndOperatingEnvironment,
  itAndProcesses,
  risks,
  materiality,
  detailedPlan,

  // Auditing definitions
  reservesSheet,
  financialExpensesSheet,
  financialIncomeSheet,
  advancePaymentsSheet,
  otherLiabilitiesSheet,
  financialInstitutionsSheet,
  capitalLoansSheet,
  ownCapitalSheet,
  loanReceivablesSheet,
  cashEquivalentsSheet,
  otherReceivablesSheet,
  warehousesSheet,
  fundingSheet,
  fixedAssetsSheet,
  taxesSheet,
  otherOperatingExpensesSheet,
  tradeCreditorsSheet,
  materialsAndServicesSheet,
  otherOperatingIncomeSheet,
  accountsReceivableSheet,
  turnOverSheet,
  investmentsSheet,
  investments,
  warehouses,
  ownCapital,
  taxes,
  fixedAssets,
  innerCircleTab,
  manualExports,
  continuityOfOperation,
  capitalLoans,
  advancePayments,
  turnOver,
  financialInstitutions,
  loanReceivables,
  otherReceivables,
  cashEquivalents,
  accountsReceivable,
  funding,
  otherOperatingIncome,
  otherOperatingExpenses,
  tradeCreditors,
  otherLiabilities,
  materialsAndServices,
  auditingProcedures,
  personelCosts,
  governmentTransfersAndTaxRevenues,
  management,
  assignmentsFirstTime,
  reserves,
  financialIncome,
  financialExpenses,
  presentationAndContent,

  // Reporting definitions
  reporting,
  errors,
  closure,
  communicationAndReporting,

  instructions: InstructionsRenderer('instructions'),
  feedback: FeebackRenderer('feedback'),
};

export default AuditingSections;
