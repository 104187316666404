import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { ContextInfoProps } from '../ContextInfo';
import Icon from '../Icon';
import Modal from '../Modal';
import Button from './Button';
import Label from './Label';
import TextArea from './TextArea';

const Container = styled.div`
  white-space: pre-wrap;
  i {
    color: ${p => p.theme.color.grey300};
  }
`;

const StyledInput = styled.input`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const EditButton = styled.button`
  border-radius: 20px;
  position: absolute;
  left: 0.1rem;
  top: 0.1rem;
  height: 18px;
  width: 18px;
  cursor: pointer;
  z-index: 2;

  svg {
    margin-bottom: 1px;
  }
`;

interface Props {
  label?: string;
  inputLabel?: string;
  contextInfo?: ContextInfoProps;
  value: string;
  onChange: (newValue: string) => void;
  placeholder?: string;
  modalTitle?: string;
  disabled?: boolean;
  editOnlyInModal?: boolean;
}

/**
 * More or less regular input, but it has edit button which triggers
 * modal dialog to show textarea where input's value is editable.
 *
 */
export const TextInputModal: React.FC<Props> = ({
  label,
  inputLabel,
  contextInfo,
  value = '',
  onChange,
  placeholder,
  modalTitle,
  disabled,
  editOnlyInModal,
}) => {
  const { t } = useTranslation();

  // This internal value is shown in actual input in the table cell
  const [inputValue, setInputValue] = useState<string>(value);
  const inputValueRef = useRef<string>(inputValue);

  // This internal value is shown in TEXT AREA input when the modal is open
  const [stagedValue, setStagedValue] = useState<string | null>();

  /** Check if value changed externally
   *
   * -------------------------------------------------------------------------
   * DISABLED! This caused problems such as when user was typing
   * the form state updates and during typing the current value
   * was overridden with the external value from recently updated
   * (but already old) form state.
   *
   * By disabling this we might (and probably will) cause some other bug,
   * but couldn't figure out what it may be... I'll leave this here for now,
   * in case the reason why this was added in the first place comes up.
   * -------------------------------------------------------------------------
   */
  // useEffect(() => {
  //   if (value) setInputValue(value);
  // }, [value]);

  /**
   * If (internal) input value was changed, update an external value after a short delay.
   *  This is done to prevent lagging during typing, since no need
   * to update external value after every key down event.
   */
  useEffect(() => {
    const timeout = setTimeout(async () => {
      const inputValueChanged = inputValue !== inputValueRef.current;
      if (inputValueChanged) {
        inputValueRef.current = inputValue;
        onChange(inputValue);
      }
    }, 250);

    return () => clearTimeout(timeout);
  }, [inputValue, onChange]);

  const handleInternalChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    setStagedValue(event.currentTarget.value);
  };

  const handleExternalChange = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setInputValue(stagedValue ?? inputValue);
    handleCloseDialog();
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.currentTarget.value);
  };

  const handleOpenDialog = () => {
    setStagedValue(value);
  };

  const handleCloseDialog = () => {
    setStagedValue(null);
  };

  return (
    <Container>
      {!editOnlyInModal && (
        <>
          <EditButton
            onClick={handleOpenDialog}
            className="edit-button"
            type="button"
            tabIndex={-1}
          >
            <Icon
              type={disabled ? 'Visible' : 'Edit'}
              color="primary300"
              size={10}
            />
          </EditButton>
          <StyledInput
            value={inputValue}
            placeholder={placeholder}
            onChange={handleInputChange}
            onDoubleClick={handleOpenDialog}
            onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) => {
              if (event.key === 'Enter') handleOpenDialog();
            }}
            disabled={disabled}
          />
        </>
      )}

      {editOnlyInModal && (
        <div>
          <div className="flex-row justify-start flex-row-gap">
            <Label contextInfo={contextInfo}>{label}</Label>
            <Button
              onClick={handleOpenDialog}
              icon={<Icon type="Edit" />}
              type="button"
              variant="outlined"
            />
          </div>

          <div onDoubleClick={handleOpenDialog}>
            {inputValue || <i>{placeholder}</i>}
          </div>
        </div>
      )}

      {stagedValue !== null && stagedValue !== undefined && (
        <Modal
          title={disabled ? undefined : modalTitle ?? t(`action:editText`)}
          onClose={handleCloseDialog}
          isInitiallyOpen
        >
          <form onSubmit={handleExternalChange}>
            {disabled ? (
              stagedValue
            ) : (
              <TextArea
                label={inputLabel}
                value={stagedValue}
                placeholder={placeholder}
                onChange={handleInternalChange}
                autoFocus
                fullWidth
              />
            )}
            {!disabled && (
              <div className="flex-row flex-row-gap flex-row-reverse">
                <Button
                  text={t('action:save')}
                  type="submit"
                  minWidth
                  disabled={disabled}
                />
                <Button
                  text={disabled ? t('action:close') : t('action:cancel')}
                  variant="outlined"
                  onClick={handleCloseDialog}
                  minWidth
                />
              </div>
            )}
          </form>
        </Modal>
      )}
    </Container>
  );
};

export default TextInputModal;
