import { Popover as HeadlessPopover } from '@headlessui/react';
import React from 'react';
import styled from 'styled-components';
import { Button } from './inputs';

const PopoverContentContainer = styled.div`
  .popover-panel > div {
    min-width: 400px;
    max-width: 600px;

    background-color: ${p => p.theme.color.grey100};
    border-radius: ${p => p.theme.borderRadius.md};

    ${p => p.theme.font.size[14]};
    ${p => p.theme.shadow.sm};

    position: absolute;
    z-index: 9;

    padding: ${p => p.theme.spacing.md} ${p => p.theme.spacing.xl};

    &.align-bottom-left,
    &.align-top-left {
      padding-left: ${p => p.theme.spacing.lg};
    }

    &.align-top-left > button,
    &.align-top-right > button,
    &.align-bottom-left > button,
    &.align-bottom-right > button {
      position: absolute;
    }

    &.align-top-left,
    &.align-top-right {
      bottom: -12px;
      > button {
        bottom: 12px;
      }
    }

    &.align-bottom-left,
    &.align-bottom-right {
      top: -12px;
      > button {
        top: 12px;
      }
    }

    &.align-top-left,
    &.align-bottom-left {
      right: -10px;
      > button {
        right: 10px;
      }
    }

    &.align-top-right,
    &.align-bottom-right {
      left: -10px;
      > button {
        left: 10px;
      }
    }
  }
`;

export type PopoverPosition =
  | 'top-left'
  | 'top-right'
  | 'bottom-left'
  | 'bottom-right';

interface PopoverPanelProps {
  open: boolean;
  close: (
    focusableElement?:
      | HTMLElement
      | React.MutableRefObject<HTMLElement | null>
      | undefined
  ) => void;
}

interface Props {
  button?: string | JSX.Element;
  icon?: JSX.Element;
  position?: PopoverPosition;
  children: React.ReactNode | ((props: PopoverPanelProps) => React.ReactNode);
}

const Popover: React.FC<Props> = ({
  button,
  icon,
  position = 'bottom-right',
  children,
}) => {
  const buttonElement =
    typeof button === 'string' ? <Button text={button} /> : button;

  const iconButtonElement = icon ? <button>{icon}</button> : undefined;

  const getChildren = (props: PopoverPanelProps) =>
    typeof children === 'function' ? children(props) : children;

  return (
    <HeadlessPopover style={{ position: 'relative' }}>
      <HeadlessPopover.Button as="div">
        {buttonElement ?? iconButtonElement}
      </HeadlessPopover.Button>

      <PopoverContentContainer>
        <HeadlessPopover.Panel className="popover-panel">
          {renderProps => (
            <div className={`align-${position}`}>
              <>
                {icon && (
                  <HeadlessPopover.Button>{icon}</HeadlessPopover.Button>
                )}
                {getChildren(renderProps)}
              </>
            </div>
          )}
        </HeadlessPopover.Panel>
      </PopoverContentContainer>
    </HeadlessPopover>
  );
};

export default Popover;
