import { displayAuditingProcedures } from '../../../components/auditing/AuditingProcedureItem2';
import auditingJson from '../../../i18n/locales/fi/auditing.json';
import { hidePartBasedOnItAndProcesses } from '../../../utils';
import { FormFieldBuilder } from '../FormFieldBuilder';
import { AccountClassKey } from '../planning/accountMapKeys';
import { FinancialStatementBatch } from './auditingProceduresUtils';
import { FixedAssetsDescriptionTable } from './notReUsables/fixedAssetsProcess';
import { O1Form2, getInitialValues } from './reUsables/O1Form';
import { ProcedureAccountsElement } from './reUsables/ProcedureAccountsElement';
import { SpreadSheet } from './reUsables/SpreadSheet';
import { TableColumn } from './reUsables/TableColumn';

type SectionFormType = FixedAssetsForm;

const sectionKey: AuditingSectionKey = 'fixedAssets';

export const tBase = `auditing:form.${sectionKey}`;

const batchString = 'O1-fixedAssets';

const batch =
  FinancialStatementBatch[batchString as keyof typeof FinancialStatementBatch];

const classKey = AccountClassKey[batch as keyof typeof AccountClassKey];
const formBuilder = new FormFieldBuilder<SectionFormType>({ sectionKey });
const jsonVocab = auditingJson.form.fixedAssets;
const defaultFormState: DefaultFormState<SectionFormType> = ({ auditing }) => ({
  // Funding - SECTION: RAHOITUS_OMAISUUS
  reasonableEvidenceForAssets: jsonVocab.reasonableEvidenceForAssetsValue,

  // Funding - SECTION: DESCRIBE_PERIOD
  describePeriod: jsonVocab.describePeriodValue,
  procedureAccountsManualData: [
    {
      currentYear: 10000,
      priorYear: 10000,
      accountName: 'esimerkki',
      comment: '',
    },
  ],
  tableColumnData: [
    {
      left: 'Taseelle merkityt varat ovat olemassa ja tallessa.',
      right: auditingJson.OLE,
    },
    {
      left: 'Yhteisö hallitsee varoja tai sillä on määräysvalta niitä koskeviin oikeuksiin (ja velat ovat yhteisön velvoitteita).',
      right: auditingJson.OVE,
    },
    {
      left: 'Käyttöomaisuus ja poistot on kirjattu oikeassa arvossa ja mahdolliset alaskirjaustarpeet on tunnistettu.',
      right: auditingJson.OIK_ARV,
    },
    {
      left: 'Kaikki käyttöomaisuushyödykkeet ja poistot, jotka olisi tullut kirjata on kirjattu.',
      right: auditingJson.TAY,
    },
    {
      left: 'Käyttöomaisuuteen on kirjattu eriä, jotka sinne luonteensa puolesta kuuluvat. Poistot on luokiteltu tuloslaskelmalla oikein.',
      right: auditingJson.LUOK,
    },
    {
      left: 'Tapahtumat on yhdistelty ja jaoteltu oikein / ne on kuvattu selvästi. Käyttöomaisuuteen liittyvät tilinpäätöksessä esitettävät tiedot ovat relevantteja ja ymmärrettäviä sovellettavan tilinpäätösnormiston mukaan.',
      right: auditingJson.ESI,
    },
  ],

  // ProcedureAccounts
  procedureAccountsData: {},
  O1FormData: getInitialValues(auditing, classKey),
  O1Form2Data: [
    {
      label: 'Käyttöomaisuuskirjanpito alkusaldo ',
      value: 0,
      id: 1,
    },
    {
      label: 'Kirjanpidon alkusaldo',
      value: 0,
      id: 2,
    },
    {
      label: 'Käyttöomaisuuskirjanpito loppusaldo',
      value: 0,
      id: 3,
    },
    {
      label: 'Kirjanpito loppusaldo ',
      value: 0,
      id: 4,
    },
  ],

  useCalculation3: null,
  giveMoreInfoIfNeeded: auditingJson.giveAdditionalInfoIfNeeded,

  processText: '',
  processDate: '',
  processValue: '',

  useSheetTab: null,

  useSheet1: null,
  sheetdata1: [
    {
      cellData: [],
    },
  ],
  useSheet2: null,
  sheetdata2: [
    {
      cellData: [],
    },
  ],
  useSheet3: null,
  sheetdata3: [
    {
      cellData: [],
    },
  ],
  useSheet4: null,
  sheetdata4: [
    {
      cellData: [],
    },
  ],
  useSheet5: null,
  sheetdata5: [
    {
      cellData: [],
    },
  ],
  useSheet6: null,
  sheetdata6: [
    {
      cellData: [],
    },
  ],
  useSheet7: null,
  sheetdata7: [
    {
      cellData: [],
    },
  ],
  useSheet8: null,
  sheetdata8: [
    {
      cellData: [],
    },
  ],
});

const formFields: FormFields<SectionFormType> = ({
  formState,
  auditing,
  store,
}) => [
  formBuilder.group({
    items: [
      formBuilder.boolean({
        accessor: 'useSheetTab',
        plainLabel: 'Käytä laskenta taulukko osiota',
      }),
      formBuilder.custom(props => (
        <ProcedureAccountsElement
          {...props}
          addCommentField
          auditing={auditing}
          batchString={batchString}
          showChange
          showTitles
          // customAccountTitle="Tilinumero ja nimi"
        />
      )),
      formBuilder.custom(props => <TableColumn {...props} />),
      formBuilder.textArea({
        accessor: 'reasonableEvidenceForAssets',
        plainLabel: auditingJson.conclusion,
      }),
    ],
  }),

  formBuilder.accordionGroup({
    title: auditingJson.form.itAndProcesses.processDescript,
    items: [
      formBuilder.custom(props => (
        <FixedAssetsDescriptionTable
          processText={''}
          processDate={null}
          processValue={''}
          {...props}
        />
      )),
    ],
    hidden: hidePartBasedOnItAndProcesses(auditing, 'nonCurrentAssets'),
  }),

  ...displayAuditingProcedures(store, batchString, formBuilder, [
    {
      accordionId: 'accountingReconciliation',
      lowerElements: [
        formBuilder.boolean({
          accessor: 'useCalculation3',
          plainLabel: jsonVocab.useCalculation,
        }),
        formBuilder.custom(props =>
          formState.useCalculation3 === true ? (
            <O1Form2
              auditing={auditing}
              classKey={AccountClassKey[batch as keyof typeof AccountClassKey]}
              {...props}
            />
          ) : null
        ),
        formBuilder.boolean({
          accessor: 'useSheet1',
          plainLabel: auditingJson.useSheet,
        }),
        formBuilder.custom(props =>
          formState.useSheet1 ? (
            <SpreadSheet id="sheetdata1" {...props} />
          ) : null
        ),
      ],
    },
    {
      accordionId: 'verifyingRemovals',
      lowerElements: [
        formBuilder.boolean({
          accessor: 'useSheet2',
          plainLabel: auditingJson.useSheet,
        }),
        formBuilder.custom(props =>
          formState.useSheet2 ? (
            <SpreadSheet id="sheetdata2" {...props} />
          ) : null
        ),
      ],
    },
    {
      accordionId: 'removedDifferencesCheck',
      lowerElements: [
        formBuilder.boolean({
          accessor: 'useSheet3',
          plainLabel: auditingJson.useSheet,
        }),
        formBuilder.custom(props =>
          formState.useSheet3 ? (
            <SpreadSheet id="sheetdata3" {...props} />
          ) : null
        ),
      ],
    },
    {
      accordionId: 'additionsCheck',
      lowerElements: [
        formBuilder.boolean({
          accessor: 'useSheet4',
          plainLabel: auditingJson.useSheet,
        }),
        formBuilder.custom(props =>
          formState.useSheet4 ? (
            <SpreadSheet id="sheetdata4" {...props} />
          ) : null
        ),
      ],
    },
    {
      accordionId: 'removalsCheck',
      lowerElements: [
        formBuilder.boolean({
          accessor: 'useSheet5',
          plainLabel: auditingJson.useSheet,
        }),
        formBuilder.custom(props =>
          formState.useSheet5 ? (
            <SpreadSheet id="sheetdata5" {...props} />
          ) : null
        ),
      ],
    },
    {
      accordionId: 'fixedAssetsAppreciation',
      lowerElements: [
        formBuilder.boolean({
          accessor: 'useSheet6',
          plainLabel: auditingJson.useSheet,
        }),
        formBuilder.custom(props =>
          formState.useSheet6 ? (
            <SpreadSheet id="sheetdata6" {...props} />
          ) : null
        ),
      ],
    },
    {
      accordionId: 'decreasedValueOfFixedAssetsCheck',
      lowerElements: [
        formBuilder.boolean({
          accessor: 'useSheet7',
          plainLabel: auditingJson.useSheet,
        }),
        formBuilder.custom(props =>
          formState.useSheet7 ? (
            <SpreadSheet id="sheetdata7" {...props} />
          ) : null
        ),
      ],
    },
    {
      accordionId: 'checkingAdditionsAndSubtractions',
      lowerElements: [
        formBuilder.boolean({
          accessor: 'useSheet8',
          plainLabel: auditingJson.useSheet,
        }),
        formBuilder.custom(props =>
          formState.useSheet8 ? (
            <SpreadSheet id="sheetdata8" {...props} />
          ) : null
        ),
      ],
    },
  ]),
];

const section: AuditingSectionDefinition<SectionFormType> = {
  sectionKey,
  defaultFormState,
  formFields,
};

export default section;
