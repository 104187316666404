import { ApiConfig } from './apiConfig';
import BaseApi from './BaseApi';
import {
  AuditingApi,
  AuditFirmApi,
  AuthApi,
  CommonApi,
  CustomerCompanyApi,
  UserApi,
  SanctionsApi,
  FreezingApi,
} from './modules';

export class Api extends BaseApi {
  public auth: AuthApi;
  public user: UserApi;
  public common: CommonApi;
  public auditFirm: AuditFirmApi;
  public customerCompany: CustomerCompanyApi;
  public auditing: AuditingApi;
  public sanctions: SanctionsApi;
  public freezing: FreezingApi;

  constructor(token?: string, config?: ApiConfig) {
    super(token, config);

    this.auth = new AuthApi(this);
    this.user = new UserApi(this);
    this.auditFirm = new AuditFirmApi(this);
    this.customerCompany = new CustomerCompanyApi(this);
    this.common = new CommonApi(this);
    this.auditing = new AuditingApi(this);
    this.sanctions = new SanctionsApi(this);
    this.freezing = new FreezingApi(this);
  }
}
