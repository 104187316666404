import { confirmDelete, formatCurrency } from '../../../../utils';
import AuditingTable from '../../../../components/table/AuditingTable';
import {
  OnEditProps,
  TableColumnDefs,
} from '../../../../components/table/Table';
import { useState } from 'react';
import styled from 'styled-components';

const Container = styled.div`
  .additional-info {
    font-style: italic;
    color: ${p => p.theme.color.grey400};
    text-align: right;
  }
  .custom-button {
    margin-bottom: 5px;
    text-decoration: underline;
  }
`;

export function DebtInstallmentsTableColumn(props: any) {
  const formState = props.formState;
  const patchFormState = props.patchFormState;
  const sectionKey = props.sectionKey;
  const id = props.id;

  const [showBackgroundMaterial2, setShowBackgroundMaterial2] = useState(true);
  const [buttonText, setButtonText] = useState(
    'Näytä Saldo taustamateriaali 2'
  );

  const [showBackgroundMaterial3, setShowBackgroundMaterial3] = useState(false);
  const [buttonText2, setButtonText2] = useState(
    'Piilota Saldo taustamateriaali 3'
  );

  if (!formState) return null;

  const data = formState.debtInstallments[id] ?? [];

  function handleEdit<T>({
    itemId,
    rowIndex,
    columnId,
    value,
  }: OnEditProps<T>) {
    var duplicate = [...formState.debtInstallments[id]];

    duplicate[rowIndex][columnId] = value;
    if (
      columnId === 'difference' ||
      columnId === 'ledgerBalance' ||
      columnId === 'backgroundMaterial1' ||
      columnId === 'backgroundMaterial2' ||
      columnId === 'backgroundMaterial3'
    ) {
      let materials =
        duplicate[rowIndex].backgroundMaterial1 +
          duplicate[rowIndex].backgroundMaterial2 +
          (!duplicate[rowIndex].backgroundMaterial3
            ? 0
            : duplicate[rowIndex].backgroundMaterial3) || 0;
      let difference = duplicate[rowIndex].ledgerBalance - materials || 0;
      duplicate[rowIndex].difference = difference;
      // Format and seprate Tenths, Hundredths and Thousandths with commas (duplicate[rowIndex].difference = number (not a string))
      formatNumber(duplicate[rowIndex].difference);
    }
    const newFormatState = {
      ...formState,
      debtInstallments: { ...formState.debtInstallments, [id]: duplicate },
    };
    patchFormState(newFormatState);
  }

  function formatNumber(num: any) {
    return num.toLocaleString('fi-FI', {
      minimumFractionDigits: 1,
      maximumFractionDigits: 2,
    });
  }

  const toggleBackgroundMaterial2 = (event: any) => {
    event.preventDefault();
    const updatedShowBackgroundMaterial2 = !showBackgroundMaterial2;
    setShowBackgroundMaterial2(updatedShowBackgroundMaterial2);
    setButtonText(
      updatedShowBackgroundMaterial2
        ? 'Näytä Saldo taustamateriaali 2'
        : 'Piilota Saldo taustamateriaali 2'
    );
    const updatedFormState = formState.debtInstallments[id].map((row: any) => {
      const materials = showBackgroundMaterial2
        ? row.backgroundMaterial1 + row.backgroundMaterial2
        : row.backgroundMaterial1;
      const newDifference = row.ledgerBalance - materials;
      return {
        ...row,
        hidden: !row.hidden,
        backgroundMaterial2: 0,
        difference: newDifference,
      };
    });
    const newFormatState = {
      ...formState,
      debtInstallments: {
        ...formState.debtInstallments,
        [id]: updatedFormState,
      },
    };
    patchFormState(newFormatState);
  };

  const addNewMaterial3 = (event: any) => {
    event.preventDefault();
    const updatedShowBackgroundMaterial3 = !showBackgroundMaterial3;
    setShowBackgroundMaterial3(updatedShowBackgroundMaterial3);
    setButtonText2(
      updatedShowBackgroundMaterial3
        ? 'Piilota Saldo taustamateriaali 3'
        : 'Näytä Saldo taustamateriaali 3'
    );
    const updatedFormState = formState.debtInstallments[id].map((row: any) => {
      const materials = row.backgroundMaterial1 + row.backgroundMaterial2;
      const newDifference = row.ledgerBalance - materials;
      return {
        ...row,
        addNewMaterial: !row.addNewMaterial,
        backgroundMaterial3: 0,
        difference: newDifference,
      };
    });
    const newFormatState = {
      ...formState,
      debtInstallments: {
        ...formState.debtInstallments,
        [id]: updatedFormState,
      },
    };
    patchFormState(newFormatState);
  };

  let tableColumns: TableColumnDefs<DebtInstallmentsTable> = [
    {
      accessorKey: 'label',
      header: () => 'Saamiserä',
      className: 'text-center width-middle',
      placeholder: '2921...',
      onEdit: (editProps: OnEditProps<string>) => {
        handleEdit<string>(editProps);
      },
    },
    {
      accessorKey: 'ledgerBalance',
      header: () => 'Pääkirjan saldo €',
      className: 'text-center width-middle',
      onNumberEdit: (editProps: OnEditProps<number>) =>
        handleEdit<number>(editProps),
      cell: ({ cell }) => (
        <div>{formatNumber(cell.row.original.ledgerBalance)} €</div>
      ),
    },
    {
      accessorKey: 'backgroundMaterial1',
      header: () => 'Saldo taustamateriaalilla 1 €',
      className: 'text-center width-middle',
      onNumberEdit: (editProps: OnEditProps<number>) =>
        handleEdit<number>(editProps),
      //cell: ({ cell }) => <div>{formatNumber(cell.row.original.backgroundMaterial1)} €</div>,
    },
    ...(formState.debtInstallments[id].find((row: any) => row.hidden === true)
      ? [
          {
            accessorKey: 'backgroundMaterial2',
            header: () => 'Saldo taustamateriaalilla 2 €',
            className: 'text-center width-middle',
            onNumberEdit: (editProps: OnEditProps<number>) =>
              handleEdit<number>(editProps),
          },
        ]
      : []),
    ...(formState.debtInstallments[id].find(
      (row: any) => row.addNewMaterial === true
    )
      ? [
          {
            accessorKey: 'backgroundMaterial3',
            header: () => 'Saldo taustamateriaalilla 3 €',
            className: 'text-center width-middle',
            onNumberEdit: (editProps: OnEditProps<number>) =>
              handleEdit<number>(editProps),
          },
        ]
      : []),
    {
      accessorKey: 'difference',
      header: () => 'Ero €',
      className: 'text-center width-middle',
      accessorFn: (row: any) => {
        return formatCurrency(row.difference);
      },
      cell: ({ cell }) => (
        <div>{formatNumber(cell.row.original.difference)} €</div>
      ),
    },
    {
      accessorKey: 'backgroundMaterial',
      header: () => 'Taustamateriaali',
      className: 'text-center width-middle',
      placeholder: 'Taustamateriaali',
      onEdit: (editProps: OnEditProps<string>) => {
        handleEdit<string>(editProps);
      },
    },
    {
      accessorKey: 'other',
      header: () => 'Muuta ',
      className: 'text-center width-middle',
      placeholder: 'Muuta...',
      onEdit: (editProps: OnEditProps<string>) => {
        handleEdit<string>(editProps);
      },
    },
  ];

  const handleAddNewRow = () => {
    // Check if hidden is true or false
    const hidden = showBackgroundMaterial2 ? false : true;
    patchFormState({
      ...formState,
      debtInstallments: {
        ...formState.debtInstallments,
        [id]: [
          ...formState.debtInstallments[id],
          {
            label: '',
            ledgerBalance: 0,
            backgroundMaterial1: 0,
            backgroundMaterial2: 0,
            backgroundMaterial3: 0,
            difference: 0,
            backgroundMaterial: '',
            other: '',
            hidden: hidden,
            addNewMaterial: false,
          },
        ],
      },
    });
  };

  const handleDeleteRow = ({ id, label }: DebtInstallmentsTable) => {
    if (confirmDelete(label ?? '')) {
      const patch = (formState.debtInstallments[id] ?? []).filter(
        (row: any) => row.id !== id
      );
      patchFormState({
        ...formState,
        debtInstallments: { ...formState.debtInstallments, patch },
      });
    }
  };

  return (
    <>
      <AuditingTable<DebtInstallmentsTable>
        sectionKey={sectionKey}
        onAddNewRow={handleAddNewRow}
        data={data}
        columns={tableColumns}
        onRowDelete={handleDeleteRow}
        showGlobalFilter={false}
        disableSort
        variant="default"
      />
      <Container>
        <button className="custom-button" onClick={toggleBackgroundMaterial2}>
          {buttonText}
        </button>
        <br></br>
        {/* If toggleBackgroundMaterial2 is true show new button (addNewMaterial3) */}
        {!showBackgroundMaterial2 && (
          <button className="custom-button" onClick={addNewMaterial3}>
            {buttonText2}
          </button>
        )}
        <div className="additional-info">
          Erotus: Pääkirjan saldo - Saldo taustamateriaalilla{' '}
        </div>
      </Container>
    </>
  );
}
