import React from 'react';
import styled from 'styled-components';
import { Disclosure } from '@headlessui/react';
import Icon from './Icon';
import { useAnimation } from '../utils/hooks';
import { AuditingProcedureResult } from '../views/auditingSections/auditing/auditingProceduresUtils';

const FADED_COLOR = 'grey300';

interface StyledAccordionProps {
  variant?: 'default' | 'faded';
  noBorder?: boolean;
}

const AccordionContainer = styled.div<StyledAccordionProps>`
  padding: ${p => p.theme.spacing.md} 0;
  border-bottom: ${p =>
    p.noBorder ? undefined : `1px solid ${p.theme.color.grey300}`};

  .accordion-title-row {
    display: flex;
    align-items: center;
  }

  .accordion-button {
    display: flex;
    align-items: center;
    width: 100%;
    cursor: pointer;

    > svg {
      margin-right: ${p => p.theme.spacing.md};
    }

    .accordion-title,
    .accordion-title > h1,
    .accordion-title > h2,
    .accordion-title > h3,
    .accordion-title > h4,
    .accordion-title > h5,
    .accordion-title > h6 {
      flex: 1;
      text-align: left;
      margin: 0;
      color: ${p =>
        p.variant === 'faded' ? p.theme.color[FADED_COLOR] : undefined};
    }
  }

  .accordion-panel {
    padding-top: 0.5rem;
    padding-left: 1.1rem;
  }
`;

interface AccordionProps extends StyledAccordionProps {
  id?: string;
  title: string | JSX.Element;
  sideContent?: boolean | string | JSX.Element;
  defaultOpen?: boolean;
  className?: string;
  onClick?: (isOpen: boolean) => void;
  showRemark?: boolean;
  children: React.ReactNode;
  auditingResult?: AuditingProcedureResult;
}

export const ProcedureResult = ({ auditingResult, children }: { auditingResult: AuditingProcedureResult|null, children?: React.ReactNode }) => {
  const getBackgroundColor = (result: AuditingProcedureResult|null) => {
    switch (result) {
      case AuditingProcedureResult.treatedGood:
      case AuditingProcedureResult.treatedModerately:
        return '#DAF7A6';
      case AuditingProcedureResult.shortcomings:
      case AuditingProcedureResult.notStarted:
      case AuditingProcedureResult.noEvidenceReceived:
        return '#FF5733';
      case AuditingProcedureResult.inProgress:
        return 'yellow';
      default:
        return 'transparent';
    }
  };

  const backgroundColor = getBackgroundColor(auditingResult);

  return (
    <div style={{ backgroundColor }}>
      {children}
      {(() => {
        switch (auditingResult) {
          case AuditingProcedureResult.treatedGood:
          case AuditingProcedureResult.treatedModerately:
            return <Icon type="Check" color="success600" />;
          case AuditingProcedureResult.shortcomings:
          case AuditingProcedureResult.notStarted:
          case AuditingProcedureResult.noEvidenceReceived:
            return <Icon type="Close" color="c70039" />;
          case AuditingProcedureResult.inProgress:
            return <Icon type="Hourglass" color="fada5e" />;
          default:
            return null;
        }
      })()}
    </div>
  );
};

const Accordion: React.FC<AccordionProps> = ({
  id,
  title,
  sideContent,
  defaultOpen,
  className,
  onClick,
  variant,
  noBorder,
  showRemark,
  children,
  auditingResult,
}) => {
  const animationParent = useAnimation();

  return (
    <AccordionContainer
      id={id}
      data-testid={id}
      variant={variant}
      className={`accordion ${className}`}
      noBorder={noBorder}
    >
      <Disclosure defaultOpen={defaultOpen}>
        {({ open }) => (
          <>
            <div className="accordion-title-row">
              <Disclosure.Button
                className="accordion-button"
                data-testid={`${id}-button`}
                onClick={() => onClick && onClick(!open)}
              >
                <Icon
                  type={open ? 'ChevronUp' : 'ChevronDown'}
                  color={variant === 'faded' ? FADED_COLOR : 'secondary'}
                />
                {showRemark && (
                  <Icon type="ExclamationTriangle" color="error" />
                )}
                <div className="accordion-title" data-testid={`${id}-title`}>
                  {title}
                </div>
              </Disclosure.Button>

              {sideContent}
              {auditingResult && <ProcedureResult auditingResult={auditingResult} />}
            </div>

            <div ref={animationParent}>
              <Disclosure.Panel
                className="accordion-panel"
                data-testid={`${id}-content`}
              >
                {children}
              </Disclosure.Panel>
            </div>
          </>
        )}
      </Disclosure>
    </AccordionContainer>
  );
};

export default Accordion;
