import { RootStore } from '../../../../store/RootStore';
import { AuditingTemplate, uuid } from '../../../../utils';
import { AccountKeyScoping } from '../accountMapKeysScoping';

export enum MaterialityBasedInSecond {
  actualNumbers = 'actualNumbers',
  budgetedNumbers = 'budgetedNumbers',
}

export type MaterialityBasisSecond =
  | AccountKeyScoping
  | 'combinedRevenues'
  | 'averageOfLiabilitiesAndRevenues'
  | 'other';

export const isSameMaterialitySecondForAllItems = (
  template?: AuditingTemplate
): boolean | null => {
  switch (template) {
    case AuditingTemplate.public:
      return false;
    default:
      return null;
  }
};

export const usingActualNumbersForMaterialitySecond = (
  value: MaterialityBasedInSecond | null
) => value === MaterialityBasedInSecond.actualNumbers;

// Sum of operating income, tax revenues and government transfers
export const getCombinedRevenuesSecond = (
  { auditingStore }: RootStore,
  value: 'currentYear' | 'priorYear' = 'currentYear'
) =>
  [
    AccountKeyScoping.operatingIncome,
    AccountKeyScoping.taxRevenues,
    AccountKeyScoping.governmentTransfers,
  ].reduce(
    (sum, key) =>
      sum + (auditingStore.getFinancialNumberScoping(key, value) ?? 0),
    0
  );

export const getAverageOfLiabilitiesAndRevenuesSecond = (
  store: RootStore,
  value: 'currentYear' | 'priorYear' = 'currentYear'
) => {
  const { getFinancialNumberScoping } = store.auditingStore;
  const liabilities = getFinancialNumberScoping(
    AccountKeyScoping.balanceLiabilities,
    value
  );
  const revenues = getCombinedRevenuesSecond(store, value);
  return liabilities && revenues ? (liabilities + revenues) / 2 : null;
};

const computeMaterialitySecondBaseNumber = ({
  materialitySecondBasedIn,
  materialitySecondBasis,
  store,
}: {
  materialitySecondBasedIn: MaterialityBasedInSecond | null;
  materialitySecondBasis: MaterialityBasisSecond | null;
  store: RootStore;
}) => {
  if (!usingActualNumbersForMaterialitySecond(materialitySecondBasedIn))
    return null;

  const { getFinancialNumberScoping } = store.auditingStore;

  switch (materialitySecondBasis) {
    case 'combinedRevenues':
      return getCombinedRevenuesSecond(store);
    case 'averageOfLiabilitiesAndRevenues':
      return getAverageOfLiabilitiesAndRevenuesSecond(store);
    case 'other':
      return null;
    default:
      return getFinancialNumberScoping(materialitySecondBasis);
  }
};

export function handleMaterialitySecondBasedInChange(
  selectedValue: string | undefined,
  { formState, store, patchFormState }: FormFieldProps<MaterialityForm>
) {
  const materialitySecondBasedIn = selectedValue as MaterialityBasedInSecond;

  if (materialitySecondBasedIn !== formState.materialitySecondBasedIn) {
    patchFormState({
      materialitySecondBasedIn,
      materialitySecondBaseNumber: computeMaterialitySecondBaseNumber({
        materialitySecondBasedIn,
        materialitySecondBasis: formState.materialitySecondBasis,
        store,
      }),
    });
  }
}

export const getMaterialityBasisOptionsSecond = (
  template?: AuditingTemplate
): MaterialityBasisSecond[] => {
  switch (template) {
    case AuditingTemplate.public:
      return [
        AccountKeyScoping.balanceLiabilities,
        'combinedRevenues',
        'averageOfLiabilitiesAndRevenues',
        AccountKeyScoping.annualContributionMargin,
        'other',
      ];
    case AuditingTemplate.private:
      return [
        AccountKeyScoping.turnover,
        AccountKeyScoping.profitOrLossBeforeTransfersAndTaxes,
        AccountKeyScoping.equity,
        AccountKeyScoping.balanceLiabilities,
        'other',
      ];
    default:
      return [];
  }
};

export function handleMaterialitySecondBasisChange(
  selectedValue: string | undefined,
  { formState, store, patchFormState }: FormFieldProps<MaterialityForm>
) {
  const materialitySecondBasis = selectedValue as MaterialityBasisSecond;

  if (materialitySecondBasis !== formState.materialitySecondBasis) {
    patchFormState({
      materialitySecondBasis,
      materialitySecondBaseNumber: computeMaterialitySecondBaseNumber({
        materialitySecondBasedIn: formState.materialitySecondBasedIn,
        materialitySecondBasis,
        store,
      }),
    });
  }
}

export const emptySeparateMaterialitySecond = (
  separateMaterialy?: Partial<SeparateSecondMateriality>
) => ({
  id: uuid(),
  name: '',
  methodOrBasis: '',
  materiality: null,
  workingMateriality: null,
  singleErrorMateriality: null,
  description: '',
  ...separateMaterialy,
});

export const getDefaultSeparateMaterialitiesSecond = (
  template?: AuditingTemplate
) => {
  switch (template) {
    case AuditingTemplate.public:
      return [
        emptySeparateMaterialitySecond({
          name: 'Olennaisuuden määrittely talousarvion noudattamisen tarkastusta varten',
          methodOrBasis:
            'Sitovuustasolla olennaisena voidaan pitää lähtökohtana vähintään 5 %:n ylitystä. Poikkeaman tulee olla myös euromääräisesti olennainen erä. Olennaisuusrajan määrittelyssä on käytetetty päävastuullisen tilintarkastajan harkintaa ja yhteisöstä muodostettua käsitystä.',
          description:
            'Kunnan toiminnassa ja taloudenhoidossa on noudatettava talousarviota (KuntaL 110 § 5 mom.). Kunnan hallintoa ei ole hoidettu lain ja valtuuston päätösten mukaisesti, jos talousarviopoikkeamat sitovuustasolla ovat olennaisia tai talousarvion määrärahoja on ylitetty tai tuloarvioita alitettu toistuvasti.',
        }),
      ];
    default:
      return [emptySeparateMaterialitySecond()];
  }
};

export const utilsForSecond = {
  MaterialityBasedInSecond,
  isSameMaterialitySecondForAllItems,
  usingActualNumbersForMaterialitySecond,
  getCombinedRevenuesSecond,
  getAverageOfLiabilitiesAndRevenuesSecond,
  computeMaterialitySecondBaseNumber,
  handleMaterialitySecondBasedInChange,
  getMaterialityBasisOptionsSecond,
  handleMaterialitySecondBasisChange,
  emptySeparateMaterialitySecond,
  getDefaultSeparateMaterialitiesSecond,
};
