import { useTranslation } from 'react-i18next';
import { formatCurrency } from '../../utils';
import AuditingTable from '../table/AuditingTable';
import { TableColumnDefs } from '../table/Table';

const sectionKey: AuditingSectionKey = 'generalLedger';

export const GroupedGeneralLedgerTable = ({
  formState,
}: FormFieldProps<GeneralLedgerForm>) => {
  const { t } = useTranslation();

  if (!formState) return null;

  const tBase = `auditing:form.${sectionKey}`;

  const data = formState.groupedGeneralLedger ?? [];

  const tableColumns: TableColumnDefs<GroupedGeneralLedgerItem> = [
    {
      accessorKey: 'account',
      className: 'text-center',
      header: () => t(`${tBase}.account`),
    },
    {
      accessorKey: 'accountName',
      header: () => t(`${tBase}.accountName`),
    },
    {
      id: 'currentYear',
      accessorFn: row => formatCurrency(row.currentYear) ?? '-',
      header: () => t(`${tBase}.currentYear`),
      sortingFn: (a, b) =>
        Number(a.original.currentYear) - Number(b.original.currentYear),
      className: 'text-right nowrap',
    },
    {
      id: 'priorYear',
      accessorFn: row => formatCurrency(row.priorYear) ?? '-',
      header: () => t(`${tBase}.priorYear`),
      sortingFn: (a, b) =>
        Number(a.original.priorYear) - Number(b.original.priorYear),
      className: 'text-right nowrap',
    },
  ];

  return (
    <AuditingTable<GroupedGeneralLedgerItem>
      sectionKey={sectionKey}
      data={data}
      columns={tableColumns}
      showGlobalFilter={false}
      disableSort
    />
  );
};
