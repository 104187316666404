import { observer } from 'mobx-react-lite';
import React, { useMemo, useState } from 'react';
import AuditingSections from './auditingSections';
import Form from '../components/forms/Form';
import AuditingSectionWrapper from '../components/layout/AuditingSectionWrapper';
import { useStore } from '../store/RootContext';
import { today, useFetchAuditing, useParams } from '../utils';
import UnfinishedDependingSections from '../components/UnfinishedDependingSections';

interface Props {
  sectionKey: AuditingSectionKey;
}

function AuditingSection<SectionForm>({ sectionKey }: Props) {
  const { id } = useParams();

  const store = useStore();

  const {
    authStore: { userId: authUserId },
    auditingStore: {
      auditing,
      auditingTemplate,
      auditingLocked,
      getAuditingSection,
      isAuditingSectionFinished,
      editEntity,
    },
  } = store;

  useFetchAuditing({ id });

  const [formSaveState, setFormSaveState] = useState<FormSaveState>();

  const sectionDefinitions = AuditingSections[sectionKey];

  const formSectionKey = sectionDefinitions.formSectionKey ?? sectionKey;

  const auditingSection = getAuditingSection(formSectionKey);

  const sectionLocked = isAuditingSectionFinished(sectionKey);

  const unfinishedDependingSectionKeys = useMemo(() => {
    if (!auditing) return [];
    const depending = typeof sectionDefinitions.depending === 'function'
      ? sectionDefinitions.depending(auditing)
      : sectionDefinitions.depending;
    return (depending ?? []).filter(
      dependingSectionKey => !isAuditingSectionFinished(dependingSectionKey)
    );
  }, [auditing, isAuditingSectionFinished, sectionDefinitions]);

  if (!auditing) return null;

  const getDefaultFormState = (auditing: Auditing) => {
    const formState = sectionDefinitions.defaultFormState;
    if (typeof formState === 'function') return formState({ auditing, store });
    return formState;
  };

  const initialFormState: SectionForm = {
    ...getDefaultFormState(auditing),
    ...(auditingSection?.form ?? {}),
  };

  // Couldn't get this to work without casting the type but it should be fine in this case...
  const formFields = sectionDefinitions.formFields as FormFields<SectionForm>;

  const handleSubmit = async (formState: SectionForm) => {
    const entityPatch: Partial<Auditing> = {
      [formSectionKey]: {
        ...auditingSection,
        updatedAt: today(),
        updatedBy: authUserId,
        // Set status to 'started' if there is no previous status value (first time editing)
        status: auditingSection?.status ? auditingSection?.status : 'started',
        form: formState,
      },
    };
    const { success } = await editEntity(
      { id, data: entityPatch },
      { isBackground: true, isSilent: true }
    );
    return success;
  };

  const hasUnfinishedDependingSections =
    process.env.NODE_ENV !== 'development' &&
    !!unfinishedDependingSectionKeys.length;

  return (
    <AuditingSectionWrapper
      sectionKey={sectionKey}
      formSaveState={formSaveState}
      disabled={hasUnfinishedDependingSections}
    >
      {hasUnfinishedDependingSections ? (
        <UnfinishedDependingSections
          sectionKeys={unfinishedDependingSectionKeys}
        />
      ) : (
        <Form<SectionForm>
          formId={`${sectionKey}-${formSectionKey}`}
          initialState={initialFormState}
          onSubmit={handleSubmit}
          fields={formFields}
          translationBase={`auditing:form.${formSectionKey}`}
          onFormSaveStateChange={saveState => setFormSaveState(saveState)}
          sectionKey={formSectionKey}
          auditingTemplate={auditingTemplate}
          disabled={auditingLocked || sectionLocked}
        />
      )}
    </AuditingSectionWrapper>
  );
}

export default observer(AuditingSection);
