import { AppContext } from './AppContext';
import { observer } from 'mobx-react-lite';
import { useState, useEffect } from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Spinner from './components/Spinner';
import AppRouter from './router/AppRouter';
import { useStore } from './store/RootContext';
import { GlobalStyle, theme, ThemeProvider } from './theme';
import { TOAST_OPTIONS, API_URL } from './utils';
import { Disconnect } from './views/Disconnect';

const App = observer(() => {
  const {
    appStore: { appState },
  } = useStore();

  const showFullScreenSpinner =
    appState.isProcessing || appState.isFetching || appState.isSaving;

  const [isDisconnected, setIsDisconnected] = useState(true);

  useEffect(() => {
    const checkConnection = () => {
      fetch(API_URL)
        .then((response) => {
          if (response.ok) {
            setIsDisconnected(false);
          } else {
            setIsDisconnected(true);
          }
        })
        .catch((error) => {
          setIsDisconnected(true);
        });
    };

    // Run the check immediately
    checkConnection();

    // Then run it every 30 seconds, but start after a delay
    const timeoutId = setTimeout(() => {
      const intervalId = setInterval(checkConnection, 30000);

      // Clean up the interval on unmount
      return () => clearInterval(intervalId);
    }, 30000); // delay before starting the interval

    // Clean up the timeout on unmount
    return () => clearTimeout(timeoutId);
  }, []);

  return (
    <AppContext.Provider value={{ setIsDisconnected }}>
      {isDisconnected && <Disconnect />}
      <ThemeProvider theme={theme}>
        {showFullScreenSpinner && <Spinner isFullScreen />}
        <ToastContainer {...TOAST_OPTIONS} />
        <GlobalStyle />
        {!isDisconnected && <AppRouter />}
      </ThemeProvider>
    </AppContext.Provider>
  );
});

export default App;
