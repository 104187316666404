import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import {
  generalLedgerParser,
  parseGroupedAccountsData,
  ParseMethod,
  ParserCallbackFn,
  ParserOptions,
} from '../../views/auditingSections/analyzes/generalLedgerUtils';
import FileUpload from '../inputs/FileUpload';
import { generateFinancialStatementData } from '../../views/auditingSections/analyzes/incomeStatementAndBalanceUtils';
import { AuditingTemplate, useParams } from '../../utils';
import { Select } from '../inputs';
import CsvParseMethodExample from '../CsvParseMethodExample';
import { generateAccountMap } from '../../views/auditingSections/analyzes/accountMapUtils';
import notificationJson from '../../i18n/locales/fi/notification.json';

import { Risk } from '../../views/auditingSections/analyzes/incomeStatementAndBalance';
import { AccountKey } from '../../views/auditingSections/planning/accountMapKeys';

const ParseMethodContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: ${p => p.theme.spacing.md};
`;

export const UploadGeneralLedger = ({
  formState,
  patchFormState,
  store,
}: FormFieldProps<GeneralLedgerForm>) => {
  const { t } = useTranslation();
  const { id: auditingId } = useParams();

  const { auditingStore, notificationStore } = store;

  const { auditingLocked, isAuditingSectionFinished, getAccountMap } =
    auditingStore;

  const disabled = auditingLocked || isAuditingSectionFinished('generalLedger');

  const [parseMethod, setParseMethod] = useState<ParseMethod | undefined>(
    formState?.parseMethod
  );

  useEffect(() => {
    if (formState?.parseMethod) setParseMethod(formState.parseMethod);
  }, [formState.parseMethod]);

  if (!formState) return null;

  // Generates income statement and balance sheets, and silently patches them to API in the background.
  const generateIncomeStatementAndBalance = (
    groupedGeneralLedger: GroupedGeneralLedger
  ) => {
    const incomeStatementAndBalanceSection = auditingStore.getAuditingSection(
      'incomeStatementAndBalance'
    );

    const updatedForm = generateFinancialStatementData(
      groupedGeneralLedger,
      getAccountMap()
    );

    // Update specific fields in the form
    const accountKeysToUpdate = [
      'incomeTaxes',
      'equity',
      'cashInHandAndAtBanks',
    ];
    const statementKeys = [
      'ole',
      'ove',
      'tap',
      'tay',
      'oik',
      'arv',
      'luo',
      'esi',
    ] as const;
    const updateRisk = (items: (IncomeStatementItem | BalanceItem)[]) => {
      items.forEach((row: IncomeStatementItem | BalanceItem) => {
        if (accountKeysToUpdate.includes(row.key as AccountKey)) {
          row.risk = Risk.limitedRisk;
        }
        // Update statement keys to true (boolean | null), dont set them where currentYear and priorYear are null or undefined
        //if (row.currentYear !== undefined && row.priorYear !== undefined) {
        statementKeys.forEach(key => {
          (row as any)[key] = true;
        });
        //}
      });
    };

    updateRisk(updatedForm.incomeStatement);
    updateRisk(updatedForm.balanceAssets);
    updateRisk(updatedForm.balanceLiabilities);

    const auditingPatch: Partial<Auditing> = {
      incomeStatementAndBalance: {
        ...incomeStatementAndBalanceSection,
        form: updatedForm,
      },
    };

    auditingStore.editEntity(
      { id: auditingId, data: auditingPatch },
      { isSilent: true, isBackground: true }
    );
  };

  const callback = (
    groupedGeneralLedger?: GroupedGeneralLedger,
    errorKey = 'common'
  ) => {
    if (groupedGeneralLedger) {
      // Success
      patchFormState({ parseMethod, groupedGeneralLedger });
      generateIncomeStatementAndBalance(groupedGeneralLedger);
      notificationStore.notify(t('notification:success.fileUpload'), 'success');
    } else {
      // Error
      notificationStore.notify(t(`notification:error.${errorKey}`), 'error');
    }
  };

  const handleFileUpload = (parseMethod?: ParseMethod) => (file: File) => {
    const parserProps: [File, ParserCallbackFn, ParserOptions] = [
      file,
      callback,
      { accountMap: getAccountMap() },
    ];

    switch (parseMethod) {
      case ParseMethod.netvisor:
        return generalLedgerParser.parseNetvisorData(...parserProps);
      default:
        return generalLedgerParser.parseGroupedAccounts(...parserProps);
    }
  };

  if (disabled) return null;

  return (
    <FileUpload
      label={t('common:label.uploadGeneralLedger')}
      accept={['.csv', '.xlsx', '.xls']}
      onFileUpload={handleFileUpload(parseMethod)}
    >
      <ParseMethodContainer>
        <Select
          options={Object.values(ParseMethod)}
          value={parseMethod}
          setValue={setParseMethod}
          displayValue={option =>
            t(`auditing:form.generalLedger.parseMethods.${option}`)
          }
          disabled={disabled}
        />

        <CsvParseMethodExample type={parseMethod} />
      </ParseMethodContainer>
    </FileUpload>
  );
};

export const UploadNoGeneralLedgerFunction = ({
  formState,
  patchFormState,
  store,
  id,
}: any) => {
  const { auditingStore, notificationStore } = store;

  const { getAccountMap } = auditingStore;

  const parseMethod = formState?.parseMethod;

  const generateIncomeStatementAndBalance = (
    groupedGeneralLedger: GroupedGeneralLedger
  ) => {
    const incomeStatementAndBalanceSection = auditingStore.getAuditingSection(
      'incomeStatementAndBalance'
    );

    const auditingPatch: Partial<Auditing> = {
      incomeStatementAndBalance: {
        ...incomeStatementAndBalanceSection,
        form: generateFinancialStatementData(
          groupedGeneralLedger,
          getAccountMap(),
          false
        ),
      },
    };

    auditingStore.editEntity(
      { id: id.id, data: auditingPatch },
      { isSilent: true, isBackground: true }
    );
  };

  if (!formState) return null;

  const callback = (
    groupedGeneralLedger?: GroupedGeneralLedger,
    errorKey = 'common'
  ) => {
    if (groupedGeneralLedger) {
      // Success
      patchFormState({ parseMethod, groupedGeneralLedger });
      generateIncomeStatementAndBalance(groupedGeneralLedger);
      notificationStore.notify(notificationJson.success.fileUpload, 'success');
    } else {
      // Error
      notificationStore.notify(notificationJson.error.common, 'error');
    }
  };

  const accountMap = generateAccountMap(AuditingTemplate.private);

  const example = [
    ['tili', 'selite', 'Tilikauden alusta CY', 'tilikauden alusta PY', ''],
    [
      '0',
      "Ei pääkirjaa käytetty. Lisää tiedot 'Tuloslaskelma ja tase' välilehdiltä tai liitä pääkirja",
      '?',
      '?',
      '1089 Muut pitkävaikutteiset menot',
    ],
  ];

  try {
    callback(parseGroupedAccountsData(example, accountMap));
  } catch (error) {
    callback(undefined, 'invalidDataFormat');
  }
};
