import { findByKey } from '../../../utils/array';
import { AccountKey } from '../planning/accountMapKeys';
import {
  getValueOrDefaultValue,
  makeEmptyThresholdTestRow,
} from './auditingProceduresUtils';

/**
 * Define rows for auditing procedure unit rows.
 */
export const analyticalAuditOfPersonnelOverheadCostsRows = () => [
  makeEmptyThresholdTestRow({
    key: 'actualExpectedPensionCostPercentage',
    defaultValue: 17.4,
    unit: '%',
  }),
  makeEmptyThresholdTestRow({
    key: 'expectedPensionCost',
    unit: '€',
    disabled: true,
  }),
  makeEmptyThresholdTestRow({
    key: 'actualPensionCost',
    unit: '€',
    disabled: true,
  }),
  makeEmptyThresholdTestRow({
    key: 'differenceInPensionCost',
    unit: '€',
    disabled: true,
  }),
  makeEmptyThresholdTestRow({ key: 'pensionCost_THRESHOLD', unit: '€' }),
  makeEmptyThresholdTestRow({ key: 'pensionCost_CONCLUSION', disabled: true }),

  makeEmptyThresholdTestRow({
    key: 'actualExpectedUnemploymentInsurancePercentage',
    defaultValue: 1.53,
    unit: '%',
  }),
  makeEmptyThresholdTestRow({
    key: 'expectedUnemploymentInsuranceCost',
    unit: '€',
    disabled: true,
  }),
  makeEmptyThresholdTestRow({
    key: 'actualUnemploymentInsuranceCost',
    unit: '€',
    disabled: true,
  }),
  makeEmptyThresholdTestRow({
    key: 'differenceInUnemploymentInsuranceCost',
    unit: '€',
    disabled: true,
  }),
  makeEmptyThresholdTestRow({
    key: 'unemploymentInsuranceCost_THRESHOLD',
    unit: '€',
  }),
  makeEmptyThresholdTestRow({
    key: 'unemploymentInsuranceCost_CONCLUSION',
    disabled: true,
  }),

  makeEmptyThresholdTestRow({
    key: 'otherPensionExpensesPercentage',
    defaultValue: 2,
    unit: '%',
  }),
  makeEmptyThresholdTestRow({
    key: 'expectedPensionExpenses',
    unit: '€',
    disabled: true,
  }),
  makeEmptyThresholdTestRow({
    key: 'actualPensionExpenses',
    unit: '€',
    disabled: true,
  }),
  makeEmptyThresholdTestRow({
    key: 'differenceInPensionExpenses',
    unit: '€',
    disabled: true,
  }),
  makeEmptyThresholdTestRow({
    key: 'otherPensionExpenses_THRESHOLD',
    unit: '€',
  }),
  makeEmptyThresholdTestRow({
    key: 'otherPensionExpenses_CONCLUSION',
    disabled: true,
  }),

  makeEmptyThresholdTestRow({
    key: 'otherSideCostsPercentage',
    defaultValue: 2,
    unit: '%',
  }),
  makeEmptyThresholdTestRow({
    key: 'expectedOtherSideCosts',
    unit: '€',
    disabled: true,
  }),
  makeEmptyThresholdTestRow({
    key: 'actualOtherSideCosts',
    unit: '€',
    disabled: true,
  }),
  makeEmptyThresholdTestRow({
    key: 'differenceInOtherSideCosts',
    unit: '€',
    disabled: true,
  }),
  makeEmptyThresholdTestRow({
    key: 'otherSideCosts_THRESHOLD',
    unit: '€',
  }),
  makeEmptyThresholdTestRow({
    key: 'otherSideCosts_CONCLUSION',
    disabled: true,
  }),
];

/**
 * Define computation rules/formulas for each row.
 */
const computationRules: ThresholdComputationRules = {
  // Todellinen/ odotusarvo eläkekulu-%
  actualExpectedPensionCostPercentage: ({ data }) => {
    return getValueOrDefaultValue(
      data.find(findByKey('actualExpectedPensionCostPercentage'))
    );
  },

  // Odotusarvoinen eläkekulu (Tyel/ Kuel tai vastaava)
  // (”palkat ja palkkiot” – ”YEL-palkat”) x ”Todellinen/odotusarvo eläkekulu-%”
  expectedPensionCost: props => {
    const { getAccountMappingValues } = props;
    const { actualExpectedPensionCostPercentage } = computationRules;
    const [wagesTotal, tyelOrKuel] = getAccountMappingValues.py(
      'wagesTotal',
      'tyelOrKuelAccounts'
    );
    return Math.abs(
      ((wagesTotal - tyelOrKuel) / 100) *
        actualExpectedPensionCostPercentage(props)
    );
  },

  // Toteuma (Tyel/ Kuel tai vastaava)
  actualPensionCost: props => {
    const { getAccountMappingValues } = props;
    const [tyelOrKuel] = getAccountMappingValues.cy('tyelOrKuelAccounts');
    return Math.abs(tyelOrKuel);
  },

  // Erotus
  differenceInPensionCost: props => {
    const { expectedPensionCost, actualPensionCost } = computationRules;
    return expectedPensionCost(props) - actualPensionCost(props);
  },

  // Eläkekulujen kynnysarvo
  pensionCost_THRESHOLD: ({ data, auditingStore }) => {
    return Math.abs(
      getValueOrDefaultValue(
        data.find(findByKey('pensionCost_THRESHOLD')),
        auditingStore.materialityComputeds().singleErrorMateriality
      )
    );
  },

  // Eläkekulujen johtopäätös
  pensionCost_CONCLUSION: props => {
    const threshold = computationRules.pensionCost_THRESHOLD(props);
    const comparisonValue = Math.abs(
      computationRules.differenceInPensionCost(props)
    );
    const comparison = threshold - comparisonValue;
    return comparison < 0 ? -1 : comparison > 0 ? 1 : 0;
  },

  // Todellinen/ odotusarvo savamaksu-%
  actualExpectedUnemploymentInsurancePercentage: ({ data }) => {
    return getValueOrDefaultValue(
      data.find(findByKey('actualExpectedUnemploymentInsurancePercentage'))
    );
  },
  // Odotusarvoinen savamaksu = edeltävän tilikauden summa
  expectedUnemploymentInsuranceCost: props => {
    const { getAccountMappingValues } = props;
    const [sicknessInsurance] = getAccountMappingValues.py(
      'sicknessInsuranceAccounts'
    );
    return Math.abs(sicknessInsurance);
  },
  // Toteuma (Savamaksu)
  actualUnemploymentInsuranceCost: props => {
    const { getAccountMappingValues } = props;
    const [sicknessInsurance] = getAccountMappingValues.cy(
      'sicknessInsuranceAccounts'
    );
    return Math.abs(sicknessInsurance);
  },
  // Erotus
  differenceInUnemploymentInsuranceCost: props => {
    const {
      expectedUnemploymentInsuranceCost: expected,
      actualUnemploymentInsuranceCost: actual,
    } = computationRules;
    return expected(props) - actual(props);
  },
  // Sava-maksun kynnysarvo
  unemploymentInsuranceCost_THRESHOLD: ({ data, auditingStore }) => {
    return Math.abs(
      getValueOrDefaultValue(
        data.find(findByKey('unemploymentInsuranceCost_THRESHOLD')),
        auditingStore.materialityComputeds().singleErrorMateriality
      )
    );
  },
  // Sava-maksun johtopäätös
  unemploymentInsuranceCost_CONCLUSION: props => {
    const threshold =
      computationRules.unemploymentInsuranceCost_THRESHOLD(props);
    const comparisonValue = Math.abs(
      computationRules.differenceInUnemploymentInsuranceCost(props)
    );
    const comparison = threshold - comparisonValue;
    return comparison < 0 ? -1 : comparison > 0 ? 1 : 0;
  },

  // Muut eläkekulut %
  otherPensionExpensesPercentage: ({ data }) => {
    return getValueOrDefaultValue(
      data.find(findByKey('otherPensionExpensesPercentage'))
    );
  },

  // Odotusarvoinen eläkekulu = kuluvan vuoden palkat * syötetty prosentti
  expectedPensionExpenses: props => {
    const { auditingStore } = props;
    const cyWagesAndSalaries =
      auditingStore.getFinancialNumber(AccountKey.wagesAndSalaries) ?? 0;
    const { otherPensionExpensesPercentage } = computationRules;
    return Math.abs(
      cyWagesAndSalaries * (otherPensionExpensesPercentage(props) / 100)
    );
  },

  // Toteuma (Tyel / Kuel tai vastaava)
  actualPensionExpenses: props => {
    const { getAccountMappingValues } = props;
    const [pensionExpensesCy] = getAccountMappingValues.cy('pensionExpenses');
    const { otherPensionExpensesPercentage } = computationRules;
    return Math.abs(
      pensionExpensesCy * (otherPensionExpensesPercentage(props) / 100)
    );
  },

  // Erotus
  differenceInPensionExpenses: props => {
    const { expectedPensionExpenses, actualPensionExpenses } = computationRules;
    return Math.abs(
      expectedPensionExpenses(props) - actualPensionExpenses(props)
    );
  },

  // Muut eläkekulut - kynnysarvo
  otherPensionExpenses_THRESHOLD: ({ data, auditingStore }) => {
    return Math.abs(
      getValueOrDefaultValue(
        data.find(findByKey('otherPensionExpenses_THRESHOLD')),
        auditingStore.materialityComputeds().singleErrorMateriality
      )
    );
  },

  // Muut eläkekulut - johtopäätös
  otherPensionExpenses_CONCLUSION: props => {
    const threshold = computationRules.otherPensionExpenses_THRESHOLD(props);
    const comparisonValue = Math.abs(
      computationRules.differenceInPensionExpenses(props)
    );
    const comparison = threshold - comparisonValue;
    return comparison < 0 ? -1 : comparison > 0 ? 1 : 0;
  },

  // Muut sivukulut %
  otherSideCostsPercentage: ({ data }) => {
    return getValueOrDefaultValue(
      data.find(findByKey('otherSideCostsPercentage'))
    );
  },

  // Odotusarvoinen sivukulu = kuluvan vuoden palkat * syötetty prosentti
  expectedOtherSideCosts: props => {
    const { auditingStore } = props;
    const { otherSideCostsPercentage } = computationRules;
    const cyWagesAndSalaries =
      auditingStore.getFinancialNumber(AccountKey.wagesAndSalaries) ?? 0;
    return Math.abs(
      cyWagesAndSalaries * (otherSideCostsPercentage(props) / 100)
    );
  },

  // Toteuma
  actualOtherSideCosts: ({ auditingStore }) => {
    // TODO: tarkasta haetaanko oikea
    const otherSocialSecurityCosts = auditingStore.getFinancialItem(
      AccountKey.otherSocialSecurityCosts
    );
    const cyEur = otherSocialSecurityCosts?.currentYear
      ? otherSocialSecurityCosts?.currentYear
      : 0;
    return Math.abs(cyEur);
  },

  // Erotus
  differenceInOtherSideCosts: props => {
    const { expectedOtherSideCosts, actualOtherSideCosts } = computationRules;
    return Math.abs(
      expectedOtherSideCosts(props) - actualOtherSideCosts(props)
    );
  },

  // Muut sivukulut - kynnysarvo
  otherSideCosts_THRESHOLD: ({ data, auditingStore }) => {
    return Math.abs(
      getValueOrDefaultValue(
        data.find(findByKey('otherSideCosts_THRESHOLD')),
        auditingStore.materialityComputeds().singleErrorMateriality
      )
    );
  },

  // Muut sivukulut - johtopäätös
  otherSideCosts_CONCLUSION: props => {
    const threshold = computationRules.otherSideCosts_THRESHOLD(props);
    const comparisonValue = Math.abs(
      computationRules.differenceInOtherSideCosts(props)
    );
    const comparison = threshold - comparisonValue;
    return comparison < 0 ? -1 : comparison > 0 ? 1 : 0;
  },
};

export const analyticalAuditOfPersonnelOverheadCostsComputationRules =
  computationRules;
