import { IncomeStatementAndBalanceScopingTableItem } from '../../../components/scoping/IncomeStatementAndBalanceScoping';
import { IncomeStatementAndBalanceComputeds } from '../../../components/scoping/IncomeStatementAndBalanceScoping.ComputedsTable';
import { MaterialitiesTable } from '../../../components/auditing/Materiality.MaterialitiesTable';
import { FormFieldBuilder } from '../FormFieldBuilder';
//import { IncomeStatementAndBalancePin } from '../../../components/scoping/IncomeStatementPinScoping';
import { CopyRisksData } from './copyFunctions';
import { IncomeStatementMaterialityScoping } from './incomeStatementMaterialityScoping';
import InstructionBox from '../../../components/InstructionBox';
import { t } from '../../../i18n';

type SectionFormType = IncomeStatementAndBalanceScopingForm;

const sectionKey: AuditingSectionKey = 'incomeStatementAndBalanceScoping';

const formBuilder = new FormFieldBuilder<SectionFormType>({ sectionKey });

export enum Risk {
  notSignificant = 'notSignificant',
  limitedRisk = 'limitedRisk',
  significant = 'significant',
}

const defaultFormState: DefaultFormState<SectionFormType> = {
  incomeStatement: [],
  balanceAssets: [],
  balanceLiabilities: [],

  dateIncome: 'Ajanjakso 1.1-31.12.202X.',
  dateBalance: 'Ajanjakso 1.1-31.12.202X.',
  dateLiabilities: 'Ajanjakso 1.1-31.12.202X.',

  selectMateriality: true,
  materialityNumber: '',
  incomeStatementHelp: false,
};

const plainTitle = 'Tarkastuksen ajanjakso';

const formFields: FormFields<SectionFormType> = ({ auditing }) => [
  formBuilder.custom(
    <InstructionBox
      buttonText='Katso ohje!'
      title='Ohje'>
      <div style={{whiteSpace: 'pre-line'}}>
        {t('auditing:form.incomeStatementAndBalanceScoping.incomeStatementHelpInfo')}
      </div>
    </InstructionBox>
  ),

  formBuilder.custom(<CopyRisksData />),

  formBuilder.group({
    items: [
      formBuilder.custom(props => (
        <IncomeStatementMaterialityScoping {...props} />
      )),
    ],
  }),

  formBuilder.accordionGroup({
    open: true,
    titleKey: 'incomeStatement',
    items: [
      formBuilder.groupRow({
        items: [
          formBuilder.textInput({
            accessor: 'dateIncome',
            plainLabel: plainTitle,
          }),
        ],
      }),
      formBuilder.custom(props => (
        <IncomeStatementAndBalanceScopingTableItem
          targetKey="incomeStatement"
          {...props}
        />
      )),
    ],
  }),

  formBuilder.accordionGroup({
    open: true,
    titleKey: 'balanceAssets',
    items: [
      formBuilder.groupRow({
        items: [
          formBuilder.textInput({
            accessor: 'dateBalance',
            plainLabel: plainTitle,
          }),
        ],
      }),
      formBuilder.custom(props => (
        <IncomeStatementAndBalanceScopingTableItem
          targetKey="balanceAssets"
          {...props}
        />
      )),
    ],
  }),

  formBuilder.accordionGroup({
    open: true,
    titleKey: 'balanceLiabilities',
    items: [
      formBuilder.groupRow({
        items: [
          formBuilder.textInput({
            accessor: 'dateLiabilities',
            plainLabel: plainTitle,
          }),
        ],
      }),
      formBuilder.custom(props => (
        <IncomeStatementAndBalanceScopingTableItem
          targetKey="balanceLiabilities"
          {...props}
        />
      )),
    ],
  }),

  formBuilder.custom(props => (
    <IncomeStatementAndBalanceComputeds {...props} />
  )),

  /* formBuilder.custom(props => (
    <IncomeStatementAndBalancePin {...props} />
  )), */

  /*   formBuilder.accordionGroup({
    open: false,
    titleKey: 'pin',
    items: [
      formBuilder.custom(props => (
        <IncomeStatementAndBalancePin {...props} />
      )),
    ],
  }), */

  formBuilder.custom(props => {
    const formState = auditing?.materiality?.form;
    if (!formState) return;
    return <MaterialitiesTable {...props} formState={formState} readonly />;
  }),
];

const section: AuditingSectionDefinition<SectionFormType> = {
  sectionKey,
  defaultFormState,
  formFields,
  depending: ['accountMapScoping', 'generalLedgerScoping'],
};

export default section;
