import React from 'react';
import styled from 'styled-components';
import ContextInfo, { ContextInfoProps } from '../ContextInfo';
import Icon from '../Icon';

const LabelWithContextInfo = styled.div`
  display: flex;
  align-items: center;

  > * {
    :not(:first-child) {
      margin-left: ${p => p.theme.spacing.sm};
    }
  }
`;

interface StyledLabelProps {
  disabled?: boolean;
  variant?: 'normal' | 'light';
}

const StyledLabel = styled.label<StyledLabelProps>`
  display: block;
  color: ${p => (p.disabled ? p.theme.color.grey400 : p.theme.color.grey600)};
  ${p => p.theme.font.size[14]};
  text-align: left;
  font-weight: ${p => (p.variant === 'light' ? 'normal' : 'bold')};
  position: relative;

  .remark-icon {
    position: absolute;
    left: -${p => p.theme.spacing.lg};
  }

  &.is-required {
    :after {
      content: '*';
      color: ${p => p.theme.color.primary};
      margin-left: 0.2rem;
    }
  }
`;

interface Props extends StyledLabelProps {
  contextInfo?: ContextInfoProps;
  className?: string;
  showRemark?: boolean;
  children: React.ReactNode;
}

export const Label: React.FC<Props> = ({
  disabled,
  contextInfo,
  className,
  showRemark,
  variant = 'normal',
  children,
}) => {
  const labelElement = (
    <StyledLabel disabled={disabled} className={className} variant={variant}>
      {showRemark && (
        <Icon type="Exclamation" color="error" className="remark-icon" />
      )}
      {children}
    </StyledLabel>
  );

  if (!contextInfo) return labelElement;

  return (
    <LabelWithContextInfo>
      {labelElement}
      <ContextInfo {...contextInfo} />
    </LabelWithContextInfo>
  );
};

export default Label;
