import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import Icon from './Icon';

const Container = styled.div`
  display: flex;
  align-items: center;
  height: 32px;

  color: ${p => p.theme.color.grey400};
  ${p => p.theme.font.size[14]};
  font-style: italic;

  svg {
    margin-right: ${p => p.theme.spacing.sm};
  }
`;

interface Props {
  id?: string;
  saveState: FormSaveState;
}

const SaveState: React.FC<Props> = ({ id, saveState }) => {
  const { t } = useTranslation();

  const getProps = (saveState: FormSaveState) => {
    let text = '';
    let icon: JSX.Element | undefined = undefined;

    switch (saveState) {
      case 'UNSAVED':
        text = t('common:state:unsaved');
        icon = <Icon type="Hourglass" color="grey300" />;
        break;
      case 'SAVING':
        text = t('common:state:saving');
        icon = (
          <Icon type="Hourglass" color="primary" className="is-spinning" />
        );
        break;
      case 'SAVED':
        text = t('common:state:changesSaved');
        icon = <Icon type="Check" color="success" />;
        break;
      case 'ERROR':
        text = t('common:state:error');
        icon = <Icon type="Unhappy" color="error" />;
        break;
      default:
      // do nothing
    }

    return { text, icon };
  };

  const { text, icon } = getProps(saveState);

  return (
    <Container id={id} data-testid={id}>
      {icon}
      <div data-testid={`${id}-text`}>{text}</div>
    </Container>
  );
};

export default SaveState;
