import styled from 'styled-components';

export const Overlay = styled.div`
  z-index: 5;
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background-color: ${p => p.theme.color['background']};
  opacity: 0.9;
  filter: blur(8px);
`;
