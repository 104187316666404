import styled from 'styled-components';
import { INPUT_WIDTH } from '../../utils';

export interface SelectContainerProps {
  fullWidth?: boolean;
  minimal?: boolean;
}

export const SelectContainer = styled.div<SelectContainerProps>`
  position: relative;
  display: block;
  width: ${p => (p.fullWidth ? '100%' : undefined)};

  .clear-select-button {
    position: absolute;
    right: -6px;
    top: -6px;
    z-index: 1;
    width: 16px;
    height: 16px;
    background-color: ${p => p.theme.color.grey300};
    border-radius: 10rem;
    svg {
      fill: white;
    }
  }

  .remark-icon {
    position: absolute;
    left: -8px;
    top: -6px;
    z-index: 1;
  }

  .select-options {
    position: absolute;
    margin-top: -4px;
    z-index: 3;
    background-color: ${p => p.theme.color.background};
    width: ${p => (p.fullWidth ? '100%' : INPUT_WIDTH)};
    border: 1px solid ${p => p.theme.color.secondary};
    border-radius: ${p => p.theme.borderRadius.sm};
    ${p => p.theme.shadow.sm};
    text-align: left;
    ${p => (p.minimal ? p.theme.font.size[12] : undefined)};

    max-height: 250px;
    overflow-y: auto;

    .select-option {
      position: relative;
      padding: ${p => p.theme.spacing[p.minimal ? 'sm' : 'md']};
      padding-left: 2.25rem;
      color: ${p => p.theme.color.grey500};

      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      :not(.nothing-found) {
        cursor: pointer;
      }

      &.is-selected {
        color: ${p => p.theme.color.text};
      }

      &.is-selected,
      :hover:not(.nothing-found) {
        border-radius: ${p => p.theme.borderRadius.sm};
        background-color: ${p => p.theme.color.primary100};
      }

      svg {
        position: absolute;
        left: 0.75rem;
        bottom: ${p => (p.minimal ? 0.4 : 0.7)}rem;
      }
    }
  }
`;
