import { displayAuditingProcedures } from '../../../components/auditing/AuditingProcedureItem2';
import { SamplingTableColumn } from '../../../components/auditing/MaterialsAndServices.SamplingTable';
import auditingJson from '../../../i18n/locales/fi/auditing.json';
import { hidePartBasedOnItAndProcesses } from '../../../utils/auditing';
import { FormFieldBuilder } from '../FormFieldBuilder';
import { ProcedureAccountsElement } from './reUsables/ProcedureAccountsElement';
import { ProcessDescriptionTable } from './reUsables/ProcessDescription';
import { SpreadSheet } from './reUsables/SpreadSheet';
import { TableColumn } from './reUsables/TableColumn';
import { t } from '../../../i18n';
import {
  innerCircleElement,
  innerCircleElementDFS,
} from './reUsables/innerCircle';

type SectionFormType = UseInnerCircle<MaterialsAndServicesForm>;

const sectionKey: AuditingSectionKey = 'materialsAndServices';

export const tBase = `auditing:form.${sectionKey}`;

const samplingLargestUnits = t(`${tBase}.samplingTable.largestUnits`);
const samplingRandomUnits = t(`${tBase}.samplingTable.randomUnits`);
const monetarySampling = t(`${tBase}.samplingTable.monetary`);
const otherSamplingProcedure = t(
  `${tBase}.samplingTable.otherSamplingProcedure`
);

const vocabulary = auditingJson.form.materialsAndServices;
const batchString = 'K1-materialsAndServices';

const formBuilder = new FormFieldBuilder<SectionFormType>({ sectionKey });

const defaultFormState: DefaultFormState<SectionFormType> = ({ auditing }) => {
  return {
    procedureAccountsData: {},
    firstTable: [
      {
        id: '1',
        purchases: '4000 Ostot',
        CY: 0,
        PY: 0,
      },
    ],
    samplingTable: [
      {
        id: '1',
        label: samplingLargestUnits,
        one: false,
        more: false,
      },
      {
        id: '2',
        label: samplingRandomUnits,
        one: false,
        more: false,
      },
      {
        id: '3',
        label: monetarySampling,
        one: false,
        more: false,
      },
      {
        id: '4',
        label: otherSamplingProcedure,
        one: false,
        more: false,
      },
    ],
    secondTableColumnData: [
      {
        left: '',
        right: '',
      },
    ],
    tableColumnData: [
      {
        left: 'Ostoihin kirjatut tapahtumat ovat toteutuneet ja kuuluvat yhteisölle',
        right: auditingJson.TAP,
      },
      {
        left: 'Kaikki tapahtumat, jotka olisi tullut kirjata kuluksi on kirjattu',
        right: auditingJson.TAY,
      },
      {
        left: 'Kulut on kirjattu oikeassa määrässä',
        right: auditingJson.OIK_ARV,
      },
      {
        left: 'Tilikaudelle on kirjattu vain suoriteperusteisesti sille kuuluvat kuluerät ',
        right: auditingJson.KAT,
      },
      {
        left: 'Materiaalien ja palveluiden alle on luokiteltu ainoastaan sinne kuuluvia eriä',
        right: auditingJson.LUOK,
      },
      {
        left: 'Ostojen tapahtumat on yhdistelty ja jaoteltu oikein / ne on kuvattu selvästi, ja/tai niihin liittyvät tiedot tilinpäätöksessä ovat relevantteja ja ymmärrettäviä sovellettavan tilinpäätösnormiston mukaan',
        right: auditingJson.ESI,
      },
    ],
    textArea:
      'Suoritetuilla tarkastustoimenpiteillä on saatu riittävä evidenssi materiaalien ja palveluiden oikeellisuudesta ja tarkastuksen tavoitteet on saavutettu. Suoritetun tarkastuksen perusteella materiaalit ja palvelut ovat olennaisin osin oikein.',
    // Ostojen analyyttinen tarkastus
    inspectionConclusionPurchases: '1',
    // Kateanalyysi
    inspectionConclusionAnalysis: '1',

    // Ostojen aineistotarkastus
    useSheet: null,
    sheetdata1: [
      {
        cellData: [],
      },
    ],
    useSheet2: null,
    sheetdata2: [
      {
        cellData: [],
      },
    ],
    useSheet3: null,
    sheetdata3: [
      {
        cellData: [],
      },
    ],
    sheetdata4: [
      {
        cellData: [],
      },
    ],
    inspectionObservationsInspection: 'Esitä tarkastushavainnot ja kommentit.',
    largestUnitsTextField:
      'Valitaan tarkastukseen olennaiset, yli PM:n ylittävät yksittäiset ostotapahtumat /  osto-/kulutilit, sekä suurimpien toimittajien suurimpia osto-/kululaskuja ostolaskuluettelolta. Tarkastukseen valitut tapahtumat kattavat yhteensä XX eur eli X % materiaaleista ja palveluista.',
    randomUnitsTextField:
      'Kuvaa, millä perustein otoskoko on katsottu riittäväksi. Ostojen oikeellisuudesta on saatu evidenssiä kateanalyysin ja analyyttisen tarkastelun perusteella. Suoritetut toimenpiteet ja niiden kautta saatu evidenssi vähentävät tarvetta aineistotarkastustoimenpiteille. Tarkastus kattoi yhteensä XX eur eli X % materiaaleista ja palveluista. Ammatillisen harkinnan perusteella arvioimme kattavuuden riittäväksi.',
    otherSamplingProcedureTextField:
      'Tarkastettavat satunnaiset yksiköt on valittu monetary unit sampling -otannalla. Otannan perusteet ja määrittelyt ks. KX.X MUS // Otoskoon määrittelyyn on käytetty otantatyökalua ks. otoskoon määrittely KX.X',

    processText: auditing?.otherOperatingExpenses?.form.processText ?? '',
    processDate: auditing?.otherOperatingExpenses?.form.processDate ?? '',
    processValue: auditing?.otherOperatingExpenses?.form.processValue ?? '',
    useSheetTab: null,
    ...innerCircleElementDFS,
  };
};

//formBuilder.custom(props => (<FirstTableColumn {...props} />)),

const formFields: FormFields<SectionFormType> = ({
  formState,
  auditing,
  store,
}) => [
  formBuilder.group({
    items: [
      formBuilder.boolean({
        accessor: 'useSheetTab',
        plainLabel: 'Käytä laskenta taulukko osiota',
      }),
      formBuilder.custom(props => (
        <ProcedureAccountsElement
          {...props}
          auditing={auditing}
          batchString={batchString}
          addCommentField={true}
        />
      )),
      formBuilder.custom(props => (
        <TableColumn
          title="Mahdollinen virheellisyyden riski ostoissa"
          headerText="Olennaisen virheellisyyden riskin kannanotto, johon tarkastustoimenpiteillä vastataan (ISA 315.11)"
          {...props}
        />
      )),
      formBuilder.textArea({
        accessor: 'textArea',
      }),
    ],
  }),

  // Here we use "hidePartBasedOnItAndProcesses" to hide the part if IT and Processes "toDocument" is false
  formBuilder.accordionGroup({
    title: auditingJson.form.itAndProcesses.processDescript,
    items: [
      formBuilder.group({
        items: [
          formBuilder.custom(props => (
            <ProcessDescriptionTable
              processText={
                auditing?.otherOperatingExpenses?.form.processText ?? ''
              }
              processDate={
                auditing?.otherOperatingExpenses?.form.processDate ?? ''
              }
              processValue={
                auditing?.otherOperatingExpenses?.form.processValue ?? ''
              }
              {...props}
            />
          )),
        ],
      }),
    ],
    hidden: hidePartBasedOnItAndProcesses(auditing, 'purchases'),
  }),

  ...displayAuditingProcedures(store, batchString, formBuilder, [
    {
      accordionId: 'analyticalInspectionOfPurchases',
      upperElements: [
        formBuilder.checkboxGroup({
          accessor: 'inspectionConclusionPurchases',
          options: Object.keys(vocabulary.inspectionConclusionPurchasesOptions),
        }),
      ],
      lowerElements: [
        formBuilder.boolean({
          accessor: 'useSheet',
          plainLabel: auditingJson.useSheet,
        }),
        formBuilder.custom(props =>
          formState.useSheet ? <SpreadSheet id="sheetdata1" {...props} /> : null
        ),
      ],
    },
    {
      // Kateanalyysi
      accordionId: 'marginAnalysis',
      upperElements: [
        formBuilder.checkboxGroup({
          accessor: 'inspectionConclusionAnalysis',
          options: Object.keys(vocabulary.inspectionConclusionAnalysisOptions),
        }),
      ],
      lowerElements: [
        formBuilder.boolean({
          accessor: 'useSheet2',
          plainLabel: auditingJson.useSheet,
        }),
        formBuilder.custom(props =>
          formState.useSheet2 ? (
            <SpreadSheet id="sheetdata2" {...props} />
          ) : null
        ),
      ],
    },
    {
      // Ostojen katkon tarkastus
      accordionId: 'inspectionOfInterruption',
      lowerElements: [
        formBuilder.boolean({
          accessor: 'useSheetTab',
          plainLabel: auditingJson.useSheet,
        }),
        formBuilder.custom(props =>
          formState.useSheetTab ? (
            <SpreadSheet id="sheetdata4" {...props} />
          ) : null
        ),
      ],
    },
    {
      // Ostojen ainestotarkastus
      accordionId: 'materialInspection',
      upperElements: [
        formBuilder.custom(props => <SamplingTableColumn {...props} />),
        formBuilder.textArea({
          accessor: 'largestUnitsTextField',
          hidden: !formState.samplingTable.some(
            item =>
              item.label === samplingLargestUnits && (item.one || item.more)
          ),
        }),
        formBuilder.textArea({
          accessor: 'randomUnitsTextField',
          hidden: !formState.samplingTable.some(
            item =>
              item.label === samplingRandomUnits && (item.one || item.more)
          ),
        }),
        formBuilder.textArea({
          accessor: 'otherSamplingProcedureTextField',
          hidden: !formState.samplingTable.some(
            item =>
              (item.label === monetarySampling ||
                item.label === otherSamplingProcedure) &&
              (item.one || item.more)
          ),
        }),
      ],
      lowerElements: [
        formBuilder.boolean({
          accessor: 'useSheet3',
          plainLabel: auditingJson.useSheet,
        }),
        formBuilder.custom(props =>
          formState.useSheet3 ? (
            <SpreadSheet id="sheetdata3" {...props} />
          ) : null
        ),
      ],
    },
    {
      // Lähipiiri
      useDefault: false,
      accordionId: 'innerCircle',
      upperElements: innerCircleElement(formBuilder),
    },
  ]),
];

const section: AuditingSectionDefinition<SectionFormType> = {
  sectionKey,
  defaultFormState,
  formFields,
};

export default section;
