import React from 'react';
import AuditingTable from '../../../../components/table/AuditingTable';
import {
  OnEditProps,
  TableColumnDefs,
} from '../../../../components/table/Table';
import { formatCurrency, getIsInterimAudit } from '../../../../utils';
// Normally, the following import would be used:
import {
  generateAccountMap,
  getAllAccountMapRowsMatchingClassKey,
} from '../../scoping/accountMapUtilsScoping';
import {
  generateAccountMap as originalAccountMap,
  getAllAccountMapRowsMatchingClassKey as originalMatchingClassKey,
} from '../../analyzes/accountMapUtils';

interface Props {
  classKey: AccountClassKey;
  auditing?: Auditing;
  addCommentField?: boolean;
  formState?: any;
  patchFormState?: Function;
  showChange?: boolean;
  showSum?: boolean;
  customAccountTitle?: string;
  showTitles?: boolean;
}

const ProcedureAccounts: React.FC<Props> = ({
  auditing,
  classKey,
  addCommentField,
  formState,
  patchFormState,
  showChange,
  showSum,
  customAccountTitle,
  showTitles,
}) => {
  /*
    Using this function requires you to create a variable named procedureAccountsData in the defaultFormState of where you use it, like this:
    
      const defaultFormState: DefaultFormState<SectionFormType> = ({ auditing }) => ({
        procedureAccountsData: {},
      })
  */

  const isInterimAudit = getIsInterimAudit(
    auditing?.internalControlAndOperatingEnvironment?.form.isInterimAudit
  );

  const auditingTemplate = auditing?.template;
  const auditingAccountMapScoping =
    auditing?.accountMapScoping?.form.accountMapScoping;
  const auditingGeneralLedgerScoping =
    auditing?.generalLedgerScoping?.form.groupedGeneralLedger;

  const accountMapScoping =
    !auditingAccountMapScoping && auditingTemplate
      ? generateAccountMap(auditingTemplate)
      : auditingAccountMapScoping;

  if (!accountMapScoping) return null;

  // Get all rows from the account map that match the class key
  const accountsMapRowsScoping = getAllAccountMapRowsMatchingClassKey(
    accountMapScoping,
    classKey
  );

  const auditingAccountMap = auditing?.accountMap?.form.accountMap;
  const auditingGeneralLedger =
    auditing?.generalLedger?.form.groupedGeneralLedger;
  const accountMap =
    !auditingAccountMap && auditingTemplate
      ? originalAccountMap(auditingTemplate)
      : auditingAccountMap;

  if (!accountMap) return null;

  const accountMapRows = originalMatchingClassKey(accountMap, classKey);

  var procedureAccountRows: any[] = [];

  // Filter the general ledger to only include rows that match the account map rows
  if (isInterimAudit) {
    const procedureAccountRowsPreData = auditingGeneralLedgerScoping?.length
      ? auditingGeneralLedgerScoping.filter(({ account }) => {
          const hasMatchingAccountClass = !!accountsMapRowsScoping.find(
            ({ start, end }) => account >= start && account <= end
          );
          return hasMatchingAccountClass;
        })
      : auditingGeneralLedger?.filter(({ account }) => {
          const hasMatchingAccountClass = !!accountMapRows.find(
            ({ start, end }) => account >= start && account <= end
          );
          return hasMatchingAccountClass;
        });

    if (procedureAccountRowsPreData) {
      for (var x = 0; x < procedureAccountRowsPreData?.length; x++) {
        var objectx = procedureAccountRowsPreData[x];
        if (formState?.procedureAccountsData[x]) {
          objectx.commentText = formState?.procedureAccountsData[x];
        }
        if (showChange) {
          const undefinedHandler = (item: number | undefined) => {
            if (item === undefined || item === null) {
              return 0;
            } else return item;
          };
          objectx.changeInNumbers =
            undefinedHandler(objectx.priorYear) -
            undefinedHandler(objectx.currentYear);
          objectx.changeInPercent = Math.round(
            ((undefinedHandler(objectx.currentYear) -
              undefinedHandler(objectx.priorYear)) /
              undefinedHandler(objectx.priorYear)) *
              100
          );
          if (
            objectx.currentYear === undefined ||
            objectx.priorYear === undefined
          ) {
            objectx.changeInPercent = undefined;
          } else {
            objectx.changeInPercent = Math.round(
              ((objectx.currentYear - objectx.priorYear) / objectx.priorYear) *
                100
            );
          }
        }
        procedureAccountRows.push(objectx);
      }
    }
    if (!procedureAccountRows?.length) return null;
  } else {
    const procedureAccountRowsPreData = auditingGeneralLedgerScoping?.filter(
      ({ account }) => {
        const hasMatchingAccountClass = !!accountsMapRowsScoping.find(
          ({ start, end }) => account >= start && account <= end
        );
        return hasMatchingAccountClass;
      }
    );

    if (procedureAccountRowsPreData) {
      for (var i = 0; i < procedureAccountRowsPreData?.length; i++) {
        var object = procedureAccountRowsPreData[i];
        if (formState?.procedureAccountsData[i]) {
          object.commentText = formState?.procedureAccountsData[i];
        }
        if (showChange) {
          const undefinedHandler = (item: number | undefined) => {
            if (item === undefined || item === null) {
              return 0;
            } else return item;
          };
          object.changeInNumbers =
            undefinedHandler(object.priorYear) -
            undefinedHandler(object.currentYear);
          object.changeInPercent = Math.round(
            ((undefinedHandler(object.currentYear) -
              undefinedHandler(object.priorYear)) /
              undefinedHandler(object.priorYear)) *
              100
          );
          if (
            object.currentYear === undefined ||
            object.priorYear === undefined
          ) {
            object.changeInPercent = undefined;
          } else {
            object.changeInPercent = Math.round(
              ((object.currentYear - object.priorYear) / object.priorYear) * 100
            );
          }
        }
        procedureAccountRows.push(object);
      }
    }
    if (!procedureAccountRows?.length) return null;
  }

  function handleComment<T>({
    itemId,
    rowIndex,
    columnId,
    value,
  }: OnEditProps<T>) {
    const patch = {
      ...formState?.procedureAccountsData,
      [rowIndex]: value,
    };
    patchFormState &&
      patchFormState({
        ...formState,
        procedureAccountsData: patch,
      });
  }

  var tableColumns: TableColumnDefs<any> = [
    {
      accessorKey: 'account',
      className: 'width-min',
      header: () => (showTitles === undefined ? null : 'Tilinumero'),
    },
    {
      accessorKey: 'accountName',
      header: () => (showTitles === undefined ? null : 'Tilinnimi'),
    },
    {
      accessorKey: 'currentYear',
      header: () => 'CY',
      className: 'width-min text-center nowrap',
      accessorFn: (row: any) => formatCurrency(row.currentYear),
    },
    {
      accessorKey: 'priorYear',
      header: () => 'PY',
      className: 'width-min text-center nowrap',
      accessorFn: (row: any) => formatCurrency(row.priorYear),
    },
  ];
  if (showChange) {
    tableColumns.push({
      accessorKey: 'changeInNumbers',
      header: () => 'Muutos',
      accessorFn: (row: any) => formatCurrency(row.changeInNumbers),

      className: 'width-middle text-center nowrap',
    });
    tableColumns.push({
      accessorKey: 'changeInPercent',
      header: () => 'Muutos %',
      accessorFn: (row: any) =>
        row.changeInPercent !== undefined
          ? row.changeInPercent + ' %'
          : 'Ei laskettavissa',
      className: 'width-middle text-center nowrap',
    });
  }
  if (addCommentField) {
    tableColumns.push({
      accessorKey: 'commentText',
      header: () =>
        customAccountTitle !== undefined ? customAccountTitle : 'Muuta',
      className: 'width-middle text-center nowrap',
      onEdit: (editProps: OnEditProps<string>) => {
        handleComment<string>(editProps);
      },
    });
  }

  var combinedCy = 0;
  var combinedPy = 0;
  if (showSum) {
    for (var l = 0; l < procedureAccountRows.length; l++) {
      if (procedureAccountRows[l].currentYear !== undefined) {
        combinedCy += procedureAccountRows[l].currentYear;
      }
      if (procedureAccountRows[l].priorYear !== undefined) {
        combinedPy += procedureAccountRows[l].priorYear;
      }
    }
  }

  return (
    <>
      <AuditingTable
        title="Tilit, joita työpaperi koskee"
        data={procedureAccountRows}
        columns={tableColumns}
        showGlobalFilter={false}
        variant="default"
      />
      {showSum ? (
        <AuditingTable
          showGlobalFilter={false}
          hideHeader
          columns={
            [
              {
                accessorKey: 'label',
                className: 'width-min',
                header: () => null,
              },
              {
                accessorKey: 'cy',
                className: 'width-min',
                header: () => null,
              },
              {
                accessorKey: 'py',
                className: 'width-min',
                header: () => null,
              },
              showChange && {
                accessorKey: 'changeInNumbers',
                className: 'width-min',
                header: () => null,
              },
            ] as TableColumnDefs<any>
          }
          data={[
            {
              label: 'Yhteensä',
              cy: 'CY: ' + formatCurrency(combinedCy),
              py: 'PY: ' + formatCurrency(combinedPy),
              changeInNumbers:
                'Muutos: ' + formatCurrency(combinedCy - combinedPy),
            },
          ]}
        />
      ) : null}
    </>
  );
};

export default ProcedureAccounts;
