import { Api } from '../RootApi';
import { ModuleBaseApi } from './ModuleBaseApi';

const URL = {
  base: 'auditings',
};

export class AuditingApi extends ModuleBaseApi<Auditing> {
  constructor(rootApi: Api) {
    super(rootApi, URL.base);
  }
}

// getAuditing function, which fetches a single Auditing entity from the API.
export async function getAuditing(rootApi: Api, id: number): Promise<Auditing> {
  const response = await new AuditingApi(rootApi).getOneEntity({ id });
  if (response instanceof Object) {
    return response.data;
  } else {
    throw new Error('Auditing not found');
  }
}

export async function getAllAuditings(rootApi: Api): Promise<Auditing[]> {
  const response = await new AuditingApi(rootApi).getAllEntities();
  if (response instanceof Object) {
    return response.data;
  } else {
    throw new Error('Auditings not found');
  }
}
