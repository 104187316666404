/* Tästä tiedostosta voidaan muokata tiettyjä projektin toiminnallisuuksia */

const displayAllParts = process.env.NODE_ENV === 'development'; // Tämän asettaminen trueksi tekee sen, että kaikki välilehdet on näkyvissä.
// Esim kehitysvaiheessa, välilehteä ei välttämättä ole lisätty yksityiskohtaiseen suunnitelmaan, mutta halutaan nähdä se silti, koska sitä kehitetään.

// Tarkastus välilehdet jotka ovat aina näkyvissä, vaikka niitä ei olisi lisätty/voisi lisätä yksityiskohtaiseen suunnitelmaan
const partsToDisplayDespiteDetailedPlan = [
  'auditingProcedures',
  'presentationAndContent',
  'management',
];

// Add a section here to enable adding control! This means that in detailedPlan, in addition to a "Add new row" option, there is also a "Add new control" option, which adds a control.
const partsThatUseControls = [
  'J2-accountsReceivable',
  'Q2-cashEquivalents',
  'J1-turnover',
  'K1-materialsAndServices',
  'P1-warehouses',
  'L-personelCosts',
  'XJ-financialIncome',
  'XK-financialExpenses',
]; // In some parts in detailed plan we must have the option to add new row/section AND a control. Add secton here to enable adding control!

const tabSettings = {
  displayAllParts,
  partsToDisplayDespiteDetailedPlan,
};
export { tabSettings, partsThatUseControls };
