import { useTranslation } from 'react-i18next';
import { confirmDelete } from '../../utils';
import {
  AutomationOfPartialAccounting,
  emptyBusinessProcess,
} from '../../views/auditingSections/planning/itAndProcessesUtils';
import AuditingTable from '../table/AuditingTable';
import { OnEditProps, TableColumnDefs } from '../table/Table';

const sectionKey: AuditingSectionKey = 'itAndProcesses';

export const BusinessProcesses = ({
  formState,
  patchFormState,
}: FormFieldProps<ItAndProcessesForm>) => {
  const { t } = useTranslation();

  if (!formState) return null;

  const tBase = `auditing:form.${sectionKey}.businessProcesses`;

  const data = formState.businessProcesses ?? [];

  function handleEdit<T>({
    itemId,
    rowIndex,
    columnId,
    value,
  }: OnEditProps<T>) {
    const patch = formState.businessProcesses.map((row, index) => {
      const idMatch = itemId !== undefined && itemId === row.id;
      const rowIndexMatch = itemId === undefined && rowIndex === index;
      if (idMatch || rowIndexMatch) return { ...row, [columnId]: value };
      return row;
    });
    patchFormState({ ...formState, businessProcesses: patch });
  }

  const validKinds = [
    'turnover',
    'operatingIncomesAndTradeReceivables',
    'purchases',
    'purchasesKB',
    'wages',
    'stocks',
    'paymentServices',
    'nonCurrentAssets',
    'governmentTransfersAndTaxRevenues',
    'budget',
    'groupSupervision',
  ];

  const tableColumns: TableColumnDefs<BusinessProcess> = [
    {
      accessorKey: 'selected',
      className: 'text-center width-min',
      header: () => '',
      onBooleanEdit: (editProps: OnEditProps<boolean>) =>
        handleEdit<boolean>(editProps),
    },
    {
      accessorKey: 'label',
      header: () => t(`${tBase}.kind`),
      className: 'width-half',
      placeholder: t(`${tBase}.kind`) + '...',
      disabled: (item: BusinessProcess) => {
        const isCustomField = item.kind && item.kind?.includes('custom');
        return !isCustomField;
      },
      onEdit: (editProps: OnEditProps<string>) => {
        handleEdit<string>(editProps);
      },
    },
    {
      accessorKey: 'details',
      header: () => t(`${tBase}.details`),
      className: 'width-half',
      onEdit: (editProps: OnEditProps<string>) => handleEdit<string>(editProps),
    },
    {
      accessorKey: 'automationOfPartialAccounting',
      className: 'text-center width-min',
      header: () => t(`${tBase}.automationOfPartialAccounting`),
      selectProps: {
        options: Object.values(AutomationOfPartialAccounting),
        displayValue: (option: string) =>
          t(`${tBase}.automationOfPartialAccountingOptions.${option}`),
        setValue: (editProps: OnEditProps<string>) =>
          handleEdit<string>(editProps),
      },
    },
    {
      accessorKey: 'toDocument',
      header: () => t(`${tBase}.processDescriptionToDocument`),
      className: 'width-min text-center',
      onBooleanEdit: (editProps: OnEditProps<boolean>) =>
        handleEdit<boolean>(editProps),
      //hidden: (cell: BusinessProcess) => !validKinds.includes(cell.kind ?? ''),
      disabled: (cell: BusinessProcess) =>
        !validKinds.includes(cell.kind ?? ''),
    },
  ];

  const handleAddNewRow = () => {
    patchFormState({
      ...formState,
      businessProcesses: [
        ...(formState.businessProcesses ?? []),
        emptyBusinessProcess(),
      ],
    });
  };

  const handleDeleteRow = ({ id, label }: BusinessProcess) => {
    if (confirmDelete(label ?? '')) {
      const patch = (formState.businessProcesses ?? []).filter(
        row => row.id !== id
      );
      patchFormState({ ...formState, businessProcesses: patch });
    }
  };

  return (
    <AuditingTable<BusinessProcess>
      sectionKey={sectionKey}
      onAddNewRow={handleAddNewRow}
      title={t(`${tBase}.title`)}
      data={data}
      columns={tableColumns}
      onRowDelete={handleDeleteRow}
      showGlobalFilter={false}
      disableSort
    />
  );
};
