import { displayAuditingProcedures } from '../../../components/auditing/AuditingProcedureItem2';
import {
  ActionReportFifthTable,
  ActionReportFourthTable,
  ActionReportOfficerTable,
  ActionReportOther2Table,
  ActionReportOther3Table,
  ActionReportOtherTable,
  ActionReportSecondTable,
  ActionReportSeventhTable,
  ActionReportSixthTable,
  ActionReportTable,
  ActionReportThirdTable,
  ActionTable,
  ActionTable2,
  ActionTable3,
  ActionTable4,
  ActionTable5,
  MikroCommentTable,
  MikroGroupTable,
  MikroGroupTableSecond,
  MikroGroupTableThird,
  MikroKPAEightTable,
  MikroKPAFifthTable,
  MikroKPAFifthTableTwo,
  MikroKPAFourthTable,
  MikroKPAOtherTable,
  MikroKPASecondTable,
  MikroKPASeventhTable,
  MikroKPASixthTable,
  MikroKPATable,
  MikroKPAThirdTable,
  MikroPMATable,
  SmallBusinessTable,
  SmallBusinessTableSecond,
} from '../../../components/auditing/PMATables';
import { PresentationTable } from '../../../components/auditing/PresentationTable';
import auditingJson from '../../../i18n/locales/fi/auditing.json';
import { FormFieldBuilder } from '../FormFieldBuilder';

type SectionFormType = PresentationAndContentForm;
const sectionKey: AuditingSectionKey = 'presentationAndContent';
export const tBase = `auditing:form.${sectionKey}`;

const jsonVocab = auditingJson.form.presentationAndContent;
const batchString = 'F3-presentationAndContent';

const formBuilder = new FormFieldBuilder<SectionFormType>({ sectionKey });

const defaultFormState: DefaultFormState<SectionFormType> = ({
  formState,
  auditing,
}) => {
  return {
    mikroPMATable: [
      {
        label: jsonVocab.MikroPMA1_1,
        id: '1',
        shownAttatchment: null,
        other: '',
      },
      {
        label: jsonVocab.MikroPMA1_2,
        id: '2',
        shownAttatchment: null,
        other: '',
      },
      {
        label: jsonVocab.MikroPMA3_1,
        id: '3',
        shownAttatchment: null,
        other: '',
      },
      {
        label: jsonVocab.MikroPMA3_2,
        id: '4',
        shownAttatchment: null,
        other: '',
      },
      {
        label: jsonVocab.MikroPMA3_3,
        id: '5',
        shownAttatchment: null,
        other: '',
      },
      {
        label: jsonVocab.MikroPMA3_4,
        id: '6',
        shownAttatchment: null,
        other: '',
      },
      {
        label: jsonVocab.MikroPMA3_6,
        id: '7',
        shownAttatchment: null,
        other: '',
      },
      {
        label: jsonVocab.MikroPMA3_7,
        id: '8',
        shownAttatchment: null,
        other: '',
      },
      {
        label: jsonVocab.MikroPMA3_8,
        id: '9',
        shownAttatchment: null,
        other: '',
      },
      {
        label: jsonVocab.MikroPMA3_9,
        id: '10',
        shownAttatchment: null,
        other: jsonVocab.MikroPMA3_9InfoInfo,
      },
      {
        label: jsonVocab.MikroPMA3_10,
        id: '11',
        shownAttatchment: null,
        other: jsonVocab.MikroPMA3_10InfoInfo,
      },
      {
        label: jsonVocab.MikroPMA3_11,
        id: '12',
        shownAttatchment: null,
        other: '',
      },
      {
        label: jsonVocab.MikroPMA3_12,
        id: '13',
        shownAttatchment: null,
        other: '',
      },
      {
        label: jsonVocab.MikroPMA1_3,
        id: '14',
        shownAttatchment: null,
        other: '',
      },
      /*             {
                label: jsonVocab.MikroPMAComment,
                id: '15',
                shownAttatchment: null,
                other: '',
            }, */
    ],
    mikroCommentTable: [
      {
        label: jsonVocab.MikroOYL8_5,
        id: '20',
        shownAttatchment: null,
        other: '',
      },
      {
        label: jsonVocab.MikroOYL8_51,
        id: '21',
        shownAttatchment: null,
        other: '',
      },
      {
        label: jsonVocab.MikroOYL8_52,
        id: '22',
        shownAttatchment: null,
        other: '',
      },
      {
        label: jsonVocab.MikroOYL8_53,
        id: '23',
        shownAttatchment: null,
        other: '',
      },
      {
        label: jsonVocab.MikroOYL8_6,
        id: '24',
        shownAttatchment: null,
        other: '',
      },
      {
        label: jsonVocab.MikroOYL8_7,
        id: '25',
        shownAttatchment: null,
        other: jsonVocab.MikroOYL8_7InfoInfo,
      },
      {
        label: jsonVocab.MikroOYL8_8,
        id: '26',
        shownAttatchment: null,
        other: jsonVocab.MikroOYL8_8InfoInfo,
      },
    ],
    mikroKPATable: [
      {
        label: jsonVocab.KPA1,
        id: '1',
        shownAttatchment: null,
        other: '',
      },
      {
        label: jsonVocab.KPA2,
        id: '2',
        shownAttatchment: null,
        other: '',
      },
      {
        label: jsonVocab.KPA3,
        id: '3',
        shownAttatchment: null,
        other: '',
      },
      {
        label: jsonVocab.KPA5,
        id: '4',
        shownAttatchment: null,
        other: '',
      },
      {
        label: jsonVocab.KPA6,
        id: '5',
        shownAttatchment: null,
        other: '',
      },
      {
        label: jsonVocab.KPA7,
        id: '6',
        shownAttatchment: null,
        other: '',
      },
      {
        label: jsonVocab.KPA8,
        id: '7',
        shownAttatchment: null,
        other: jsonVocab.KPA8Info,
      },
      {
        label: jsonVocab.KPA9,
        id: '8',
        shownAttatchment: null,
        other: jsonVocab.KPA9Info,
      },
    ],
    mikroKPASecondTable: [
      {
        label: jsonVocab.KPA1_1,
        id: '1',
        shownAttatchment: null,
        other: '',
      },
      {
        label: jsonVocab.KPA1_2,
        id: '2',
        shownAttatchment: null,
        other: '',
      },
      {
        label: jsonVocab.KPA1_3,
        id: '3',
        shownAttatchment: null,
        other: '',
      },
      {
        label: jsonVocab.KPA1_4,
        id: '4',
        shownAttatchment: null,
        other: jsonVocab.KPA1_4Info,
      },
      {
        label: jsonVocab.KPA1_5,
        id: '5',
        shownAttatchment: null,
        other: '',
      },
      {
        label: jsonVocab.KPA1_6,
        id: '6',
        shownAttatchment: null,
        other: '',
      },
      {
        label: jsonVocab.KPA1_7,
        id: '7',
        shownAttatchment: null,
        other: '',
      },
    ],
    mikroKPAThirdTable: [
      {
        label: jsonVocab.KPA2_1,
        id: '1',
        shownAttatchment: null,
        other: '',
      },
      {
        label: jsonVocab.KPA2_2,
        id: '2',
        shownAttatchment: null,
        other: '',
      },
      {
        label: jsonVocab.KPA2_3,
        id: '3',
        shownAttatchment: null,
        other: '',
      },
      {
        label: jsonVocab.KPA2_4,
        id: '4',
        shownAttatchment: null,
        other: jsonVocab.KPA2_4Info,
      },
      {
        label: jsonVocab.KPA2_5,
        id: '5',
        shownAttatchment: null,
        other: '',
      },
      {
        label: jsonVocab.KPA2_6,
        id: '6',
        shownAttatchment: null,
        other: '',
      },
      {
        label: jsonVocab.KPA2_7,
        id: '7',
        shownAttatchment: null,
        other: '',
      },
      {
        label: jsonVocab.KPA2_8,
        id: '8',
        shownAttatchment: null,
        other: '',
      },
      {
        label: jsonVocab.KPA2_9,
        id: '9',
        shownAttatchment: null,
        other: '',
      },
    ],
    mikroKPAFourthTable: [
      {
        label: jsonVocab.KPA3_1,
        id: '1',
        shownAttatchment: null,
        other: '',
      },
      {
        label: jsonVocab.KPA3_2,
        id: '2',
        shownAttatchment: null,
        other: '',
      },
      {
        label: jsonVocab.KPA3_3,
        id: '3',
        shownAttatchment: null,
        other: '',
      },
      {
        label: jsonVocab.KPA3_4,
        id: '4',
        shownAttatchment: null,
        other: '',
      },
      {
        label: jsonVocab.KPA3_5,
        id: '5',
        shownAttatchment: null,
        other: '',
      },
      {
        label: jsonVocab.KPA3_6,
        id: '6',
        shownAttatchment: null,
        other: '',
      },
      {
        label: jsonVocab.KPA3_7,
        id: '7',
        shownAttatchment: null,
        other: '',
      },
      {
        label: jsonVocab.KPA3_8,
        id: '8',
        shownAttatchment: null,
        other: '',
      },
      {
        label: jsonVocab.KPA3_9,
        id: '9',
        shownAttatchment: null,
        other: '',
      },
      {
        label: jsonVocab.KPA3_10,
        id: '10',
        shownAttatchment: null,
        other: '',
      },
    ],
    mikroKPAOtherTable: [
      {
        label: jsonVocab.KPA4_1,
        id: '1',
        shownAttatchment: null,
        other: '',
      },
      {
        label: jsonVocab.KPA4_2,
        id: '2',
        shownAttatchment: null,
        other: '',
      },
      {
        label: jsonVocab.KPA4_3,
        id: '3',
        shownAttatchment: null,
        other: '',
      },
      {
        label: jsonVocab.KPA4_4,
        id: '4',
        shownAttatchment: null,
        other: '',
      },
      {
        label: jsonVocab.KPA4_5,
        id: '5',
        shownAttatchment: null,
        other: '',
      },
    ],
    mikroKPAFifthTable: [
      {
        label: jsonVocab.KPA5_1,
        id: '1',
        shownAttatchment: null,
        other: '',
      },
      {
        label: jsonVocab.KPA5_2,
        id: '2',
        shownAttatchment: null,
        other: '',
      },
      {
        label: jsonVocab.KPA5_3,
        id: '3',
        shownAttatchment: null,
        other: '',
      },
      {
        label: jsonVocab.KPA5_4,
        id: '4',
        shownAttatchment: null,
        other: '',
      },
      {
        label: jsonVocab.KPA5_5,
        id: '5',
        shownAttatchment: null,
        other: '',
      },
      {
        label: jsonVocab.KPA5_6,
        id: '6',
        shownAttatchment: null,
        other: '',
      },
    ],
    mikroKPAFifthTableTwo: [
      {
        label: jsonVocab.KPA5_7,
        id: '7',
        shownAttatchment: null,
        other: '',
      },
      {
        label: jsonVocab.KPA5_8,
        id: '8',
        shownAttatchment: null,
        other: '',
      },
      {
        label: jsonVocab.KPA5_9,
        id: '9',
        shownAttatchment: null,
        other: '',
      },
      {
        label: jsonVocab.KPA5_10,
        id: '10',
        shownAttatchment: null,
        other: '',
      },
      {
        label: jsonVocab.KPA5_11,
        id: '11',
        shownAttatchment: null,
        other: '',
      },
    ],
    mikroKPASixthTable: [
      {
        label: jsonVocab.KPA6_1,
        id: '1',
        shownAttatchment: null,
        other: '',
      },
      {
        label: jsonVocab.KPA6_2,
        id: '2',
        shownAttatchment: null,
        other: '',
      },
      {
        label: jsonVocab.KPA6_3,
        id: '3',
        shownAttatchment: null,
        other: jsonVocab.KPA6_3Info,
      },
      {
        label: jsonVocab.KPA6_4,
        id: '4',
        shownAttatchment: null,
        other: '',
      },
      {
        label: jsonVocab.KPA6_5,
        id: '5',
        shownAttatchment: null,
        other: '',
      },
      {
        label: jsonVocab.KPA6_6,
        id: '6',
        shownAttatchment: null,
        other: '',
      },
      {
        label: jsonVocab.KPA6_7,
        id: '7',
        shownAttatchment: null,
        other: '',
      },
    ],
    mikroKPASeventhTable: [
      {
        label: jsonVocab.KPA7_1,
        id: '1',
        shownAttatchment: null,
        other: '',
      },
      {
        label: jsonVocab.KPA7_2,
        id: '2',
        shownAttatchment: null,
        other: '',
      },
    ],
    mikroKPAEightTable: [
      {
        label: jsonVocab.KPA8_1,
        id: '1',
        shownAttatchment: null,
        other: '',
      },
      {
        label: jsonVocab.KPA8_2,
        id: '2',
        shownAttatchment: null,
        other: '',
      },
      {
        label: jsonVocab.KPA8_3,
        id: '3',
        shownAttatchment: null,
        other: '',
      },
      {
        label: jsonVocab.KPA8_4,
        id: '4',
        shownAttatchment: null,
        other: '',
      },
    ],
    mikroGroupTable: [
      {
        label: jsonVocab.group1,
        id: '1',
        shownAttatchment: null,
        other: '',
      },
      {
        label: jsonVocab.group2,
        id: '2',
        shownAttatchment: null,
        other: '',
      },
      {
        label: jsonVocab.group3,
        id: '3',
        shownAttatchment: null,
        other: '',
      },
      {
        label: jsonVocab.group4,
        id: '4',
        shownAttatchment: null,
        other: '',
      },
      {
        label: jsonVocab.group5,
        id: '5',
        shownAttatchment: null,
        other: '',
      },
      {
        label: jsonVocab.group5a,
        id: '6',
        shownAttatchment: null,
        other: jsonVocab.group5aInfoInfo,
      },
      {
        label: jsonVocab.group6,
        id: '7',
        shownAttatchment: null,
        other: '',
      },
      {
        label: jsonVocab.group7,
        id: '8',
        shownAttatchment: null,
        other: '',
      },
      {
        label: jsonVocab.group8,
        id: '9',
        shownAttatchment: null,
        other: '',
      },
      {
        label: jsonVocab.group9,
        id: '10',
        shownAttatchment: null,
        other: '',
      },
    ],
    mikroGroupTableSecond: [
      {
        label: jsonVocab.group1b,
        id: '1',
        shownAttatchment: null,
        other: '',
      },
      {
        label: jsonVocab.group2b,
        id: '2',
        shownAttatchment: null,
        other: '',
      },
      {
        label: jsonVocab.group3b,
        id: '3',
        shownAttatchment: null,
        other: '',
      },
      {
        label: jsonVocab.group4b,
        id: '4',
        shownAttatchment: null,
        other: '',
      },
      {
        label: jsonVocab.group5b,
        id: '5',
        shownAttatchment: null,
        other: '',
      },
      {
        label: jsonVocab.group6b,
        id: '6',
        shownAttatchment: null,
        other: '',
      },
    ],
    mikroGroupTableThird: [
      {
        label: jsonVocab.group1c,
        id: '1',
        shownAttatchment: null,
        other: '',
      },
      {
        label: jsonVocab.group2c,
        id: '2',
        shownAttatchment: null,
        other: '',
      },
      {
        label: jsonVocab.group3c,
        id: '3',
        shownAttatchment: null,
        other: '',
      },
      {
        label: jsonVocab.group4c,
        id: '4',
        shownAttatchment: null,
        other: '',
      },
      {
        label: jsonVocab.group5c,
        id: '5',
        shownAttatchment: null,
        other: '',
      },
      {
        label: jsonVocab.group6c,
        id: '6',
        shownAttatchment: null,
        other:
          'Liitetietojen täydellisyys tarkastettu erillisellä työpaperilla F3.1. Ohessa käyty läpi ainoastaan konsernitilinpäätöksen liitetiedot.',
      },
    ],
    smallBusinessTable: [
      {
        label: jsonVocab.PMA1_1,
        id: '1',
        shownAttatchment: null,
        other: '',
      },
      {
        label: jsonVocab.PMA1_2,
        id: '2',
        shownAttatchment: null,
        other: '',
      },
      {
        label: jsonVocab.PMA1_3,
        id: '3',
        shownAttatchment: null,
        other: '',
      },
      {
        label: jsonVocab.PMA1_4,
        id: '4',
        shownAttatchment: null,
        other: '',
      },
      {
        label: jsonVocab.PMA1_5,
        id: '5',
        shownAttatchment: null,
        other: '',
      },
      {
        label: jsonVocab.PMA1_6,
        id: '6',
        shownAttatchment: null,
        other: '',
      },
      {
        label: jsonVocab.PMA1_7,
        id: '7',
        shownAttatchment: null,
        other: jsonVocab.PMA1_7Info,
      },
      {
        label: jsonVocab.PMA1_8,
        id: '8',
        shownAttatchment: null,
        other: '',
      },
      {
        label: jsonVocab.PMA1_9,
        id: '9',
        shownAttatchment: null,
        other: '',
      },
      {
        label: jsonVocab.PMA1_10,
        id: '10',
        shownAttatchment: null,
        other: '',
      },
      {
        label: jsonVocab.PMA1_11,
        id: '11',
        shownAttatchment: null,
        other: jsonVocab.PMA1_11Info,
      },
      {
        label: jsonVocab.PMA1_12,
        id: '12',
        shownAttatchment: null,
        other: jsonVocab.PMA1_12Info,
      },
      {
        label: jsonVocab.PMA1_13,
        id: '13',
        shownAttatchment: null,
        other: '',
      },
      {
        label: jsonVocab.PMA1_14,
        id: '14',
        shownAttatchment: null,
        other: '',
      },
      {
        label: jsonVocab.PMA1_15,
        id: '15',
        shownAttatchment: null,
        other: '',
      },
    ],
    smallBusinessSecondTable: [
      {
        label: jsonVocab.PMA2_1,
        id: '1',
        shownAttatchment: null,
        other: '',
      },
      {
        label: jsonVocab.PMA2_2,
        id: '2',
        shownAttatchment: null,
        other: '',
      },
      {
        label: jsonVocab.PMA2_3,
        id: '3',
        shownAttatchment: null,
        other: '',
      },
      {
        label: jsonVocab.PMA2_4,
        id: '4',
        shownAttatchment: null,
        other: '',
      },
      {
        label: jsonVocab.PMA2_5,
        id: '5',
        shownAttatchment: null,
        other: '',
      },
      {
        label: jsonVocab.PMA2_6,
        id: '6',
        shownAttatchment: null,
        other: jsonVocab.PMA2_6Info,
      },
      {
        label: jsonVocab.PMA2_7,
        id: '7',
        shownAttatchment: null,
        other: jsonVocab.PMA2_7Info,
      },
    ],
    presentationTable: [
      {
        label: 'esim. Vakuudet',
        id: '1',
        auditable: 'esim. nimeä taustamateriaali esim saldovahvistus S1.1',
        backgroundMaterial: '',
        amountMaterial: 0,
        attachment: 0,
        minus: 0,
        other: '',
      },
    ],
    actionReportTable: [
      {
        label: jsonVocab.action1_1,
        id: '1',
        shownAttatchment: null,
        other: '',
      },
      {
        label: jsonVocab.action1_2,
        id: '2',
        shownAttatchment: null,
        other: '',
      },
      {
        label: jsonVocab.action1_3,
        id: '3',
        shownAttatchment: null,
        other: '',
      },
      {
        label: jsonVocab.action1_4,
        id: '4',
        shownAttatchment: null,
        other: '',
      },
      {
        label: jsonVocab.action1_5,
        id: '5',
        shownAttatchment: null,
        other: '',
      },
      {
        label: jsonVocab.action1_6,
        id: '6',
        shownAttatchment: null,
        other: jsonVocab.action1_6Info,
      },
      {
        label: jsonVocab.action1_7,
        id: '7',
        shownAttatchment: null,
        other: jsonVocab.action1_7Info,
      },
    ],
    actionReportSecondTable: [
      {
        label: jsonVocab.action2_1,
        id: '1',
        shownAttatchment: null,
        other: '',
      },
      {
        label: jsonVocab.action2_2,
        id: '2',
        shownAttatchment: null,
        other: '',
      },
      {
        label: jsonVocab.action2_3,
        id: '3',
        shownAttatchment: null,
        other: '',
      },
    ],
    actionReportThirdTable: [
      {
        label: jsonVocab.action3_1,
        id: '1',
        shownAttatchment: null,
        other: '',
      },
      {
        label: jsonVocab.action3_2,
        id: '2',
        shownAttatchment: null,
        other: '',
      },
      {
        label: jsonVocab.action3_3,
        id: '3',
        shownAttatchment: null,
        other: '',
      },
      {
        label: jsonVocab.action3_4,
        id: '4',
        shownAttatchment: null,
        other: '',
      },
    ],
    actionReportFourthTable: [
      {
        label: jsonVocab.action4_1,
        id: '1',
        shownAttatchment: null,
        other: '',
      },
      {
        label: jsonVocab.action4_2,
        id: '2',
        shownAttatchment: null,
        other: '',
      },
    ],
    actionReportFifthTable: [
      {
        label: jsonVocab.action5_1,
        id: '1',
        shownAttatchment: null,
        other: '',
      },
    ],
    actionReportSixthTable: [
      {
        label: jsonVocab.action5_2,
        id: '1',
        shownAttatchment: null,
        other: '',
      },
    ],
    actionReportSeventhTable: [
      {
        label: jsonVocab.action6_1,
        id: '1',
        shownAttatchment: null,
        other: '',
      },
    ],
    actionReportOtherTable: [
      {
        label: jsonVocab.other1_1,
        id: '1',
        shownAttatchment: null,
        other: '',
      },
      {
        label: jsonVocab.other1_2,
        id: '2',
        shownAttatchment: null,
        other: '',
      },
    ],
    actionReportOther2Table: [
      {
        label: jsonVocab.other2_1,
        id: '1',
        shownAttatchment: null,
        other: '',
      },
    ],
    actionReportOther3Table: [
      {
        label: jsonVocab.other3_1,
        id: '1',
        shownAttatchment: null,
        other: '',
      },
      {
        label: jsonVocab.other3_2,
        id: '2',
        shownAttatchment: null,
        other: '',
      },
      {
        label: jsonVocab.other3_3,
        id: '3',
        shownAttatchment: null,
        other: '',
      },
    ],
    actionReportOfficerTable: [
      {
        label: jsonVocab.officer1_1,
        id: '1',
        shownAttatchment: null,
        other: '',
      },
      {
        label: jsonVocab.officer1_2,
        id: '2',
        shownAttatchment: null,
        other: '',
      },
    ],
    actionTable: [
      {
        label: jsonVocab.action_table_1,
        id: '1',
        shownAttatchment: null,
        other: '',
      },
      {
        label: jsonVocab.action_table_2,
        id: '2',
        shownAttatchment: null,
        other: '',
      },
      {
        label: jsonVocab.action_table_3,
        id: '3',
        shownAttatchment: null,
        other: '',
      },
      {
        label: jsonVocab.action_table_4,
        id: '4',
        shownAttatchment: null,
        other: '',
      },
      {
        label: jsonVocab.action_table_5,
        id: '5',
        shownAttatchment: null,
        other: '',
      },
      {
        label: jsonVocab.action_table_6,
        id: '6',
        shownAttatchment: null,
        other: '',
      },
      {
        label: jsonVocab.action_table_7,
        id: '7',
        shownAttatchment: null,
        other: '',
      },
    ],
    actionTable2: [
      {
        label: jsonVocab.action_table2_1,
        id: '1',
        shownAttatchment: null,
        other: '',
      },
      {
        label: jsonVocab.action_table2_2,
        id: '2',
        shownAttatchment: null,
        other: '',
      },
      {
        label: jsonVocab.action_table2_3,
        id: '3',
        shownAttatchment: null,
        other: '',
      },
    ],
    actionTable3: [
      {
        label: jsonVocab.action_table3_1,
        id: '1',
        shownAttatchment: null,
        other: '',
      },
      {
        label: jsonVocab.action_table3_2,
        id: '2',
        shownAttatchment: null,
        other: '',
      },
      {
        label: jsonVocab.action_table3_3,
        id: '3',
        shownAttatchment: null,
        other: '',
      },
      {
        label: jsonVocab.action_table3_4,
        id: '4',
        shownAttatchment: null,
        other: '',
      },
    ],
    actionTable4: [
      {
        label: jsonVocab.action_table4_1,
        id: '1',
        shownAttatchment: null,
        other: '',
      },
      {
        label: jsonVocab.action_table4_2,
        id: '2',
        shownAttatchment: null,
        other: '',
      },
    ],
    actionTable5: [
      {
        label: jsonVocab.action_table5_1,
        id: '1',
        shownAttatchment: null,
        other: '',
      },
      {
        label: jsonVocab.action_table5_2,
        id: '2',
        shownAttatchment: null,
        other: '',
      },
    ],
    actionReport1: 'notApplicable',
    actionReport1Info: '',
    actionReport2: 'notApplicable',
    actionReport2Info: '',
    actionReportOther: 'notApplicable',
    actionReportOtherInfo: '',
    accountingOfficer: 'no',
    // Tilipäätöksen allekirjoitukset
    theFinancialStatements: '',
    theFinancialStatementsInfo: '',
    hasCEO: '',
    hasCEOInfo: '',
    CEOhasSigned: '',
    CEOhasSignedInfo: '',
    financialStatementActionReport: '',
    financialStatementActionReportInfo: '',
    hasTheCompanyReport: '',
    hasTheCompanyReportInfo: '',
    isTheCompany: '',
    isTheCompanyInfo: '',
    isCompanySigned: '',
    isCompanySignedInfo: '',
    inspectionConclusionAction: '',
    referenceAction: '',
    statementsAction: '',
    inspectionFindingsAction: '',
    useReadyMadeAction: null,
    actionFinancialIndications: 'notApplicable',
    actionFinancialIndicationsInfo: '',
    actionOfPersonnel: 'notApplicable',
    actionOfPersonnelInfo: '',
    theAnnualReport: 'notApplicable',
    theAnnualReportInfo: '',
    theAccountingBoard: 'notApplicable',
    theAccountingBoardInfo: '',
    environmentalimpact: 'notApplicable',
    environmentalimpactInfo: '',
    theAccountingBoardRecommends: 'notApplicable',
    theAccountingBoardRecommendsInfo: '',
    informationOnMaterial: 'notApplicable',
    informationOnMaterialInfo: '',
    assessmentOfLikely: 'notApplicable',
    assessmentOfLikelyInfo: '',
    statementOfScope: 'notApplicable',
    statementOfScopeInfo: '',
    reportShouldProvide: 'notApplicable',
    reportShouldProvideInfo: '',
    informationAboutBranches: 'notApplicable',
    informationAboutBranchesInfo: '',
    informationOnFinancial: 'notApplicable',
    informationOnFinancialInfo: '',
    activityReport: 'notApplicable',
    activityReportInfo: '',
    theAccountingOfficer: 'no',
    // F3.1 Tilinpäätöksen prikkaus ja liitetiedot
    theOpeningBalance: '',
    theOpeningBalanceComment: '',
    theOpeningBalanceMore: '',
    theClosingBalance: '',
    theClosingBalanceComment: '',
    theClosingBalanceMore: '',
    figuresForAudited: 'yes',
    figuresForAuditedInfo: '',
    figuresForComparison: 'yes',
    figuresForComparisonInfo: '',
    attachedInformation: '',
    attachedInformationInfo: '',
    theRuleUsed: 'yes',
    theRuleUsedInfo: '',
    theCompanyHasPrepared: 'no',
    theCompanyHasPreparedInfo: '',
    inspectionConclusion: 'two',
    reference:
      'Viittaus soveltuvin osin esim. tarkastustiedostoon tai tarkastus oheisessa taulukossa',
    statements:
      'Tarkastuksen tavoiteena on varmistua siitä, että liitetiedoissa on esitetty kaikki sovellettavan normiston edellyttämät seikat',
    inspectionFindings:
      'Kuvaa tässä mahdolliset havainnot / ei havaintoja, liitetiedoissa on annettu tarvittavat tiedot',
    useReadyMadePMA: false,
    useReadyMadePMA2: false,
    useReadyMadeKPA: false,
    // F3.1 konsernin liitetietojen tarkastus
    inspectionConclusion2: 'one',
    reference2:
      'Viittaus soveltuvin osin esim. tarkastustiedostoon tai tarkastus oheisessa taulukossa',
    statements2:
      'Tarkastuksen tavoiteena on varmistua siitä, että liitetiedoissa on esitetty kaikki sovellettavan normiston edellyttämät seikat',
    inspectionFindings2:
      'Kuvaa tässä mahdolliset havainnot / ei havaintoja, liitetiedoissa on annettu tarvittavat tiedot',
    useReadyMadeGroup: true,
    // F3.1 Toimintakertomuksen prikkaus ja sisältö
    inspectionConclusion3: 'one',
    reference3:
      'Viittaus soveltuvin osin esim. tarkastustiedostoon tai tarkastus oheisessa taulukossa',
    statements3:
      'Tarkastuksen tavoiteena on varmistua siitä, että liitetiedoissa on esitetty kaikki sovellettavan normiston edellyttämät seikat',
    inspectionFindings3:
      'Kuvaa tässä mahdolliset havainnot / ei havaintoja, liitetiedoissa on annettu tarvittavat tiedot',
    useReadyMadeList: null,
    useReadyMadeList2: '',
    useReadyMadeList2Info: 'Anna tarvittaessa lisätietoa.',
    // Vastuiden tarkastus
    inspectionConclusion4: 'one',
    reference4:
      'Viittaus soveltuvin osin esim. tarkastustiedostoon tai tarkastus oheisessa taulukossa',
    statements4:
      'Tarkastuksen tavoiteena on varmistua siitä, että vastuut on esitetty asianmukaisessa määrässä.',
    inspectionFindings4: 'Kuvaa tässä mahdolliset havainnot / ei havaintoja',
    useReadyMade: null,

    mikroComment: '',
    mikroKPAForFifth: '',
    mikroKPAForFifthTwo: '',
    isPartOfGroup: '',

    // Boolean checkboxes for textAreas
  };
};

const formFields: FormFields<SectionFormType> = ({
  formState,
  auditing,
  store,
}) => [
  ...displayAuditingProcedures(store, batchString, formBuilder, [
    // F3 Tilinpäätöksen allekirjoitusten asianmukaisuus
    {
      accordionId: 'appropriatenessOfFinancial',
      hide: ['auditingReference', 'auditingProcedureGoals', 'auditingComments'],
      lowerElements: [
        formBuilder.group({
          items: [
            formBuilder.subtitle({ title: jsonVocab.financialStatements }),

            formBuilder.checkboxGroup({
              accessor: 'theFinancialStatements',
              options: Object.keys(jsonVocab.YesAndNoOptions),
              optionDisplayValue: (key: string) =>
                jsonVocab.YesAndNoOptions[
                  key as keyof typeof jsonVocab.YesAndNoOptions
                ],
            }),
            formBuilder.textArea({
              accessor: 'theFinancialStatementsInfo',
              plainLabel: 'Tilinpäätöksen on allekirjoittanut kommentit',
              hidden:
                formState.theFinancialStatements !== null &&
                formState.theFinancialStatements.hasOwnProperty('comment')
                  ? formState.theFinancialStatements.comment !== true
                  : true,
            }),

            formBuilder.checkboxGroup({
              accessor: 'hasCEO',
              options: Object.keys(jsonVocab.YesAndNoOptions),
              optionDisplayValue: (key: string) =>
                jsonVocab.YesAndNoOptions[
                  key as keyof typeof jsonVocab.YesAndNoOptions
                ],
            }),
            formBuilder.textArea({
              accessor: 'hasCEOInfo',
              plainLabel: 'Yhtiöllä on toimitusjohtaja kommentit',
              hidden:
                formState.hasCEO !== null &&
                formState.hasCEO.hasOwnProperty('comment')
                  ? formState.hasCEO.comment !== true
                  : true,
            }),

            formBuilder.checkboxGroup({
              accessor: 'CEOhasSigned',
              options: Object.keys(jsonVocab.YesAndNoOptions),
              optionDisplayValue: (key: string) =>
                jsonVocab.YesAndNoOptions[
                  key as keyof typeof jsonVocab.YesAndNoOptions
                ],
              hidden: formState.hasCEO.no,
            }),
            formBuilder.textArea({
              accessor: 'CEOhasSignedInfo',
              plainLabel:
                'Toimitusjohtaja on allekirjoittanut tilinpäätöksen kommentit',
              hidden:
                formState.CEOhasSigned !== null &&
                formState.CEOhasSigned.hasOwnProperty('comment')
                  ? formState.CEOhasSigned.comment !== true
                  : true,
            }),

            formBuilder.checkboxGroup({
              accessor: 'financialStatementActionReport',
              options: Object.keys(jsonVocab.YesAndNoOptions),
              optionDisplayValue: (key: string) =>
                jsonVocab.YesAndNoOptions[
                  key as keyof typeof jsonVocab.YesAndNoOptions
                ],
            }),
            formBuilder.textArea({
              accessor: 'financialStatementActionReportInfo',
              plainLabel: 'Tilinpäätös kommentit',
              hidden:
                formState.financialStatementActionReport !== null &&
                formState.financialStatementActionReport.hasOwnProperty(
                  'comment'
                )
                  ? formState.financialStatementActionReport.comment !== true
                  : true,
            }),
          ],
        }),
        formBuilder.group({
          items: [
            formBuilder.subtitle({ title: jsonVocab.actionReport }),
            /* formBuilder.select({
                            accessor: 'hasTheCompanyReport',
                            options: Object.keys(jsonVocab.YesAndNoOptions),
                            optionDisplayValue: (key: string) => jsonVocab.YesAndNoOptions[key as keyof typeof jsonVocab.YesAndNoOptions],
                        }),
                        formBuilder.textArea({accessor: 'hasTheCompanyReportInfo', plainLabel: 'Kommentit'}), */
            formBuilder.checkboxGroup({
              accessor: 'isTheCompany',
              options: Object.keys(jsonVocab.YesAndNoOptions),
              optionDisplayValue: (key: string) =>
                jsonVocab.YesAndNoOptions[
                  key as keyof typeof jsonVocab.YesAndNoOptions
                ],
            }),
            formBuilder.textArea({
              accessor: 'isTheCompanyInfo',
              plainLabel:
                'Onko yhtiö velvollinen laatimaan toimintakeromuksen kommentit',
              hidden:
                formState.isTheCompany !== null &&
                formState.isTheCompany.hasOwnProperty('comment')
                  ? formState.isTheCompany.comment !== true
                  : true,
            }),
            formBuilder.checkboxGroup({
              accessor: 'isCompanySigned',
              options: Object.keys(jsonVocab.YesAndNoOptions),
              optionDisplayValue: (key: string) =>
                jsonVocab.YesAndNoOptions[
                  key as keyof typeof jsonVocab.YesAndNoOptions
                ],
            }),
            formBuilder.textArea({
              accessor: 'isCompanySignedInfo',
              plainLabel: 'Onko yhtiö laatinut toimintakertomuksen kommentit',
              hidden:
                formState.isCompanySigned !== null &&
                formState.isCompanySigned.hasOwnProperty('comment')
                  ? formState.isCompanySigned.comment !== true
                  : true,
            }),

            formBuilder.group({
              items: [
                formBuilder.subtitle({
                  title: 'Toimintakertomuksen tarkastus',
                }),
                formBuilder.checkboxGroup({
                  accessor: 'inspectionConclusionAction',
                  options: Object.keys(jsonVocab.conclusionOptions2),
                  optionDisplayValue: (key: string) =>
                    jsonVocab.conclusionOptions2[
                      key as keyof typeof jsonVocab.conclusionOptions2
                    ],
                }),
                formBuilder.textArea({
                  accessor: 'referenceAction',
                  plainLabel: jsonVocab.reference,
                }),
                formBuilder.textArea({
                  accessor: 'statementsAction',
                  plainLabel: jsonVocab.statements,
                }),
                formBuilder.textArea({
                  accessor: 'inspectionFindingsAction',
                  plainLabel: jsonVocab.inspectionFindings,
                }),
                formBuilder.boolean({
                  accessor: 'useReadyMadeAction',
                  plainLabel: 'Käytä valmista tarkastuslistaa toimintakertomus',
                }),
                ...(formState.useReadyMadeAction
                  ? [
                      formBuilder.accordionGroup({
                        title: 'Tarkastuslista toimintakertomus',
                        items: [
                          formBuilder.custom(props => (
                            <ActionTable {...props} />
                          )),
                          formBuilder.group({
                            items: [
                              formBuilder.custom(props => (
                                <ActionTable2 {...props} />
                              )),
                              formBuilder.text({
                                text: 'Silloin, kun se on tarpeen 1 momentissa tarkoitetun kuvauksen ymmärtämiseksi, toimintakertomukseen on sisällytettävä',
                              }),
                              formBuilder.select({
                                accessor: 'actionFinancialIndications',
                                options: Object.keys(
                                  jsonVocab.actionFinancialIndicationsOptions
                                ),
                                optionDisplayValue: (key: string) =>
                                  jsonVocab.actionFinancialIndicationsOptions[
                                    key as keyof typeof jsonVocab.actionFinancialIndicationsOptions
                                  ],
                              }),
                              formBuilder.textArea({
                                accessor: 'actionFinancialIndicationsInfo',
                                plainLabel: 'Kommentit',
                              }),
                            ],
                          }),
                          formBuilder.group({
                            items: [
                              formBuilder.text({
                                text: 'Kirjanpitolautakunta pitää suositeltavana, että liiketoimintaa harjoittavan kirjanpitovelvollisen toimintakertomuksessa esitetään päättyneeltä ja kahdelta sitä edeltäneeltä tilikaudelta ainakin',
                              }),
                              formBuilder.custom(props => (
                                <ActionTable3 {...props} />
                              )),
                            ],
                            // Show if actionFinancialIndications is "yes" or "no"
                            hidden:
                              formState.actionFinancialIndications ===
                              'notApplicable',
                          }),
                          formBuilder.group({
                            items: [
                              formBuilder.text({
                                text: 'Kirjanpitolautakunta katsoo, että henkilöstöön liittyvinä tunnuslukuina kirjanpitovelvollisen tulee esittää toimintakertomuksessa pakollisina tietoina',
                              }),
                              formBuilder.custom(props => (
                                <ActionTable4 {...props} />
                              )),
                            ],
                          }),
                          // 2) muut kuin taloudelliset tunnusluvut henkilöstöstä
                          formBuilder.group({
                            items: [
                              formBuilder.select({
                                accessor: 'actionOfPersonnel',
                                options: Object.keys(
                                  jsonVocab.actionOfPersonnelOptions
                                ),
                                optionDisplayValue: (key: string) =>
                                  jsonVocab.actionOfPersonnelOptions[
                                    key as keyof typeof jsonVocab.actionOfPersonnelOptions
                                  ],
                              }),
                              formBuilder.textArea({
                                accessor: 'actionOfPersonnelInfo',
                                plainLabel: 'Kommentit',
                              }),
                              formBuilder.select({
                                accessor: 'theAnnualReport',
                                options: Object.keys(
                                  jsonVocab.actionOfPersonnelOptions
                                ),
                                optionDisplayValue: (key: string) =>
                                  jsonVocab.actionOfPersonnelOptions[
                                    key as keyof typeof jsonVocab.actionOfPersonnelOptions
                                  ],
                              }),
                              formBuilder.textArea({
                                accessor: 'theAnnualReportInfo',
                                plainLabel: 'Kommentit',
                              }),
                              formBuilder.select({
                                accessor: 'theAccountingBoard',
                                options: Object.keys(
                                  jsonVocab.actionOfPersonnelOptions
                                ),
                                optionDisplayValue: (key: string) =>
                                  jsonVocab.actionOfPersonnelOptions[
                                    key as keyof typeof jsonVocab.actionOfPersonnelOptions
                                  ],
                                hidden:
                                  formState.actionOfPersonnel ===
                                  'notApplicable',
                              }),
                              formBuilder.textArea({
                                accessor: 'theAccountingBoardInfo',
                                plainLabel: 'Kommentit',
                                hidden:
                                  formState.actionOfPersonnel ===
                                  'notApplicable',
                              }),
                              formBuilder.select({
                                accessor: 'environmentalimpact',
                                options: Object.keys(
                                  jsonVocab.actionOfPersonnelOptions
                                ),
                                optionDisplayValue: (key: string) =>
                                  jsonVocab.actionOfPersonnelOptions[
                                    key as keyof typeof jsonVocab.actionOfPersonnelOptions
                                  ],
                              }),
                              formBuilder.textArea({
                                accessor: 'environmentalimpactInfo',
                                plainLabel: 'Kommentit',
                              }),
                              formBuilder.select({
                                accessor: 'theAccountingBoardRecommends',
                                options: Object.keys(
                                  jsonVocab.actionOfPersonnelOptions
                                ),
                                optionDisplayValue: (key: string) =>
                                  jsonVocab.actionOfPersonnelOptions[
                                    key as keyof typeof jsonVocab.actionOfPersonnelOptions
                                  ],
                                hidden:
                                  formState.environmentalimpact ===
                                  'notApplicable',
                              }),
                              formBuilder.textArea({
                                accessor: 'theAccountingBoardRecommendsInfo',
                                plainLabel: 'Kommentit',
                                hidden:
                                  formState.environmentalimpact ===
                                  'notApplicable',
                              }),
                            ],
                          }),
                          formBuilder.group({
                            items: [
                              formBuilder.subtitle({
                                title: 'KPL 3:1a.4 mukaiset tiedot',
                              }),
                              formBuilder.select({
                                accessor: 'informationOnMaterial',
                                options: Object.keys(
                                  jsonVocab.actionOfPersonnelOptions
                                ),
                                optionDisplayValue: (key: string) =>
                                  jsonVocab.actionOfPersonnelOptions[
                                    key as keyof typeof jsonVocab.actionOfPersonnelOptions
                                  ],
                              }),
                              formBuilder.textArea({
                                accessor: 'informationOnMaterialInfo',
                                plainLabel: 'Kommentit',
                              }),
                              formBuilder.select({
                                accessor: 'assessmentOfLikely',
                                options: Object.keys(
                                  jsonVocab.actionOfPersonnelOptions
                                ),
                                optionDisplayValue: (key: string) =>
                                  jsonVocab.actionOfPersonnelOptions[
                                    key as keyof typeof jsonVocab.actionOfPersonnelOptions
                                  ],
                              }),
                              formBuilder.textArea({
                                accessor: 'assessmentOfLikelyInfo',
                                plainLabel: 'Kommentit',
                              }),
                              formBuilder.select({
                                accessor: 'statementOfScope',
                                options: Object.keys(
                                  jsonVocab.actionOfPersonnelOptions
                                ),
                                optionDisplayValue: (key: string) =>
                                  jsonVocab.actionOfPersonnelOptions[
                                    key as keyof typeof jsonVocab.actionOfPersonnelOptions
                                  ],
                              }),
                              formBuilder.textArea({
                                accessor: 'statementOfScopeInfo',
                                plainLabel: 'Kommentit',
                              }),
                              formBuilder.select({
                                accessor: 'reportShouldProvide',
                                options: Object.keys(
                                  jsonVocab.actionOfPersonnelOptions
                                ),
                                optionDisplayValue: (key: string) =>
                                  jsonVocab.actionOfPersonnelOptions[
                                    key as keyof typeof jsonVocab.actionOfPersonnelOptions
                                  ],
                                hidden:
                                  formState.statementOfScope ===
                                  'notApplicable',
                              }),
                              formBuilder.textArea({
                                accessor: 'reportShouldProvideInfo',
                                plainLabel: 'Kommentit',
                                hidden:
                                  formState.statementOfScope ===
                                  'notApplicable',
                              }),
                              formBuilder.select({
                                accessor: 'informationAboutBranches',
                                options: Object.keys(
                                  jsonVocab.actionOfPersonnelOptions
                                ),
                                optionDisplayValue: (key: string) =>
                                  jsonVocab.actionOfPersonnelOptions[
                                    key as keyof typeof jsonVocab.actionOfPersonnelOptions
                                  ],
                              }),
                              formBuilder.textArea({
                                accessor: 'informationAboutBranchesInfo',
                                plainLabel: 'Kommentit',
                              }),
                              formBuilder.select({
                                accessor: 'informationOnFinancial',
                                options: Object.keys(
                                  jsonVocab.actionOfPersonnelOptions
                                ),
                                optionDisplayValue: (key: string) =>
                                  jsonVocab.actionOfPersonnelOptions[
                                    key as keyof typeof jsonVocab.actionOfPersonnelOptions
                                  ],
                              }),
                              formBuilder.textArea({
                                accessor: 'informationOnFinancialInfo',
                                plainLabel: 'Kommentit',
                              }),
                              formBuilder.select({
                                accessor: 'activityReport',
                                options: Object.keys(
                                  jsonVocab.actionOfPersonnelOptions
                                ),
                                optionDisplayValue: (key: string) =>
                                  jsonVocab.actionOfPersonnelOptions[
                                    key as keyof typeof jsonVocab.actionOfPersonnelOptions
                                  ],
                              }),
                              formBuilder.textArea({
                                accessor: 'activityReportInfo',
                                plainLabel: 'Kommentit',
                              }),
                            ],
                          }),
                          formBuilder.select({
                            accessor: 'theAccountingOfficer',
                            options: Object.keys(
                              jsonVocab.theAccountingOfficerOptions
                            ),
                            optionDisplayValue: (key: string) =>
                              jsonVocab.theAccountingOfficerOptions[
                                key as keyof typeof jsonVocab.theAccountingOfficerOptions
                              ],
                          }),
                          formBuilder.group({
                            items: [
                              formBuilder.text({
                                text: ' Kauppa- ja teollisuusministeriön asetus rahoitusvälineiden arvostamisesta sekä merkitsemisestä tilinpäätökseen ja konsernitilinpäätökseen toimintakertomuksessa tulee esittää käypään arvoon merkityistä rahoitusvälineistä tiedot 9§:',
                              }),
                              formBuilder.custom(props => (
                                <ActionTable5 {...props} />
                              )),
                            ],
                            hidden: formState.theAccountingOfficer === 'no',
                          }),
                        ],
                      }),
                    ]
                  : []),
              ],
              hidden:
                formState.isCompanySigned !== null &&
                formState.isCompanySigned.hasOwnProperty('yes')
                  ? formState.isCompanySigned.yes !== true
                  : true,
            }),
          ],
        }),
      ],
    },
    // F3.1 Tilinpäätöksen prikkaus ja liitetiedot
    {
      accordionId: 'summaryOfTheFinancial',
      hide: ['auditingReference', 'auditingProcedureGoals', 'auditingComments'],
      lowerElements: [
        formBuilder.group({
          items: [
            formBuilder.checkboxGroup({
              accessor: 'figuresForAudited',
              options: Object.keys(jsonVocab.YesAndNoOptions),
              optionDisplayValue: (key: string) =>
                jsonVocab.YesAndNoOptions[
                  key as keyof typeof jsonVocab.YesAndNoOptions
                ],
            }),
            formBuilder.textArea({
              accessor: 'figuresForAuditedInfo',
              plainLabel:
                'Tilinpäätöksellä esitetyt tarkastettavan tilikauden luvut täsmäävät pääkirjanpitoon kommentit',
              hidden:
                formState.figuresForAudited !== null &&
                formState.figuresForAudited.hasOwnProperty('comment')
                  ? formState.figuresForAudited.comment !== true
                  : true,
            }),
            formBuilder.checkboxGroup({
              accessor: 'figuresForComparison',
              options: Object.keys(jsonVocab.YesAndNoOptions),
              optionDisplayValue: (key: string) =>
                jsonVocab.YesAndNoOptions[
                  key as keyof typeof jsonVocab.YesAndNoOptions
                ],
            }),
            formBuilder.textArea({
              accessor: 'figuresForComparisonInfo',
              plainLabel:
                'Vertailukauden luvut täsmäävät edellisen vuoden vahvistettuun tilinpäätökseen kommentit',
              hidden:
                formState.figuresForComparison !== null &&
                formState.figuresForComparison.hasOwnProperty('comment')
                  ? formState.figuresForComparison.comment !== true
                  : true,
            }),

            formBuilder.group({
              items: [
                formBuilder.checkboxGroup({
                  accessor: 'theOpeningBalance',
                  options: Object.keys(jsonVocab.theOpeningBalanceOptions),
                  optionDisplayValue: (key: string) =>
                    jsonVocab.theOpeningBalanceOptions[
                      key as keyof typeof jsonVocab.theOpeningBalanceOptions
                    ],
                }),
                formBuilder.textArea({
                  accessor: 'theOpeningBalanceComment',
                  plainLabel: 'Kommentit',
                  hidden:
                    formState.theOpeningBalance !== null &&
                    formState.theOpeningBalance.hasOwnProperty('comment')
                      ? formState.theOpeningBalance.comment !== true
                      : true,
                }),
                formBuilder.checkboxGroup({
                  accessor: 'theOpeningBalanceMore',
                  options: Object.keys(jsonVocab.theOpeningBalanceMoreOptions),
                  optionDisplayValue: (key: string) =>
                    jsonVocab.theOpeningBalanceMoreOptions[
                      key as keyof typeof jsonVocab.theOpeningBalanceMoreOptions
                    ],
                  hidden:
                    formState.theOpeningBalance !== null &&
                    formState.theOpeningBalance.hasOwnProperty('two')
                      ? formState.theOpeningBalance.two !== true
                      : true,
                }),
                formBuilder.checkboxGroup({
                  accessor: 'theClosingBalance',
                  options: Object.keys(jsonVocab.theOpeningBalanceOptions),
                  optionDisplayValue: (key: string) =>
                    jsonVocab.theOpeningBalanceOptions[
                      key as keyof typeof jsonVocab.theOpeningBalanceOptions
                    ],
                }),
                formBuilder.textArea({
                  accessor: 'theClosingBalanceComment',
                  plainLabel: 'Kommentit',
                  hidden:
                    formState.theClosingBalance !== null &&
                    formState.theClosingBalance.hasOwnProperty('comment')
                      ? formState.theClosingBalance.comment !== true
                      : true,
                }),
                formBuilder.checkboxGroup({
                  accessor: 'theClosingBalanceMore',
                  options: Object.keys(jsonVocab.theOpeningBalanceMoreOptions),
                  optionDisplayValue: (key: string) =>
                    jsonVocab.theOpeningBalanceMoreOptions[
                      key as keyof typeof jsonVocab.theOpeningBalanceMoreOptions
                    ],
                  hidden:
                    formState.theClosingBalance !== null &&
                    formState.theClosingBalance.hasOwnProperty('two')
                      ? formState.theClosingBalance.two !== true
                      : true,
                }),
              ],
            }),

            formBuilder.checkboxGroup({
              accessor: 'attachedInformation',
              options: Object.keys(jsonVocab.PMAOptions),
              optionDisplayValue: (key: string) =>
                jsonVocab.PMAOptions[key as keyof typeof jsonVocab.PMAOptions],
            }),
            formBuilder.textArea({
              accessor: 'attachedInformationInfo',
              plainLabel:
                'Liitetiedot on laaditaan noudattaen säännöstöä kommentit',
              hidden:
                formState.attachedInformation !== null &&
                formState.attachedInformation.hasOwnProperty('comment')
                  ? formState.attachedInformation.comment !== true
                  : true,
            }),
            formBuilder.checkboxGroup({
              accessor: 'theRuleUsed',
              options: Object.keys(jsonVocab.YesAndNoOptions),
              optionDisplayValue: (key: string) =>
                jsonVocab.YesAndNoOptions[
                  key as keyof typeof jsonVocab.YesAndNoOptions
                ],
            }),
            formBuilder.textArea({
              accessor: 'theRuleUsedInfo',
              plainLabel:
                'Käytetty säännöstä on asianmukainen yhtiön koko huomioden kommentit',
              hidden:
                formState.theRuleUsed !== null &&
                formState.theRuleUsed.hasOwnProperty('comment')
                  ? formState.theRuleUsed.comment !== true
                  : true,
            }),
            formBuilder.checkboxGroup({
              accessor: 'theCompanyHasPrepared',
              options: Object.keys(jsonVocab.YesAndNoOptions),
              optionDisplayValue: (key: string) =>
                jsonVocab.YesAndNoOptions[
                  key as keyof typeof jsonVocab.YesAndNoOptions
                ],
            }),
            formBuilder.textArea({
              accessor: 'theCompanyHasPreparedInfo',
              plainLabel: 'Yhtiö on laatinut konsernitilinpäätöksen kommentit',
              hidden:
                formState.theCompanyHasPrepared !== null &&
                formState.theCompanyHasPrepared.hasOwnProperty('comment')
                  ? formState.theCompanyHasPrepared.comment !== true
                  : true,
            }),
          ],
        }),
        formBuilder.group({
          items: [
            formBuilder.subtitle({ title: 'Liitetietojen tarkastus' }),
            formBuilder.checkboxGroup({
              accessor: 'inspectionConclusion',
              options: Object.keys(jsonVocab.conclusionOptions),
              optionDisplayValue: (key: string) =>
                jsonVocab.conclusionOptions[
                  key as keyof typeof jsonVocab.conclusionOptions
                ],
            }),
            formBuilder.textArea({
              accessor: 'reference',
              plainLabel: jsonVocab.reference,
            }),
            formBuilder.textArea({
              accessor: 'statements',
              plainLabel: jsonVocab.statements,
            }),
            formBuilder.textArea({
              accessor: 'inspectionFindings',
              plainLabel: jsonVocab.inspectionFindings,
            }),
            formBuilder.boolean({
              accessor: 'useReadyMadePMA',
              hidden: formState.useReadyMadePMA2 || formState.useReadyMadeKPA,
            }),
            ...(formState.useReadyMadePMA
              ? [
                  formBuilder.accordionGroup({
                    title: 'Tarkastuslista PMA Mikro',
                    items: [
                      formBuilder.custom(props => <MikroPMATable {...props} />),
                      formBuilder.select({
                        accessor: 'mikroComment',
                        plainLabel: jsonVocab.MikroPMAComment,
                        options: Object.keys(jsonVocab.mikroSelect),
                        optionDisplayValue: (key: string) =>
                          jsonVocab.mikroSelect[
                            key as keyof typeof jsonVocab.mikroSelect
                          ],
                      }),
                      ...(formState.mikroComment === 'yes'
                        ? [
                            formBuilder.custom(props => (
                              <MikroCommentTable {...props} />
                            )),
                          ]
                        : []),
                    ],
                  }),
                ]
              : []),
            formBuilder.boolean({
              accessor: 'useReadyMadePMA2',
              hidden: formState.useReadyMadePMA || formState.useReadyMadeKPA,
            }),
            ...(formState.useReadyMadePMA2
              ? [
                  formBuilder.accordionGroup({
                    title: 'Tarkastuslista PMA Pienyritys',
                    items: [
                      formBuilder.custom(props => (
                        <SmallBusinessTable {...props} />
                      )),
                      formBuilder.custom(props => (
                        <SmallBusinessTableSecond {...props} />
                      )),
                    ],
                  }),
                ]
              : []),
            formBuilder.boolean({
              accessor: 'useReadyMadeKPA',
              hidden: formState.useReadyMadePMA || formState.useReadyMadePMA2,
            }),
            ...(formState.useReadyMadeKPA
              ? [
                  formBuilder.accordionGroup({
                    title: 'Tarkastuslista KPA mukaiset liitetiedostot',
                    items: [
                      formBuilder.group({
                        items: [
                          formBuilder.subtitle({
                            title: 'Liitetieto vaatimukset KPA',
                          }),
                          formBuilder.custom(props => (
                            <MikroKPATable {...props} />
                          )),
                          formBuilder.custom(props => (
                            <MikroKPASecondTable {...props} />
                          )),
                          formBuilder.custom(props => (
                            <MikroKPAThirdTable {...props} />
                          )),
                          formBuilder.custom(props => (
                            <MikroKPAFourthTable {...props} />
                          )),
                          formBuilder.custom(props => (
                            <MikroKPAOtherTable {...props} />
                          )),
                          formBuilder.select({
                            accessor: 'mikroKPAForFifth',
                            plainLabel:
                              'Kirjanpitovelvollisella on pantteja tai muita esineoikeudellisia vakuuksia',
                            options: Object.keys(jsonVocab.mikroSelect),
                            optionDisplayValue: (key: string) =>
                              jsonVocab.mikroSelect[
                                key as keyof typeof jsonVocab.mikroSelect
                              ],
                          }),
                          ...(formState.mikroKPAForFifth === 'yes'
                            ? [
                                formBuilder.custom(props => (
                                  <MikroKPAFifthTable {...props} />
                                )),
                                formBuilder.select({
                                  accessor: 'mikroKPAForFifthTwo',
                                  plainLabel:
                                    'Kirjanpitovelvollisella on vastuusitoumus tai muu vastuu, jota ei ole merkitty taseeseen kirjanpitolain 5 luvun 14 §:n tarkoittamalla tavalla tai muutoin?',
                                  options: Object.keys(jsonVocab.mikroSelect),
                                  optionDisplayValue: (key: string) =>
                                    jsonVocab.mikroSelect[
                                      key as keyof typeof jsonVocab.mikroSelect
                                    ],
                                }),
                                ...(formState.mikroKPAForFifthTwo === 'yes'
                                  ? [
                                      formBuilder.custom(props => (
                                        <MikroKPAFifthTableTwo {...props} />
                                      )),
                                    ]
                                  : []),
                              ]
                            : []),
                          // NEW TABLE
                          formBuilder.custom(props => (
                            <MikroKPASixthTable {...props} />
                          )),
                          formBuilder.custom(props => (
                            <MikroKPASeventhTable {...props} />
                          )),
                          formBuilder.subtitle({
                            title: jsonVocab.mikroTable.KPASubtitle8,
                          }),
                          formBuilder.select({
                            accessor: 'isPartOfGroup',
                            plainLabel: 'Yhtiö on osa konsernia',
                            options: Object.keys(jsonVocab.mikroSelect),
                            optionDisplayValue: (key: string) =>
                              jsonVocab.mikroSelect[
                                key as keyof typeof jsonVocab.mikroSelect
                              ],
                          }),
                          ...(formState.isPartOfGroup === 'yes'
                            ? [
                                formBuilder.custom(props => (
                                  <MikroKPAEightTable {...props} />
                                )),
                              ]
                            : []),
                        ],
                      }),
                    ],
                  }),
                ]
              : []),
          ],
        }),
      ],
    },
    // F3.1 konsernin liitetietojen tarkastus
    {
      accordionId: 'inspectionOfTheConsolidated',
      hide: ['auditingReference', 'auditingProcedureGoals', 'auditingComments'],
      lowerElements: [
        formBuilder.group({
          items: [
            formBuilder.subtitle({ title: 'Liitetietojen tarkastus' }),
            formBuilder.checkboxGroup({
              accessor: 'inspectionConclusion2',
              plainLabel: jsonVocab.inspectionConclusion,
              options: Object.keys(jsonVocab.conclusionOptions),
              optionDisplayValue: (key: string) =>
                jsonVocab.conclusionOptions[
                  key as keyof typeof jsonVocab.conclusionOptions
                ],
            }),
            formBuilder.textArea({
              accessor: 'reference2',
              plainLabel: jsonVocab.reference,
            }),
            formBuilder.textArea({
              accessor: 'statements2',
              plainLabel: jsonVocab.statements,
            }),
            formBuilder.textArea({
              accessor: 'inspectionFindings2',
              plainLabel: jsonVocab.inspectionFindings,
            }),
            formBuilder.boolean({ accessor: 'useReadyMadeGroup' }),
            ...(formState.useReadyMadeGroup
              ? [
                  //defaultGroupElement(formState, formBuilder, auditing),
                  formBuilder.custom(props => <MikroGroupTable {...props} />),
                  formBuilder.custom(props => (
                    <MikroGroupTableSecond {...props} />
                  )),
                  formBuilder.custom(props => (
                    <MikroGroupTableThird {...props} />
                  )),
                ]
              : []),
          ],
        }),
      ],
    },
    // F3.1 Toimintakertomuksen prikkaus ja sisältö
    {
      accordionId: 'summaryAndContent',
      hide: ['auditingReference', 'auditingProcedureGoals', 'auditingComments'],
      lowerElements: [
        formBuilder.checkboxGroup({
          accessor: 'inspectionConclusion3',
          plainLabel: jsonVocab.inspectionConclusion,
          options: Object.keys(jsonVocab.conclusionOptions2),
          optionDisplayValue: (key: string) =>
            jsonVocab.conclusionOptions2[
              key as keyof typeof jsonVocab.conclusionOptions2
            ],
        }),
        formBuilder.textArea({
          accessor: 'reference3',
          plainLabel: jsonVocab.reference,
        }),
        formBuilder.textArea({
          accessor: 'statements3',
          plainLabel: jsonVocab.statements,
        }),
        formBuilder.textArea({
          accessor: 'inspectionFindings3',
          plainLabel: jsonVocab.inspectionFindings,
        }),
        formBuilder.boolean({ accessor: 'useReadyMadeList' }),
        formBuilder.accordionGroup({
          title: 'Toimintakertomus Tarkastuslista',
          hidden: !formState.useReadyMadeList,
          open: true,
          items: [
            formBuilder.custom(props => <ActionReportTable {...props} />),
            formBuilder.custom(props => <ActionReportSecondTable {...props} />),
            formBuilder.group({
              items: [
                formBuilder.subtitle({
                  title:
                    'Silloin, kun se on tarpeen 1 momentissa tarkoitetun kuvauksen ymmärtämiseksi, toimintakertomukseen on sisällytettävä',
                }),
                formBuilder.select({
                  accessor: 'useReadyMadeList2',
                  plainLabel: '1) Taloudelliset tunnusluvut',
                  options: Object.keys(jsonVocab.mikroSelect),
                  optionDisplayValue: (key: string) =>
                    jsonVocab.mikroSelect[
                      key as keyof typeof jsonVocab.mikroSelect
                    ],
                }),
                formBuilder.textArea({
                  accessor: 'useReadyMadeList2Info',
                  plainLabel: 'Taloudelliset tunnusluvut kommentit',
                  hidden: formState.useReadyMadeList2 !== 'no',
                }),
                ...(formState.useReadyMadeList2 === 'yes'
                  ? [
                      formBuilder.subtitle({
                        title:
                          'Kirjanpitolautakunta pitää suositeltavana, että liiketoimintaa harjoittavan kirjanpitovelvollisen toimintakertomuksessa esitetään päättyneeltä ja kahdelta sitä edeltäneeltä tilikaudelta ainakin',
                      }),
                      formBuilder.custom(props => (
                        <ActionReportThirdTable {...props} />
                      )),
                      formBuilder.subtitle({
                        title:
                          'Kirjanpitolautakunta katsoo, että henkilöstöön liittyvinä tunnuslukuina kirjanpitovelvollisen tulee esittää toimintakertomuksessa pakollisina tietoina ',
                      }),
                      formBuilder.custom(props => (
                        <ActionReportFourthTable {...props} />
                      )),
                    ]
                  : []),
              ],
            }),
            formBuilder.group({
              items: [
                formBuilder.text({}),
                formBuilder.select({
                  accessor: 'actionReport1',
                  plainLabel: jsonVocab.mikroTable.actionReportSubTitle2,
                  options: Object.keys(jsonVocab.mikroSelect),
                  optionDisplayValue: (key: string) =>
                    jsonVocab.mikroSelect[
                      key as keyof typeof jsonVocab.mikroSelect
                    ],
                }),
                //formBuilder.textArea({accessor: 'actionReport1Info', plainLabel: 'Kommentit'}),
                formBuilder.custom(props => (
                  <ActionReportFifthTable {...props} />
                )),
                ...(formState.actionReport1 === 'yes'
                  ? [
                      formBuilder.custom(props => (
                        <ActionReportSixthTable {...props} />
                      )),
                    ]
                  : []),
                formBuilder.text({}),
                formBuilder.select({
                  accessor: 'actionReport2',
                  plainLabel: jsonVocab.mikroTable.actionReportSubTitle3,
                  options: Object.keys(jsonVocab.mikroSelect),
                  optionDisplayValue: (key: string) =>
                    jsonVocab.mikroSelect[
                      key as keyof typeof jsonVocab.mikroSelect
                    ],
                }),
                //formBuilder.textArea({accessor: 'actionReport2Info', plainLabel: 'Kommentit'}),
                ...(formState.actionReport2 === 'yes'
                  ? [
                      formBuilder.custom(props => (
                        <ActionReportSeventhTable {...props} />
                      )),
                    ]
                  : []),
              ],
            }),
            formBuilder.custom(props => <ActionReportOtherTable {...props} />),
            formBuilder.group({
              items: [
                formBuilder.select({
                  accessor: 'actionReportOther',
                  plainLabel: jsonVocab.other1_3,
                  options: Object.keys(jsonVocab.mikroSelect),
                  optionDisplayValue: (key: string) =>
                    jsonVocab.mikroSelect[
                      key as keyof typeof jsonVocab.mikroSelect
                    ],
                }),
                //formBuilder.textArea({accessor: 'actionReportOtherInfo', plainLabel: 'Kommentit'}),
                ...(formState.actionReportOther === 'yes'
                  ? [
                      formBuilder.custom(props => (
                        <ActionReportOther2Table {...props} />
                      )),
                    ]
                  : []),
                formBuilder.custom(props => (
                  <ActionReportOther3Table {...props} />
                )),
              ],
            }),
            formBuilder.group({
              items: [
                formBuilder.select({
                  accessor: 'accountingOfficer',
                  plainLabel:
                    'Kirjanpitovelvollinen merkitsee rahoitusvälineet käypään arvoon',
                  options: Object.keys(jsonVocab.mikroSelect),
                  optionDisplayValue: (key: string) =>
                    jsonVocab.mikroSelect[
                      key as keyof typeof jsonVocab.mikroSelect
                    ],
                }),
                ...(formState.accountingOfficer === 'yes'
                  ? [
                      formBuilder.subtitle({
                        title:
                          ' Kauppa- ja teollisuusministeriön asetus rahoitusvälineiden arvostamisesta sekä merkitsemisestä tilinpäätökseen ja konsernitilinpäätökseen toimintakertomuksessa tulee esittää käypään arvoon merkityistä rahoitusvälineistä tiedot 9§',
                      }),
                      formBuilder.custom(props => (
                        <ActionReportOfficerTable {...props} />
                      )),
                    ]
                  : []),
              ],
            }),
          ],
        }),
      ],
    },
    // G3 vastuiden tarkastus (työpaperi F3)
    {
      accordionId: 'inspectionOfResponsibilities',
      hide: ['auditingReference', 'auditingProcedureGoals', 'auditingComments'],
      lowerElements: [
        /*
        formBuilder.select({
          accessor: 'inspectionConclusion4',
          plainLabel: jsonVocab.inspectionConclusion,
          options: Object.keys(
            auditingJson.inspectionConclusionOptionsWithEvidenceChoice
          ),
          optionDisplayValue: (key: string) =>
            auditingJson.inspectionConclusionOptionsWithEvidenceChoice[
              key as keyof typeof auditingJson.inspectionConclusionOptionsWithEvidenceChoice
            ],
        }),
        */
        formBuilder.textArea({
          accessor: 'reference4',
          plainLabel: jsonVocab.reference,
        }),
        formBuilder.textArea({
          accessor: 'statements4',
          plainLabel: jsonVocab.statements,
        }),
        formBuilder.textArea({
          accessor: 'inspectionFindings4',
          plainLabel: jsonVocab.inspectionFindings,
        }),
        formBuilder.boolean({ accessor: 'useReadyMade' }),
        // If useReadyMade is true, show the table
        ...(formState.useReadyMade
          ? [formBuilder.custom(props => <PresentationTable {...props} />)]
          : []),
      ],
    },
  ]),
];

const section: AuditingSectionDefinition<SectionFormType> = {
  sectionKey,
  defaultFormState,
  formFields,
};

export default section;
