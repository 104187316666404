import { useTranslation } from 'react-i18next';
import { confirmDelete, uuid } from '../../utils';
import AuditingTable from '../table/AuditingTable';
import { OnEditProps, TableColumnDefs } from '../table/Table';
import styled from 'styled-components';

const Container = styled.div`
  .additional-info {
    font-style: italic;
    color: ${p => p.theme.color.grey400};
    text-align: right;
  }
`;

const sectionKey: AuditingSectionKey = 'tradeCreditors';

export const TradeTableColumn = ({
  formState,
  patchFormState,
}: FormFieldProps<TradeCreditorsForm>) => {
  const { t } = useTranslation();

  if (!formState) return null;

  const tBase = `auditing:form.${sectionKey}.tradeTable`;

  const data = formState.tradeTable ?? [];

  function handleEdit<T>({
    itemId,
    rowIndex,
    columnId,
    value,
  }: OnEditProps<T>) {
    const patch = formState.tradeTable.map((row, index) => {
      const idMatch = itemId !== undefined && itemId === row.id;
      const rowIndexMatch = itemId === undefined && rowIndex === index;
      if (idMatch || rowIndexMatch) {
        const newRow = { ...row, [columnId]: value };
        // Calculate minus column, if rekontra or confirm column is edited
        if (columnId === 'rekontra' || columnId === 'confirm') {
          let { rekontra, confirm } = newRow || 0;
          let minus = rekontra - confirm || 0;
          newRow.minus = minus;
          formatNumber(newRow.minus);
        }
        return newRow;
      }
      return row;
    });
    const newFormState = { ...formState, tradeTable: patch };
    patchFormState(newFormState);
  }

  // Format number to have 2 decimals
  function formatNumber(num: any) {
    return num.toLocaleString('fi-FI', {
      minimumFractionDigits: 1,
      maximumFractionDigits: 2,
    });
  }

  const tableColumns: TableColumnDefs<tradeTableColumn> = [
    {
      accessorKey: 'label',
      header: () => 'Ostovelat',
      className: 'text-left width-middle',
      placeholder: t(`${tBase}.purchases`) + '...',
      onEdit: (editProps: OnEditProps<string>) => {
        handleEdit<string>(editProps);
      },
    },
    {
      accessorKey: 'date',
      header: () => 'Päivämäärä',
      className: 'text-center width-middle',
      placeholder: '31.12.202X - 30.6.202X',
      onEdit: (editProps: OnEditProps<string>) => {
        handleEdit<string>(editProps);
      },
    },
    {
      accessorKey: 'rekontra',
      header: () => t(`${tBase}.rekontra`), // + ' €',
      className: 'text-center width-middle',
      onNumberEdit: (editProps: OnEditProps<number>) =>
        handleEdit<number>(editProps),
      cell: ({ cell }) => (
        <div>{formatNumber(cell.row.original.rekontra)} €</div>
      ),
    },
    {
      accessorKey: 'confirm',
      header: () => t(`${tBase}.confirm`), // + ' €',
      className: 'text-center width-middle',
      onNumberEdit: (editProps: OnEditProps<number>) =>
        handleEdit<number>(editProps),
      cell: ({ cell }) => (
        <div>{formatNumber(cell.row.original.confirm)} €</div>
      ),
    },
    {
      accessorKey: 'minus',
      header: () => t(`${tBase}.minus`),
      className: 'text-center width-middle',
      //onEdit: (editProps: OnEditProps<string>) => handleEdit<string>(editProps),
      cell: ({ cell }) => <div>{formatNumber(cell.row.original.minus)} €</div>,
    },
  ];

  const handleAddNewRow = () => {
    patchFormState({
      ...formState,
      tradeTable: [
        ...(formState.tradeTable ?? []),
        {
          label: '',
          id: uuid(), //Math.floor(Math.random()*100).toString(),
          account: '',
          date: '',
          rekontra: 0,
          confirm: 0,
          minus: 0,
        },
      ],
    });
  };

  const handleDeleteRow = ({ id, label }: tradeTableColumn) => {
    if (confirmDelete(label ?? '')) {
      const patch = (formState.tradeTable ?? []).filter(row => row.id !== id);
      patchFormState({ ...formState, tradeTable: patch });
    }
  };

  return (
    <>
      <AuditingTable<tradeTableColumn>
        sectionKey={sectionKey}
        onAddNewRow={handleAddNewRow}
        data={data}
        columns={tableColumns}
        onRowDelete={handleDeleteRow}
        showGlobalFilter={false}
        disableSort
      />
      <Container>
        <div className="additional-info">
          Erotus: Saldo reskontralla tai erilliskirjanpidossa - Saldo
          pääkirjanpidossa
        </div>
      </Container>
    </>
  );
};

export const CheckTableColumn = ({
  formState,
  patchFormState,
}: FormFieldProps<TradeCreditorsForm>) => {
  const { t } = useTranslation();

  if (!formState) return null;

  const tBase = `auditing:form.${sectionKey}.checkTable`;

  const data = formState.checkTable ?? [];

  function handleEdit<T>({
    itemId,
    rowIndex,
    columnId,
    value,
  }: OnEditProps<T>) {
    const patch = formState.checkTable.map((row, index) => {
      const idMatch = itemId !== undefined && itemId === row.id;
      const rowIndexMatch = itemId === undefined && rowIndex === index;
      if (idMatch || rowIndexMatch) {
        const newRow = { ...row, [columnId]: value };
        if (columnId === 'rekontra' || columnId === 'confirm') {
          let { rekontra, confirm } = newRow || 0;
          let minus = rekontra - confirm || 0;
          newRow.minus = minus;
          formatNumber(newRow.minus);
        }
        return newRow;
      }
      return row;
    });
    const newFormState = { ...formState, checkTable: patch };
    patchFormState(newFormState);
  }

  function formatNumber(num: any) {
    return num.toLocaleString('fi-FI', {
      minimumFractionDigits: 1,
      maximumFractionDigits: 2,
    });
  }

  const tableColumns: TableColumnDefs<checkTableColumn> = [
    {
      accessorKey: 'label',
      header: () => 'Ostovelat',
      className: 'text-left width-middle',
      placeholder: t(`${tBase}.purchases`) + '...',
      onEdit: (editProps: OnEditProps<string>) => {
        handleEdit<string>(editProps);
      },
    },
    {
      accessorKey: 'date',
      header: () => 'Päivämäärä',
      className: 'text-center width-middle',
      placeholder: '31.12.202X - 30.6.202X',
      onEdit: (editProps: OnEditProps<string>) => {
        handleEdit<string>(editProps);
      },
    },
    {
      accessorKey: 'rekontra',
      header: () => t(`${tBase}.rekontra`), // + ' €',
      className: 'text-center width-middle',
      onNumberEdit: (editProps: OnEditProps<number>) =>
        handleEdit<number>(editProps),
      cell: ({ cell }) => (
        <div>{formatNumber(cell.row.original.rekontra)} €</div>
      ),
    },
    {
      accessorKey: 'confirm',
      header: () => t(`${tBase}.confirm`), // + ' €',
      className: 'text-center width-middle',
      onNumberEdit: (editProps: OnEditProps<number>) =>
        handleEdit<number>(editProps),
      cell: ({ cell }) => (
        <div>{formatNumber(cell.row.original.confirm)} €</div>
      ),
    },
    {
      accessorKey: 'minus',
      header: () => t(`${tBase}.minus`),
      className: 'text-center width-middle',
      //onEdit: (editProps: OnEditProps<string>) => handleEdit<string>(editProps),
      cell: ({ cell }) => <div>{formatNumber(cell.row.original.minus)} €</div>,
    },
  ];

  const handleAddNewRow = () => {
    patchFormState({
      ...formState,
      checkTable: [
        ...(formState.checkTable ?? []),
        {
          label: '',
          id: uuid(), //Math.floor(Math.random()*100).toString(),
          account: '',
          date: '',
          rekontra: 0,
          confirm: 0,
          minus: 0,
        },
      ],
    });
  };

  const handleDeleteRow = ({ id, label }: checkTableColumn) => {
    if (confirmDelete(label ?? '')) {
      const patch = (formState.checkTable ?? []).filter(row => row.id !== id);
      patchFormState({ ...formState, checkTable: patch });
    }
  };

  return (
    <>
      <AuditingTable<checkTableColumn>
        sectionKey={sectionKey}
        onAddNewRow={handleAddNewRow}
        data={data}
        columns={tableColumns}
        onRowDelete={handleDeleteRow}
        showGlobalFilter={false}
        disableSort
      />
      <Container>
        <div className="additional-info">
          Erotus: Saldo reskontralla tai erilliskirjanpidossa - Saldo
          pääkirjanpidossa
        </div>
      </Container>
    </>
  );
};

export const CreditorsTableColumn = ({
  formState,
  patchFormState,
}: FormFieldProps<TradeCreditorsForm>) => {
  const { t } = useTranslation();

  if (!formState) return null;

  const tBase = `auditing:form.${sectionKey}.creditorsTable`;

  const data = formState.creditorsTable ?? [];

  function handleEdit<T>({
    itemId,
    rowIndex,
    columnId,
    value,
  }: OnEditProps<T>) {
    const patch = formState.creditorsTable.map((row, index) => {
      const idMatch = itemId !== undefined && itemId === row.id;
      const rowIndexMatch = itemId === undefined && rowIndex === index;
      if (idMatch || rowIndexMatch) return { ...row, [columnId]: value };
      return row;
    });
    patchFormState({ ...formState, creditorsTable: patch });
  }

  const tableColumns: TableColumnDefs<creditorsTableColumn> = [
    {
      accessorKey: 'label',
      header: () => '',
      className: 'text-center width-half',
      placeholder: t(`${tBase}.purchases`) + '...',
      onEdit: (editProps: OnEditProps<string>) => {
        handleEdit<string>(editProps);
      },
    },
    {
      accessorKey: 'CY',
      header: () => t(`${tBase}.CY`) + ' €',
      className: 'text-center width-middle',
      onNumberEdit: (editProps: OnEditProps<number>) =>
        handleEdit<number>(editProps),
      cell: ({ cell }) => <div>{cell.row.original.CY} €</div>,
    },
    {
      accessorKey: 'PY',
      header: () => t(`${tBase}.PY`) + ' €',
      className: 'text-center width-middle',
      onNumberEdit: (editProps: OnEditProps<number>) =>
        handleEdit<number>(editProps),
      cell: ({ cell }) => <div>{cell.row.original.PY} €</div>,
    },
  ];

  const handleAddNewRow = () => {
    patchFormState({
      ...formState,
      creditorsTable: [
        ...(formState.creditorsTable ?? []),
        {
          label: '',
          id: uuid(), //Math.floor(Math.random()*100).toString(),
          CY: 0,
          PY: 0,
        },
      ],
    });
  };

  const handleDeleteRow = ({ id, label }: creditorsTableColumn) => {
    if (confirmDelete(label ?? '')) {
      const patch = (formState.creditorsTable ?? []).filter(
        row => row.id !== id
      );
      patchFormState({ ...formState, creditorsTable: patch });
    }
  };

  return (
    <>
      <AuditingTable<creditorsTableColumn>
        sectionKey={sectionKey}
        title="Tilit, joita työpari koskee:"
        onAddNewRow={handleAddNewRow}
        data={data}
        columns={tableColumns}
        onRowDelete={handleDeleteRow}
        showGlobalFilter={false}
        disableSort
      />
    </>
  );
};

export const CalculationBaseColumn = ({
  formState,
  patchFormState,
}: FormFieldProps<TradeCreditorsForm>) => {
  const { t } = useTranslation();

  if (!formState) return null;

  const tBase = `auditing:form.${sectionKey}.calculationBaseTable`;

  const data = formState.calculationBaseTable ?? [];

  function handleEdit<T>({
    itemId,
    rowIndex,
    columnId,
    value,
  }: OnEditProps<T>) {
    const patch = formState.calculationBaseTable.map((row, index) => {
      const idMatch = itemId !== undefined && itemId === row.id;
      const rowIndexMatch = itemId === undefined && rowIndex === index;
      if (idMatch || rowIndexMatch) {
        const newRow = { ...row, [columnId]: value };
        if (columnId === 'rekontra' || columnId === 'confirm') {
          const { rekontra, confirm } = newRow || 0;
          const minus = rekontra - confirm || 0;
          newRow.minus = minus;
          formatNumber(newRow.minus);
        }
        return newRow;
      }
      return row;
    });
    const newFormState = { ...formState, calculationBaseTable: patch };
    patchFormState(newFormState);
  }

  function formatNumber(num: any) {
    return num.toLocaleString('fi-FI', {
      minimumFractionDigits: 1,
      maximumFractionDigits: 2,
    });
  }

  const tableColumns: TableColumnDefs<calculationBaseColumn> = [
    {
      accessorKey: 'label',
      header: () => 'Asiakas',
      className: 'text-left width-middle',
      placeholder: t(`${tBase}.purchases`) + '...',
      onEdit: (editProps: OnEditProps<string>) => {
        handleEdit<string>(editProps);
      },
    },
    {
      accessorKey: 'rekontra',
      header: () => t(`${tBase}.rekontra`),
      className: 'text-center width-middle',
      onNumberEdit: (editProps: OnEditProps<number>) =>
        handleEdit<number>(editProps),
    },
    {
      accessorKey: 'confirm',
      header: () => t(`${tBase}.confirm`),
      className: 'text-center width-middle',
      onNumberEdit: (editProps: OnEditProps<number>) =>
        handleEdit<number>(editProps),
    },
    {
      accessorKey: 'minus',
      header: () => t(`${tBase}.minus`),
      className: 'text-center width-middle',
    },
    {
      accessorKey: 'other',
      header: () => t(`${tBase}.other`),
      className: 'text-center width-middle',
      onEdit: (editProps: OnEditProps<string>) => handleEdit<string>(editProps),
    },
  ];

  const handleAddNewRow = () => {
    const newRow: calculationBaseColumn = {
      label: '',
      id: uuid(),
      account: '',
      rekontra: 0,
      confirm: 0,
      minus: 0,
      other: '',
    };
    const patch = [...(formState.calculationBaseTable ?? []), newRow];
    const newFormState = { ...formState, calculationBaseTable: patch };
    patchFormState(newFormState);
  };

  const handleDeleteRow = ({ id, label }: calculationBaseColumn) => {
    if (confirmDelete(label ?? '')) {
      const patch = (formState.calculationBaseTable ?? []).filter(
        row => row.id !== id
      );
      patchFormState({ ...formState, calculationBaseTable: patch });
    }
  };

  return (
    <>
      <AuditingTable<calculationBaseColumn>
        sectionKey={sectionKey}
        title="Ostovelkojen vahvistukset"
        onAddNewRow={handleAddNewRow}
        data={data}
        columns={tableColumns}
        onRowDelete={handleDeleteRow}
        showGlobalFilter={false}
        disableSort
      />
      <Container>
        <div className="additional-info">
          Erotus: Saldo reskontralla - Saldo vahvistuksella
        </div>
      </Container>
    </>
  );
};
