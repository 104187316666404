import OtpInput from "react-otp-input";
import { observer } from "mobx-react-lite";
import Logo from "../components/Logo";
import styled from "styled-components";
import { useState } from "react";
import { useStore } from "../store/RootContext";

const OTPContainer = styled.div`  
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
    text-align: center;

    form {
    width: 400px;

    > div {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        margin: ${p => p.theme.spacing.lg} 0;
    }

    button {
        margin-left: 5px;
        margin-right: 5px;
        margin-top: 5px;
        margin-bottom: 5px;
        background-color: #00a6ff;
        width: 65%;
        height: 50px;
        border-radius: 20px;
        color: #fff;
        border: 1px solid #ccc;
        outline: none;
        
        &:focus {
            border-color: #007bff;
        }
    }

    input {
        width: 3rem!important;
        height: 50px;
        text-align: center;
        font-size: 20px;
        margin: 0 10px;
        border: 1px solid #ccc;
        border-radius: 5px;
        outline: none;

        &:focus {
            border-color: #007bff;
        }
    }

    p {
        margin-bottom: 0px;
    }
}`;

export const OTPage: React.FC = observer(() => {

    const [otp, setOtp] = useState('');

    const { authStore: { otpcheck, logout }, userStore: { getOTP } } = useStore();

    // Fetch OTP secret
    getOTP();

    // Handle logout
    const handleSubmit = (event: any) => {
        event.preventDefault();
        event.stopPropagation();
        logout();
    };

    // Handle OTP check
    const handleOTPCheck = (event: any) => {
        event.preventDefault();
        event.stopPropagation();
        otpcheck({ otpSecret: otp });
    };
    const handleOtpChange = (otp: string) => {
        setOtp(otp.toUpperCase());
    }

    const handleFormSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        handleOTPCheck(event);
    };

    return (
        <>
        <OTPContainer>
            <form onSubmit={handleFormSubmit}>
                <Logo height={90} />
                
                <p>Sinulle on lähetetty <b>6 - numeroinen</b> koodi <br></br>Yhteydenotto sähköpostiosoitteeseesi.</p>
                <p><i>Tarkista varalta Roskaposti, jos et saanut viestiä.</i></p>
                <div>
                    <OtpInput
                        value={otp}
                        onChange={handleOtpChange}
                        numInputs={6}
                        renderSeparator={<span>-</span>}
                        renderInput={(props) => <input {...props} />}
                    />
                </div>
                <div>
                    <button type="button" onClick={handleSubmit}>Kirjaudu ulos</button>
                    <button type="submit">Tarkista</button>
                </div>
            </form>
        </OTPContainer>
        </>
    );
});