import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { useStore } from '../../store/RootContext';
import { AUDITING_STATUS_OPTIONS, confirm } from '../../utils';
import { Select } from '../inputs';
import StatusBadge from '../StatusBadge';
import StatusHistory, { StatusType } from '../StatusHistory';

const SelectContainer = styled.div`
  position: relative;

  .badge-container {
    position: absolute;
    left: 216px;
    bottom: -20px;
    z-index: 3;
  }
`;

const AuditingStatus: React.FC = () => {
  const { t } = useTranslation();

  const {
    auditingStore: { auditing, auditingStatus, editEntity },
  } = useStore();

  const [status, setStatus] = useState<AuditingStatus | undefined>();

  useEffect(() => {
    if (auditingStatus) setStatus(auditingStatus);
  }, [auditingStatus]);

  const handleStatusChange = (
    newStatus: React.SetStateAction<AuditingStatus | undefined>
  ) => {
    const confirmed =
      newStatus === 'approved' ? confirm(t('auditing:approvedConfirm')) : true;

    if (confirmed) {
      setStatus(newStatus);
      if (auditing?.id && typeof newStatus === 'string') {
        editEntity({ id: auditing.id, data: { status: newStatus } });
      }
    }
  };

  return (
    <>
      <SelectContainer>
        <Select<AuditingStatus>
          label={t('auditing:status.label')}
          options={AUDITING_STATUS_OPTIONS}
          value={status}
          setValue={handleStatusChange}
          displayValue={(status?: AuditingSectionStatus) =>
            status ? t(`auditing:status.${status}`) : ''
          }
          disabled={auditingStatus === 'approved'}
        />
        <div className="badge-container">
          <StatusBadge status={status} isSmall />
        </div>
      </SelectContainer>
      <StatusHistory {...auditing} hidden={[StatusType.updated]} />
    </>
  );
};

export default AuditingStatus;
