import { lazy } from 'react';
import { path, rt } from '../i18n/utils';
import { Role } from '../utils/constants';
import { notFoundRoute } from './routes.common';

const Customer = lazy(() => import('../views/Customer'));
const CustomerRaport = lazy(() => import('../views/CustomerRaport'));

const customerRoutes: Route[] = [
    {
        path: path('customer.customerCompany'),
        title: rt('customer.customerCompany'),
        Element: <Customer />,
        roles: [Role.Customer],
        iconType: 'CustomerCompany',
    },
    {
        path: path('customer.customerRaport'),
        title: rt('customer.customerRaport'),
        Element: <CustomerRaport />,
        roles: [Role.Customer],
        iconType: 'Pdf',
    },
    notFoundRoute,
];

export default customerRoutes;