import { observer } from 'mobx-react-lite';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { path } from '../../i18n/utils';
import { useStore } from '../../store/RootContext';
import { Button } from '../inputs';
import StatusBadge from '../StatusBadge';

const Container = styled.div`
  position: relative;
  flex: 0 0 ${p => p.theme.ui.headerHeight}px;
  padding: 0 ${p => p.theme.spacing.xxl};
  ${p => p.theme.font.size[20]};

  /* Draw the line under header */
  &:before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 3%;
    right: 3%;
    width: 94%;
    height: 1px;
    border-bottom: 1px solid ${p => p.theme.color.secondary};
  }
`;

const AuditingHeader: React.FC = observer(() => {
  const { t } = useTranslation(['auditing']);
  const navigate = useNavigate();

  const {
    appStore: {
      windowDimensions: { isTablet },
    },
    auditingStore: { companyName, auditingStatus, getFinancialYear },
  } = useStore();

  const handleChangeAuditingContext = () => {
    navigate(`/${path('auditing')}`);
  };

  const changeContextButton = (
    <Button
      text={t('action:change')}
      onClick={handleChangeAuditingContext}
      variant="outlined"
      minWidth
    />
  );

  const itemClassName = 'flex-row flex-row-gap justify-start';
  const yearClassName = !isTablet ? 'text-center' : 'text-right';

  return (
    <Container className="flex-row flex-row-gap">
      <div className={itemClassName}>
        <div>{companyName}</div>
        <div>{changeContextButton}</div>
      </div>

      <div className={`${itemClassName} ${yearClassName}`}>
        <StatusBadge status={auditingStatus} animate />
        <div>{`${t('financialYear')}: ${getFinancialYear()}`}</div>
        <div>{changeContextButton}</div>
      </div>
    </Container>
  );
});

export default AuditingHeader;
