import { displayAuditingProcedures } from '../../../components/auditing/AuditingProcedureItem2';
import { SamplingTableColumn } from '../../../components/auditing/turnOver.SamplingTable';
import auditingJson from '../../../i18n/locales/fi/auditing.json';
import { FormFieldBuilder } from '../FormFieldBuilder';
import { ProcedureAccountsElement } from './reUsables/ProcedureAccountsElement';
import { SpreadSheet } from './reUsables/SpreadSheet';
import { TableColumn } from './reUsables/TableColumn';
import { t } from '../../../i18n';
import {
  innerCircleElement,
  innerCircleElementDFS,
} from './reUsables/innerCircle';

type SectionFormType = UseInnerCircle<OtherOperatingIncomeForm>;

const sectionKey: AuditingSectionKey = 'otherOperatingIncome';
export const tBase = `auditing:form.${sectionKey}`;

const tSamplingTable = 'auditing:form.materialsAndServices.samplingTable';
const samplingLargestUnits = t(`${tSamplingTable}.largestUnits`);
const samplingRandomUnits = t(`${tSamplingTable}.randomUnits`);
const monetarySampling = t(`${tSamplingTable}.monetary`);
const otherSamplingProcedure = t(`${tSamplingTable}.otherSamplingProcedure`);

const batchString = 'JB-otherOperatingIncome';

const formBuilder = new FormFieldBuilder<SectionFormType>({ sectionKey });

const defaultFormState: DefaultFormState<SectionFormType> = ({ auditing }) => {
  return {
    procedureAccountsManualData: [
      {
        currentYear: 0,
        priorYear: 0,
        accountName: '0000...',
        comment: '',
      },
    ],
    procedureAccountsData: {},
    firstTable: [
      {
        id: '1',
        account: '',
        CY: 0,
        PY: 0,
        other: '',
      },
    ],
    samplingTable: [
      {
        id: '1',
        label: samplingLargestUnits,
        one: false,
        more: false,
      },
      {
        id: '2',
        label: samplingRandomUnits,
        one: false,
        more: false,
      },
      {
        id: '3',
        label: monetarySampling,
        one: false,
        more: false,
      },
      {
        id: '4',
        label: otherSamplingProcedure,
        one: false,
        more: false,
      },
    ],
    tableColumnData: [
      {
        left: 'Kirjatut tapahtumat ovat toteutuneet ja kuuluvat yhteisölle.',
        right: auditingJson.TAP,
      },
      {
        left: 'Kaikki tapahtumat, jotka olisi tullut kirjata on kirjattu.',
        right: auditingJson.TAY,
      },
      {
        left: 'Muut tuotot on kirjattu oikeassa määrässä.',
        right: auditingJson.OIK_ARV,
      },
      {
        left: 'Tilikaudelle on kirjattu vain suoriteperusteisesti sille kuuluvat tuotot.',
        right: auditingJson.KAT,
      },
      {
        left: 'Muihin tuottoihin on luokiteltu ainoastaan sinne kuuluvia eriä.',
        right: auditingJson.LUOK,
      },
      {
        left: 'Tapahtumat on yhdistelty ja jaoteltu oikein / ne on kuvattu selvästi, ja/tai niihin liittyvät tiedot tilinpäätöksessä ovat relevantteja ja ymmärrettäviä sovellettavan tilinpäätösnormiston mukaan.',
        right: auditingJson.ESI,
      },
    ],
    textArea:
      'Suoritetuilla tarkastustoimenpiteillä on saatu riittävä evidenssi liiketoiminnan muiden tuottojen oikeellisuudesta ja tarkastuksen tavoitteet on saavutettu. Suoritetun tarkastuksen perusreella liiketoiminnan muut tuotot ovat olennaisin osin oikein.',

    largestUnitsTextField:
      'Valitaan tarkastukseen olennaiset, yli PM:n ylittävät yksittäiset tapahtumat. Tarkastukseen valitut tapahtumat kattavat yhteensä XX eur eli X % koko populaatiosta.',
    randomUnitsTextField:
      'Kuvaa, millä perustein otoskoko on katsottu riittäväksi.',
    monetaryTextField:
      'Tarkastettavat yksiköt on valittu monetary unit samplingilla. Käytetyllä otantamenetelmällä suuremmilla laskuilla on suurempi todennäköisyys tulla valituiksi. Viite tiedostoon, jossa otanta määritetty esim. JB.',
    otherSamplingProcedureTextField:
      'Tarkastettavat satunnaiset yksiköt on valittu monetary unit sampling -otannalla. Otannan perusteet ja määrittelyt ks XX MUS\nOtoskoon määrittelyyn on käytetty otantatyökalua ks. otoskoon määrittely JB1 XX',

    // Sheets
    useSheet: null,
    sheetdata1: [
      {
        cellData: [],
      },
    ],
    useSheetTab: null,
    ...innerCircleElementDFS,
  };
};

const formFields: FormFields<SectionFormType> = ({
  formState,
  auditing,
  store,
}) => [
  // First Area
  formBuilder.group({
    items: [
      formBuilder.boolean({
        accessor: 'useSheetTab',
        plainLabel: 'Käytä laskenta taulukko osiota',
      }),
      formBuilder.custom(props => (
        <ProcedureAccountsElement
          {...props}
          auditing={auditing}
          batchString={batchString}
          addCommentField={true}
        />
      )),
      formBuilder.custom(props => (
        <TableColumn
          title="Tarkastuksen tavoitteeina on varmistua seuraavista seikoista / kannanotoista:"
          {...props}
        />
      )),
      formBuilder.textArea({
        accessor: 'textArea',
      }),
    ],
  }),

  ...displayAuditingProcedures(store, batchString, formBuilder, [
    {
      accordionId: 'individualTransactionInspection',
      lowerElements: [
        formBuilder.boolean({
          accessor: 'useSheet',
          plainLabel: auditingJson.useSheet,
        }),
        formBuilder.custom(props =>
          props.formState.useSheet ? (
            <SpreadSheet id="sheetdata1" {...props} />
          ) : null
        ),
      ],
      upperElements: [
        formBuilder.custom(props => <SamplingTableColumn {...props} />),
        formBuilder.textArea({
          accessor: 'largestUnitsTextField',
          hidden: !formState.samplingTable.some(
            item =>
              item.label === samplingLargestUnits && (item.one || item.more)
          ),
        }),
        formBuilder.textArea({
          accessor: 'randomUnitsTextField',
          hidden: !formState.samplingTable.some(
            item =>
              item.label === samplingRandomUnits && (item.one || item.more)
          ),
        }),
        formBuilder.textArea({
          accessor: 'otherSamplingProcedureTextField',
          hidden: !formState.samplingTable.some(
            item =>
              (item.label === monetarySampling ||
                item.label === otherSamplingProcedure) &&
              (item.one || item.more)
          ),
        }),
      ],
    },
    {
      // Lähipiiri
      useDefault: false,
      accordionId: 'innerCircle',
      upperElements: innerCircleElement(formBuilder),
    },
  ]),
];

const section: AuditingSectionDefinition<SectionFormType> = {
  sectionKey,
  defaultFormState,
  formFields,
};

export default section;
