import appRoutes from '../../router/routes';
import { useStore } from '../../store/RootContext';
/* import { hasWagesAndSalariesAccessor } from '../../views/auditingSections/remarkRuleUtils'; */
import { AuditingTemplate, OMITTED_SECTIONS } from '../auditing';
import { getUserRoutes } from '../route';
import { isTabChosenInDetailedPlan } from '../auditing';

const omitSections =
  (template?: AuditingTemplate) =>
  ({ id }: Route) => {
    // In this case, route id should always match auditing section key, thus casting should be safe.
    const sectionKey = id as AuditingSectionKey;
    if (!template) return true;
    if (!sectionKey) return false;
    return !OMITTED_SECTIONS[template].includes(
      sectionKey as AuditingSectionKey
    );
  };

export const useGroupedAuditingRoutes = (template?: AuditingTemplate) => {
  const {
    authStore: { role },
    auditingStore: { auditing },
  } = useStore();

  const auditingRoutes = getUserRoutes(appRoutes.auditingRoutes, role.type)
    .filter(({ isHidden }) => !isHidden)
    .filter(omitSections(template))
    // Filter to show only the sections that are chosen in the detailed plan or are sheets
    .filter(
      item =>
        isTabChosenInDetailedPlan(auditing, item.id!, item.category as any) ||
        item.id?.includes('Sheet')
    );

  const hasCategory = (category: AuditingCategory) => (item: Route) =>
    item.category?.includes(category);

  return {
    // Kategoria
    start: auditingRoutes.filter(hasCategory('start')),
    planning: auditingRoutes.filter(hasCategory('planning')),
    auditing: auditingRoutes.filter(hasCategory('auditing')),
    reporting: auditingRoutes.filter(hasCategory('reporting')),

    essentials: auditingRoutes.filter(hasCategory('essentials')),
    inspection: auditingRoutes.filter(hasCategory('inspection')),
    analyzes: auditingRoutes.filter(hasCategory('analyzes')),
    scoping: auditingRoutes.filter(hasCategory('scoping')),

    end: auditingRoutes.filter(hasCategory('end')),
  };
};
