import { t } from '../../../i18n';
import { hasShortcomings } from '../auditing/auditingProceduresUtils';
import {
  AuditingReportFormat,
  OtherInformationIncludedInAuditingReport,
  OtherReportingOblications,
  OtherReportingToManagement,
  QualityControlConfirmations,
  SignOffConfirmations,
} from './communicationAndReportingUtils';
import { AuditorInformationKind } from '../planning/permanentDataUtils';
import { FormFieldBuilder } from '../FormFieldBuilder';

type SectionFormType = CommunicationAndReportingForm;

const sectionKey: AuditingSectionKey = 'communicationAndReporting';

export const tBase = `auditing:form.communicationAndReporting`;

const formBuilder = new FormFieldBuilder<SectionFormType>({ sectionKey });

const defaultFormState: DefaultFormState<SectionFormType> = ({ auditing }) => {
  const auditingProcedures =
    auditing.auditingProcedures?.form.auditingProcedures;

  const chiefAuditor = auditing.permanentData?.form.auditorInformation?.find(
    ({ kind }) => kind === AuditorInformationKind.chiefAuditor
  )?.value;

  return {
    // PART I (Communication)
    errorsDetected: auditingProcedures?.some(hasShortcomings) ?? null,
    errorsCommunicatedToManagement: null,
    errorsCommunicatedToManagementDetails: '',
    errorsFixedByManagement: null,
    errorsNotFixedForReasonableReasons: null,
    errorsNotFixedForReasonableReasonsDetails: '',
    areUnfixedErrorsRelevant: null,
    receivedConfirmationNotificationLetter: null,
    receivedConfirmationNotificationLetterDetails: '',

    // PART II (Reporting)
    auditingReportFormat: null,
    provideAdditionalInformationInAuditingReport: null,
    provideAdditionalInformationInAuditingReportDetails: '',
    addAnnotationToAuditingReport: null,
    addAnnotationToAuditingReportDetails: '',
    otherInformationIncludedInAuditingReport: null,
    otherInformationIncludedInAuditingReportDetails: '',
    otherReportingOblications: null,
    otherReportingOblicationsDetails: '',
    otherReportingToManagement: null,
    otherReportingToManagementDetails: '',

    // PART III (Sign-off)
    documentationReviewedDate: null,
    documentationReviewedBy: chiefAuditor ?? '',
    signOffConfirmations: {
      topic1: true,
      topic2: true,
      topic3: true,
      topic4: true,
      topic5: true,
      topic6: true,
      topic7: true,
      topic8: true,
      topic9: true,
      topic10: true,
    },
    isa240Confirmation: true,
    isa240Details: t(
      'auditing:form.communicationAndReporting.isa240DetailsInfo'
    ),
    documentationReviewedDetails: '',
    needForQualityControl: null,
    qualityControlDate: null,
    qualityControlBy: '',
    qualityControlConfirmations: {
      topic1: true,
      topic2: true,
      topic3: true,
      topic4: true,
      topic5: true,
      topic6: true,
    },
    qualityControlDetails: '',
  };
};

const formFields: FormFields<SectionFormType> = ({ formState }) => [
  /**
   * PART I (Communication)
   */

  formBuilder.accordionGroup({
    titleKey: 'communication',
    open: true,
    items: [
      formBuilder.boolean({
        accessor: 'errorsDetected',
        options: ['no', 'yes'],
      }),
      formBuilder.boolean({
        accessor: 'errorsCommunicatedToManagement',
        options: ['no', 'yes'],
        detailsAccessor: 'errorsCommunicatedToManagementDetails',
        hidden: !formState.errorsDetected,
        showDetailsContextInfo: 'top-right',
      }),
      formBuilder.boolean({
        accessor: 'errorsFixedByManagement',
        options: ['no', 'yes'],
        hidden: !formState.errorsDetected,
      }),
      formBuilder.boolean({
        accessor: 'errorsNotFixedForReasonableReasons',
        options: ['no', 'yes'],
        detailsAccessor: 'errorsNotFixedForReasonableReasonsDetails',
        hidden:
          !formState.errorsDetected ||
          formState.errorsFixedByManagement !== false,
      }),
      formBuilder.boolean({
        accessor: 'areUnfixedErrorsRelevant',
        options: ['no', 'yes'],
      }),
      formBuilder.boolean({
        accessor: 'receivedConfirmationNotificationLetter',
        options: ['no', 'yes'],
        detailsAccessor: 'receivedConfirmationNotificationLetterDetails',
        showDetailsContextInfo: 'top-right',
      }),
    ],
  }),

  /**
   * PART II (Reporting)
   */

  formBuilder.accordionGroup({
    titleKey: 'reporting',
    open: true,
    items: [
      formBuilder.radioGroup({
        accessor: 'auditingReportFormat',
        options: Object.values(AuditingReportFormat),
      }),
      formBuilder.boolean({
        accessor: 'provideAdditionalInformationInAuditingReport',
        options: ['no', 'yes'],
        detailsAccessor: 'provideAdditionalInformationInAuditingReportDetails',
        detailsHidden: !formState.provideAdditionalInformationInAuditingReport,
      }),
      formBuilder.boolean({
        accessor: 'addAnnotationToAuditingReport',
        options: ['no', 'yes'],
        detailsAccessor: 'addAnnotationToAuditingReportDetails',
        detailsHidden: !formState.addAnnotationToAuditingReport,
      }),
      formBuilder.radioGroup({
        accessor: 'otherInformationIncludedInAuditingReport',
        options: Object.values(OtherInformationIncludedInAuditingReport),
        detailsAccessor: 'otherInformationIncludedInAuditingReportDetails',
        detailsHidden:
          formState.otherInformationIncludedInAuditingReport !==
          OtherInformationIncludedInAuditingReport.yesWithErrors,
      }),
      formBuilder.radioGroup({
        accessor: 'otherReportingOblications',
        options: Object.values(OtherReportingOblications),
        detailsAccessor: 'otherReportingOblicationsDetails',
        showDetailsContextInfo: 'top-right',
        detailsHidden:
          formState.otherReportingOblications !==
          OtherReportingOblications.otherReportingOblications,
      }),
      formBuilder.radioGroup({
        accessor: 'otherReportingToManagement',
        options: Object.values(OtherReportingToManagement),
        detailsAccessor: 'otherReportingToManagementDetails',
        showDetailsContextInfo: 'top-right',
        detailsHidden:
          formState.otherReportingToManagement !==
          OtherReportingToManagement.other,
      }),
    ],
  }),

  /**
   * PART II (Sign-off)
   */

  formBuilder.accordionGroup({
    titleKey: 'signOff',
    open: true,
    items: [
      formBuilder.group({
        items: [
          formBuilder.groupRow({
            items: [
              formBuilder.dateField({ accessor: 'documentationReviewedDate' }),
              formBuilder.textInput({ accessor: 'documentationReviewedBy' }),
            ],
          }),
          formBuilder.checkboxGroup({
            accessor: 'signOffConfirmations',
            options: Object.values(SignOffConfirmations),
          }),
          formBuilder.textArea({ accessor: 'isa240Details' }),
          formBuilder.textArea({
            accessor: 'documentationReviewedDetails',
            showContextInfo: 'top-right',
          }),
        ],
      }),

      formBuilder.boolean({
        accessor: 'needForQualityControl',
        options: ['no', 'yes'],
      }),

      formBuilder.group({
        hidden: !formState.needForQualityControl,
        items: [
          formBuilder.groupRow({
            items: [
              formBuilder.dateField({ accessor: 'qualityControlDate' }),
              formBuilder.textInput({ accessor: 'qualityControlBy' }),
            ],
          }),
          formBuilder.checkboxGroup({
            accessor: 'qualityControlConfirmations',
            options: Object.values(QualityControlConfirmations),
          }),

          formBuilder.textArea({
            accessor: 'qualityControlDetails',
            showContextInfo: 'top-right',
          }),
        ],
      }),
    ],
  }),
];

const section: AuditingSectionDefinition<SectionFormType> = {
  sectionKey,
  defaultFormState,
  formFields,
  depending: ['auditingProcedures'],
};

export default section;
