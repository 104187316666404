import { useTranslation } from 'react-i18next';
import { confirmDelete, uuid } from '../../utils';
import AuditingTable from '../table/AuditingTable';
import { OnEditProps, TableColumnDefs } from '../table/Table';
import styled from 'styled-components';

const Container = styled.div`
  .additional-info {
    font-style: italic;
    color: ${p => p.theme.color.grey400};
    text-align: right;
  }
`;

const sectionKey: AuditingSectionKey = 'reserves';

export const ReservesTableColumn = ({
  formState,
  patchFormState,
}: FormFieldProps<ReservesForm>) => {
  const { t } = useTranslation();

  if (!formState) return null;

  const tBase = `auditing:form.${sectionKey}.reservesTable`;

  const data = formState.reservesTable ?? [];

  function handleEdit<T>({
    itemId,
    rowIndex,
    columnId,
    value,
  }: OnEditProps<T>) {
    const patch = formState.reservesTable.map((row, index) => {
      const idMatch = itemId !== undefined && itemId === row.id;
      const rowIndexMatch = itemId === undefined && rowIndex === index;
      if (idMatch || rowIndexMatch) {
        const newRow = { ...row, [columnId]: value };
        if (columnId === 'generalLedger' || columnId === 'confirmation') {
          let { generalLedger, confirmation } = newRow || 0;
          let minus = generalLedger - confirmation || 0;
          newRow.minus = minus;
          formatNumber(newRow.minus);
        }
        return newRow;
      }
      return row;
    });
    patchFormState({ ...formState, reservesTable: patch });
  }

  function formatNumber(num: any) {
    return num.toLocaleString('fi-FI', {
      minimumFractionDigits: 1,
      maximumFractionDigits: 2,
    });
  }

  const tableColumns: TableColumnDefs<ReservesTable> = [
    {
      accessorKey: 'label',
      header: () => t(`${tBase}.label`),
      className: 'text-left width-middle',
      placeholder: t(`${tBase}.placement`) + '...',
      onEdit: (editProps: OnEditProps<string>) => {
        handleEdit<string>(editProps);
      },
    },
    {
      accessorKey: 'generalLedger',
      header: () => t(`${tBase}.generalLedger`),
      className: 'text-center width-middle',
      onNumberEdit: (editProps: OnEditProps<number>) =>
        handleEdit<number>(editProps),
      cell: ({ cell }) => (
        <div>{formatNumber(cell.row.original.generalLedger)} €</div>
      ),
    },
    {
      accessorKey: 'confirmation',
      header: () => t(`${tBase}.confirmation`),
      className: 'text-center width-middle',
      onNumberEdit: (editProps: OnEditProps<number>) =>
        handleEdit<number>(editProps),
      cell: ({ cell }) => (
        <div>{formatNumber(cell.row.original.confirmation)} €</div>
      ),
    },
    {
      accessorKey: 'minus',
      header: () => t(`${tBase}.minus`),
      className: 'text-center width-middle',
      cell: ({ cell }) => <div>{formatNumber(cell.row.original.minus)} €</div>,
    },
    {
      accessorKey: 'other',
      header: () => t(`${tBase}.other`),
      className: 'text-left width-middle',
      placeholder: t(`${tBase}.other`) + '...',
      onEdit: (editProps: OnEditProps<string>) => {
        handleEdit<string>(editProps);
      },
    },
  ];

  const handleAddNewRow = () => {
    patchFormState({
      ...formState,
      reservesTable: [
        ...(formState.reservesTable ?? []),
        {
          id: uuid(), //Math.floor(Math.random()*100).toString(),
          label: '',
          generalLedger: 0,
          confirmation: 0,
          minus: 0,
          other: '',
        },
      ],
    });
  };

  const handleDeleteRow = ({ id, label }: ReservesTable) => {
    if (confirmDelete(label ?? '')) {
      const patch = (formState.reservesTable ?? []).filter(
        row => row.id !== id
      );
      patchFormState({ ...formState, reservesTable: patch });
    }
  };

  return (
    <>
      <AuditingTable<ReservesTable>
        sectionKey={sectionKey}
        title="Saldovahvistukset:"
        onAddNewRow={handleAddNewRow}
        data={data}
        columns={tableColumns}
        onRowDelete={handleDeleteRow}
        showGlobalFilter={false}
        disableSort
      />
      <Container>
        <div className="additional-info">
          Erotus: Saldo pääkirjalla - Saldo vahvistuksella
        </div>
      </Container>
    </>
  );
};
