const FOREGROUND = '#020026';
const BACKGROUND = '#FFFFFF';

export const Colors = Object.freeze({
  primary: '#56a7ea',
  primary100: '#d2e7f9',
  primary200: '#a4d0f4',
  primary300: '#77b8ee',
  primary400: '#56a7ea',
  primary500: '#3395e6',
  primary600: '#1c89e3',
  primary700: '#166eb6',
  primary800: '#115288',
  primary900: '#0b375b',

  secondary: '#22025E',
  secondary100: '#bd9bfd',
  secondary200: '#ad82fc',
  secondary300: '#8c50fb',
  secondary400: '#6b1efa',
  secondary500: '#5205e1',
  secondary600: '#4004af',
  secondary700: '#2d037d',
  secondary800: '#22025E',
  secondary900: '#120132',

  grey: '#E0E0E0',
  grey100: '#F2F2F2',
  grey200: '#E0E0E0',
  grey300: '#BDBDBD',
  grey400: '#828282',
  grey500: '#4F4F4F',
  grey600: '#333333',

  accent: '#56a7ea',

  foreground: FOREGROUND,
  background: BACKGROUND,

  heading: FOREGROUND,
  text: FOREGROUND,

  error: '#e74c3c',
  c70039: '#C70039',

  success: '#07bc0c',
  success100: '#b5fcb8',
  success200: '#84fb88',
  success300: '#53f958',
  success400: '#22f729',
  success500: '#08dd0f',
  success600: '#07bc0c',

  green: '#eafaea',
  yellow: '#fafaea',
  fada5e: '#FADA5E',
  orange: '#faf2ea',
  red: '#faeaea',

  transparent: 'transparent',
});
