import { findByKey } from '../../../utils/array';
import {
  getValueOrDefaultValue,
  makeEmptyThresholdTestRow,
} from './auditingProceduresUtils';

/**
 * Define rows for auditing procedure unit rows.
 */
export const matchingOfWagesToAnnualReportRows = () => [
  makeEmptyThresholdTestRow({
    key: 'totalPayrollInAccounting',
    unit: '€',
    disabled: true,
  }),
  makeEmptyThresholdTestRow({
    key: 'sickLeaveCompensationAndOther',
    unit: '€',
    useCustomValue: true,
  }),
  makeEmptyThresholdTestRow({
    key: 'otherCreditPostingsToSalaryAccounts',
    unit: '€',
    useCustomValue: true,
  }),
  makeEmptyThresholdTestRow({
    key: 'pyAccruedVacationPayWithoutAncillaryCosts',
    unit: '€',
    disabled: true,
  }),
  makeEmptyThresholdTestRow({
    key: 'cyAccruedVacationPayWithoutAncillaryCosts',
    unit: '€',
    disabled: true,
  }),
  makeEmptyThresholdTestRow({
    key: 'pyUnpaidSalariesWithoutAncillaryCosts',
    unit: '€',
    useCustomValue: true,
  }),
  makeEmptyThresholdTestRow({
    key: 'cyUnpaidSalariesWithoutAncillaryCosts',
    unit: '€',
    useCustomValue: true,
  }),
  makeEmptyThresholdTestRow({
    key: 'total',
    unit: '€',
    disabled: true,
  }),
  makeEmptyThresholdTestRow({
    key: 'taxAdministrationAnnualReport',
    unit: '€',
    useCustomValue: true,
  }),
  makeEmptyThresholdTestRow({
    key: 'differenceInPayrollBetweenAccountingAndTaxAdministration',
    unit: '€',
    disabled: true,
  }),
  makeEmptyThresholdTestRow({
    key: 'differenceInPayrollBetweenAccountingAndTaxAdministrationPercentage',
    unit: '%',
    disabled: true,
  }),
  makeEmptyThresholdTestRow({ key: 'THRESHOLD', unit: '€' }),
  makeEmptyThresholdTestRow({ key: 'CONCLUSION', disabled: true }),
];

/**
 * Define computation rules/formulas for each row.
 */
export const computationRules: ThresholdComputationRules = {
  // Kirjanpidon palkat yhteensä: tilikauden palkat + aktivoidut palkat
  totalPayrollInAccounting: ({ getAccountMappingValues }) => {
    const [wagesTotal, capitalizedWagesOrOtherAdjustmentsAccounts] =
      getAccountMappingValues.cy(
        'wagesTotal',
        'capitalizedWagesOrOtherAdjustmentsAccounts'
      );
    return Math.abs(wagesTotal + capitalizedWagesOrOtherAdjustmentsAccounts);
  },

  // Ed. tilik. Lomapalkkavelka (ilman sivukuluja)
  pyAccruedVacationPayWithoutAncillaryCosts: ({ accountMappingValues }) => {
    return Math.abs(
      accountMappingValues.holidayPayWithoutAssociatedCostsAccounts.py
    );
  },

  // Tilik. Lomapalkkavelka (ilman sivukuluja)
  cyAccruedVacationPayWithoutAncillaryCosts: ({ accountMappingValues }) => {
    return Math.abs(
      accountMappingValues.holidayPayWithoutAssociatedCostsAccounts.cy
    );
  },

  // Yhteensä
  total: props => {
    const { data } = props;
    const {
      totalPayrollInAccounting,
      pyAccruedVacationPayWithoutAncillaryCosts,
      cyAccruedVacationPayWithoutAncillaryCosts,
    } = computationRules;

    const find = (key: string) =>
      getValueOrDefaultValue(data.find(findByKey(key)));

    return (
      totalPayrollInAccounting(props) +
      find('sickLeaveCompensationAndOther') +
      find('otherCreditPostingsToSalaryAccounts') +
      pyAccruedVacationPayWithoutAncillaryCosts(props) -
      cyAccruedVacationPayWithoutAncillaryCosts(props) +
      find('pyUnpaidSalariesWithoutAncillaryCosts') -
      find('cyUnpaidSalariesWithoutAncillaryCosts')
    );
  },

  // Kirjanpidon palkkojen ero verottajan vuosi-ilmoitukseen (euroa)
  differenceInPayrollBetweenAccountingAndTaxAdministration: props => {
    const { data } = props;
    const { total } = computationRules;
    const taxAdministrationAnnualReport = getValueOrDefaultValue(
      data.find(findByKey('taxAdministrationAnnualReport'))
    );
    return taxAdministrationAnnualReport - total(props);
  },

  // Kirjanpidon palkkojen ero verottajan vuosi-ilmoitukseen (%)
  differenceInPayrollBetweenAccountingAndTaxAdministrationPercentage: props => {
    const { data } = props;
    const {
      differenceInPayrollBetweenAccountingAndTaxAdministration: difference,
    } = computationRules;

    const taxAdministrationAnnualReport = getValueOrDefaultValue(
      data.find(findByKey('taxAdministrationAnnualReport'))
    );

    if (taxAdministrationAnnualReport === 0) return 0;

    return (difference(props) / taxAdministrationAnnualReport) * 100;
  },

  // Olennaisuusraja (PM)
  THRESHOLD: ({ data, auditingStore }) =>
    Math.abs(
      getValueOrDefaultValue(
        data.find(findByKey('THRESHOLD')),
        auditingStore.materialityComputeds().materiality
      )
    ),

  // Jos erotus alle olennaisuusrajan (PM), kaikki ok.
  CONCLUSION: props => {
    const {
      THRESHOLD,
      differenceInPayrollBetweenAccountingAndTaxAdministration: difference,
    } = computationRules;

    const comparison = THRESHOLD(props) - Math.abs(difference(props));
    return comparison < 0 ? -1 : comparison > 0 ? 1 : 0;
  },
};

export const matchingOfWagesToAnnualReportComputationRules = computationRules;
