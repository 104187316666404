import incomestatementAndBalanceImg from '../assets/instructionsMedia/incomeStatementAndBalance.png'
import detailedPlanImg from '../assets/instructionsMedia/detailedPlan.png'
import generalLedger from '../assets/instructionsMedia/generalLedger.png'
import pageImage from '../assets/instructionsMedia/page.png'
import savedData from '../assets/instructionsMedia/savedData.png'
import savedData2 from '../assets/instructionsMedia/savedData2.png'
import googleBrowserData from '../assets/instructionsMedia/googleBrowserData.png'
import Accordion from '../components/Accordion';

export function InstructionsPage() {
    return (
        <div>
                <Accordion title="Miten alustaa käytetään">
                    <h2>Miten alustaa käytetään</h2>

                    <br/>
                    Tilintarkastajat kirjautuvat alustalle ja valitsevat asiakasyhteisön, jonka tietoja he tarkastavat. Tilintarkastaja valitsee sen lisäksi olemassa olevan tilikauden, tai tekee uuden tilikauden, ja pääsee sitten muokkaamaan valitun tilikauden tarkastustietoja tilintarkastusnäkymään.
                    <br/>
                    Tilintarkastusnäkymässä on eri osioita, jotka sisältävät välilehtiä. Jokainen välilehti sisältää kenttiä, joihin annetaan tilintarkastusprosessiin olennaista tietoa.
                    Tilintarkastusnäkymä näkyy sivun vasemmassa reunassa.
                    <br/>

                    <h4>Sisäinen valvonta ja toimintaympäristö</h4>
                    Osioon lisätty painike, joka piilottaa/avaa Kaudenaikaisen tarkistuksen. <br/>Yksityiskohtainen suunnitelman lukitus avautuu, kun Kaudenaikaisen TAI Tilinpäätöstarkastuksen Tuloslaskelma ja tase on valmis tai hyväksytty.
                    <br/>

                    <h4>Tilikartta</h4>
                    Voit muokata tilikarttaa Tilikartta-välilehdellä "Alku", "Loppu" ja "Erä" sarakkeista. Joitakin oletusarvoja on jo valittu, joten välilehteä ei välttämättä tarvitse muokata.
                    <br/>

                    <h4>Pääkirja</h4>
                    Pääkirja välilehdestä lisätään csv/xlsx pääkirja projektiin, tai jos pääkirjaa ei käytetä, valitaan ”ei pääkirjaa”. <br/><br/><i>Muista joko painaa ”ei pääkirja” nappia tai pudottaa pääkirja</i>.
                    <br/><img src={generalLedger} style={{width: 1000}} alt="pääkirja" />
                    <br/>

                    <h4>Tuloslaskelma ja tase</h4>
                    ”Tuloslaskelma ja tase” kohdasta valitaan eri tarkastus osa-alueiden riskit, ja jos riski on olemassa, välilehti jossa riski arvioidaan siirtyy automaattisesti yksityiskohtaiseen suunnitelmaan (Välilehti tulee siis näkyviin vain jos riskiksi valitaan "<i><b>merkittävä</b></i>" tai "<i><b>rajattu</b></i>").
                    <br/><img src={incomestatementAndBalanceImg} style={{width: 1000}} alt="tuloslaskelma ja tase" />

                    <h4>Yksityiskohtainen suunnitelma</h4>
                    "Yksityiskohtainen suunnitelma" välilehteen siirtymisen jälkeen valitaan tarkemmin, että mitkä tarkastustoimenpiteet mistäkin välilehdestä siirtyvät tarkastukseen<br/>
                    Jos kumpaakaan täppää ( <i><b>Kausi / TP</b></i> ) ei ole täpätty, niin rivillä kerrottua tarkastustoimenpidettä ei tule näkyviin tarkastusvälilehteen.
                    <br/><img src={detailedPlanImg} style={{width: 1000}} alt="yksityiskohtainen suunnitelma" />

                    <h4>Tarkastusvälilehdet</h4>
                    Edellisten välilehtien kautta vain tarpeelliset tarkastusvälilehdet tulevat näkyviin "Tarkastus" osioon, tarkastusvälilehdet tulevat näkyviin vasta kun tilikartta, pääkirja, tuloslaskelma ja tase, ja yksityiskohtainen suunnitelma on käyty läpi.
                    
                    Tarkastusvälilehdet tulevat näkyviin tarkastus osioon, josta ne sitten tarkastetaan. 
                    Tarkastusvälilehden nimeä (esim. oheisen kuvan <i><b>F1 - Ensimmäistä kertaa suoritettavat toimeksiannot</b></i>) painamalla kyseinen tarkastusvälilehti avautuu.
                    <br/><img src={pageImage} style={{width: 1000}} alt="välilehti" />

                    <br/>
                    <h4>Tunnusluvut</h4><br/>
                    Tunnusluvut välilehdellä on taulukko, jossa on erilaisia tunnuslukuja. Taulukkoa voi muokata, ja jokaiselle riville voi antaa kommentin.
                    Tämä myös tulostaa riskiarvion heikosta, keskinkertaisesta tai hyvästä tilanteesta.

                    <br/>
                    <h4>Raportti</h4><br/>
                    Raportti välilehdellä raportin kyseisestä tilikaudesta. Muista tarkistaa että kaikilta tarvittavilta välilehdiltä on annettu tietoa, jotta raportti on nähtävänä.<br/>
                    "<b>Tietojen tarkistaminen</b>" haitarista voit tarkistaa mitä tietoa on annettu ja mitä ei. Tarvittaessa käy antamassa puuttuvat tiedot.<br/>
                    Tämä välilehti on vielä työn alla, joten se ei toimi vielä täysin oikein.
                    <br/>
                </Accordion>

                <Accordion title="Päivitetyt">
                    <h4 style={{marginBottom: 0}}>Päivitetyt</h4>
                    <br/>
                    <p>
                        - Useaan välilehteen on lisätty "<b>Ohje</b>" painike, josta näkee ohjeet kyseisen välilehden täyttämiseen.<br/>
                        - Yksityiskohtaiseen suunnitelmaan on lisätty <b>Excel</b> täppä, tämä valittuna tarkoittaa että kyseinen tarkastustoimenpide on Excelissä ( <i>Kausi / TP valintaa ei voi valita tällöin.</i> ).<br/>
                        - Usean painikkeen/valinnan nimi on muutettu selkeämmäksi.
                    </p>
                    <br/>
                    <h4 style={{marginBottom: 0}}>Päivitykset</h4>
                    <br/>
                    <p>
                        Kun päivityksen tuo sivustolle, se tulee näkyviin uudelle tilikaudelle, joten jos haluat käyttää uusia ominaisuuksia, luo uusi tilikausi. Ellei kyseessä ole pakollinen/ulkoasu päivitys, voit jatkaa vanhalla tilikaudella.
                    </p>
                </Accordion>

                <Accordion title="Virheiden korjaus">
                    <h3>Välimuistin tyhjentäminen</h3>
                    <p>Jos muutoksia on tuotu alustalle ja jostain syystä muutokset eivät näy, on hyvä kokeilla tyhjentää välimuisti ja päivittää sivusto tämän jälkeen.</p>
                    <h4>Google Chrome</h4>
                    <p>
                        <ol>
                            <li>Laita Google Chrome aktiiviseen tilaan</li>
                            <li>Paina <b>Ctrl + Shift + Delete</b> näppäimiä</li>
                            <li>Valitse <b>Time range</b> kohdasta <b>All time</b></li>
                            <li>Valitse aktiiviseksi vain <b>Cached images and files</b></li>
                            <li>Valitse <b>Clear data</b></li>
                            <br/><img src={googleBrowserData} style={{width: 309, height: 275}} alt="google broswer data" />
                        </ol>
                    </p>
                    <h4>Muut selaimet</h4>
                    <p>
                        Muissa selaimissa välimuistin tyhjentäminen onnistuu yleensä painamalla <b>Ctrl + Shift + Delete</b> näppäimiä ja valitsemalla <b>Clear cache</b> tai <b>Clear data</b>.<br/>
                        Muista valita <b>Time range</b> kohdasta <b>All time</b> ja valita <b>Cached images and files</b>.
                    </p>
                </Accordion>

                <Accordion title="Muuta">
                    <h2>Muuta</h2>
                    <h4>Tallennus</h4>
                    <p>
                        Kaikki välilehtiin tehdyt muutokset tallentuvat <b>automaattisesti</b>. <br/>Odota ilmoitusta, joka vahvistaa tallennuksen ennen kuin siirryt pois välilehdeltä tai suljet sivun, varmistaaksesi että muutokset ovat tallentuneet onnistuneesti.<br/>
                        Ilmoitus tallennuksen onnistumisesta ilmenee esimerkiksi seuraavilla tavoilla: <br/>
                    </p>
                    <div style={{borderWidth: 2, borderColor: 'black', borderStyle: 'solid', padding: 20}}>
                        <br/><img src={savedData} style={{width: 200}} alt="tallennettu tieto kuva" />
                        <p>↑ Kyseinen ilmoitus tulee näkyviin sivun oikealle puolelle kommenttikentän alapuolelle ↑</p>
                    </div>
                    <br/>
                    <div style={{borderWidth: 2, borderColor: 'black', borderStyle: 'solid', padding: 20}}>
                        <br/><img src={savedData2} style={{width: 200}} alt="tallennettu tieto kuva 2" />
                        <p>↑ Kyseinen ilmoitus tulee nettisivun yläosaan ja häviää parissa sekunnissa↑</p>
                    </div>
                    <br/>
                    <h4>Päätös ja Tarkastus</h4>
                    <p>
                        Projektissa on osiot <i>"Analyysit ja scoping - tilinpäätöstarkastus"</i> ja <i>"Analyysit ja scoping - tilikauden aikainen tarkastus"</i>, näistä voi täyttää vain jommankumman tai molemmat.
                        Tilikauden aikainen tarkastus ja tilinpäätöstarkastus molemmat käyttävät samaa "Yksityiskohtainen suunnitelma" välilehteä. 

                        Analyysit ja scoping -välilehdet on täytettävä seuraavassa järjestyksessä:
                        <ul>
                            <li>1. Tilikartta</li>
                            <li>2. Pääkirja</li>
                            <li>3. Tuloslaskelma ja tase</li>
                            <li>4. Yksityiskohtainen suunnitelma</li>
                        </ul>
                        Kun olet täyttänyt jonkun näistä välilehdistä, "Työkirjan tila" tulee muuttaa sivun oikealta puolelta olevasta kentästä "Valmis" tai "Hyväksytty" tilaan,
                        tämän jälkeen voit muokata "Analyysit ja scoping" osion seuraavaa välilehteä.
                    </p>

                    <h4>Kommenttikenttä</h4>
                    <p>
                        Jokaisen välilehden oikealla puolen on kommenttikenttä, johon voit kirjoittaa vapaasti mitä vaan. Teksti näkyy kaikille välilehteä katsoville, ja kentän teksti on heidän muokattavissa.
                    </p>
                </Accordion>
        </div>
    );
}
