import { useTranslation } from 'react-i18next';
import { confirmDelete, uuid } from '../../utils';
import AuditingTable from '../table/AuditingTable';
import { OnEditProps, TableColumnDefs } from '../table/Table';
import styled from 'styled-components';

const Container = styled.div`
  .additional-info {
    font-style: italic;
    color: ${p => p.theme.color.grey400};
    text-align: right;
  }
`;

const sectionKey: AuditingSectionKey = 'presentationAndContent';

export const PresentationTable = ({
  formState,
  patchFormState,
}: FormFieldProps<PresentationAndContentForm>) => {
  const { t } = useTranslation();

  if (!formState) return null;

  const tBase = `auditing:form.${sectionKey}.presentationTable`;

  const data = formState.presentationTable ?? [];

  function formatNumber(num: any) {
    return num.toLocaleString('fi-FI', {
      minimumFractionDigits: 1,
      maximumFractionDigits: 2,
    });
  }

  function handleEdit<T>({
    itemId,
    rowIndex,
    columnId,
    value,
  }: OnEditProps<T>) {
    const patch = formState.presentationTable.map((row, index) => {
      const idMatch = itemId !== undefined && itemId === row.id;
      const rowIndexMatch = itemId === undefined && rowIndex === index;
      if (idMatch || rowIndexMatch) {
        const newRow = { ...row, [columnId]: value };
        if (columnId === 'amountMaterial' || columnId === 'attachment') {
          let { amountMaterial, attachment } = newRow || 0;
          let minus = amountMaterial - attachment || 0;
          newRow.minus = minus;
          formatNumber(newRow.minus);
        }
        return newRow;
      }
      return row;
    });
    const newFormState = { ...formState, presentationTable: patch };
    patchFormState(newFormState);
  }

  const tableColumns: TableColumnDefs<PresentationTable> = [
    {
      accessorKey: 'label',
      header: () => 'Tarkastettava vastuu',
      className: 'text-left width-middle',
      placeholder: '',
      onEdit: (editProps: OnEditProps<string>) => {
        handleEdit<string>(editProps);
      },
    },
    {
      accessorKey: 'backgroundMaterial',
      header: () => 'Taustamateriaali',
      className: 'text-center width-middle',
      placeholder: '',
      onEdit: (editProps: OnEditProps<string>) => {
        handleEdit<string>(editProps);
      },
    },
    {
      accessorKey: 'amountMaterial',
      header: () => t(`${tBase}.amountMaterial`), // + ' €',
      className: 'text-center width-middle',
      onNumberEdit: (editProps: OnEditProps<number>) =>
        handleEdit<number>(editProps),
      cell: ({ cell }) => (
        <div>{formatNumber(cell.row.original.amountMaterial)}</div>
      ),
    },
    {
      accessorKey: 'attachment',
      header: () => t(`${tBase}.attachment`), // + ' €',
      className: 'text-center width-middle',
      onNumberEdit: (editProps: OnEditProps<number>) =>
        handleEdit<number>(editProps),
      cell: ({ cell }) => (
        <div>{formatNumber(cell.row.original.attachment)}</div>
      ),
    },
    {
      accessorKey: 'minus',
      header: () => t(`${tBase}.minus`),
      className: 'text-center width-middle',
      //onEdit: (editProps: OnEditProps<string>) => handleEdit<string>(editProps),
      cell: ({ cell }) => <div>{formatNumber(cell.row.original.minus)}</div>,
    },
    {
      accessorKey: 'other',
      header: () => t(`${tBase}.other`),
      className: 'text-center width-middle',
      onEdit: (editProps: OnEditProps<string>) => handleEdit<string>(editProps),
    },
  ];

  const handleAddNewRow = () => {
    patchFormState({
      ...formState,
      presentationTable: [
        ...(formState.presentationTable ?? []),
        {
          label: '',
          id: uuid(), //Math.floor(Math.random()*100).toString(),
          auditable: '',
          backgroundMaterial: '',
          amountMaterial: 0,
          attachment: 0,
          minus: 0,
          other: '',
        },
      ],
    });
  };

  const handleDeleteRow = ({ id, label }: PresentationTable) => {
    if (confirmDelete(label ?? '')) {
      const patch = (formState.presentationTable ?? []).filter(
        row => row.id !== id
      );
      patchFormState({ ...formState, presentationTable: patch });
    }
  };

  return (
    <>
      <AuditingTable<PresentationTable>
        sectionKey={sectionKey}
        onAddNewRow={handleAddNewRow}
        data={data}
        columns={tableColumns}
        onRowDelete={handleDeleteRow}
        showGlobalFilter={false}
        disableSort
        variant="default"
      />
      <Container>
        <div className="additional-info">
          Erotus: Määrä taustamateriaalilla - liitetieto
        </div>
      </Container>
    </>
  );
};
