import { action, computed, makeObservable, observable, toJS } from 'mobx';
import { Api } from '../../api/RootApi';
import { Role, Storage } from '../../utils';
import BaseStore from '../BaseStore';
import { RootStore } from '../RootStore';

export class AuthStore extends BaseStore {
  /**
   * Observables
   */
  token?: string;
  user?: User;

  constructor(rootStore: RootStore, api: Api, token?: string) {
    super(rootStore, api);
    makeObservable(this, {
      token: observable,
      user: observable,
      setToken: action,
      setOTP: action,
      setVerified: action,
      setUser: action,
      isLoggedIn: computed,
      role: computed,
      userId: computed,
      userFullName: computed,
      userOtpSecret: computed,
      userContactEmail: computed,
      userIsVerified: computed,
      userAuditFirmId: computed,
      login: action,
      logout: action,
      forgotPassword: action,
      resetPassword: action,
      getMe: action,
    });

    if (token?.length) {
      this.setToken(token);
    }
  }

  /**
   * Setters
   */
  setToken = (token?: string) => {
    this.token = token;
    Storage.write({
      key: 'AUTH_TOKEN',
      value: token ?? null,
    });
  };

  // set OTP in User called otpSecret
  setOTP = (otpSecret?: string) => {
    if (this.user) {
      this.user.otpSecret = otpSecret;
    }
  };
  setVerified = (isVerified?: boolean) => {
    if (this.user) {
      this.user.isVerified = isVerified;
    }
  };

  setUser = (user?: User) => {
    this.user = user;
  };

  /**
   * Computeds
   */
  get isLoggedIn() {
    return this.token?.length && this.user;
  }
  get role() {
    const role = this.user?.role ?? '';
    return {
      type: this.user?.role,
      isAdmin: role === Role.Admin,
      isAuditorAdmin: role === Role.AuditorAdmin,
      isAuditor: role === Role.Auditor,
      isCustomer: role === Role.Customer,
    };
  }
  get userId() {
    return this.user?.id;
  }
  get userFullName() {
    if (!this.user) return '';
    return `${this.user.firstName} ${this.user.lastName}`;
  }
  get userOtpSecret() {
    if (!this.user) return '';
    return this.user.otpSecret;
  }
  get userContactEmail() {
    if (!this.user) return '';
    return this.user.contactEmail;
  }
  get userIsVerified() {
    if (!this.user) return false;
    return this.user.isVerified;
  }
  get userAuditFirmId() {
    return this.rootStore.userStore.getAuditFirmId(toJS(this.user));
  }

  /**
   * Actions
   */
  login = async (params: Api.Payload.Login) => {
    type ReturnType = Login;
    await this.defaultAction<ReturnType>({
      taskType: 'login',
      apiRequest: () => this.api.auth.login(params),
      onSuccess: (response: Api.Success<ReturnType>) => {
        this.setToken(response.data.accessToken);
        this.sendOTP({ email: response.data.user.email });
        this.setVerified(false);
        this.setUser(response.data.user);
      },
    });
  };

  otpcheck = async (params: Api.Payload.OTP) => {
    type ReturnType = OTP;
    await this.defaultAction<ReturnType>({
      taskType: 'otpcheck',
      apiRequest: () => this.api.auth.otpcheck(params),
      onSuccess: (response: Api.Success<ReturnType>) => {
        this.setOTP(response.data.user.otpSecret);
        this.setUser(response.data.user);
      },
    });
  };

  // Send OTP
  sendOTP = async (params: Api.Payload.SendOTP) => {
    await this.defaultAction({
      isSilent: true,
      taskType: 'processing',
      apiRequest: () => this.api.auth.sendOTP(params),
    });
  };

  logout = () => {
    this.setToken(undefined);
    this.setOTP(undefined);
    this.setVerified(undefined);
    this.setUser(undefined);
    this.rootStore.notificationStore.success('logout');
    this.rootStore.resetAllStores();
    try {
      this.api.auth.logout();
    } catch (error) {
      // API-logout failed
    }
  };

  forgotPassword = async (params: Api.Payload.ForgotPassword) => {
    await this.defaultAction({
      taskType: 'processing',
      apiRequest: () => this.api.auth.forgotPassword(params),
    });
  };

  resetPassword = async (params: Api.Payload.ResetPassword) => {
    await this.defaultAction({
      taskType: 'processing',
      apiRequest: () => this.api.auth.resetPassword(params),
    });
  };

  getMe = async () => {
    type ReturnType = User;
    await this.defaultAction<ReturnType>({
      taskName: 'GET_ME',
      taskType: 'fetching',
      isSilent: true,
      apiRequest: () => this.api.user.getMe(),
      onSuccess: (response: Api.Success<ReturnType>) =>
        this.setUser(response.data),
      onError: this.logout,
    });
  };
}
