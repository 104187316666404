import { AccountClassKey, AccountKey } from './accountMapKeys';

const accountMap: AccountMap = {
  incomeStatementAccountMap: [
    {
      key: AccountKey.turnover,
      heading: 2,
      start: 3000,
      end: 3599,
      classKey: AccountClassKey['J1-turnover'],
    },
    {
      key: AccountKey.inventoryChange,
      heading: 2,
      start: 3600,
      end: 3629,
    },
    {
      key: AccountKey.manufactureForOwnUse,
      heading: 2,
      start: 3630,
      end: 3649,
    },
    {
      key: AccountKey.otherOperatingIncome,
      heading: 2,
      start: 3650,
      end: 3999,
      classKey: AccountClassKey['JB-otherOperatingIncome'],
    },
    {
      key: AccountKey.materialsAndServices,
      heading: 2,
      start: 4000,
      end: 4999,
      classKey: AccountClassKey['K1-materialsAndServices'],
    },
    {
      key: AccountKey.materialsSupliesAndGoods,
      heading: 2,
      start: 4000,
      end: 4999,
    },
    {
      key: AccountKey.purchases,
      start: 4000,
      end: 4390,
    },
    {
      key: AccountKey.stocksChange,
      start: 4400,
      end: 4449,
    },
    {
      key: AccountKey.externalServices,
      start: 4450,
      end: 4999,
    },
    {
      key: AccountKey.personelCosts,
      heading: 2,
      classKey: AccountClassKey['L-personelCosts'],
      start: 5000,
      end: 6799,
    },
    {
      key: AccountKey.wagesAndSalaries,
      start: 5000,
      end: 5999,
    },
    {
      key: AccountKey.socialSecurityCosts,
      heading: 2,
      start: 6000,
      end: 6799,
    },
    {
      key: AccountKey.pensionExpences,
      start: 6000,
      end: 6299,
    },
    {
      key: AccountKey.otherSocialSecurityCosts,
      start: 6300,
      end: 6799,
    },
    {
      key: AccountKey.depreciationAndAmortization,
      start: 6800,
      end: 6999,
      classKey: AccountClassKey['O1-fixedAssets'],
      heading: 2,
    },
    {
      key: AccountKey.otherOperatingExpenses,
      start: 7000,
      end: 8999,
      classKey: AccountClassKey['KB-otherOperatingExpenses'],
      heading: 2,
    },
    {
      key: AccountKey.operatingProfitOrLoss,
      heading: 1,
      start: 3000,
      end: 8999,
    },
    {
      key: AccountKey.financialIncomeAndExpences,
      heading: 2,
      start: 9000,
      end: 9799,
    },
    {
      key: AccountKey.financialIncome,
      start: 9000,
      end: 9299,
      classKey: AccountClassKey['XJ-financialIncome'],
    },
    {
      key: AccountKey.financialExpenses,
      start: 9300,
      end: 9799,
      classKey: AccountClassKey['XK-financialExpenses'],
    },
    {
      key: AccountKey.profitOrLossBeforeTransfersAndTaxes,
      heading: 1,
      start: 3000,
      end: 9799,
    },
    {
      key: AccountKey.financialStatementTransfers,
      start: 9800,
      end: 9899,
      classKey: AccountClassKey['O1-fixedAssets'],
    },
    {
      key: AccountKey.incomeTaxes,
      heading: 2,
      start: 9900,
      end: 9979,
      classKey: AccountClassKey['N-taxes'],
    },
    {
      key: AccountKey.otherTaxes,
      start: 9980,
      end: 9999,
    },
    {
      key: AccountKey.profitOrLoss,
      heading: 1,
      start: 3000,
      end: 9999,
    },
  ],
  balanceSheetAssetsAccountMap: [
    {
      key: AccountKey.nonCurrentAssets,
      heading: 1,
      start: 1000,
      end: 1499,
    },
    {
      key: AccountKey.intangibleAssets,
      heading: 2,
      start: 1000,
      end: 1099,
      classKey: AccountClassKey['O1-fixedAssets'],
    },
    {
      key: AccountKey.startUpCosts,
      start: 1000,
      end: 1009,
    },
    {
      key: AccountKey.researchCosts,
      start: 1010,
      end: 1019,
    },
    {
      key: AccountKey.developmentCosts,
      start: 1020,
      end: 1029,
    },
    {
      key: AccountKey.intangibleRights,
      start: 1030,
      end: 1049,
    },
    {
      key: AccountKey.goodwill,
      start: 1050,
      end: 1059,
    },
    {
      key: AccountKey.otherCapitalisedLongtermCosts,
      start: 1060,
      end: 1089,
    },
    {
      key: AccountKey.nonCurrentAssets_advancePayments,
      start: 1090,
      end: 1099,
    },
    {
      key: AccountKey.tangibleAssets,
      heading: 2,
      start: 1100,
      end: 1399,
      classKey: AccountClassKey['O1-fixedAssets'],
    },
    {
      key: AccountKey.landAndWaters,
      start: 1100,
      end: 1119,
    },
    {
      key: AccountKey.buildings,
      start: 1120,
      end: 1159,
    },
    {
      key: AccountKey.machineryAndEquipment,
      start: 1160,
      end: 1249,
    },
    {
      key: AccountKey.otherTangibleAssets,
      start: 1300,
      end: 1379,
    },
    {
      key: AccountKey.advancePaymentsAndConstructionInProgress,
      start: 1380,
      end: 1399,
    },
    {
      key: AccountKey.nonCurrentAssets_investments,
      heading: 2,
      start: 1400,
      end: 1499,
      classKey: AccountClassKey['O3-investments'],
    },
    {
      key: AccountKey.nonCurrentAssets_holdingsInGroupMemberCompanies,
      start: 1400,
      end: 1409,
    },
    {
      key: AccountKey.receivablesFromGroupMemberCompanies,
      start: 1410,
      end: 1419,
    },
    {
      key: AccountKey.participatingInterests,
      start: 1420,
      end: 1429,
    },
    {
      key: AccountKey.receivablesFromParticipatingInterestCompanies,
      start: 1430,
      end: 1439,
    },
    {
      key: AccountKey.nonCurrentAssets_otherSharesAndSimilarRightsOfOwnership,
      start: 1440,
      end: 1469,
    },
    {
      key: AccountKey.otherReceivables,
      start: 1470,
      end: 1489,
    },
    {
      key: AccountKey.nonCurrentAssets_ownSharesAndSimilarRightsOfOwnership,
      start: 1490,
      end: 1499,
    },
    {
      key: AccountKey.currentAssets,
      heading: 1,
      start: 1500,
      end: 1999,
    },
    {
      key: AccountKey.stocks,
      heading: 2,
      start: 1500,
      end: 1599,
      classKey: AccountClassKey['P1-warehouses'],
    },
    {
      key: AccountKey.rawMaterialsAndConsumables,
      start: 1500,
      end: 1509,
    },
    {
      key: AccountKey.semiFinishedProducts,
      start: 1510,
      end: 1519,
    },
    {
      key: AccountKey.finishedProductsOrGoods,
      start: 1520,
      end: 1529,
    },
    {
      key: AccountKey.otherStocks,
      start: 1530,
      end: 1549,
    },
    {
      key: AccountKey.currentAssets_advancePayments,
      start: 1550,
      end: 1599,
    },
    {
      key: AccountKey.receivables,
      heading: 1,
      start: 1600,
      end: 1859,
    },
    {
      key: AccountKey.longTermReceivables,
      heading: 2,
      start: 1600,
      end: 1699,
    },
    {
      key: AccountKey.longTerm_tradeReceivables,
      start: 1600,
      end: 1629,
      classKey: AccountClassKey['J2-accountsReceivable'],
    },
    {
      key: AccountKey.longTerm_receivablesFromGroupMemberCompanies,
      start: 1630,
      end: 1639,
      classKey: AccountClassKey['Q1-otherReceivables'],
    },
    {
      key: AccountKey.longTerm_receivablesFromParticipatingInterestCompanies,
      start: 1640,
      end: 1649,
      classKey: AccountClassKey['Q1-otherReceivables'],
    },
    {
      key: AccountKey.longTerm_loanReceivables,
      start: 1650,
      end: 1659,
      classKey: AccountClassKey['Q3-loanReceivables'],
    },
    {
      key: AccountKey.longTerm_otherReceivables,
      start: 1660,
      end: 1669,
      classKey: AccountClassKey['Q1-otherReceivables'],
    },
    {
      key: AccountKey.longTerm_subscribedCapitalUnpaid,
      start: 1670,
      end: 1679,
      classKey: AccountClassKey['Q1-otherReceivables'],
    },
    {
      key: AccountKey.longTerm_prepaymentsAndAccruedIncome,
      start: 1680,
      end: 1689,
      classKey: AccountClassKey['Q1-otherReceivables'],
    },
    {
      key: AccountKey.longTerm_taxReceivables,
      start: 1690,
      end: 1699,
      classKey: AccountClassKey['N-taxes'],
    },
    {
      key: AccountKey.shortTermReceivables,
      heading: 2,
      start: 1700,
      end: 1859,
    },
    {
      key: AccountKey.shortTerm_tradeReceivables,
      start: 1700,
      end: 1729,
      classKey: AccountClassKey['J2-accountsReceivable'],
    },
    {
      key: AccountKey.shortTerm_receivablesFromGroupMemberCompanies,
      start: 1730,
      end: 1739,
      classKey: AccountClassKey['Q1-otherReceivables'],
    },
    {
      key: AccountKey.shortTerm_receivablesFromParticipatingInterestCompanies,
      start: 1740,
      end: 1749,
      classKey: AccountClassKey['Q1-otherReceivables'],
    },
    {
      key: AccountKey.shortTerm_loanReceivables,
      start: 1750,
      end: 1759,
      classKey: AccountClassKey['Q3-loanReceivables'],
    },
    {
      key: AccountKey.shortTerm_otherReceivables,
      start: 1760,
      end: 1779,
      classKey: AccountClassKey['Q1-otherReceivables'],
    },
    {
      key: AccountKey.shortTerm_subscribedCapitalUnpaid,
      start: 1780,
      end: 1799,
      classKey: AccountClassKey['Q1-otherReceivables'],
    },
    {
      key: AccountKey.shortTerm_prepaymentsAndAccruedIncome,
      start: 1800,
      end: 1849,
      classKey: AccountClassKey['Q1-otherReceivables'],
    },
    {
      key: AccountKey.shortTerm_taxReceivables,
      start: 1850,
      end: 1859,
      classKey: AccountClassKey['N-taxes'],
    },
    {
      key: AccountKey.currentAssets_investments,
      heading: 2,
      start: 1860,
      end: 1899,
    },
    {
      key: AccountKey.currentAssets_holdingsInGroupMemberCompanies,
      start: 1860,
      end: 1869,
      classKey: AccountClassKey['Q2-cashEquivalents'],
    },
    {
      key: AccountKey.currentAssets_ownSharesAndSimilarRightsOfOwnership,
      start: 1870,
      end: 1879,
      classKey: AccountClassKey['Q2-cashEquivalents'],
    },
    {
      key: AccountKey.currentAssets_otherSharesAndSimilarRightsOfOwnership,
      start: 1880,
      end: 1889,
      classKey: AccountClassKey['Q2-cashEquivalents'],
    },
    {
      key: AccountKey.otherInvestments,
      heading: 2,
      start: 1890,
      end: 1899,
      classKey: AccountClassKey['O4-funding'],
    },
    {
      key: AccountKey.cashInHandAndAtBanks,
      heading: 2,
      start: 1900,
      end: 1999,
      classKey: AccountClassKey['Q2-cashEquivalents'],
    },
    {
      key: AccountKey.balanceAssets,
      heading: 1,
      start: 1000,
      end: 1999,
    },
  ],
  balanceSheetLiabilitiesAccountMap: [
    {
      key: AccountKey.equity,
      heading: 1,
      start: 2000,
      end: 2399,
      classKey: AccountClassKey['R1-ownCapital'],
    },
    {
      key: AccountKey.tiedEquity,
      heading: 2,
      start: 2000,
      end: 2059,
    },
    {
      key: AccountKey.subscribedEquity,
      start: 2000,
      end: 2010,
    },
    {
      key: AccountKey.emissionOfShares,
      start: 2010,
      end: 2019,
    },
    {
      key: AccountKey.sharePremiumAccount,
      start: 2020,
      end: 2029,
    },
    {
      key: AccountKey.revaluationReserve,
      start: 2030,
      end: 2039,
    },
    {
      key: AccountKey.otherReserves,
      start: 2040,
      end: 2059,
    },
    {
      key: AccountKey.freeEquity,
      heading: 2,
      start: 2060,
      end: 2399,
    },
    {
      key: AccountKey.svop,
      start: 2060,
      end: 2249,
    },
    {
      key: AccountKey.retainedProfitOrLoss,
      start: 2250,
      end: 2369,
    },
    {
      key: AccountKey.freeEquity_profitOrLoss,
      start: 2370,
      end: 2379,
    },
    {
      key: AccountKey.freeEquity_subordinatedLoans,
      start: 2380,
      end: 2399,
    },
    {
      key: AccountKey.appropriations,
      heading: 1,
      start: 2400,
      end: 2499,
    },
    {
      key: AccountKey.cumulativeAcceleratedDepreciation,
      start: 2400,
      end: 2449,
      classKey: AccountClassKey['O1-fixedAssets'],
    },
    {
      key: AccountKey.untaxedReserves,
      start: 2450,
      end: 2499,
      classKey: AccountClassKey['N-taxes'],
    },
    {
      key: AccountKey.provisions,
      heading: 1,
      start: 2500,
      end: 2599,
      classKey: AccountClassKey['V-reserves'],
    },
    {
      key: AccountKey.provisionsForPensions,
      start: 2500,
      end: 2529,
    },
    {
      key: AccountKey.provisionsForTaxation,
      start: 2530,
      end: 2549,
    },
    {
      key: AccountKey.otherProvisions,
      start: 2550,
      end: 2599,
    },
    {
      key: AccountKey.creditors,
      heading: 1,
      start: 2600,
      end: 2999,
    },
    {
      key: AccountKey.longTermCreditors,
      heading: 2,
      start: 2600,
      end: 2799,
    },
    {
      key: AccountKey.longTermCreditors_subordinatedLoans,
      start: 2780,
      end: 2799,
      classKey: AccountClassKey['R2-capitalLoans'],
    },
    {
      key: AccountKey.longTermCreditors_bonds,
      start: 2600,
      end: 2609,
      classKey: AccountClassKey['S1-financialInstitutions'],
    },
    {
      key: AccountKey.longTermCreditors_convertibleBonds,
      start: 2610,
      end: 2619,
      classKey: AccountClassKey['S1-financialInstitutions'],
    },
    {
      key: AccountKey.longTermCreditors_loansFromCreditInstitutions,
      start: 2620,
      end: 2649,
      classKey: AccountClassKey['S1-financialInstitutions'],
    },
    {
      key: AccountKey.longTermCreditors_pensionLoans,
      start: 2650,
      end: 2659,
      classKey: AccountClassKey['S1-financialInstitutions'],
    },
    {
      key: AccountKey.longTermCreditors_advancesReceived,
      start: 2660,
      end: 2669,
      classKey: AccountClassKey['T2-advancePayments'],
    },
    {
      key: AccountKey.longTermCreditors_tradeCreditors,
      start: 2670,
      end: 2699,
      classKey: AccountClassKey['K2-tradeCreditors'],
    },
    {
      key: AccountKey.longTermCreditors_amountsOwedToGroupMemberCompanies,
      start: 2700,
      end: 2709,
      classKey: AccountClassKey['T1-otherLiabilities'],
    },
    {
      key: AccountKey.longTermCreditors_amountsOwedToParticipatingInterestCompanies,
      start: 2710,
      end: 2719,
      classKey: AccountClassKey['T1-otherLiabilities'],
    },
    {
      key: AccountKey.longTermCreditors_taxCreditors,
      start: 2770,
      end: 2779,
      classKey: AccountClassKey['N-taxes'],
    },
    {
      key: AccountKey.longTermCreditors_otherCreditors,
      start: 2720,
      end: 2749,
      classKey: AccountClassKey['T1-otherLiabilities'],
    },
    {
      key: AccountKey.longTermCreditors_accrualsAndDeferredIncome,
      start: 2750,
      end: 2769,
      classKey: AccountClassKey['T1-otherLiabilities'],
    },
    {
      key: AccountKey.shortTermCreditors,
      heading: 2,
      start: 2800,
      end: 2999,
    },
    {
      key: AccountKey.shortTermCreditors_subordinatedLoans,
      start: 2830,
      end: 2849,
      classKey: AccountClassKey['R2-capitalLoans'],
    },
    {
      key: AccountKey.shortTermCreditors_bonds,
      start: 2800,
      end: 2809,
      classKey: AccountClassKey['S1-financialInstitutions'],
    },
    {
      key: AccountKey.shortTermCreditors_convertibleBonds,
      start: 2810,
      end: 2819,
      classKey: AccountClassKey['S1-financialInstitutions'],
    },
    {
      key: AccountKey.shortTermCreditors_loansFromCreditInstitutions,
      start: 2820,
      end: 2829,
      classKey: AccountClassKey['S1-financialInstitutions'],
    },
    {
      key: AccountKey.shortTermCreditors_pensionLoans,
      start: 2850,
      end: 2859,
      classKey: AccountClassKey['S1-financialInstitutions'],
    },
    {
      key: AccountKey.shortTermCreditors_advancesReceived,
      start: 2860,
      end: 2869,
      classKey: AccountClassKey['T2-advancePayments'],
    },
    {
      key: AccountKey.shortTermCreditors_tradeCreditors,
      start: 2870,
      end: 2899,
      classKey: AccountClassKey['K2-tradeCreditors'],
    },
    {
      key: AccountKey.shortTermCreditors_amountsOwedToGroupMemberCompanies,
      start: 2900,
      end: 2910,
      classKey: AccountClassKey['T1-otherLiabilities'],
    },
    {
      key: AccountKey.shortTermCreditors_amountsOwedToParticipatingInterestCompanies,
      start: 2910,
      end: 2919,
      classKey: AccountClassKey['T1-otherLiabilities'],
    },
    {
      key: AccountKey.shortTermCreditors_amountsOwedToParticipatingInterestCompanies,
      start: 2910,
      end: 2919,
      classKey: AccountClassKey['O4-funding'],
    },
    {
      key: AccountKey.shortTermCreditors_taxCreditors,
      start: 2980,
      end: 2999,
      classKey: AccountClassKey['N-taxes'],
    },
    {
      key: AccountKey.shortTermCreditors_otherCreditors,
      start: 2920,
      end: 2949,
      //classKey: AccountClassKey['T-otherCreditors'],
      classKey: AccountClassKey['T1-otherLiabilities'],
    },
    {
      key: AccountKey.shortTermCreditors_accrualsAndDeferredIncome,
      start: 2950,
      end: 2979,
      //classKey: AccountClassKey['T-otherCreditors'],
      classKey: AccountClassKey['T1-otherLiabilities'],
    },
    {
      key: AccountKey.balanceLiabilities,
      heading: 1,
      start: 2000,
      end: 2999,
    },
  ],
};

export default accountMap;
