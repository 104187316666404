import { observer } from 'mobx-react-lite';
import React, { PropsWithChildren, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { useStore } from '../../store/RootContext';
import {
  AuditingProcedureResult,
  FinancialStatementBatchKey,
} from '../../views/auditingSections/auditing/auditingProceduresUtils';
import { Checkbox, Select, TextArea } from '../inputs';
import { ProcedureResult } from '../Accordion';
import { RootStore } from '../../store/RootStore';
import { FormFieldBuilder } from '../../views/auditingSections/FormFieldBuilder';

const tBase = 'auditing:form.auditingProcedures';

const Container = styled.section`
  > * {
    :not(:last-child) {
      margin-bottom: ${p => p.theme.spacing.lg};
    }
  }
`;

interface Props extends PropsWithChildren {
  auditingProcedure: AuditingProcedure;
}

export enum AuditingProcedureFields {
  auditingResult = 'auditingResult',
  auditingReference = 'auditingReference',
  auditingProcedureGoals = 'auditingProcedureGoals',
  auditingComments = 'auditingComments',
}

function TextAreaSaveComponent({
  label,
  auditingProcedure,
  idKey,
  saveTextToDatabase,
}: {
  /*
    Renders text area
  */
  label: string;
  auditingProcedure: any;
  idKey: string;
  saveTextToDatabase: Function;
}) {
  const [textValue, setTextValue] = useState(
    auditingProcedure[idKey] ? `${auditingProcedure[idKey]}` : ''
  );
  const [oldTextValue, setOldTextValue] = useState(
    auditingProcedure[idKey] ? `${auditingProcedure[idKey]}` : ''
  );
  const [loadingDots, setLoadingDots] = useState(''); // we display this as animation
  const timeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null); // use this to save after stopped typing for 2 sec

  useEffect(() => {
    if (textValue !== oldTextValue) {
      // if textinput changed
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current); // remove old timeout since new letter added
      }

      timeoutRef.current = setTimeout(() => {
        // add new timeout and try to save in 2 sec
        saveTextToDatabase(idKey, textValue);
        setOldTextValue(textValue);
      }, 2000);
    }

    return () => {
      if (timeoutRef.current) {
        // clear timeout
        clearTimeout(timeoutRef.current);
      }
    };
  }, [textValue, oldTextValue, idKey, saveTextToDatabase]);

  useEffect(() => {
    /*
      Changes loadingDots "." -> ".." -> "..." -> "" -> and repeat
    */
    const interval = setInterval(() => {
      setLoadingDots(prev => (prev === '...' ? '' : prev + '.'));
    }, 300);
    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <TextArea
      label={
        label +
        (textValue === oldTextValue ? '' : ` (Tallennetaan${loadingDots})`)
      } // dispalys saving animation
      value={textValue}
      fullWidth
      setValue={setTextValue}
    />
  );
}

const AuditingProcedureItem: React.FC<
  Props & { hideFields?: Array<keyof AuditingProcedure> }
> = observer(
  ({
    auditingProcedure,
    children,
    hideFields = [],
  }: Props & { hideFields?: Array<keyof AuditingProcedure> }) => {
    const { t } = useTranslation();

    const {
      auditingStore: { auditing, getAuditingSection, editEntity },
    } = useStore();

    const handleSave = (newAuditingProcedure: AuditingProcedure) => {
      /*
        saves auditingProcedures
      */
      const section = getAuditingSection('auditingProcedures');

      if (!auditing?.id || !section) return;

      const auditingProcedures =
        section as AuditingSection<AuditingProceduresForm>;

      const auditingProceduresBatch: AuditingProcedure[] =
        auditingProcedures.form.auditingProcedures.map(procedure =>
          procedure.id === newAuditingProcedure.id
            ? newAuditingProcedure
            : procedure
        );

      const auditingPatch: Partial<Auditing> = {
        auditingProcedures: {
          ...auditingProcedures,
          form: {
            ...auditingProcedures.form,
            auditingProcedures: auditingProceduresBatch,
          },
        },
      };

      editEntity({ id: auditing.id, data: auditingPatch });
    };

    const handleSelectChange = // saves select fields

        (key: keyof AuditingProcedure) =>
        (value?: React.SetStateAction<AuditingProcedureResult | undefined>) => {
          if (typeof value === 'string') {
            handleSave({ ...auditingProcedure, [key]: value });
          }
        };

    const saveTextToDatabase = // saves text to database
      (key: keyof AuditingProcedure, newValue: any) => {
        handleSave({ ...auditingProcedure, [key]: newValue });
      };

    const renderTextInputModal = (
      key: keyof AuditingProcedure,
      { showContextInfo }: { showContextInfo?: boolean } = {}
    ) => (
      <TextAreaSaveComponent
        auditingProcedure={auditingProcedure}
        idKey={key}
        saveTextToDatabase={saveTextToDatabase}
        label={t(`${tBase}.${key}`)}
      />
    );

    return (
      <Container>
        {!hideFields.includes('auditingResult') && (
          <Select<AuditingProcedureResult>
            label={t(`${tBase}.conclusionOfInspection`)}
            value={auditingProcedure.auditingResult ?? undefined}
            options={Object.values(AuditingProcedureResult)}
            displayValue={option =>
              t(`${tBase}.auditingResultOptionsNew.${option}`)
            }
            setValue={handleSelectChange('auditingResult')}
          />
        )}

        {!hideFields.includes('auditingReference') &&
          renderTextInputModal('auditingReference')}
        {!hideFields.includes('auditingProcedureGoals') &&
          renderTextInputModal('auditingProcedureGoals')}
        {!hideFields.includes('auditingComments') &&
          renderTextInputModal('auditingComments')}

        {children}
      </Container>
    );
  }
);

// Kirjanpidolliset arviot
const AccountingEstimatesItem: React.FC<Props> = observer(
  ({ auditingProcedure, children }) => {
    const { t } = useTranslation();

    const {
      auditingStore: { auditing, getAuditingSection, editEntity },
    } = useStore();

    const handleSave = (newAuditingProcedure: AuditingProcedure) => {
      /*
        saves auditingProcedures
      */
      const section = getAuditingSection('auditingProcedures');

      if (!auditing?.id || !section) return;

      const auditingProcedures =
        section as AuditingSection<AuditingProceduresForm>;

      const auditingProceduresBatch: AuditingProcedure[] =
        auditingProcedures.form.auditingProcedures.map(procedure =>
          procedure.id === newAuditingProcedure.id
            ? newAuditingProcedure
            : procedure
        );

      const auditingPatch: Partial<Auditing> = {
        auditingProcedures: {
          ...auditingProcedures,
          form: {
            ...auditingProcedures.form,
            auditingProcedures: auditingProceduresBatch,
          },
        },
      };

      editEntity({ id: auditing.id, data: auditingPatch });
    };

    const handleSelectChange = // saves select fields

        (key: keyof AuditingProcedure) =>
        (value?: React.SetStateAction<AuditingProcedureResult | undefined>) => {
          if (typeof value === 'string') {
            handleSave({ ...auditingProcedure, [key]: value });
          }
        };

    const saveTextToDatabase = // saves text to database
      (key: keyof AuditingProcedure, newValue: any) => {
        handleSave({ ...auditingProcedure, [key]: newValue });
      };

    const renderTextInputModal = (
      key: keyof AuditingProcedure,
      { showContextInfo }: { showContextInfo?: boolean } = {}
    ) => (
      <TextAreaSaveComponent
        auditingProcedure={auditingProcedure}
        idKey={key}
        saveTextToDatabase={saveTextToDatabase}
        label={t(`${tBase}.${key}`)}
      />
    );

    return (
      <Container>
        <Select<AuditingProcedureResult>
          label={t(`${tBase}.conclusionOfInspection`)}
          value={auditingProcedure.auditingResult ?? undefined}
          options={Object.values(AuditingProcedureResult)}
          displayValue={option =>
            t(`${tBase}.auditingResultOptionsNew.${option}`)
          }
          setValue={handleSelectChange('auditingResult')}
        />

        {renderTextInputModal('auditingReference')}
        {renderTextInputModal('inspectionDescription')}
        {renderTextInputModal('inspectionBackground')}
        {renderTextInputModal('auditingProcedureGoals')}
        {renderTextInputModal('auditingComments')}

        {children}
      </Container>
    );
  }
);

// Käsityksen muodostaminen
const DescribePeriodItem: React.FC<Props> = observer(
  /*
    This component renders only forming an opinion text box.
  */
  ({ auditingProcedure, children }) => {
    const { t } = useTranslation();

    const {
      auditingStore: { auditing, getAuditingSection, editEntity },
    } = useStore();

    const handleSave = (newAuditingProcedure: AuditingProcedure) => {
      /*
        saves auditingProcedures
      */
      const section = getAuditingSection('auditingProcedures');

      if (!auditing?.id || !section) return;

      const auditingProcedures =
        section as AuditingSection<AuditingProceduresForm>;

      const auditingProceduresBatch: AuditingProcedure[] =
        auditingProcedures.form.auditingProcedures.map(procedure =>
          procedure.id === newAuditingProcedure.id
            ? newAuditingProcedure
            : procedure
        );

      const auditingPatch: Partial<Auditing> = {
        auditingProcedures: {
          ...auditingProcedures,
          form: {
            ...auditingProcedures.form,
            auditingProcedures: auditingProceduresBatch,
          },
        },
      };

      editEntity({ id: auditing.id, data: auditingPatch });
    };

    const saveTextToDatabase = // saves text to database
      (key: keyof AuditingProcedure, newValue: any) => {
        handleSave({ ...auditingProcedure, [key]: newValue });
      };

    const renderTextInputModal = (
      key: keyof AuditingProcedure,
      { showContextInfo }: { showContextInfo?: boolean } = {}
    ) => (
      <TextAreaSaveComponent
        auditingProcedure={auditingProcedure}
        idKey={key}
        saveTextToDatabase={saveTextToDatabase}
        label={t(`${tBase}.${key}New`)}
      />
    );

    return (
      <Container>
        {renderTextInputModal('auditingProcedurePerceptions')}
        {children}
      </Container>
    );
  }
);
// Lähipiiri
const InnerCircleItem: React.FC<Props> = observer(
  ({ auditingProcedure, children }) => {
    const { t } = useTranslation();

    const {
      auditingStore: { auditing, getAuditingSection, editEntity },
    } = useStore();

    const [inspectionConclusionValid, setInspectionConclusionValid] = useState(
      auditingProcedure.innerCircleAuditingResult
        ? auditingProcedure.innerCircleAuditingResult
        : {}
    );

    const handleSave = (newAuditingProcedure: AuditingProcedure) => {
      /*
        saves auditingProcedures
      */
      const section = getAuditingSection('auditingProcedures');

      if (!auditing?.id || !section) return;

      const auditingProcedures =
        section as AuditingSection<AuditingProceduresForm>;

      const auditingProceduresBatch: AuditingProcedure[] =
        auditingProcedures.form.auditingProcedures.map(procedure =>
          procedure.id === newAuditingProcedure.id
            ? newAuditingProcedure
            : procedure
        );

      const auditingPatch: Partial<Auditing> = {
        auditingProcedures: {
          ...auditingProcedures,
          form: {
            ...auditingProcedures.form,
            auditingProcedures: auditingProceduresBatch,
          },
        },
      };

      editEntity({ id: auditing.id, data: auditingPatch });
    };

    const saveTextToDatabase = // saves text to database
      (key: keyof AuditingProcedure, newValue: any) => {
        handleSave({ ...auditingProcedure, [key]: newValue });
      };

    const saveInspectionResultToDatabase = // saves text to database
      (key: string, newValue: boolean | string) => {
        console.log(newValue);

        if (!auditingProcedure.innerCircleAuditingResult) {
          return;
        }

        const newInspectionConclusionValue = { ...inspectionConclusionValid };
        console.log(newInspectionConclusionValue);
        newInspectionConclusionValue[key] = newValue;
        setInspectionConclusionValid(newInspectionConclusionValue);
        handleSave({
          ...auditingProcedure,
          innerCircleAuditingResult: {
            [key]: newValue,
            ...auditingProcedure.innerCircleAuditingResult[key],
          },
        });
      };

    const renderTextInputModal = (
      key: keyof AuditingProcedure,
      { showContextInfo }: { showContextInfo?: boolean } = {}
    ) => (
      <TextAreaSaveComponent
        auditingProcedure={auditingProcedure}
        idKey={key}
        saveTextToDatabase={saveTextToDatabase}
        label={t(`${tBase}.${key}`)}
      />
    );

    return (
      <Container>
        <Container>
          <p>
            <small>
              <b>Lähipiiritapahtumien käsittely kirjanpidossa</b>
            </small>
          </p>
          <Checkbox
            label={t(`${tBase}.innerCircleValues.handlingInAccounting.true`)}
            setValue={newValue =>
              saveInspectionResultToDatabase(
                'handlingInAccounting',
                newValue as boolean
              )
            }
            checked={inspectionConclusionValid.handlingInAccounting}
          />
          <Checkbox
            label={t(`${tBase}.innerCircleValues.handlingInAccounting.false`)}
            setValue={newValue =>
              saveInspectionResultToDatabase(
                'handlingInAccounting',
                !newValue as boolean
              )
            }
            checked={inspectionConclusionValid.handlingInAccounting === false}
          />
        </Container>

        <Container>
          <hr />
          <p>
            <small>
              <b>
                Tilinpäätöksellä ja soveltuvin osin toimintakertomuksella
                esitetyt tiedot lähipiirisuhteista ja -liiketoimista
              </b>
            </small>
          </p>
          <Checkbox
            label={t(`${tBase}.innerCircleValues.demonstratedData.one`)}
            setValue={() =>
              saveInspectionResultToDatabase('demonstratedData', 'one')
            }
            checked={inspectionConclusionValid.demonstratedData === 'one'}
          />
          <Checkbox
            label={t(`${tBase}.innerCircleValues.demonstratedData.two`)}
            setValue={() =>
              saveInspectionResultToDatabase('demonstratedData', 'two')
            }
            checked={inspectionConclusionValid.demonstratedData === 'two'}
          />
          <Checkbox
            label={t(`${tBase}.innerCircleValues.demonstratedData.three`)}
            setValue={() =>
              saveInspectionResultToDatabase('demonstratedData', 'three')
            }
            checked={inspectionConclusionValid.demonstratedData === 'three'}
          />
        </Container>

        <Container>
          <hr />

          <p>
            <small>
              <b>
                Lähipiiritapahtumien vaikutus tilinpäätöksen antamaan oikeaan ja
                riittävään kuvaan
              </b>
            </small>
          </p>
          <Checkbox
            label={t(`${tBase}.innerCircleValues.effectOnPicture.true`)}
            setValue={newValue =>
              saveInspectionResultToDatabase(
                'effectOnPicture',
                newValue as boolean
              )
            }
            checked={inspectionConclusionValid.effectOnPicture}
          />
          <Checkbox
            label={t(`${tBase}.innerCircleValues.effectOnPicture.false`)}
            setValue={newValue =>
              saveInspectionResultToDatabase(
                'effectOnPicture',
                !newValue as boolean
              )
            }
            checked={inspectionConclusionValid.effectOnPicture === false}
          />
        </Container>

        <Container>
          <hr />
          <p>
            <small>
              <b>Väärinkäytösriskit</b>
            </small>
          </p>
          <Checkbox
            label={t(`${tBase}.innerCircleValues.abuseRisks.true`)}
            setValue={newValue =>
              saveInspectionResultToDatabase('abuseRisks', newValue as boolean)
            }
            checked={inspectionConclusionValid.abuseRisks}
          />
          <Checkbox
            label={t(`${tBase}.innerCircleValues.abuseRisks.false`)}
            setValue={newValue =>
              saveInspectionResultToDatabase('abuseRisks', !newValue as boolean)
            }
            checked={inspectionConclusionValid.abuseRisks === false}
          />
        </Container>
        <hr />

        {renderTextInputModal('auditingReference')}
        {renderTextInputModal('auditingProcedureGoals')}
        {renderTextInputModal('auditingComments')}

        {children}
      </Container>
    );
  }
);

type CustomSetting<T> = {
  /**
   * ID of the accordion we are creating/extending.
   * This must match a valid actionKey.
   */
  accordionId: string;

  /**
   * These elements are always placed above the generic auditing section form textarea inputs.
   */
  upperElements?: Array<FormField<T>>;

  /**
   * These elements are always placed below the generic auditing section form textarea inputs.
   */
  lowerElements?: Array<FormField<T>>;

  /**
   * This field determines which AuditingProcedure fields should be hidden.
   * i.e:
   * ```
   * const hiddenElements: keyof AuditingProcedure = ['auditingComments']
   * ```
   */
  hide?: Array<keyof AuditingProcedure>;

  /**
   * Whether to use default component or not. Used for some reusable components.
   */
  useDefault?: boolean;
};

/**
 *
 * @param store Root store for localStorage
 * @param batchString Key of the auditing category
 * @param formBuilder Form builder
 * @param customSettings The custom form fields/custom render callbacks you want to pass
 * @returns An array of accordionGroups as form fields.
 */
export function displayAuditingProcedures<T>(
  store: RootStore,
  batchString: FinancialStatementBatchKey,
  formBuilder: FormFieldBuilder<T>,
  customSettings?: Array<CustomSetting<T>>
): Array<FormField<T>> {
  /*
    Creates an element for each auditingProcedure + adds custom elements defined in customSettings
  */
  const auditingProceduresOfThisBatch =
    store.auditingStore.getAuditingProceduresBatch(batchString);

  // The exclusion array
  const exclusions = [
    {
      batchString: 'G-management',
      actionKeys: ['innerCircle'],
    },
    // Add more exclusions here
  ];

  const elements = auditingProceduresOfThisBatch
    .map((procedure: AuditingProcedure) => {
      let setting: CustomSetting<T> = {
        accordionId: procedure.actionKey,
        upperElements: [],
        lowerElements: [],
        hide: [],
        useDefault: true,
      };

      const customSettingOfProcedure = customSettings?.find(
        setting => setting.accordionId === procedure.actionKey
      );
      setting = customSettingOfProcedure ?? setting;

      // Check if the procedure should be excluded
      const shouldExclude = exclusions.some(
        exclusions =>
          exclusions.batchString === batchString &&
          exclusions.actionKeys.includes(procedure.actionKey)
      );
      if (shouldExclude) {
        return null;
      }

      const customElementMapping: Record<string, JSX.Element> = {
        describePeriod: <DescribePeriodItem auditingProcedure={procedure} />,
        accountingEstimates: (
          <AccountingEstimatesItem auditingProcedure={procedure} />
        ),
        innerCircle: <InnerCircleItem auditingProcedure={procedure} />,
      };

      // Accordions with the id describePeriod will be displayed with just one text box(describePeriod text box)
      const customElement =
        setting.useDefault && customElementMapping[procedure.actionKey] ? (
          customElementMapping[procedure.actionKey]
        ) : (
          <AuditingProcedureItem
            auditingProcedure={procedure}
            hideFields={setting.hide}
          />
        );

      return formBuilder.accordionGroup({
        items: [
          ...(setting.upperElements ?? []),
          formBuilder.custom(() => customElement),
          ...(setting.lowerElements ?? []),
        ],
        title: (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <ProcedureResult auditingResult={procedure.auditingResult}>
              <span>{procedure.action}</span>
            </ProcedureResult>
          </div>
        ),
      });
    })
    .filter((element): element is FormField<T> => element !== null);

  return elements;
}
