import React from 'react';
import { useTranslation } from 'react-i18next';
import { formatCurrency, getSummedAccountItems } from '../../../utils';
import AuditingTable from '../../table/AuditingTable';
import { OnEditProps, TableColumnDefs } from '../../table/Table';

interface FormIncludingAccountMappings {
  accountMappings: AccountMappings;
}

interface Props extends FormFieldProps<FormIncludingAccountMappings> {
  classKey: AccountClassKey;
  auditing?: Auditing;
  accountMappings: AccountMappings;
}

function AccountMappings({
  classKey,
  auditing,
  accountMappings,
  formState,
  patchFormState,
}: Props) {
  const { t } = useTranslation();

  const auditingGeneralLedger =
    auditing?.generalLedger?.form.groupedGeneralLedger;

  function handleEdit<T>({ itemId, value }: OnEditProps<T>) {
    patchFormState({
      ...formState,
      accountMappings: {
        ...formState.accountMappings,
        [`${itemId}`]: value as string,
      },
    });
  }

  const data: SummedAccountItem[] = getSummedAccountItems(
    auditingGeneralLedger ?? [],
    accountMappings
  );

  const tableColumns: TableColumnDefs<SummedAccountItem> = [
    {
      accessorKey: 'accountRange',
      header: () => t('auditing:accountMappings.rowsToSum'),
      className: 'width-min text-left nowrap',
      onEdit: (editProps: OnEditProps<string>) => {
        handleEdit<string>(editProps);
      },
      contextInfoProps: {
        text: t('auditing:accountMappings.rowsToSumInfo'),
        position: 'top-right',
      },
    },
    {
      accessorKey: 'accountName',
      accessorFn: row =>
        t(`auditing:accountMappings.${classKey}.${row.accountKey}`),
      header: () => null,
      className: 'width-full text-bold',
    },
    {
      accessorKey: 'currentYear',
      header: () => 'CY',
      className: 'width-min text-right nowrap',
      accessorFn: row => formatCurrency(row.currentYear),
    },
    {
      accessorKey: 'priorYear',
      header: () => 'PY',
      className: 'width-min text-right nowrap',
      accessorFn: row => formatCurrency(row.priorYear),
    },
  ];

  return (
    <AuditingTable
      data={data}
      columns={tableColumns}
      showGlobalFilter={false}
      disableSort
    />
  );
}

export default AccountMappings;
