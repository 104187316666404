import { displayAuditingProcedures } from '../../../components/auditing/AuditingProcedureItem2';
import {
  AuditAccuredTableColumn,
  DebtInstallmentsTableColumn,
  OtherDebtsTableColumn,
  OtherDebtsTableColumn2,
} from '../../../components/auditing/OtherLiabilities';
import auditingJson from '../../../i18n/locales/fi/auditing.json';
import { FormFieldBuilder } from '../FormFieldBuilder';
import {
  innerCircleElement,
  innerCircleElementDFS,
} from './reUsables/innerCircle';
import { ProcedureAccountsElement } from './reUsables/ProcedureAccountsElement';
import { SpreadSheet } from './reUsables/SpreadSheet';
import { TableColumn } from './reUsables/TableColumn';

type SectionFormType = UseInnerCircle<OtherLiabilitiesForm>;
const sectionKey: AuditingSectionKey = 'otherLiabilities';
export const tBase = `auditing:form.${sectionKey}`;

const batchString = 'T1-otherLiabilities';

const formBuilder = new FormFieldBuilder<SectionFormType>({ sectionKey });

const defaultFormState: DefaultFormState<SectionFormType> = ({ auditing }) => ({
  procedureAccountsManualData: [
    {
      currentYear: 0,
      priorYear: 0,
      accountName: '',
      comment: '',
    },
  ],
  procedureAccountsData: {},
  otherLiabilitiesData: {},
  creditorsTable: [
    {
      id: '1',
      CY: 0,
      PY: 0,
    },
  ],
  debtInstallments: [
    {
      id: '1',
      ledgerBalance: 0,
      backgroundMaterial1: 0,
      backgroundMaterial2: 0,
      backgroundMaterial3: 0,
      difference: 0,
      backgroundMaterial: '',
      other: '',
      hidden: true,
      addNewMaterial: false,
    },
  ],
  otherDebts: [
    {
      id: '1',
      ledgerBalance: 0,
      backgroundMaterial1: 0,
      backgroundMaterial2: 0,
      backgroundMaterial3: 0,
      difference: 0,
      backgroundMaterial: '',
      other: '',
      hidden: true,
      addNewMaterial: false,
    },
  ],
  otherDebts2: [
    {
      id: '1',
      ledgerBalance: 0,
      backgroundMaterial1: 0,
      backgroundMaterial2: 0,
      backgroundMaterial3: 0,
      difference: 0,
      backgroundMaterial: '',
      other: '',
      hidden: true,
      addNewMaterial: false,
    },
  ],
  auditAccured: [
    {
      id: '1',
      ledgerBalance: 0,
      backgroundMaterial1: 0,
      backgroundMaterial2: 0,
      backgroundMaterial3: 0,
      difference: 0,
      backgroundMaterial: '',
      other: '',
      hidden: true,
      addNewMaterial: false,
    },
  ],
  tableColumnData: [
    {
      left: 'Velat, jotka taseelle on merkitty ovat olemassa.',
      right: auditingJson.OLE,
    },
    {
      left: 'Taseella esitetyt velat ovat yhtiön velvotteita.',
      right: auditingJson.OVE,
    },
    {
      left: 'Kaikki velat, jotka olisi pitänyt kirjata on kirjattu ja niihin liittyvät tiedot, jorka olisi pitänyt esittää tilinpäätöksellä on esitetty.',
      right: auditingJson.TAY,
    },
    {
      left: 'Velat on esitetty asianmukaisessa määrässä, arvostamisesta ja kohdistamisesta johtuvat oikaisut on kirjattu asianmukaisesti.',
      right: auditingJson.ARV,
    },
    {
      left: 'Velat on luokiteltu oikein',
      right: auditingJson.LUOK,
    },
    {
      left: 'Velat on yhdistelty tai jaoteltu asianmukaisesti ja niihin liittyvät tilinpäätöksessä esitettävät tiedot ovat relevantteja.',
      right: auditingJson.ESI,
    },
  ],
  conclusion:
    'Suoritetuilla tarkastustoimenpiteillä on saatu riittävä evidenssi vieraan pääoman erien oikeellisuudesta ja tarkastuksen tavoitteet on saavutettu. Suoritetun tarkastuksen perusreella erät ovat olennaisin osin oikein',

  loansIncluded: null,

  debtsIncluded: null,
  debtsIncluded2: null,

  liaibilitiesIncluded: null,

  spreadsheet1: [
    {
      cellData: [],
    },
  ],
  spreadsheet2: [
    {
      cellData: [],
    },
  ],
  spreadsheet3: [
    {
      cellData: [],
    },
  ],
  spreadsheet4: [
    {
      cellData: [],
    },
  ],
  spreadsheet5: [
    {
      cellData: [],
    },
  ],
  spreadsheet6: [
    {
      cellData: [],
    },
  ],
  // Sheets
  useSheetTab: null,
  useSheet1: null,
  useSheet2: null,
  useSheet3: null,
  useSheet4: null,
  useSheet5: null,
  useSheet6: null,
  ...innerCircleElementDFS,
});

const formFields: FormFields<SectionFormType> = ({
  formState,
  auditing,
  store,
}) => [
  formBuilder.group({
    items: [
      formBuilder.boolean({
        accessor: 'useSheetTab',
        plainLabel: 'Käytä laskenta taulukko osiota',
      }),
      formBuilder.custom(props => (
        <ProcedureAccountsElement
          auditing={auditing}
          {...props}
          batchString={batchString}
          addCommentField
        />
      )),
      formBuilder.custom(props => (
        <TableColumn
          title="Tarkastuksen tavoitteet ja kannantot, joihin tarkastuksella vastataan"
          {...props}
        />
      )),
      formBuilder.textArea({ accessor: 'conclusion' }),
    ],
  }),

  ...displayAuditingProcedures(store, batchString, formBuilder, [
    {
      //
      accordionId: 'inspectionOfLoans',
      lowerElements: [
        formBuilder.boolean({ accessor: 'loansIncluded' }),
        formBuilder.custom(props => {
          return formState.loansIncluded ? (
            <DebtInstallmentsTableColumn {...props} />
          ) : null;
        }),
        formBuilder.boolean({
          accessor: 'useSheet1',
          plainLabel: auditingJson.useSheet,
        }),
        formBuilder.custom(props =>
          formState.useSheet1 ? (
            <SpreadSheet id="spreadsheet1" {...props} />
          ) : null
        ),
      ],
    },
    {
      // Muiden velkojen tarkastus
      accordionId: 'auditOfOtherDebts',
      lowerElements: [
        formBuilder.boolean({ accessor: 'debtsIncluded' }),
        formBuilder.custom(props => {
          return formState.debtsIncluded ? (
            <OtherDebtsTableColumn {...props} />
          ) : null;
        }),
        formBuilder.boolean({
          accessor: 'useSheet2',
          plainLabel: auditingJson.useSheet,
        }),
        formBuilder.custom(props =>
          formState.useSheet2 ? (
            <SpreadSheet id="spreadsheet2" {...props} />
          ) : null
        ),
      ],
    },
    {
      // Siitrovelkojen tarkastus
      accordionId: 'auditOfAccruedLiabilities',
      lowerElements: [
        formBuilder.boolean({ accessor: 'liaibilitiesIncluded' }),
        formBuilder.custom(props => {
          return formState.liaibilitiesIncluded ? (
            <AuditAccuredTableColumn {...props} />
          ) : null;
        }),
        formBuilder.boolean({
          accessor: 'useSheet3',
          plainLabel: auditingJson.useSheet,
        }),
        formBuilder.custom(props =>
          formState.useSheet3 ? (
            <SpreadSheet id="spreadsheet3" {...props} />
          ) : null
        ),
      ],
    },
    {
      // Velkojen luokittelun tarkastus
      accordionId: 'inspectionOfTheClassification',
      lowerElements: [
        formBuilder.boolean({
          accessor: 'useSheet4',
          plainLabel: auditingJson.useSheet,
        }),
        formBuilder.custom(props =>
          formState.useSheet4 ? (
            <SpreadSheet id="spreadsheet4" {...props} />
          ) : null
        ),
      ],
    },
    {
      // Muiden velkojen ja siirtovelkojen täydellisyys
      accordionId: 'completenessOfOther',
      lowerElements: [
        formBuilder.boolean({
          accessor: 'useSheet5',
          plainLabel: auditingJson.useSheet,
        }),
        formBuilder.custom(props =>
          formState.useSheet5 ? (
            <SpreadSheet id="spreadsheet5" {...props} />
          ) : null
        ),
      ],
    },
    {
      // checkingOtherDebts
      accordionId: 'checkingOtherDebts',
      lowerElements: [
        formBuilder.boolean({ accessor: 'debtsIncluded2' }),
        formBuilder.custom(props => {
          return formState.debtsIncluded2 ? (
            <OtherDebtsTableColumn2 {...props} />
          ) : null;
        }),
        formBuilder.boolean({
          accessor: 'useSheet6',
          plainLabel: auditingJson.useSheet,
        }),
        formBuilder.custom(props =>
          formState.useSheet6 ? (
            <SpreadSheet id="spreadsheet6" {...props} />
          ) : null
        ),
      ],
    },
    {
      // Lähipiiri
      useDefault: false,
      accordionId: 'innerCircle',
      upperElements: innerCircleElement(formBuilder),
    },
  ]),
];

const section: AuditingSectionDefinition<SectionFormType> = {
  sectionKey,
  defaultFormState,
  formFields,
};

export default section;
