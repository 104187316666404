import React from 'react';
import { InputContainer } from './InputContainer';
import { Label } from './Label';
import auditingJson from '../../i18n/locales/fi/auditing.json';

interface Props extends CommonInputProps {
  placeholder?: string;
  value: string | number;
  onChange?: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
  setValue?: React.Dispatch<React.SetStateAction<string>>;
  setNumberValue?: React.Dispatch<React.SetStateAction<number>>;
  rows?: number;
  fullWidth?: boolean;
  showRemark?: boolean;
}

export const TextArea: React.FC<Props> = ({
  plainLabel,
  name,
  label,
  contextInfo,
  placeholder,
  value,
  onChange,
  setValue,
  setNumberValue,
  rows = 5,
  autoFocus,
  required,
  fullWidth,
  disabled,
  showRemark,
}) => {
  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    if (onChange) onChange(event);
    if (setValue) setValue(event.currentTarget.value);
    if (setNumberValue) setNumberValue(Number(event.currentTarget.value));
  };

  const regex = /https?:\/\/[www]?\S+\.\w{1,3}\S+/g;
  const detectedLinks = String(value).match(regex);
  const isReference = (String(label) + String(plainLabel)).includes(
    auditingJson.reference
  );

  return (
    <InputContainer fullWidth={fullWidth}>
      {plainLabel ? (
        <Label
          disabled={disabled}
          contextInfo={contextInfo}
          className={required ? 'is-required' : ''}
          showRemark={showRemark}
        >
          {plainLabel}
        </Label>
      ) : (
        label && (
          <Label
            disabled={disabled}
            contextInfo={contextInfo}
            className={required ? 'is-required' : ''}
            showRemark={showRemark}
          >
            {label}
          </Label>
        )
      )}
      <textarea
        name={name}
        value={value}
        onChange={handleChange}
        rows={rows}
        placeholder={placeholder}
        autoFocus={autoFocus}
        required={required}
        disabled={disabled}
      />
      {isReference && detectedLinks && <p>Linkit:</p>}
      {isReference &&
        detectedLinks &&
        detectedLinks.map((link, index) => (
          <a target="__blank" style={{ fontSize: 15 }} href={link} key={index}>
            {link}
            <br />
          </a>
        ))}
    </InputContainer>
  );
};

export default TextArea;
