import { FormFieldBuilder } from '../FormFieldBuilder';
// Import necessary modules and components
import { displayAuditingProcedures } from '../../../components/auditing/AuditingProcedureItem2';
import { AdvancedTaxTable, LastSeasonTable, TaxAccrualTable, TaxCalculationTable } from '../../../components/auditing/Taxes.Tables';
import auditingJson from '../../../i18n/locales/fi/auditing.json';
import { ProcedureAccountsElement } from './reUsables/ProcedureAccountsElement';
import { SpreadSheet } from './reUsables/SpreadSheet';
import { TableColumn } from './reUsables/TableColumn';
// Define the vocabulary from an external JSON file
const jsonVocab = auditingJson.form.taxes;

type SectionFormType = TaxesForm;

const sectionKey: AuditingSectionKey = 'taxes';
const batchString = 'N-taxes';

export const tBase = `auditing:form.${sectionKey}`;

const formBuilder = new FormFieldBuilder<SectionFormType>({ sectionKey });

const defaultFormState: DefaultFormState<SectionFormType> = ({ auditing }) => {
  const incomeStatement: IncomeStatementAndBalanceScopingItem[] =
    auditing?.incomeStatementAndBalanceScoping?.form?.incomeStatement || [];
  return {
    procedureAccountsManualData: [
      {
        currentYear: 10000,
        priorYear: 10000,
        accountName: 'esimerkki',
        comment: '',
      },
    ],
    useSheet: null,
    sheetdata1: [
      {
        cellData: [],
      },
    ],
    useSheet2: null,
    sheetdata2: [
      {
        cellData: [],
      },
    ],

    // ProcedureAccounts
    procedureAccountsData: {},

    tableColumnData: [
      {
        left: 'Kirjatut tapahtumat ovat toteutuneet ja kuuluvat yhteisölle.	',
        right: auditingJson.TAP,
      },
      {
        left: 'Tapahtumat, jotka olisi pitänyt kirjata, on kirjattu, ja kaikki niihin liittyvät tiedot, jotka olisi pitänyt esittää tilinpäätöksessä, on esitetty siinä.	',
        right: auditingJson.TAY,
      },
      {
        left: 'Tapahtumiin liittyvät rahamäärät ja muu tieto on kirjattu asianmukaisesti, ja niihin liittyvät tilinpäätöksessä esitettävät tiedot on määritetty ja kuvattu asianmukaisesti.	',
        right: auditingJson.OIK_ARV,
      },
      {
        left: 'Tapahtumat on kirjattu oikealle tilikaudelle.',
        right: auditingJson.KAT,
      },
      {
        left: 'Tapahtumat on kirjattu oikeille tileille  / oikeaan erään kirjanpidossa	',
        right: auditingJson.LUOK,
      },
      {
        left: 'Tapahtumat on yhdistelty tai jaoteltu asianmukaisesti ja kuvattu selvästi, ja niihin liittyvät tilinpäätöksessä esitettävät tiedot ovat relevantteja ja ymmärrettäviä sovellettavan tilinpäätösnormiston vaatimukset huomioon ottaen	',
        right: auditingJson.ESI,
      },
    ],

    describePeriodTextArea: jsonVocab.describePeriodTextArea,
    AMPT: null,
    reference1: jsonVocab.reference1,
    ideasGoalsAndCompletedWork: jsonVocab.ideasGoalsAndCompletedWork,
    notesAndComments: jsonVocab.notesAndComments,
    TwoFieldSubtractionFormData: {
      '1': [],
      '2': [],
      '3': [],
    },
    TaxCalculationFormData: {
      '1': {},
    },
    lastAuditSeasonTaxAccounted: null,
    giveAdditionalInfoIfNeeded: auditingJson.giveAdditionalInfoIfNeeded,
    doesFirmHaveUnUsedLosses: null,
    giveAdditionalInfoIfNeeded2: jsonVocab.giveAdditionalInfoIfNeeded2,

    lastSeasonTable: [
      {
        id: '1',
        label: 'Edellisen tilikauden tulovero kirjanpidossa',
        value: 0,
      },
      {
        id: '2',
        label: 'Verotuspäätöksen mukainen tulovero',
        value: 0,
      }
    ],
    taxAccrualTable: [
      {
        id: '1',
        label: 'Verojaksotus laskelmalla N1.1',
        value: 0,
      },
      {
        id: '2',
        label: 'Verojaksotus pääkirjanpidossa',
        value: 0,
      }
    ],
    advanceTaxTable: [
      {
        id: '1',
        label: 'Ennakkovero ennakkoveropäätöksellä N3',
        value: 0,
      },
      {
        id: '2',
        label: 'Ennakkovero kirjanpidossa',
        value: 0,
      }
    ],
    taxCalculationTable: [
      {
        id: '1',
        label: 'Veroprosentti %',
        value: 20,
      },
      {
        id: '2',
        label: 'Voitto ennen veroja €',
        // 'profitOrLossBeforeTransfersAndTaxes' from 'incomeStatementAndBalanceScoping'
        value: incomeStatement.find(item => item.key === 'profitOrLossBeforeTransfersAndTaxes')?.currentYear ?? 0,
      }
    ],
    additionTable: [
      {
        id: '1',
        label: 'Edustuskulut',
        value: 0,
      },
      {
        id: '2',
        label: 'Verojen viivästysseuraukset',
        value: 0,
      },
      {
        id: '3',
        label: 'Sakot',
        value: 0,
      },
      {
        id: '4',
        label: 'Poistot, jotka ylittävät EVL poistot',
        value: 0,
      },
    ],
    subtractionTable: [
      {
        id: '1',
        label: 'Vahvistetut tappiot',
        value: 0,
      },
      {
        id: '2',
        label: 'Verovapaat osingot',
        value: 0,
      },
      {
        id: '3',
        label: 'Aikaisempina vuosina tehdyt poistot, (hyllypoistot)',
        value: 0,
      },
    ],
    lastTaxesTable: [
      {
        id: '0',
        label: 'Tilikauden ennakkoverot',
        value: 0,
      },
      {
        id: '1',
        label: 'Verovelka / -Verosaaminen',
        value: 10000,
      },
      {
        id: '2',
        label: 'Kirjanpidon verojaksotus',
        value: 0,
      },
      {
        id: '3',
        label: 'Ero',
        value: 0,
      },
    ],

    // checkingOFTaxes
    AMPT2: null,
    reference2: jsonVocab.reference2,
    ideasGoalsAndCompletedWork2: jsonVocab.ideasGoalsAndCompletedWork2,
    notesAndComments2: jsonVocab.notesAndComments2,
    useFoundation: null,

    giveAdditionalInfoIfNeeded3: jsonVocab.giveAdditionalInfoIfNeeded3,
    giveAdditionalInfoIfNeeded4: jsonVocab.giveAdditionalInfoIfNeeded4,
    giveAdditionalInfoIfNeeded5: jsonVocab.giveAdditionalInfoIfNeeded5,
    useSheetTab: null,
  };
};

const formFields: FormFields<SectionFormType> = ({
  formState,
  auditing,
  store,
}) => [
  formBuilder.group({
    items: [
      formBuilder.boolean({
        accessor: 'useSheetTab',
        plainLabel: 'Käytä laskenta taulukko osiota',
      }),
      formBuilder.custom(props => (
        <ProcedureAccountsElement
          {...props}
          auditing={auditing}
          batchString={batchString}
          addCommentField={true}
        />
      )),
      formBuilder.custom(props => <TableColumn {...props} />),
    ],
  }),

  ...displayAuditingProcedures(store, batchString, formBuilder, [
    {
      // Edellisen tilikauden verojen...
      accordionId: 'lastSeasonTaxesCheck',
      upperElements: [],
      lowerElements: [
        formBuilder.custom(props => (
          <LastSeasonTable {...props} />
        )),

        formBuilder.group({
          items: [
            formBuilder.checkboxGroup({
              accessor: 'lastAuditSeasonTaxAccounted',
              options: Object.keys(jsonVocab.noticedDifferencesOptions),
              optionDisplayValue: (key: string) =>
                jsonVocab.noticedDifferencesOptions[
                  key as keyof typeof jsonVocab.noticedDifferencesOptions
                ],
            }),
            formBuilder.textArea({
              accessor: 'giveAdditionalInfoIfNeeded',
              plainLabel: auditingJson.giveAdditionalInfo,
            }),
          ],
        }),

        formBuilder.group({
          items: [
            formBuilder.boolean({
              accessor: 'doesFirmHaveUnUsedLosses',
              options: ['yes', 'no'],
            }),
            formBuilder.textArea({
              accessor: 'giveAdditionalInfoIfNeeded2',
              plainLabel: auditingJson.giveAdditionalInfo,
            }),
          ],
        }),

        formBuilder.boolean({
          plainLabel: auditingJson.useSheet,
          accessor: 'useSheet',
        }),
        formBuilder.custom(props =>
          formState.useSheet ? <SpreadSheet id="sheetdata1" {...props} /> : null
        ),
      ],
    },
    {
      // Tilikauden verojen tarkastaminen
      accordionId: 'checkingOfTaxes',
      lowerElements: [
        formBuilder.checkboxGroup({
          accessor: 'useFoundation',
          options: Object.keys(jsonVocab.useFoundationOptions),
          plainLabel: ' ',
          optionDisplayValue: (key: string) =>
            jsonVocab.useFoundationOptions[
              key as keyof typeof jsonVocab.useFoundationOptions
            ],
        }),
        formBuilder.group({
          items: [
            formBuilder.subtitle({
              title: 'Asiakkaan verolaskelman täsmäytys kirjanpitoon ',
            }),
            formBuilder.custom(props => (
              <TaxAccrualTable {...props} />
            )),
            formBuilder.textArea({
              accessor: 'giveAdditionalInfoIfNeeded3',
              plainLabel: auditingJson.remarksAndComments,
            }),
          ],
          hidden: !(formState.useFoundation && formState.useFoundation.one),
        }),

        formBuilder.group({
          items: [
            formBuilder.subtitle({
              title:
                'Ennakkoveropäätöksen täsmäytys kirjanpidon ennakkoveroihin',
            }),
            formBuilder.custom(props => (
              <AdvancedTaxTable {...props} />
            )),
            formBuilder.textArea({
              accessor: 'giveAdditionalInfoIfNeeded4',
              plainLabel: auditingJson.remarksAndComments,
            }),
          ],
          hidden: !(formState.useFoundation && formState.useFoundation.two),
        }),

        formBuilder.custom(props =>
          formState.useFoundation !== null &&
          formState.useFoundation.hasOwnProperty('three') &&
          formState.useFoundation.three! === true ? (
            //<TaxCalculationForm {...props} id="1" />
            <TaxCalculationTable {...props} />
          ) : null
        ),

        formBuilder.boolean({
          plainLabel: auditingJson.useSheet,
          accessor: 'useSheet2',
        }),
        formBuilder.custom(props =>
          formState.useSheet2 ? (
            <SpreadSheet id="sheetdata2" {...props} />
          ) : null
        ),
      ],
    },
  ]),
];

const section: AuditingSectionDefinition<SectionFormType> = {
  sectionKey,
  defaultFormState,
  formFields,
};

export default section;
