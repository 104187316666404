import React, { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';

const StyledMain = styled.main`
  height: 100%;
  overflow-y: scroll;
  padding-top: ${p => p.theme.spacing.md};
  padding-right: ${p => p.theme.spacing.xl};
  padding-bottom: ${p => p.theme.spacing.lg};
  padding-left: ${p => p.theme.spacing.xl};
  width: 100%;
`;

interface Props {
  children: React.ReactNode;
}

const Main: React.FC<Props> = ({ children }) => {
  const { pathname } = useLocation();

  const mainRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    mainRef.current?.scrollTo({ top: 0, behavior: 'smooth' });
  }, [pathname]);

  return <StyledMain ref={mainRef}>{children}</StyledMain>;
};

export default Main;
