import React, { FC } from 'react';
import { useTheme } from 'styled-components';
import { Colors } from '../theme/styled';
import {
  FaBars,
  FaBuilding,
  FaCalculator,
  FaCheck,
  FaCheckCircle,
  FaChevronDown,
  FaChevronUp,
  FaExclamation,
  FaExclamationTriangle,
  FaEye,
  FaEyeSlash,
  FaHourglassHalf,
  FaMinus,
  FaPencilAlt,
  FaPlus,
  FaQuestionCircle,
  FaFrown,
  FaTrashAlt,
  FaUndo,
  FaUser,
  FaUsers,
  FaRedoAlt,
  FaLock,
  FaFilePdf,
} from 'react-icons/fa';
import { MdLogout, MdClose } from 'react-icons/md';

const Icons = {
  Approved: FaCheckCircle,
  AuditFirm: FaCalculator,
  Bars: FaBars,
  Check: FaCheck,
  ChevronDown: FaChevronDown,
  ChevronUp: FaChevronUp,
  Close: MdClose,
  CustomerCompany: FaBuilding,
  Delete: FaTrashAlt,
  Edit: FaPencilAlt,
  Exclamation: FaExclamation,
  ExclamationTriangle: FaExclamationTriangle,
  Hourglass: FaHourglassHalf,
  Lock: FaLock,
  Logout: MdLogout,
  Minus: FaMinus,
  NotVisible: FaEyeSlash,
  Pdf: FaFilePdf,
  Plus: FaPlus,
  Question: FaQuestionCircle,
  Refresh: FaRedoAlt,
  Selected: FaCheck,
  Undo: FaUndo,
  Unhappy: FaFrown,
  User: FaUser,
  Users: FaUsers,
  Visible: FaEye,
};

export type IconType = keyof typeof Icons;
export type IconColor = keyof Colors;

interface Props {
  id?: string;
  type: IconType;
  color?: IconColor;
  size?: number | string;
  className?: string;
  pullLeft?: boolean;
  pullRight?: boolean;
}

const Icon: FC<Props> = ({
  id = 'icon-component',
  type,
  color = 'primary',
  size = '1rem',
  className,
  pullLeft,
  pullRight,
}) => {
  const theme = useTheme();

  const iconProps = {
    id,
    size,
    color: color ? theme.color[color] : undefined,
    className,
    style: {
      marginRight: pullLeft ? '4px' : undefined,
      marginLeft: pullRight ? '4px' : undefined,
    },
  };

  const IconComponent = Icons[type];

  return <IconComponent data-testid={id} {...iconProps} />;
};

export default Icon;
