import styled from 'styled-components';
import Todo from '../../../components/Todo';
import { FormFieldBuilder } from '../FormFieldBuilder';
import { LimitedErrors, SignificantErrors } from './reporting.Tables';
import HealthIndexPieChart from './reportingUtils';

type SectionFormType = ReportingForm;

const sectionKey: AuditingSectionKey = 'reporting';

export const tBase = `auditing:form.${sectionKey}`;

const formBuilder = new FormFieldBuilder<SectionFormType>({ sectionKey });

const defaultFormState: DefaultFormState<SectionFormType> = ({ auditing }) => {
  const procedures: AuditingProcedure[] =
    auditing?.auditingProcedures?.form.auditingProcedures ?? [];

  const incomeStatement: IncomeStatementAndBalanceItem[] =
    auditing?.incomeStatementAndBalance?.form.incomeStatement ?? [];
  const balanceAssets: IncomeStatementAndBalanceItem[] =
    auditing?.incomeStatementAndBalance?.form.balanceAssets ?? [];
  const balanceLiabilities: IncomeStatementAndBalanceItem[] =
    auditing?.incomeStatementAndBalance?.form.balanceLiabilities ?? [];

  const indicators: IndicatorsTableItem[] =
    auditing?.indicators?.form.indicators ?? [];

  return {
    procedures: procedures,

    statements: [
      {
        incomeStatement: incomeStatement,
        balanceAssets: balanceAssets,
        balanceLiabilities: balanceLiabilities,
      },
    ],

    indicators: indicators,
  };
};

const formFields: FormFields<SectionFormType> = ({ formState, auditing }) => {
  return [
    formBuilder.custom(
      <Todo text="Sisäinen valvonta + Laki ja verotus sekä Muut virheet tekemättä." />
    ),

    /* formBuilder.custom(props => 
      {
        console.log(formState)
        return null;
      }
    ), */

    formBuilder.custom(props => <HealthIndexPieChart formState={formState} />),

    formBuilder.accordionGroup({
      title: 'Tietojen tarkastaminen',
      items: [
        formBuilder.custom(props => {
          const Container = styled.div`
            .addition-info {
              text-align: center;
            }
          `;
          return (
            <Container>
              <div className="addition-info">
                <h4>
                  Tarkista että on tiedot saatavilla, jos ympyrädiagrammi ei
                  näy.
                </h4>
                <p>Jos tietoja ei ole saatavilla, käy antamassa tietoja.</p>
              </div>
            </Container>
          );
        }),
        formBuilder.custom(props => {
          const Container = styled.div`
            .addition-info {
              text-align: center;
              margin: 0 0 0 0;
            }
          `;

          const notAvailable = 'Tietoja ei saatavilla.'; // If no data is available, show this text
          const available = 'Tiedot saatavilla.'; // If data is available, show this text
          const notAvailableColor = 'red';
          const availableColor = 'green';

          const procedures = formState.procedures ?? [];
          const isProceduresAvailable = procedures.length > 0;

          const indicators = formState.indicators ?? [];
          const isIndicatorsAvailable = indicators.length > 0;

          const statements = formState.statements ?? [];
          const isStatementsAvailable = statements.length > 0;
          return (
            <Container>
              <div className="addition-info">
                <p>
                  Tarkastuksen toteutus:{' '}
                  {isProceduresAvailable ? (
                    <span style={{ color: availableColor }}>{available}</span>
                  ) : (
                    <span style={{ color: notAvailableColor }}>
                      {notAvailable}
                    </span>
                  )}
                </p>
                <p>
                  Tuloslaskelma ja tase:{' '}
                  {isStatementsAvailable ? (
                    <span style={{ color: availableColor }}>{available}</span>
                  ) : (
                    <span style={{ color: notAvailableColor }}>
                      {notAvailable}
                    </span>
                  )}
                </p>
                <p>
                  Tunnusluvut:{' '}
                  {isIndicatorsAvailable ? (
                    <span style={{ color: availableColor }}>{available}</span>
                  ) : (
                    <span style={{ color: notAvailableColor }}>
                      {notAvailable}
                    </span>
                  )}
                </p>
              </div>
            </Container>
          );
        }),
      ],
    }),

    /**
     * Accordion group for ''
     * Display here risks that are 'significant' and AuditingProcedureResult are 'shortcomings'
     */
    formBuilder.accordionGroup({
      title: 'Merkittävät ja olennaisimmat virheet',
      items: [formBuilder.custom(props => <SignificantErrors {...props} />)],
    }),

    /**
     * Accordion group for ''
     * Display here risks that are 'significant' and AuditingProcedureResult are 'treatedModerately'
     */
    formBuilder.accordionGroup({
      title: 'Merkittävä ja kohtalaiset virheet',
      items: [formBuilder.custom(props => <LimitedErrors {...props} />)],
    }),

    /**
     * Accordion group for ''
     * Display here others risks such as 'limitedRisk'
     */
    formBuilder.accordionGroup({
      title: 'Muut virheet',
      items: [formBuilder.text({ text: 'Tähän tulee muut virheet.' })],
    }),
  ];
};

const section: AuditingSectionDefinition<SectionFormType> = {
  sectionKey,
  defaultFormState,
  formFields,
  // Dont need to depend on other sections another than generalLedger to get the values
  depending: ['generalLedger'],
};

export default section;
