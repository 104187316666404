import { OnEditProps, TableColumnDefs } from "../table/Table";
import AuditingTable from "../table/AuditingTable";
import { uuid } from "../../utils";

const sectionKey: AuditingSectionKey = 'taxes';

export const LastSeasonTable = ({
  formState,
  patchFormState,
}: FormFieldProps<TaxesForm>) => {

    if (!formState) return null;

    const data = formState.lastSeasonTable ?? [];

    function handleEdit<T>({
        itemId,
        rowIndex,
        columnId,
        value,
    }: OnEditProps<T>) {
    const patch = formState.lastSeasonTable.map((row, index) => {
        const idMatch = itemId !== undefined && itemId === row.id;
        const rowIndexMatch = itemId === undefined && rowIndex === index;
        if (idMatch || rowIndexMatch) return { ...row, [columnId]: value };
        return row;
    });
        patchFormState({ ...formState, lastSeasonTable: patch });
    }

    const tableColumns: TableColumnDefs<LastSeasonTable> = [
        {
            accessorKey: 'label',
            header: () => ' ',
            className: 'text-left width-middle nowrap',
        },
        {
            accessorKey: 'value',
            header: () => ' ',
            className: 'text-center width-middle nowrap',
            onNumberEdit: (editProps: OnEditProps<number>) =>
                handleEdit<number>(editProps),
        }
    ];

    return (
        <>
        <AuditingTable<LastSeasonTable>
          sectionKey={sectionKey}
          data={data}
          columns={tableColumns}
          showGlobalFilter={false}
          disableSort
        />
        {data.length > 0 && (
            <p style={{ margin: 5 }}>
              <b>Erotus: {data[0].value - data[1].value} </b>
            </p>
        )}
        </>
    );
};

export const TaxAccrualTable = ({
    formState,
    patchFormState,
  }: FormFieldProps<TaxesForm>) => {
  
      if (!formState) return null;
  
      const data = formState.taxAccrualTable ?? [];
  
      function handleEdit<T>({
          itemId,
          rowIndex,
          columnId,
          value,
      }: OnEditProps<T>) {
      const patch = formState.taxAccrualTable.map((row, index) => {
          const idMatch = itemId !== undefined && itemId === row.id;
          const rowIndexMatch = itemId === undefined && rowIndex === index;
          if (idMatch || rowIndexMatch) return { ...row, [columnId]: value };
          return row;
      });
          patchFormState({ ...formState, taxAccrualTable: patch });
      }
  
      const tableColumns: TableColumnDefs<TaxAccrualTable> = [
          {
              accessorKey: 'label',
              header: () => ' ',
              className: 'text-left width-middle nowrap',
          },
          {
              accessorKey: 'value',
              header: () => ' ',
              className: 'text-center width-middle nowrap',
              onNumberEdit: (editProps: OnEditProps<number>) =>
                  handleEdit<number>(editProps),
          }
      ];
  
      return (
          <>
          <AuditingTable<TaxAccrualTable>
            sectionKey={sectionKey}
            data={data}
            columns={tableColumns}
            showGlobalFilter={false}
            disableSort
          />
          {data.length > 0 && (
              <p style={{ margin: 5 }}>
                <b>Erotus: {data[0].value - data[1].value} </b>
              </p>
          )}
          </>
      );
};

export const AdvancedTaxTable = ({
    formState,
    patchFormState,
  }: FormFieldProps<TaxesForm>) => {
  
      if (!formState) return null;
  
      const data = formState.advanceTaxTable ?? [];
  
      function handleEdit<T>({
          itemId,
          rowIndex,
          columnId,
          value,
      }: OnEditProps<T>) {
      const patch = formState.advanceTaxTable.map((row, index) => {
          const idMatch = itemId !== undefined && itemId === row.id;
          const rowIndexMatch = itemId === undefined && rowIndex === index;
          if (idMatch || rowIndexMatch) return { ...row, [columnId]: value };
          return row;
      });
          patchFormState({ ...formState, advanceTaxTable: patch });
      }
  
      const tableColumns: TableColumnDefs<AdvanceTaxTable> = [
          {
              accessorKey: 'label',
              header: () => ' ',
              className: 'text-left width-middle nowrap',
          },
          {
              accessorKey: 'value',
              header: () => ' ',
              className: 'text-center width-middle nowrap',
              onNumberEdit: (editProps: OnEditProps<number>) =>
                  handleEdit<number>(editProps),
          }
      ];
  
      return (
          <>
          <AuditingTable<AdvanceTaxTable>
            sectionKey={sectionKey}
            data={data}
            columns={tableColumns}
            showGlobalFilter={false}
            disableSort
          />
          {data.length > 0 && (
              <p style={{ margin: 5 }}>
                <b>Erotus: {data[0].value - data[1].value} </b>
              </p>
          )}
          </>
      );
};

export const TaxCalculationTable = ({
    formState,
    patchFormState,
  }: FormFieldProps<TaxesForm>) => {
  
    if (!formState) return null;

    const TaxData = formState.taxCalculationTable ?? [];

    function handleTaxEdit<T>({
        itemId,
        rowIndex,
        columnId,
        value,
    }: OnEditProps<T>) {
    const patch = formState.taxCalculationTable.map((row, index) => {
        const idMatch = itemId !== undefined && itemId === row.id;
        const rowIndexMatch = itemId === undefined && rowIndex === index;
        if (idMatch || rowIndexMatch) return { ...row, [columnId]: value };
        return row;
    });
        patchFormState({ ...formState, taxCalculationTable: patch });
    }

    const TaxTableColumns: TableColumnDefs<TaxCalculationForm> = [
        {
            accessorKey: 'label',
            header: () => ' ',
            className: 'text-left width-middle nowrap',
        },
        {
            accessorKey: 'value',
            header: () => ' ',
            className: 'text-center width-middle nowrap',
            onNumberEdit: (editProps: OnEditProps<number>) =>
            handleTaxEdit<number>(editProps),
        }
    ];

    const additionData = formState.additionTable ?? [];

    function handleAdditionEdit<T>({
        itemId,
        rowIndex,
        columnId,
        value,
    }: OnEditProps<T>) {
    const patch = formState.additionTable.map((row, index) => {
        const idMatch = itemId !== undefined && itemId === row.id;
        const rowIndexMatch = itemId === undefined && rowIndex === index;
        if (idMatch || rowIndexMatch) return { ...row, [columnId]: value };
        return row;
    });
        patchFormState({ ...formState, additionTable: patch });
    }

    const AdditionTableColumns: TableColumnDefs<AdditionForm> = [
        {
            accessorKey: 'label',
            header: () => ' ',
            className: 'text-left width-half nowrap',
            onEdit: (editProps: OnEditProps<string>) =>
            handleAdditionEdit<string>(editProps),
        },
        {
            accessorKey: 'value',
            header: () => ' ',
            className: 'text-center width-half nowrap',
            onNumberEdit: (editProps: OnEditProps<number>) =>
            handleAdditionEdit<number>(editProps),
        }
    ];

    const handleAddNewAdditionRow = () => {
        const newRow = { id: uuid(), label: '', value: 0 };
        patchFormState({
            ...formState,
            additionTable: [...(formState.additionTable ?? []), newRow],
        });
    }
    const handleDeleteAdditionRow = ({ id }: AdditionForm) => {
        const patch = (formState.additionTable ?? []).filter(
            row => row.id !== id
        );
        patchFormState({ ...formState, additionTable: patch });
    }

    const subtractionData = formState.subtractionTable ?? [];

    function handleSubtractionEdit<T>({
        itemId,
        rowIndex,
        columnId,
        value,
    }: OnEditProps<T>) {
    const patch = formState.subtractionTable.map((row, index) => {
        const idMatch = itemId !== undefined && itemId === row.id;
        const rowIndexMatch = itemId === undefined && rowIndex === index;
        if (idMatch || rowIndexMatch) return { ...row, [columnId]: value };
        return row;
    });
        patchFormState({ ...formState, subtractionTable: patch });
    }

    const SubtractionTableColumns: TableColumnDefs<SubstractionForm> = [
        {
            accessorKey: 'label',
            header: () => ' ',
            className: 'text-left width-half nowrap',
            onEdit: (editProps: OnEditProps<string>) =>
            handleSubtractionEdit<string>(editProps),
        },
        {
            accessorKey: 'value',
            header: () => ' ',
            className: 'text-center width-half nowrap',
            onNumberEdit: (editProps: OnEditProps<number>) =>
            handleSubtractionEdit<number>(editProps),
        }
    ];

    const handleAddNewSubtractionRow = () => {
        const newRow = { id: uuid(), label: '', value: 0 };
        patchFormState({
            ...formState,
            subtractionTable: [...(formState.subtractionTable ?? []), newRow],
        });
    }

    const handleDeleteSubtractionRow = ({ id }: SubstractionForm) => {
        const patch = (formState.subtractionTable ?? []).filter(
            row => row.id !== id
        );
        patchFormState({ ...formState, subtractionTable: patch });
    }

    function calculateTaxedIncomes() {
        var taxedIncomes = 0;
        TaxData.forEach((row) => {
            taxedIncomes += row.value;
        });
        additionData.forEach((row) => {
            taxedIncomes += row.value;
        });
        subtractionData.forEach((row) => {
            taxedIncomes -= row.value;
        });
        return taxedIncomes;
    }

    function calculateAmountOfTaxesToBePaid(taxedIncomes: number) {
        return taxedIncomes * TaxData[0].value / 100;
    }

    const lastTaxesData = formState.lastTaxesTable ?? [];
    const advancedTaxTable = formState.advanceTaxTable ?? [];

    // if advacedTaxTable on id 2 has value, place this value to lastTaxesData's on id 0
    if (advancedTaxTable.length > 0) {
        let rowIdZero = lastTaxesData.find((row) => row.id === '0');
        if (rowIdZero) {
            rowIdZero.value = advancedTaxTable[1].value;
        }
    }

    function handleLastTaxesEdit<T>({
        itemId,
        rowIndex,
        columnId,
        value,
    }: OnEditProps<T>) {
    const patch = formState.lastTaxesTable.map((row, index) => {
        const idMatch = itemId !== undefined && itemId === row.id;
        const rowIndexMatch = itemId === undefined && rowIndex === index;
        if (idMatch || rowIndexMatch) return { ...row, [columnId]: value };

        let rowIdZero = formState.lastTaxesTable.find((row) => row.id === '0');
        let rowIdTwo = formState.lastTaxesTable.find((row) => row.id === '2');
        if (row.id === '1') {
            // Use calculateAmountOfTaxesToBePaid(calculateTaxedIncomes() / 100) - rowIdThree.value
            return { ...row, value: calculateAmountOfTaxesToBePaid(calculateTaxedIncomes() / 100) - (rowIdZero?.value ?? 0) };
        }
        // for row.id === '3' -> calculateAmountOfTaxesToBePaid(calculateTaxedIncomes() / 100) - rowIdZero.value - rowIdTwo.value
        if (row.id === '3') {
            return { ...row, value: calculateAmountOfTaxesToBePaid(calculateTaxedIncomes() / 100) - (rowIdZero?.value ?? 0) - (rowIdTwo?.value ?? 0) };
        }

        return row;
    });
        patchFormState({ ...formState, lastTaxesTable: patch });
    }

    let disableIds = ['1', '3'];
    const LastTaxesTableColumns: TableColumnDefs<LastTaxesTable> = [
        {
            accessorKey: 'label',
            header: () => ' ',
            className: 'text-left width-half nowrap',
            onEdit: (editProps: OnEditProps<string>) =>
            handleLastTaxesEdit<string>(editProps),
        },
        {
            accessorKey: 'value',
            header: () => ' ',
            className: 'text-center width-half nowrap',
            onNumberEdit: (editProps: OnEditProps<number>) =>
            handleLastTaxesEdit<number>(editProps),
            disabled: (row) => disableIds.includes(row.id),
        }
    ];
  
    return (
        <>
        <AuditingTable<TaxCalculationForm>
            sectionKey={sectionKey}
            data={TaxData}
            columns={TaxTableColumns}
            showGlobalFilter={false}
            disableSort
        />
        <AuditingTable<AdditionForm>
            title={'Lisäykset'}
            sectionKey={sectionKey}
            data={additionData}
            columns={AdditionTableColumns}
            showGlobalFilter={false}
            disableSort
            hideHeader
            onAddNewRow={handleAddNewAdditionRow}
            onRowDelete={handleDeleteAdditionRow}
        />
        <AuditingTable<SubstractionForm>
            title={'Vähennykset (Lisätään positiivisen merkkisinä laskelmaan)'}
            sectionKey={sectionKey}
            data={subtractionData}
            columns={SubtractionTableColumns}
            showGlobalFilter={false}
            disableSort
            hideHeader
            onAddNewRow={handleAddNewSubtractionRow}
            onRowDelete={handleDeleteSubtractionRow}
        />
        <hr></hr>
        <h4 style={{ margin: 0, marginTop: 40 }}>
            Verotettava tulo yhteensä: <i>{calculateTaxedIncomes()}</i> €
        </h4>
        <h4 style={{ margin: 0, marginBottom: 40 }}>
            Josta verot:{' '}
            <i>{calculateAmountOfTaxesToBePaid(calculateTaxedIncomes() / 100)}</i> €
        </h4>
        <hr></hr>
        <AuditingTable<LastTaxesTable>
            sectionKey={sectionKey}
            data={lastTaxesData}
            columns={LastTaxesTableColumns}
            showGlobalFilter={false}
            disableSort
            hideHeader
        />
        </>
    );
};