import React, { useCallback } from 'react';
import { useDelayedAction } from '../../utils';
import { Select, TextInputModal } from '../inputs';

type ItemID = UUID | string | undefined;

export interface OnEditProps<T> {
  itemId?: ItemID;
  rowIndex: number;
  columnId: string;
  value: T | null;
}

export interface TableSelectProps {
  value?: string;
  placeholder?: string;
  displayValue: (option: string) => string | undefined;
  options: string[];
  setValue: (props: OnEditProps<string>) => void;
  clearable?: boolean;
}

interface Props<T> {
  value: T;
  showRemark?: boolean;
  disabled?: boolean;
  cellPosition: {
    rowIndex: number;
    columnId: string;
  };
}

const EditOverlay = ({ children }: { children: React.ReactNode }) => (
  <>
    {children}
    <div className="editable-column-overlay" />
  </>
);

/**
 * Text input
 */
interface TextInputProps extends Props<string> {
  placeholder?: string;
  onEdit: (props: OnEditProps<string>) => void;
}
export const TableTextInput: React.FC<TextInputProps> = ({
  value,
  placeholder,
  cellPosition,
  onEdit,
  disabled,
}) => (
  <TextInputModal
    value={value}
    placeholder={placeholder}
    // TODO: Add support to show remark icon (!)
    // showRemark={showRemark}
    onChange={(newValue: string) => {
      onEdit({ ...cellPosition, value: newValue });
    }}
    disabled={disabled}
  />
);

/**
 * Number input
 */
interface NumberInputProps extends Props<string> {
  onEdit: (props: OnEditProps<number>) => void;
}
export const TableNumberInput: React.FC<NumberInputProps> = ({
  value,
  cellPosition,
  onEdit,
  disabled,
}) => {
  const [internalValue, setInternalValue] = React.useState(Number(value));

  const handleExternalUpdate = useCallback(() => {
    const valueChanged = Number(value) !== internalValue;
    if (valueChanged) onEdit({ ...cellPosition, value: internalValue });
  }, [cellPosition, internalValue, onEdit, value]);

  useDelayedAction(handleExternalUpdate, 500);

  return (
    <EditOverlay>
      <input
        className="text-center"
        type="number"
        value={internalValue}
        // TODO: Add support to show remark icon (!)
        // showRemark={showRemark}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          setInternalValue(Number(e.currentTarget.value));
        }}
        disabled={disabled}
      />
    </EditOverlay>
  );
};

/**
 * Checkbox
 */
interface CheckboxProps extends Props<boolean> {
  onEdit: (props: OnEditProps<boolean>) => void;
}
export const TableCheckbox: React.FC<CheckboxProps> = ({
  value,
  cellPosition,
  disabled,
  onEdit,
}) => (
  <input
    type="checkbox"
    checked={value}
    // TODO: Add support to show remark icon (!)
    // showRemark={showRemark}
    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
      onEdit({ ...cellPosition, value: e.currentTarget.checked });
    }}
    disabled={disabled}
  />
);

/**
 * Select
 */
interface SelectProps extends Props<string> {
  selectProps: TableSelectProps;
}
export const TableSelect: React.FC<SelectProps> = ({
  cellPosition,
  selectProps,
  showRemark,
  disabled,
}) => (
  <Select<string>
    value={selectProps.value}
    displayValue={selectProps.displayValue}
    options={selectProps.options}
    placeholder={selectProps.placeholder}
    clearable={selectProps.clearable}
    showRemark={showRemark}
    setValue={(value: React.SetStateAction<string | undefined>) => {
      selectProps.setValue({
        ...cellPosition,
        value: value !== undefined ? `${value}` : null,
      });
    }}
    disabled={disabled}
    minimal
  />
);
