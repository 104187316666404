import { useEffect } from 'react';
import { useStore } from '../../store/RootContext';

interface CommonProps {
  disabled?: boolean;
}

/**
 * FETCH ALL ROLES
 */
interface UseFetchRoles extends CommonProps {}
export const useFetchRoles = (_: UseFetchRoles = {}) => {
  const {
    authStore: { role: authRole },
    userStore: { allRoles, getRoles },
  } = useStore();

  useEffect(() => {
    // Fetch roles only for admin users!
    if (!allRoles && (authRole.isAdmin || authRole.isAuditorAdmin)) {
      getRoles();
    }
  }, [allRoles, authRole, getRoles]);
};

/**
 * FETCH ALL QUALIFICATION LEVELS
 *
 * @param params
 */
interface UseFetchQualificationLevels extends CommonProps {}
export const useFetchQualificationLevels = ({
  disabled,
}: UseFetchQualificationLevels = {}) => {
  const {
    userStore: { allQualificationLevels, getQualificationLevels },
  } = useStore();

  useEffect(() => {
    if (!allQualificationLevels && !disabled) {
      getQualificationLevels();
    }
  }, [allQualificationLevels, disabled, getQualificationLevels]);
};

/**
 * FETCH ALL USERS
 *
 * @param params
 */
interface UseFetchUsers extends CommonProps {}
export const useFetchUsers = ({ disabled }: UseFetchUsers = {}) => {
  const { userStore: entityStore } = useStore();

  useEffect(() => {
    if (!entityStore.allEntities && !disabled) {
      entityStore.getEntities();
    }
  }, [entityStore, disabled]);
};

/**
 * FETCH ALL AUDIT FIRMS
 *
 * @param params
 */
interface UseFetchAuditFirms extends CommonProps {}
export const useFetchAuditFirms = ({ disabled }: UseFetchAuditFirms = {}) => {
  const { auditFirmStore: entityStore } = useStore();

  useEffect(() => {
    if (!entityStore.allEntities && !disabled) {
      entityStore.getEntities();
    }
  }, [entityStore, disabled]);
};

/**
 * FETCH ALL CUSTOMER COMPANIES
 *
 * @param params
 */
interface UseFetchCustomerCompanies extends CommonProps {}
export const useFetchCustomerCompanies = ({
  disabled,
}: UseFetchCustomerCompanies = {}) => {
  const { customerCompanyStore: entityStore } = useStore();

  useEffect(() => {
    if (!entityStore.allEntities && !disabled) {
      entityStore.getEntities();
    }
  }, [entityStore, disabled]);
};

/**
 * FETCH ONE AUDITING (AND RELATED DATA)
 *
 * @param params
 */
interface UseFetchAuditing extends CommonProps {
  id: number;
}
export const useFetchAuditing = ({ id, disabled }: UseFetchAuditing) => {
  const {
    auditingStore: { selectedEntity, getEntity },
  } = useStore();

  // Fetch auditing
  useEffect(() => {
    if ((!selectedEntity || selectedEntity.id !== id) && !disabled) {
      getEntity(id);
    }
  }, [disabled, getEntity, id, selectedEntity]);
};
