import { makeAutoObservable } from 'mobx';
import { toast } from 'react-toastify';
import { Api } from '../../api/RootApi';
import { RootStore } from '../RootStore';
import { t } from '../../i18n';
import { TOAST_OPTIONS } from '../../utils';

export class NotificationStore {
  rootStore: RootStore;

  constructor(rootStore: RootStore, api: Api) {
    makeAutoObservable(this, {
      rootStore: false,
    });

    this.rootStore = rootStore;
  }

  private getMessage = (messageKey: string, type: NotificationType) =>
    t(`notification:${messageKey}.${type}`);

  notify = (message: string, type: NotificationType = 'info') => {
    return toast(message, {
      type,
      ...TOAST_OPTIONS,
    });
  };

  success = (messageKey: string) =>
    this.notify(this.getMessage(messageKey, 'success'), 'success');
  error = (messageKey: string) =>
    this.notify(this.getMessage(messageKey, 'error'), 'error');
  info = (messageKey: string) =>
    this.notify(this.getMessage(messageKey, 'info'), 'info');
  warning = (messageKey: string) =>
    this.notify(this.getMessage(messageKey, 'warning'), 'warning');

  /**
   * Start pending notification (toast is not closed until it's finished later)
   *
   * @return ID of for the toast
   */
  pendingNotification = (message: string) => {
    return toast.loading(message);
  };

  /**
   * Finish pending toast
   *
   * @param message Message to show
   * @param id ID of the existing toast
   * @param type Type of the message (success, error...)
   * @returns void
   */
  finishPendingNotification = (
    message: string,
    id: PendingTaskId,
    type: PendingTaskResult
  ) => {
    return toast.update(id, {
      render: message,
      type,
      isLoading: false,
      ...TOAST_OPTIONS,
    });
  };
}
