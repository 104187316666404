import { Api } from '../RootApi';
import { ModuleBaseApi } from './ModuleBaseApi';

const URL = {
  base: 'sanctionedEntity',
  info: 'sanctionedEntity/uploadInfo',
};

export class SanctionsApi extends ModuleBaseApi<SanctionedEntity> {
  constructor(rootApi: Api) {
    super(rootApi, URL.base);
  }
}

// saveNamesToDB function, which saves names to the database.
export async function saveNamesToDB(
  rootApi: Api,
  names: string[]
): Promise<void> {
  const response = await new SanctionsApi(rootApi).addEntity({
    data: { names },
  });
  if (response instanceof Object) {
    return response.data;
  } else {
    throw new Error('Names not saved');
  }
}

// getNamesFromDB function, which retrieves names from the database.
export async function getNamesFromDB(rootApi: Api): Promise<string[]> {
  const response = await new SanctionsApi(rootApi).getAllEntities();
  if (response instanceof Object) {
    return response.data;
  } else {
    throw new Error('Names not retrieved');
  }
}

// deleteAllNamesFromDB function, which deletes all names from the database.
export async function deleteAllNamesFromDB(rootApi: Api): Promise<void> {
  const response = await new SanctionsApi(rootApi).deleteAllEntities();
  if (response instanceof Object) {
    return response.data;
  } else {
    throw new Error('Names not deleted');
  }
}

// getUploadInfo function, which retrieves the upload date from the database.
export async function getUploadInfo(rootApi: Api): Promise<string> {
  const response = await rootApi.get(URL.info);
  const uploadDate = response.data;
  if (uploadDate) {
    return uploadDate;
  } else {
    return '';
  }
}
