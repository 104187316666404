import { AxiosRequestConfig } from 'axios';


export interface ApiConfig extends AxiosRequestConfig<any> {}

export const DEFAULT_API_CONFIG: AxiosRequestConfig<any> = {
  //URL Tulee olla "http://" tyylissä että axios löytää tämän osoitteen (API osoitteen)
  //Viety .env tiedostoon
  baseURL: process.env.REACT_APP_API_URL,
  timeout: 30000,
};
