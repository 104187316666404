import { uuid } from '../../../utils';

export enum AuditorInformationKind {
  auditFirm = 'auditFirm',
  auditor = 'auditor',
  chiefAuditor = 'chiefAuditor',
  deputyAuditor = 'deputyAuditor',
}

export const emptyAuditorInformationItem = (
  props: {
    kind?: AuditorInformationKind;
    value?: string;
  } = {}
): AuditorInformationItem => ({
  id: uuid(),
  kind: props.kind ?? null,
  value: props.value ?? '',
  upToDate: null,
  source: '',
});

export const initialAuditorInformationItems = (
  props: {
    auditing?: Auditing;
  } = {}
) => [
  emptyAuditorInformationItem({
    kind: AuditorInformationKind.auditFirm,
    value: props.auditing?.auditFirm?.name,
  }),
  emptyAuditorInformationItem({ kind: AuditorInformationKind.chiefAuditor }),
  emptyAuditorInformationItem({ kind: AuditorInformationKind.deputyAuditor }),
];

export enum CustomerActivityMonitoring {
  goodAuditingPractice = 'goodAuditingPractice',
  other = 'other',
}

export enum ApplicableLaws {
  limitedCompanyAct = 'limitedCompanyAct',
  accountingAct = 'accountingAct',
  taxLaws = 'taxLaws',
  housingCompanyAct = 'housingCompanyAct',
  other = 'other',
}

export enum IndustrySpecificRegulation {
  regulatedIndustry = 'regulatedIndustry',
  politicalRegulation = 'politicalRegulation',
  numerousRegulations = 'numerousRegulations',
  noImportantPoliticalRegulation = 'noImportantPoliticalRegulation',
}

export enum FinancialStatementsBasis {
  accountingRegulation = 'accountingRegulation',
  smallBusinessRegulation = 'smallBusinessRegulation',
  ifrs = 'ifrs',
  other = 'other',
}

export enum MunicipalApplicableLaws {
  municipalLaw = 'municipalLaw',
  administrativeLaw = 'administrativeLaw',
  accountingLaw = 'accountingLaw',
  taxLaw = 'taxLaw',
  stateContributionsRegulations = 'stateContributionsRegulations',
  purchaseLaw = 'purchaseLaw',
  other = 'other',
}

export enum MunicipalFinancialStatementsBasis {
  municipalLaw = 'municipalLaw',
  decreeOnMunicipalitysFinancialStatements = 'decreeOnMunicipalitysFinancialStatements',
  accountingRegulation = 'accountingRegulation',
  municipalKilaGeneralInstructions = 'municipalKilaGeneralInstructions',
}

export enum PartOfTheGroup {
  no = 'no',
  yesIsParentCompany = 'yesIsParentCompany',
  yesIsSubsidiary = 'yesIsSubsidiary',
}

export enum TaxDebtInformation {
  noTaxDebt = 'noTaxDebt',
  taxDebtOverLimit = 'taxDebtOverLimit',
}

export enum FailureToNotify {
  noDefects = 'noDefects',
  defects = 'defects',
}

export enum LegalSituation {
  normal = 'normal',
  bankruptcy = 'bankruptcy',
  liquidation = 'liquidation',
  restructuringProcedure = 'restructuringProcedure',
}

type VisibleData = {
  [key: string]: (keyof CompanyInformation)[];
};

export const visibleData: VisibleData = {
  companyInformationVisibleData: [
    'name',
    'businessId',
    'address',
    //'registeredOffice',
    'industry',
  ],
  companyPerceptionInformationVisibleData: [
    'capitalStock',
    'numberOfStocks',
    'boardChairman',
    'boardMember1',
    'boardMember2',
    'boardMember3',
    'boardDeputyMember',
    'ceo',
    'deputyCeo',
  ],
  municipalityInformationVisibleData: [
    'name',
    'businessId',
    'population',
    'councilChairman',
    'boardChairman',
    'mayor',
    'administrativeDirector',
    'chiefFinancialOfficer',
    'accountantsContactInformation',
    'payrollAccountantsContactInformation',
  ],
  municipalityMostImportantInstructionsVisibleData: [
    'administrativeRule',
    'municipalStrategy',
    'groupInstruction',
    'internalControlAndRiskManagementInstruction',
    'purchaseInstruction',
  ],
  municipalityOtherRelevantInstructionsVisibleData: [
    'riskManagementGuidelines',
  ],
};
