import { useTranslation } from 'react-i18next';
import { confirmDelete, formatCurrency, uuid } from '../../utils';
import AuditingTable from '../table/AuditingTable';
import { OnEditProps, TableColumnDefs } from '../table/Table';
import styled from 'styled-components';

export const TextContainer = styled.div`
  .additional-info {
    font-style: italic;
    color: ${p => p.theme.color.grey400};
    text-align: right;
  }
`;

export function formatNumber(num: any) {
  return num.toLocaleString('fi-FI', {
    minimumFractionDigits: 1,
    maximumFractionDigits: 2,
  });
}

const sectionKey: AuditingSectionKey = 'capitalLoans';

export const LoanConfimationsTableColumn = ({
  formState,
  patchFormState,
}: FormFieldProps<CapitalLoansForm>) => {
  const { t } = useTranslation();

  if (!formState) return null;

  const tBase = `auditing:form.${sectionKey}.creditorsTable`;

  const data = formState.loanConfirmations ?? [];

  function handleEdit<T>({
    itemId,
    rowIndex,
    columnId,
    value,
  }: OnEditProps<T>) {
    const patch = formState.loanConfirmations.map((row, index) => {
      const idMatch = itemId !== undefined && itemId === row.id;
      const rowIndexMatch = itemId === undefined && rowIndex === index;
      if (idMatch || rowIndexMatch) {
        const updatedRow = { ...row, [columnId]: value };
        if (columnId === 'generalLedger' || columnId === 'balance') {
          let generalLedgerValue = updatedRow.generalLedger || 0;
          let balanceValue = updatedRow.balance || 0;
          let minus = generalLedgerValue - balanceValue || 0;
          updatedRow.minus = minus;
          // FormatNumber
          formatNumber(updatedRow.minus);
        }
        return updatedRow;
      }
      return row;
    });
    const newFormState = { ...formState, loanConfirmations: patch };
    patchFormState(newFormState);
  }

  let tableColumns: TableColumnDefs<LoanConfirmation> = [
    {
      accessorKey: 'label',
      header: () => t(`${tBase}.loan`),
      className: 'text-left width-middle',
      placeholder: 'XXXX',
      onEdit: (editProps: OnEditProps<string>) => {
        handleEdit<string>(editProps);
      },
    },
    {
      accessorKey: 'generalLedger',
      header: () => t(`${tBase}.generalLedger`) + ' €',
      className: 'text-center width-middle',
      onNumberEdit: (editProps: OnEditProps<number>) =>
        handleEdit<number>(editProps),
      cell: ({ cell }) => <div>{cell.row.original.generalLedger} €</div>,
    },
    {
      accessorKey: 'balance',
      header: () => t(`${tBase}.balance`) + ' €',
      className: 'text-center width-middle',
      onNumberEdit: (editProps: OnEditProps<number>) =>
        handleEdit<number>(editProps),
      cell: ({ cell }) => <div>{cell.row.original.balance} €</div>,
    },
    {
      accessorKey: 'minus',
      header: () => t(`${tBase}.minus`),
      className: 'text-center width-middle',
      accessorFn: (row: any) => {
        return formatCurrency(row.minus);
      },
      cell: ({ cell }) => <div>{formatNumber(cell.row.original.minus)} €</div>,
    },
    {
      accessorKey: 'other',
      header: () => t(`${tBase}.other`),
      className: 'text-center width-middle',
      onEdit: (editProps: OnEditProps<string>) => handleEdit<string>(editProps),
    },
  ];

  const handleAddNewRow = () => {
    patchFormState({
      ...formState,
      loanConfirmations: [
        ...(formState.loanConfirmations ?? []),
        {
          label: '',
          id: uuid(), //Math.floor(Math.random()*100).toString(),
          generalLedger: 0,
          balance: 0,
          minus: 0,
          other: '',
        },
      ],
    });
  };

  const handleDeleteRow = ({ id, label }: LoanConfirmation) => {
    if (confirmDelete(label ?? '')) {
      const patch = (formState.loanConfirmations ?? []).filter(
        row => row.id !== id
      );
      patchFormState({ ...formState, loanConfirmations: patch });
    }
  };

  return (
    <>
      <AuditingTable<LoanConfirmation>
        sectionKey={sectionKey}
        title="Lainojen vahvistukset:"
        onAddNewRow={handleAddNewRow}
        data={data}
        columns={tableColumns}
        onRowDelete={handleDeleteRow}
        showGlobalFilter={false}
        disableSort
        variant="default"
      />
      <TextContainer>
        <div className="additional-info">
          Erotus: Saldo pääkirjanpidossa - Saldo vahvistuksella
        </div>
      </TextContainer>
    </>
  );
};

export const ReconciliationTableColumn = ({
  formState,
  patchFormState,
}: FormFieldProps<CapitalLoansForm>) => {
  const { t } = useTranslation();

  if (!formState) return null;

  const tBase = `auditing:form.${sectionKey}.reconciliationTable`;

  const data = formState.reconciliationTables ?? [];

  function handleEdit<T>({
    itemId,
    rowIndex,
    columnId,
    value,
  }: OnEditProps<T>) {
    const patch = formState.reconciliationTables.map((row, index) => {
      const idMatch = itemId !== undefined && itemId === row.id;
      const rowIndexMatch = itemId === undefined && rowIndex === index;
      if (idMatch || rowIndexMatch) {
        const updatedRow = { ...row, [columnId]: value };
        if (
          columnId === 'generalLedger' ||
          columnId === 'balance' ||
          columnId === 'shortPerYear' ||
          columnId === 'shortMade'
        ) {
          let generalLedgerValue = updatedRow.generalLedger || 0;
          let balanceValue = updatedRow.balance || 0;
          let shortPerYearValue = updatedRow.shortPerYear || 0;
          let shortMadeValue = updatedRow.shortMade || 0;
          let minus =
            generalLedgerValue -
              balanceValue -
              shortPerYearValue * shortMadeValue || 0;
          updatedRow.minus = minus;
          // FormatNumber
          formatNumber(updatedRow.minus);
        }
        return updatedRow;
      }
      return row;
    });
    const newFormState = { ...formState, reconciliationTables: patch };
    patchFormState(newFormState);
  }

  function formatNumber(num: any) {
    return num.toLocaleString('fi-FI', {
      minimumFractionDigits: 1,
      maximumFractionDigits: 2,
    });
  }

  let tableColumns: TableColumnDefs<ReconciliationTable> = [
    {
      accessorKey: 'label',
      header: () => t(`${tBase}.loan`),
      className: 'text-left width-middle',
      placeholder: 'XXXX',
      onEdit: (editProps: OnEditProps<string>) => {
        handleEdit<string>(editProps);
      },
    },
    {
      accessorKey: 'generalLedger',
      header: () => t(`${tBase}.generalLedger`) + ' €',
      className: 'text-center width-middle',
      onNumberEdit: (editProps: OnEditProps<number>) =>
        handleEdit<number>(editProps),
      cell: ({ cell }) => <div>{cell.row.original.generalLedger} €</div>,
    },
    {
      accessorKey: 'balance',
      header: () => t(`${tBase}.balance`) + ' €',
      className: 'text-center width-middle',
      onNumberEdit: (editProps: OnEditProps<number>) =>
        handleEdit<number>(editProps),
      cell: ({ cell }) => <div>{cell.row.original.balance} €</div>,
    },
    {
      accessorKey: 'shortPerYear',
      header: () => t(`${tBase}.shortPerYear`) + ' €',
      className: 'text-center width-middle',
      onNumberEdit: (editProps: OnEditProps<number>) =>
        handleEdit<number>(editProps),
      cell: ({ cell }) => <div>{cell.row.original.shortPerYear} €</div>,
    },
    {
      accessorKey: 'shortMade',
      header: () => t(`${tBase}.shortMade`) + ' KPL',
      className: 'text-center width-middle',
      onNumberEdit: (editProps: OnEditProps<number>) =>
        handleEdit<number>(editProps),
      cell: ({ cell }) => <div>{cell.row.original.shortMade} €</div>,
    },
    {
      accessorKey: 'minus',
      header: () => t(`${tBase}.minus`),
      className: 'text-center width-middle',
      accessorFn: (row: any) => {
        return formatCurrency(row.minus);
      },
      cell: ({ cell }) => <div>{formatNumber(cell.row.original.minus)} €</div>,
    },
    {
      accessorKey: 'other',
      header: () => t(`${tBase}.other`),
      className: 'text-center width-middle',
      onEdit: (editProps: OnEditProps<string>) => handleEdit<string>(editProps),
    },
  ];

  const handleAddNewRow = () => {
    patchFormState({
      ...formState,
      reconciliationTables: [
        ...(formState.reconciliationTables ?? []),
        {
          label: '',
          id: uuid(), //Math.floor(Math.random()*100).toString(),
          generalLedger: 0,
          shortPerYear: 0,
          shortMade: 0,
          balance: 0,
          minus: 0,
          other: '',
        },
      ],
    });
  };

  const handleDeleteRow = ({ id, label }: ReconciliationTable) => {
    if (confirmDelete(label ?? '')) {
      const patch = (formState.reconciliationTables ?? []).filter(
        row => row.id !== id
      );
      patchFormState({ ...formState, reconciliationTables: patch });
    }
  };

  return (
    <>
      <AuditingTable<ReconciliationTable>
        sectionKey={sectionKey}
        title="Lainojen täsmäytys velkakirjoille:"
        onAddNewRow={handleAddNewRow}
        data={data}
        columns={tableColumns}
        onRowDelete={handleDeleteRow}
        showGlobalFilter={false}
        disableSort
        variant="default"
      />
      <TextContainer>
        <div className="additional-info">
          Erotus: Saldo pääkirjalla - Saldo velkakirjalla - Lyhennys € per erä *
          Lyhennyksiä tehty{' '}
        </div>
      </TextContainer>
    </>
  );
};
