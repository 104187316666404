import React from 'react';
import { useTranslation } from 'react-i18next';
import { quantityLabel } from '../../utils';
import Icon, { IconColor, IconType } from '../Icon';
import { Button } from '../inputs';
import { DegreeOfReadinessSectionContainer } from './DegreeOfReadiness.StatusSection';

interface StatusRow {
  icon: IconType;
  color?: IconColor;
  label: string;
  dimmed?: boolean;
}

interface Props {
  title: string;
  remarks: number;
}

const RemarksSection: React.FC<Props> = ({ title, remarks }) => {
  const { t } = useTranslation();

  const sectionRows: StatusRow[] = [
    {
      icon: remarks ? 'ExclamationTriangle' : 'Check',
      color: remarks ? 'error' : 'grey200',
      label: quantityLabel(remarks, 'auditing:remarks.remarksQuantity'),
      dimmed: remarks === 0,
    },
  ];

  return (
    <DegreeOfReadinessSectionContainer>
      <h3>{title}</h3>

      {sectionRows.map(({ icon, color, label, dimmed }) => (
        <div key={label} className="row-item">
          <Icon type={icon} color={color} />
          <span className={dimmed ? 'dimmed' : ''}>{label}</span>
        </div>
      ))}

      <Button
        text={`${t('auditing:remarks.title2')} >`}
        variant="link"
        onClick={() => {
          const remarksList = document.querySelector('#remarks-list');
          remarksList?.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }}
      />
    </DegreeOfReadinessSectionContainer>
  );
};

export default RemarksSection;
