import React, { PropsWithChildren, useState } from 'react';
import styled from 'styled-components';

const Container = styled.div`
  position: relative;
  cursor: default;

  .tooltip {
    position: absolute;
    left: 105%;
    top: -50%;
    z-index: 1;
    margin-left: ${p => p.theme.spacing.sm};
    padding: ${p => p.theme.spacing.md};
    background-color: ${p => p.theme.color.background};
    border-radius: ${p => p.theme.borderRadius.sm};
    ${p => p.theme.shadow.sm};
    ${p => p.theme.font.size[12]};
    white-space: nowrap;
  }
`;

interface Props extends PropsWithChildren<any> {
  id?: string;
  text: string;
}

const Tooltip: React.FC<Props> = ({ id, text, children }) => {
  const [open, setOpen] = useState(false);

  const showTooltip = () => setOpen(true);
  const hideTooltip = () => setOpen(false);

  return (
    <Container
      id={id}
      data-testid={id}
      onMouseEnter={showTooltip}
      onMouseLeave={hideTooltip}
    >
      {open && (
        <div data-testid={`${id}-content`} className="tooltip">
          {text}
        </div>
      )}
      {children}
    </Container>
  );
};

export default Tooltip;
