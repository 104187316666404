import { Api } from '../RootApi';

const URL = {
  ytj: 'ytj',
};

export class CommonApi {
  private api: Api;

  constructor(api: Api) {
    this.api = api;
  }

  async fetchFromYtj({ businessId }: Api.Payload.FetchFromYtj) {
    return await this.api.get<YtjCompany>(`${URL.ytj}/${businessId.trim()}`);
  }
}
