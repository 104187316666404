import AuditingTable from '../table/AuditingTable';
import { TableColumnDefs, OnEditProps } from '../table/Table';
import { formatCurrency } from '../../utils';
import { useState } from 'react';

interface CalculatorPart {
  investment: string;
  valueAtPurchase: number;
  currentMarketValue: number;
  lowerValue?: number;
}

interface ResultPart {
  lowestValue: number;
  investmentValueOnReport: Object;
  resultAfterSubtraction: number;
}
interface Props {
  formState?: {
    currentInvestments?: Record<string, any>;
    investmentValueOnReport: Record<string, any>;
  };
  patchFormState?: Function;
  id: string;
}

export const CalculationForm = ({ formState, patchFormState, id }: Props) => {
  var investmentValueOnReport = formState?.investmentValueOnReport[id];
  var currentMarketValueAlltogether = 0;
  var valueAtPurchaseAlltogether = 0;
  const sectionKey: AuditingSectionKey = 'funding';
  const [loading, setLoading] = useState(false);

  if (!formState || !formState?.currentInvestments) return null;

  let lowerValue = 0;
  const data = [];
  for (var i = 0; i < formState.currentInvestments[id].length; i++) {
    let newObject = formState.currentInvestments[id][i];
    let lowerValueOfObject =
      newObject.currentMarketValue < newObject.valueAtPurchase
        ? newObject.currentMarketValue
        : newObject.valueAtPurchase;
    newObject.lowerValue = lowerValueOfObject;
    lowerValue += lowerValueOfObject;
    data.push(newObject);
  }

  const patchForm = async (patch: any) => {
    if (formState.currentInvestments) {
      var newLowerValue = 0;
      for (var i = 0; i < formState.currentInvestments[id].length; i++) {
        var investment = formState.currentInvestments[id];
        newLowerValue +=
          investment.valueAtPurchase > investment.currentMarketValue
            ? investment.currentMarketValue
            : investment.valueAtPurchase;
      }
      lowerValue = newLowerValue;
      patchFormState &&
        formState &&
        (await patchFormState({
          ...formState,
          currentInvestments: {
            ...formState.currentInvestments,
            [id]: patch,
          },
        }));
    }
  };

  const handleAddNewRow = () => {
    // Lisää uuden rivin jolla on eri nimi kuin muilla rivin osakkeilla.
    var nextNumberOfStock = 0;
    if (formState?.currentInvestments) {
      for (var i = 0; i < formState?.currentInvestments[id]?.length; i++) {
        if (
          Number(
            String(formState?.currentInvestments[id][i].investment).split(
              ' '
            )[2]
          ) > nextNumberOfStock
        ) {
          nextNumberOfStock = Number(
            String(formState?.currentInvestments[id][i].investment).split(
              ' '
            )[2]
          );
        }
      }
    }

    formState.currentInvestments &&
      patchForm([
        ...formState.currentInvestments[id],
        {
          investment: 'Uusi Osake ' + (nextNumberOfStock + 1).toString(),
          valueAtPurchase: 0,
          currentMarketValue: 0,
        },
      ]);
  };

  function handleEdit<T>({ rowIndex, columnId, value }: OnEditProps<T>) {
    if (formState?.currentInvestments) {
      const updatedInvestments = [...formState.currentInvestments[id]];

      if (columnId === 'investment') {
        updatedInvestments[rowIndex].investment = value as any;
      } else if (columnId === 'valueAtPurchase') {
        updatedInvestments[rowIndex].valueAtPurchase = value as any;
      } else if (columnId === 'currentMarketValue') {
        updatedInvestments[rowIndex].currentMarketValue = value as any;
      }

      patchForm(updatedInvestments);
    }
  }

  function handleEditInvestmentValueOnReport<T>({ value }: OnEditProps<T>) {
    if (typeof value == 'number') {
      patchFormState &&
        formState &&
        patchFormState({
          ...formState,
          investmentValueOnReport: {
            ...formState.investmentValueOnReport[i],
            [id]: value,
          },
        });
    }
  }

  const handleDeleteRow = async (data: any) => {
    setLoading(true);
    if (formState?.currentInvestments) {
      const newDataList = [...formState.currentInvestments[id]].filter(
        (item: { investment: any }) => item.investment !== data.investment
      );
      if (newDataList.length > 0) {
        patchForm(newDataList);
      } else {
        alert('Et voi poistaa kaikkia osakkeita.');
      }
    }
    setTimeout(() => setLoading(false), 1);
  };

  const resultPartDataArray: ResultPart[] = [
    {
      lowestValue: lowerValue,
      investmentValueOnReport:
        investmentValueOnReport === undefined ? 0 : investmentValueOnReport,
      resultAfterSubtraction:
        (investmentValueOnReport === undefined ? 0 : investmentValueOnReport) -
        lowerValue,
    },
  ];
  const resultColumns: TableColumnDefs<ResultPart> = [
    {
      accessorKey: 'investmentValueOnReport',
      header: () => 'Rahoituspaperi pääkirjalla (EUR)',
      onNumberEdit: handleEditInvestmentValueOnReport,
    },
    {
      accessorKey: 'lowestValue',
      header: () => 'Alin arvo',
      accessorFn: (row: any) => formatCurrency(row.lowestValue),
    },
    {
      accessorKey: 'resultAfterSubtraction',
      header: () => 'Erotus',
      accessorFn: (row: any) => formatCurrency(row.resultAfterSubtraction),
    },
  ];

  if (formState?.currentInvestments) {
    // eslint-disable-next-line
    for (var i = 0; i < formState?.currentInvestments[id].length; i++) {
      currentMarketValueAlltogether +=
        formState?.currentInvestments[id][i].currentMarketValue;
      valueAtPurchaseAlltogether +=
        formState?.currentInvestments[id][i].valueAtPurchase;
    }
  }

  const calculatorPartColumns: TableColumnDefs<CalculatorPart> = [
    {
      accessorKey: 'investment',
      header: () => 'Arvopaperi',
      onEdit: handleEdit,
    },
    {
      accessorKey: 'valueAtPurchase',
      header: () => 'Hankintameno',
      onNumberEdit: handleEdit,
    },
    {
      accessorKey: 'currentMarketValue',
      header: () => 'Markkina arvo',
      onNumberEdit: handleEdit,
    },
    {
      accessorKey: 'lowerValue',
      header: () => 'Alin arvo',
      accessorFn: (row: any) => formatCurrency(row.lowerValue),
    },
  ];

  const calculatorResultPartDataArray: CalculatorPart[] = [
    {
      investment: 'Yhteensä',
      valueAtPurchase: valueAtPurchaseAlltogether,
      currentMarketValue: currentMarketValueAlltogether,
      lowerValue: lowerValue,
    },
  ];
  const calculatorResultColumns: TableColumnDefs<CalculatorPart> = [
    {
      accessorKey: 'valueAtPurchase',
      header: () => 'Hankintamenot yhteensä',
      accessorFn: (row: any) => formatCurrency(row.valueAtPurchase),
    },
    {
      accessorKey: 'currentMarketValue',
      header: () => 'Markkina arvo yhteensä',
      accessorFn: (row: any) => formatCurrency(row.currentMarketValue),
    },
    {
      accessorKey: 'lowerValue',
      header: () => 'Alin arvo yhteensä',
      accessorFn: (row: any) => formatCurrency(row.lowerValue),
    },
  ];

  return !loading ? (
    <>
      <AuditingTable<ResultPart>
        sectionKey={sectionKey}
        data={resultPartDataArray}
        columns={resultColumns}
        showGlobalFilter={false}
        disableSort
      />
      <AuditingTable<CalculatorPart>
        sectionKey={sectionKey}
        data={data}
        columns={calculatorPartColumns}
        showGlobalFilter={false}
        onAddNewRow={handleAddNewRow}
        onRowDelete={handleDeleteRow}
        animationDisabled={true}
      />
      <br />
      <AuditingTable<CalculatorPart>
        sectionKey={sectionKey}
        data={calculatorResultPartDataArray}
        columns={calculatorResultColumns}
        disableSort
        showGlobalFilter={false}
      />
    </>
  ) : (
    <>
      <AuditingTable<ResultPart>
        sectionKey={sectionKey}
        data={resultPartDataArray}
        columns={resultColumns}
        showGlobalFilter={false}
        disableSort
      />
      <br />
      {data.map(item => (
        <div style={{ height: 2000 }} />
      ))}
      <AuditingTable<CalculatorPart>
        sectionKey={sectionKey}
        data={calculatorResultPartDataArray}
        columns={calculatorResultColumns}
        disableSort
        showGlobalFilter={false}
      />
    </>
  );
};
