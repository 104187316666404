import { t } from '../i18n';

export const quantityLabel = (count?: number, tBase = 'common:quantity') => {
  let key = 'none';
  if (count === 1) key = 'one';
  else if (count && count > 1) key = 'many';

  return t(`${tBase}.${key}`, { count });
};

export const toggleLabel = (toggled: boolean, itemsKey: string) =>
  t(`action:toggle.${toggled}`, {
    items: t(itemsKey).toLowerCase(),
  });
