import {
  FC,
  Suspense,
  useEffect,
  useState,
  useCallback,
  useRef,
} from 'react';
import { observer } from 'mobx-react-lite';
import {
  Route as RouterRoute,
  BrowserRouter as Router,
  Routes,
} from 'react-router-dom';
import appRoutes from './routes';
import ScrollToTop from './ScrollToTop';
import ViewWrapper from './ViewWrapper';
import { useStore } from '../store/RootContext';
import { Login } from '../views';
import Layout from '../views/Layout';
import { useTranslation } from 'react-i18next';
import { getUserRoutes } from '../utils/route';
import { path } from '../i18n/utils';
import { OTPage } from '../views/OTPage';
import Sitting from '../views/Sitting';

// 30 minutes.
const AFK_TIMEOUT = 1000 * 60 * 30;

// 1 minute.
const AFK_CHECK_INTERVAL = 1000 * 60;

interface Props {
  routes?: Route[];
}

/**
 * Creates and returns a new debounced version of the passed function which will postpone its execution until after `wait` milliseconds have elapsed since the last time it was invoked.
 * @param func - The function to debounce.
 * @param wait - The number of milliseconds to delay.
 * @returns A new function that, when invoked repetitively, postpones its execution until after `wait` milliseconds have elapsed since the last invocation.
 * The returned function has a `cancel` method which can be used to immediately cancel any pending execution.
 */
export function debounce(func: (...args: any[]) => void, wait: number) {
  let timeout: NodeJS.Timeout | null;
  const debounced = (...args: any[]) => {
    if (timeout) clearTimeout(timeout);
    timeout = setTimeout(() => func(...args), wait);
  };
  debounced.cancel = function () {
    if (timeout) clearTimeout(timeout);
  };
  return debounced;
}

const AppRouter: FC<Props> = observer(({ routes = appRoutes }) => {
  const { t } = useTranslation('login');
  const [showSittingPopup, setShowSittingPopup] = useState(false);
  
  const {
    authStore: { isLoggedIn, userIsVerified, getMe, token, user, role },
  } = useStore();

  const lastUserActionRef = useRef(0);
  const afkChecker = useRef<NodeJS.Timeout | null>(null);

  const onUserAction = useCallback(() => {
    lastUserActionRef.current = Date.now();
  }, []);

  useEffect(() => {
    if (user) lastUserActionRef.current = Date.now();

    // Checks whether the user has done any action. Interval: 1 minute.
    afkChecker.current = setInterval(() => {
      if (!user) return;

      // 30 minutes of inactivity -> sitting popup.
      if (Date.now() - lastUserActionRef.current >= AFK_TIMEOUT)
        setShowSittingPopup(true);
    }, AFK_CHECK_INTERVAL);

    window.addEventListener('mousemove', onUserAction);
    window.addEventListener('keypress', onUserAction);

    return () => {
      window.removeEventListener('mousemove', onUserAction);
      window.removeEventListener('keypress', onUserAction);
      if (afkChecker.current) clearInterval(afkChecker.current);
    };
  }, [user, onUserAction]);

  useEffect(() => {
    if (token && !user) getMe();
  }, [getMe, token, user]);

  // ...wait until getMe finishes
  if (token && !user) return null;

  // If user is not logged in, render login view.
  if (!isLoggedIn)
    return (
      <Router>
        <ViewWrapper title={t('login:title')}>
          <Login />
        </ViewWrapper>
      </Router>
    );

  // If user is not verified, render OTP view.
  if (!userIsVerified)
    return (
      <Router>
        <ViewWrapper title={t('login:titleOTP')}>
          <OTPage />
        </ViewWrapper>
      </Router>
    );

  const renderRoute = ({ path, index, title, Element }: Route) => (
    <RouterRoute
      key={path}
      index={index}
      path={path}
      element={<ViewWrapper title={title}>{Element}</ViewWrapper>}
    />
  );

  const homeRoutes = getUserRoutes(appRoutes.homeRoutes, role.type);
  const manageRoutes = getUserRoutes(appRoutes.manageRoutes, role.type);
  const auditingRoutes = getUserRoutes(appRoutes.auditingRoutes, role.type);
  const customerRoutes = getUserRoutes(appRoutes.customerRoutes, role.type);

  return (
    <Router>
      <Suspense fallback={<div>...</div>}>
        <ScrollToTop />
        {showSittingPopup && (
          <Sitting
            resetTimer={onUserAction}
            setShowSittingPopup={setShowSittingPopup}
          />
        )}
        <Routes>
          <RouterRoute path="/" element={<Layout noAuditingHeader noSideNav />}>
            {homeRoutes.map(renderRoute)}
          </RouterRoute>

          <RouterRoute
            path={`/${path('manage')}`}
            element={<Layout noAuditingHeader />}
          >
            {manageRoutes.map(renderRoute)}
          </RouterRoute>

          <RouterRoute path={`/${path('auditing')}/:id`} element={<Layout />}>
            {auditingRoutes.map(renderRoute)}
          </RouterRoute>

          <RouterRoute
            path={`/${path('customer')}`}
            element={<Layout noAuditingHeader />}
          >
            {customerRoutes.map(renderRoute)}
          </RouterRoute>
        </Routes>
      </Suspense>
    </Router>
  );
});

export default AppRouter;
