import { displayAuditingProcedures } from '../../../components/auditing/AuditingProcedureItem2';
import {
  InspectionTableColumn,
  LoanBalanceTableColumn,
  LoanConfimationsTableColumn,
  ReconciliationTableColumn,
} from '../../../components/auditing/FinancialInstitutions';
import auditingJson from '../../../i18n/locales/fi/auditing.json';
import { FormFieldBuilder } from '../FormFieldBuilder';
import { ProcedureAccountsElement } from './reUsables/ProcedureAccountsElement';
import { SpreadSheet } from './reUsables/SpreadSheet';
import { TableColumn } from './reUsables/TableColumn';

type SectionFormType = FinancialInstitutionsForm;

const sectionKey: AuditingSectionKey = 'financialInstitutions';

export const tBase = `route:auditing.${sectionKey}`;
const batchString = 'S1-financialInstitutions';

const formBuilder = new FormFieldBuilder<SectionFormType>({ sectionKey });

const defaultFormState: DefaultFormState<SectionFormType> = ({ auditing }) => ({
  loanConfirmations: [
    {
      id: '1',
      generalLedger: 0,
      balance: 0,
      minus: 0,
      other: '',
    },
  ],

  reconciliationTables: [
    {
      id: '1',
      generalLedger: 0,
      shortPerYear: 0,
      shortMade: 0,
      balance: 0,
      minus: 0,
      other: '',
    },
  ],

  inspectionClassifications: [
    {
      id: '1',
      ShortTermGeneralLedger: 0,
      ShortTerm: 0,
      minus: 0,
      backgroundMaterial: 'Saldovahvistus',
      other: '',
    },
    {
      id: '2',
      ShortTermGeneralLedger: 0,
      ShortTerm: 0,
      minus: 0,
      backgroundMaterial: 'Velkakirja',
      other: '',
    },
  ],

  loanBalances: [
    {
      id: '1',
      generalLedger: 0,
      backgroundMaterialEUR: 0,
      minus: 0,
      backgroundMaterial: 'Maksusuunnitelma',
      other: '',
    },
    {
      id: '2',
      generalLedger: 0,
      backgroundMaterialEUR: 0,
      minus: 0,
      backgroundMaterial: 'Tiliote',
      other: '',
    },
  ],

  procedureAccountsManualData: [
    {
      currentYear: 0,
      priorYear: 0,
      accountName: '0000...',
      comment: '',
    },
  ],
  procedureAccountsData: {},
  tableColumnData: [
    {
      left: 'Velat, jotka taseelle on merkitty ovat olemassa',
      right: auditingJson.OLE,
    },
    {
      left: 'Taseella esitetyt velat ovat yhtiön velvotteita',
      right: auditingJson.OVE,
    },
    {
      left: 'Kaikki velat, jotka olisi pitänyt kirjata on kirjattu ja niihin liittyvät tiedot, jorka olisi pitänyt esittää tilinpäätöksellä on esitetty',
      right: auditingJson.TAY,
    },
    {
      left: 'Velat on esitetty asianmukaisessa määrässä, arvostamisesta ja kohdistamisesta johtuvat oikaisut on kirjattu asianmukaisesti.',
      right: auditingJson.ARV,
    },
    {
      left: 'Velat on luokiteltu oikein.',
      right: auditingJson.LUOK,
    },
    {
      left: 'velat on yhdistelty tai jaoteltu asianmukaisesti ja niihin liittyvät tilinpäätöksessä esitettävät tiedot ovat relevantteja',
      right: auditingJson.ESI,
    },
  ],
  conclusion:
    'Suoritetuilla tarkastustoimenpiteillä on saatu riittävä evidenssi lainojen oikeellisuudesta ja tarkastuksen tavoitteet on saavutettu. Suoritetun tarkastuksen perusreella lainat ovat olennaisin osin oikein.',
  understandingOfLoans:
    'Kuvaa tässä lyhyesti yleisellä tasolla yhtiön lainoja ja niissä tapahtuneita muutoksia, esimerkiksi: Yhtiöllä on  lainaa rahoituslaitoksilta ja osamaksuvelalla hankittua kalustoa. Tilikaudella on nostettu yksi uusi laina. Muut lainoissa kaudella tapahtuneet muutokset ovat normaaleja lainan lyhennyksiä. Yhtiön lainoihin ei liity kovenanttiehtoja. Lainoista on annettu vakuuksia, jotka on tarkastettu tilinpäätöksen liitetietojen tarkastuksen yhteydessä',
  loanTerms: true,
  // Pääomalainojen täsmäytys saldovahvistuksille
  inspectionConclusion: 'ampt',
  confirmation: null,
  confirmationComment:
    'Tarkempi kuvaus, suunnitellut vaihtoehtoiset toimenpiteet',
  reference:
    'viittaus vahvistusten tallennuspaikkaan ja soveltuvin osin viittaus tarkastustiedostoon',
  ideasGoalsAndCompletedWork:
    'kuvaa kannanotot ja tavoitteet, joihin toimenpiteillä vastataan sekä suoritettu työ',
  findingsAndComments: 'kuvaa tässä mahdolliset havainnot / ei havaintoja',
  calculationBase: null,
  // Pääomalainojen täsmäytys velkakirjoille
  inspectionConclusion1: 'pm',
  reference1:
    'Viittaus vahvistusten tallennuspaikkaan ja soveltuvin osin viittaus tarkastustiedostoon',
  ideasGoalsAndCompletedWork1:
    'Kuvaa kannanotot ja tavoitteet, joihin toimenpiteillä vastataan sekä suoritettu työ',
  findingsAndComments1: 'Kuvaa tässä mahdolliset havainnot / ei havaintoja',
  promissoryNotes: null,
  // Lainojen tarkastus
  inspectionConclusion2: 'ampt',
  reference2:
    'Viittaus vahvistusten tallennuspaikkaan ja soveltuvin osin viittaus tarkastustiedostoon',
  ideasGoalsAndCompletedWork2:
    'Kuvaa kannanotot ja tavoitteet, joihin toimenpiteillä vastataan sekä suoritettu työ',
  findingsAndComments2: 'Kuvaa tässä mahdolliset havainnot / ei havaintoja',
  loanBalance: null,
  // Lainojen luokittelun tarkastus
  inspectionConclusion3: 'overPm',
  reference3: 'Viite tarkastustiedostoon esim S1 luokittelun tarkastus',
  ideasGoalsAndCompletedWork3:
    'Kuvaa kannanotot ja tavoitteet, joihin toimenpiteillä vastataan sekä suoritettu työ esimerkiksi Tarkastuksen tavoitteena on tarkastaa lainojen luokittelun oikeellisuutta ja tarkastuksella vastataan kannanottoon "luokittelu LUO".  Tarkastuksessa on täsmäytetty kirjanpidon lyhytaikainen osuus saldovahvistuksen / velkakirjan osoittamaan lyhytaikaiseen osuuteen.',
  findingsAndComments3:
    'Kuvaa tässä mahdolliset havainnot / ei havaintoja esimerkiksi lainojen luokittelu olennaisin osin oikein, linjassa saldovahvistusten osoittamaan määrään lyhytaikainen / pitkäaikainen',
  loanClassification: null,
  // Lainasopimusten ja -ehtojen läpikäynti
  reference4:
    'viite tiedostoon esim S1.1 Lainojen läpikäynti, ks velkakirjat S1.1 - S1.5',
  ideasGoalsAndCompletedWork4:
    'Kuvaa kannanotot ja tavoitteet, joihin toimenpiteillä vastataan sekä suoritettu työ, esimerkiksi: käyty läpi yhtiön lainasopimuksia ja niihin liittyviä ehtoja.  Tarkastuksen tavoitteena on muodostaa käsitys lainoihin liittyvistä oikeuksista ja velvollisuuksista (OVE) ja havaita ehdot, jotka voivat vaikuttaa lainojen luokitteluun / esittämiseen tilinpäätöksellä (LUO,ESI)',
  findingsAndComments4:
    'Kuvaa tässä mahdolliset havainnot / ei havaintoja esimerkiksi eskisimmät ehdot on dokumentoitu tiedostoon S1.1 lainojen läpikäynti. Tarkastelun perusteella yhtiön lainat ovat tasalyhenteisiä, laina-aika on 5-20 vuotta, korot vaihtelevat X-X% välillä oheisen dokumentaation mukaisesti. Lainasopimusten perusteella lainoihin liittyy kovenanttiehtoja, jotka on dokumentoitu tarkemmin kohtaan kovenanttien tarkastus.',
  // Kovenanttien tarkastus
  reference5:
    'viittaus lainasopimuksiin, soveltuvin osin tarkastustiedostoon esimerkiksi Nodean lainoihin X ja Y liittyy kovenanttiehtoja. Sopimukset on tallenttu kansioon S1 indekseillä S1.1 ja S1.2, kovenatit on tarkastettu tiedostossa XX.',
  ideasGoalsAndCompletedWork5:
    'Kuvaa tehty työ esim. Käyty läpi kovenanttiehdot ja niiden täyttyminen tilinpäätöshetkellä 31.12.20XX. Tarkastuksen tavoitteena on hankkia evidenssiä siitä, ovatko kovenantit täyttyneet, onko lainat luokiteltu asianmukaisesti tilinpäätöksellä (LUO) ja onko tilinpäätöksellä ja toimintakertomuksella annettu riittävät tiedot kovenanteista (ESI). Tarkastuksessa on lisäksi käyty läpi yhtiön oma kovenanttien seuranta ja siihen liittyvä prosessi',
  description:
    'Avaa tässä lainoihin liittyvät kovenanttiehdot tai viittaa tiedostoon, jossa kovenanttiehdot on käyty läpi. Avaa myös yhtiön oma prosessi kovenantteihin liittyen esimerkiksi Nordean lainaan sisältyy omavaraisuus-kovenantti, jonka mukaan omavaraisuuden tulee olla XX %. Kovenantin täyttymistä valvotaan 12 kuukauden välein. / kovenantit on dokumentoitu tiedostoon XX / kovenantit käyvät ilmi laina sopimukselta XX Yhtiössä seurataan kovenanttien täyttymistä ja koveantit käydään läpi kuukausittain toteumien perusteella sekä budjetoidun ennusteen perusteella.',
  findingsAndComments5:
    'Kuvaa havainnot, ovatko kovenantit täyttyneet / ovatko ne rikkoutuneet, jos ovat rikkouttuneet, onko saatu waiver, onko annettu riittävät tiedot tilinpäätöksellä ja toimintakertomuksella? esimerkiksi kovenantit ovat täyttyneet per 31.12.20XX, lainat on luokiteltu taseelle asianmukaisesti / Lainojen kovenanttiehdot ovat rikkoutuneet ja näin ollen pankilla on oikeus irtisanoa lainat. Tarkasteluhetkellä neuvottelut pankin kanssa ovat edelleen kesken. Kovenanttien rikkoutumisesta on annettu riittävät tiedot toimintakertomuksella ja liitetiedoissa',
  spreadsheet1: [
    {
      cellData: [],
    },
  ],
  spreadsheet2: [
    {
      cellData: [],
    },
  ],
  spreadsheet3: [
    {
      cellData: [],
    },
  ],
  spreadsheet4: [
    {
      cellData: [],
    },
  ],
  spreadsheet5: [
    {
      cellData: [],
    },
  ],
  spreadsheet6: [
    {
      cellData: [],
    },
  ],
  // Sheets
  useSheetTab: null,
  useSheet1: null,
  useSheet2: null,
  useSheet3: null,
  useSheet4: null,
  useSheet5: null,
  useSheet6: null,
});

const formFields: FormFields<SectionFormType> = ({
  formState,
  auditing,
  store,
}) => [
  formBuilder.group({
    items: [
      formBuilder.boolean({
        accessor: 'useSheetTab',
        plainLabel: 'Käytä laskenta taulukko osiota',
      }),
      formBuilder.custom(props => (
        <ProcedureAccountsElement
          auditing={auditing}
          {...props}
          batchString={batchString}
          addCommentField
        />
      )),
      formBuilder.custom(props => (
        <TableColumn
          {...props}
          title="Tarkastuksen tavoitteet ja kannantot, joihin tarkastuksella vastataan"
        />
      )),
      formBuilder.textArea({
        accessor: 'conclusion',
        plainLabel: auditingJson.conclusion,
      }),
    ],
  }),

  ...displayAuditingProcedures(store, batchString, formBuilder, [
    {
      // Käsityksen muodostaminen lainoista ja niissä tapahtuneista muutoksista
      accordionId: 'describePeriod',
      hide: ['auditingResult', 'auditingReference', 'auditingComments', 'auditingProcedureGoals'],
      lowerElements: [
        formBuilder.textArea({ accessor: 'understandingOfLoans' }),
        formBuilder.boolean({
          accessor: 'loanTerms',
          options: ['yes', 'no'],
        }),
        formBuilder.text({
          text: 'Lisättävä kovenanttien täyttymisen tarkastus toimenpide yksityiskohtainen suunnitelma välilehdeltä!',
          hidden: !!formState.loanTerms,
        }),
      ],
    },
    {
      // Pääomalainojen täsmäytys saldovahvistuksille
      accordionId: 'loanReconciliationForBalance',
      upperElements: [],
      lowerElements: [
        formBuilder.group({
          items: [
            formBuilder.boolean({
              accessor: 'confirmation',
              plainLabel: 'Vahvistus',
              options: [
                'askedConfirmationOptions.one',
                'askedConfirmationOptions.two',
              ],
            }),
            formBuilder.textArea({
              accessor: 'confirmationComment',
              hidden: !formState.confirmation,
            }),
          ],
        }),
        formBuilder.boolean({
          plainLabel: auditingJson.useCalculation,
          accessor: 'calculationBase',
        }),
        formBuilder.custom(props =>
          formState.calculationBase ? (
            <LoanConfimationsTableColumn {...props} />
          ) : null
        ),
        formBuilder.boolean({
          accessor: 'useSheet1',
          plainLabel: auditingJson.useSheet,
        }),
        formBuilder.custom(props =>
          formState.useSheet1 ? (
            <SpreadSheet id="spreadsheet1" {...props} />
          ) : null
        ),
      ],
    },
    {
      // Pääomalainojen täsmäytys velkakirjoille
      accordionId: 'reconciliationOfLoans',
      lowerElements: [
        formBuilder.boolean({
          plainLabel: auditingJson.useCalculation,
          accessor: 'promissoryNotes',
        }),
        formBuilder.custom(props =>
          formState.promissoryNotes ? (
            <ReconciliationTableColumn {...props} />
          ) : null
        ),
        formBuilder.boolean({
          accessor: 'useSheet2',
          plainLabel: auditingJson.useSheet,
        }),
        formBuilder.custom(props =>
          formState.useSheet2 ? (
            <SpreadSheet id="spreadsheet2" {...props} />
          ) : null
        ),
      ],
    },
    {
      // Lainojen tarkastus
      accordionId: 'inspectionOfLoanBalance',
      lowerElements: [
        formBuilder.boolean({
          plainLabel: auditingJson.useCalculation,
          accessor: 'loanBalance',
        }),
        formBuilder.custom(props =>
          formState.loanBalance ? <InspectionTableColumn {...props} /> : null
        ),
        formBuilder.boolean({
          accessor: 'useSheet3',
          plainLabel: auditingJson.useSheet,
        }),
        formBuilder.custom(props =>
          formState.useSheet3 ? (
            <SpreadSheet id="spreadsheet3" {...props} />
          ) : null
        ),
      ],
    },
    {
      // Lainojen luokittelun tarkastus
      accordionId: 'inspectionOfLoanClassification',
      lowerElements: [
        formBuilder.boolean({
          plainLabel: auditingJson.useCalculation,
          accessor: 'loanClassification',
        }),
        formBuilder.custom(props =>
          formState.loanClassification ? (
            <LoanBalanceTableColumn {...props} />
          ) : null
        ),
        formBuilder.boolean({
          accessor: 'useSheet4',
          plainLabel: auditingJson.useSheet,
        }),
        formBuilder.custom(props =>
          formState.useSheet4 ? (
            <SpreadSheet id="spreadsheet4" {...props} />
          ) : null
        ),
      ],
    },
    {
      // Lainasopimusten ja -ehtojen läpikäynti
      accordionId: 'reviewingLoanAgreements',
      lowerElements: [
        formBuilder.boolean({
          accessor: 'useSheet5',
          plainLabel: auditingJson.useSheet,
        }),
        formBuilder.custom(props =>
          formState.useSheet5 ? (
            <SpreadSheet id="spreadsheet5" {...props} />
          ) : null
        ),
      ],
    },
    {
      // Kovenanttien tarkastus
      accordionId: 'inspectionOfCovenants',
      lowerElements: [
        formBuilder.boolean({
          accessor: 'useSheet6',
          plainLabel: auditingJson.useSheet,
        }),
        formBuilder.custom(props =>
          formState.useSheet6 ? (
            <SpreadSheet id="spreadsheet6" {...props} />
          ) : null
        ),
      ],
    },
  ]),
];

const section: AuditingSectionDefinition<SectionFormType> = {
  sectionKey,
  defaultFormState,
  formFields,
};

export default section;
