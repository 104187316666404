import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { rt } from '../../i18n/utils';
import { useStore } from '../../store/RootContext';
import { quantityLabel } from '../../utils';
import Icon from '../Icon';

const Container = styled.div`
  > h2 > span {
    margin-left: ${p => p.theme.spacing.sm};
    ${p => p.theme.font.size[14]};
    color: ${p => p.theme.color.grey500};
  }
`;

const RemarkListItem = styled.li`
  display: flex;
  align-items: center;
  margin: ${p => p.theme.spacing.md} 0;
  padding: ${p => p.theme.spacing.xsm} 0;

  .list-item-bullet {
    margin-right: ${p => p.theme.spacing.xsm};
  }

  .list-item-content {
    padding: ${p => p.theme.spacing.xsm};
    ${p => p.theme.font.size[14]};

    .list-item-section-title {
      ${p => p.theme.font.size[14]};
      a {
        color: ${p => p.theme.color.grey300};
      }
    }

    .list-item-content-value {
      font-weight: bold;
    }
  }
`;

const RemarksList: React.FC = () => {
  const { t } = useTranslation();

  const {
    auditingStore: { getAuditingRemarks, getLinkToAuditingSection },
  } = useStore();

  const remarks = getAuditingRemarks();

  if (!remarks.length) return null;

  const getValue = (value: any) => {
    const prefix = '> ';
    switch (typeof value) {
      case 'string':
        // String value is usually (if not always) a target key to a translation.
        // Currently not able to get translations since full path is not available.
        // There are some exceptions, thought, for some known basic cases:
        if (value === 'yes') return prefix + t('action:yes');
        if (value === 'no') return prefix + t('action:no');
        return undefined;
      case 'boolean':
        return prefix + t(`action:${value ? 'yes' : 'no'}`);
      case 'number':
        return `${value}`;
      default:
        return undefined;
    }
  };

  const remarksQuantityLabel = quantityLabel(
    remarks.length,
    'auditing:remarks.remarksQuantity'
  );

  return (
    <Container id="remarks-list">
      <h2>
        {t('auditing:remarks.title2')}
        <span>{`(${remarksQuantityLabel})`}</span>
      </h2>

      <ul>
        {remarks.map(({ key, sectionKey, accessor, value }) => (
          <RemarkListItem key={key}>
            <div className="list-item-bullet">
              <Icon type="ExclamationTriangle" color="error" />
            </div>

            <div className="list-item-content">
              <div className="list-item-section-title">
                <Link to={getLinkToAuditingSection({ sectionKey })}>
                  {rt(`auditing.${sectionKey}`)}
                </Link>
              </div>
              <div>{t(`auditing:form.${key}`)}</div>
              <div className="list-item-content-value">
                {typeof accessor === 'string' && getValue(value)}
              </div>
            </div>
          </RemarkListItem>
        ))}
      </ul>
    </Container>
  );
};

export default RemarksList;
