import React from 'react';
import {
  Cell,
  Header,
  flexRender,
  getCoreRowModel,
  useReactTable,
  ColumnDef,
  SortingState,
  getSortedRowModel,
  getFilteredRowModel,
  CellContext,
  Row,
} from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';
import Icon from '../Icon';
import { StyledTable, StyledTableProps } from './TableStyles';
import { Button } from '../inputs';
import {
  OnEditProps,
  TableCheckbox,
  TableNumberInput,
  TableSelect,
  TableSelectProps,
  TableTextInput,
} from './TableInputs';
import TableHead, { GroupingHeaderCell } from './TableHead';
import TableToolbar from './TableToolbar';
import { ContextInfoProps } from '../ContextInfo';
import { useAnimation } from '../../utils/hooks';

export * from './TableHead';
export * from './TableInputs';

type ItemID = UUID | undefined;

export interface TableRowProps<T> {
  bgColor?: string | ((row: T) => string | undefined);
}

export type TableColumnDefs<T> = (ColumnDef<T, any> & {
  id?: string;
  className?: string;
  placeholder?: string;
  onEdit?: (props: OnEditProps<string>) => void;
  onNumberEdit?: (props: OnEditProps<number>) => void;
  onBooleanEdit?: (props: OnEditProps<boolean>) => void;
  selectProps?: TableSelectProps;
  contextInfoProps?: ContextInfoProps;
  showRemark?: (cell: T) => boolean | undefined;
  disabled?: (cell: T) => boolean | undefined;
  hidden?: (cell: T) => boolean | undefined;
})[];

export interface TableProps<T> extends StyledTableProps {
  data: T[] | (T & TableRowProps<T>)[];
  columns: ColumnDef<T, any>[];
  groupingHeaderRow?: GroupingHeaderCell[];
  title?: string | JSX.Element;
  onAddNew?: (() => void) | false;
  onRowEdit?: ((row: T) => void) | false;
  onRowDelete?: ((row: T) => void) | false;
  showIdColumn?: boolean;
  showGlobalFilter?: boolean;
  hideHeader?: boolean;
  disabled?: boolean;
  animationDisabled?: boolean;
}

function Table<T>({
  data,
  columns,
  groupingHeaderRow,
  title,
  onAddNew,
  onRowEdit,
  onRowDelete,
  showIdColumn,
  showGlobalFilter = true,
  disableSort = false,
  hideHeader = false,
  variant = 'default',
  disabled: tableDisabled,
  animationDisabled,
}: TableProps<T>) {
  const { t } = useTranslation(['common', 'action']);

  const animationParent = useAnimation();

  const [sorting, setSorting] = React.useState<SortingState>([]);
  const [globalFilter, setGlobalFilter] = React.useState('');

  const idColumn = {
    id: 'id',
    accessorFn: (row: { id: string | number }) => `${row.id ?? '-'}`,
    header: () => t('common:label.id'),
    className: 'text-center width-min',
    enableGlobalFilter: false,
  };

  const toolsColumn = {
    id: 'tools',
    cell: ({ row }: CellContext<T, unknown>) => (
      <div className="flex-row">
        {onRowEdit && (
          <Button
            icon={<Icon type="Edit" color="secondary" />}
            bg="transparent"
            onClick={() => onRowEdit(row.original)}
          />
        )}
        {onRowDelete && (
          <Button
            icon={<Icon type="Delete" color="error" />}
            bg="transparent"
            onClick={() => onRowDelete(row.original)}
          />
        )}
      </div>
    ),
    enableSorting: false,
    enableGlobalFilter: false,
    className: 'width-min',
  };

  const composeColumns = () => {
    let composedColumns: TableColumnDefs<T> = showIdColumn ? [idColumn] : [];
    composedColumns = [...composedColumns, ...columns];
    if (!tableDisabled && (onRowEdit || onRowDelete)) {
      composedColumns.push(toolsColumn);
    }
    return composedColumns;
  };

  const table = useReactTable({
    data,
    columns: composeColumns(),
    state: {
      sorting: disableSort ? undefined : sorting,
      globalFilter,
    },
    getCoreRowModel: getCoreRowModel(),
    onSortingChange: disableSort ? undefined : setSorting,
    getSortedRowModel: disableSort ? undefined : getSortedRowModel(),
    onGlobalFilterChange: setGlobalFilter,
    getFilteredRowModel: getFilteredRowModel(),
    // debugTable: process.env.NODE_ENV === 'development',
  });

  const getRowProps = ({ original }: Row<T>) => {
    const row = original as TableRowProps<T>;
    return {
      bgColor:
        typeof row.bgColor === 'function' ? row.bgColor(original) : row.bgColor,
    };
  };

  const getCellProps = (cell: Cell<T, unknown> | Header<T, unknown>) => {
    const columnDef: any = cell // TODO: Find out how to type this properly
      .getContext()
      .table._getColumnDefs()
      .find(({ accessorKey, id }: any) =>
        [accessorKey, id].includes(cell.column.id)
      );

    return {
      placeholder: columnDef?.placeholder ?? '',
      className: columnDef?.className ?? '',
      onEdit: columnDef?.onEdit ?? undefined,
      onNumberEdit: columnDef?.onNumberEdit ?? undefined,
      onBooleanEdit: columnDef?.onBooleanEdit ?? undefined,
      selectProps: columnDef?.selectProps ?? undefined,
      contextInfoProps: columnDef?.contextInfoProps ?? undefined,
      showRemark: columnDef?.showRemark ?? undefined,
      disabled: columnDef?.disabled ?? undefined,
      hidden: columnDef?.hidden ?? undefined,
      bgColor: columnDef?.bgColor ?? undefined,
    };
  };

  const showToolbar = showGlobalFilter || !!onAddNew || !!title;

  return (
    <>
      {showToolbar && (
        <TableToolbar
          title={title}
          onAddNew={onAddNew}
          showGlobalFilter={showGlobalFilter}
          globalFilter={globalFilter}
          setGlobalFilter={setGlobalFilter}
        />
      )}

      <StyledTable disableSort={disableSort} variant={variant}>
        {!hideHeader && (
          <TableHead<T>
            table={table}
            getClassName={header => getCellProps(header).className}
            contextInfoProps={header => getCellProps(header).contextInfoProps}
            groupingHeaderRow={groupingHeaderRow}
          />
        )}

        {!animationDisabled ? (
          <tbody ref={animationParent}>
            {table.getRowModel().rows.map(row => {
              const rowProps = getRowProps(row);
              const backgroundColor = rowProps.bgColor;

              return (
                <tr
                  key={row.id}
                  style={{ backgroundColor }}
                  ref={animationParent}
                >
                  {row.getVisibleCells().map(cell => {
                    // Try to get row item's ID (all items might not have an ID)
                    const itemId = (cell.row.original as { id?: ItemID }).id;

                    const tdProps = {
                      key: itemId ? `${itemId}-${cell.id}` : cell.id,
                      className: getCellProps(cell).className,
                    };

                    const onCellEdit = getCellProps(cell).onEdit;
                    const onCellNumberEdit = getCellProps(cell).onNumberEdit;
                    const onCellBooleanEdit = getCellProps(cell).onBooleanEdit;
                    const selectProps = getCellProps(cell).selectProps;
                    const placeholder = getCellProps(cell).placeholder;
                    const showRemarkFunction = getCellProps(cell).showRemark;
                    const disabledFunction = getCellProps(cell).disabled;
                    const hiddenFunction = getCellProps(cell).hidden;

                    const cellPosition = {
                      itemId,
                      rowIndex: cell.row.index,
                      columnId: cell.column.id,
                    };

                    const cellDisabled = disabledFunction
                      ? disabledFunction(cell.row.original)
                      : undefined;

                    const cellHidden = hiddenFunction
                      ? hiddenFunction(cell.row.original)
                      : undefined;

                    const disabled = tableDisabled || cellDisabled;

                    const commonProps = {
                      cellPosition,
                      disabled,
                      showRemark: showRemarkFunction
                        ? showRemarkFunction(cell.row.original)
                        : undefined,
                    };

                    const cellValue = cell.getValue();
                    const stringValue =
                      cellValue !== null && cellValue !== undefined
                        ? `${cellValue}`
                        : '';
                    const booleanValue = !!cellValue;

                    if (cellHidden) return null;

                    // Render editable text input
                    if (onCellEdit)
                      return (
                        <td {...tdProps}>
                          <TableTextInput
                            value={stringValue}
                            placeholder={placeholder}
                            onEdit={onCellEdit}
                            {...commonProps}
                          />
                        </td>
                      );

                    // Render editable number input
                    if (!disabled && onCellNumberEdit)
                      return (
                        <td {...tdProps}>
                          <TableNumberInput
                            value={stringValue}
                            onEdit={onCellNumberEdit}
                            {...commonProps}
                          />
                        </td>
                      );

                    // Render editable checkbox input
                    if (onCellBooleanEdit)
                      return (
                        <td {...tdProps}>
                          <TableCheckbox
                            value={booleanValue}
                            onEdit={onCellBooleanEdit}
                            {...commonProps}
                          />
                        </td>
                      );

                    // Render editable select input
                    if (selectProps) {
                      return (
                        <td {...tdProps}>
                          <TableSelect
                            value={stringValue}
                            selectProps={{
                              ...selectProps,
                              value: stringValue.length
                                ? stringValue
                                : undefined,
                            }}
                            {...commonProps}
                          />
                        </td>
                      );
                    }

                    // Render plain value
                    return (
                      <td {...tdProps}>
                        {commonProps.showRemark && (
                          <Icon
                            type="ExclamationTriangle"
                            color="error"
                            size={14}
                            className="remark-icon"
                            pullLeft
                          />
                        )}
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        ) : (
          <tbody>
            {table.getRowModel().rows.map(row => {
              const rowProps = getRowProps(row);
              const backgroundColor = rowProps.bgColor;

              return (
                <tr key={row.id} style={{ backgroundColor }}>
                  {row.getVisibleCells().map(cell => {
                    // Try to get row item's ID (all items might not have an ID)
                    const itemId = (cell.row.original as { id?: ItemID }).id;

                    const tdProps = {
                      key: itemId ? `${itemId}-${cell.id}` : cell.id,
                      className: getCellProps(cell).className,
                    };

                    const onCellEdit = getCellProps(cell).onEdit;
                    const onCellNumberEdit = getCellProps(cell).onNumberEdit;
                    const onCellBooleanEdit = getCellProps(cell).onBooleanEdit;
                    const selectProps = getCellProps(cell).selectProps;
                    const placeholder = getCellProps(cell).placeholder;
                    const showRemarkFunction = getCellProps(cell).showRemark;
                    const disabledFunction = getCellProps(cell).disabled;
                    const hiddenFunction = getCellProps(cell).hidden;

                    const cellPosition = {
                      itemId,
                      rowIndex: cell.row.index,
                      columnId: cell.column.id,
                    };

                    const cellDisabled = disabledFunction
                      ? disabledFunction(cell.row.original)
                      : undefined;

                    const cellHidden = hiddenFunction
                      ? hiddenFunction(cell.row.original)
                      : undefined;

                    const disabled = tableDisabled || cellDisabled;

                    const commonProps = {
                      cellPosition,
                      disabled,
                      showRemark: showRemarkFunction
                        ? showRemarkFunction(cell.row.original)
                        : undefined,
                    };

                    const cellValue = cell.getValue();
                    const stringValue =
                      cellValue !== null && cellValue !== undefined
                        ? `${cellValue}`
                        : '';
                    const booleanValue = !!cellValue;

                    if (cellHidden) return null;

                    // Render editable text input
                    if (onCellEdit)
                      return (
                        <td {...tdProps}>
                          <TableTextInput
                            value={stringValue}
                            placeholder={placeholder}
                            onEdit={onCellEdit}
                            {...commonProps}
                          />
                        </td>
                      );

                    // Render editable number input
                    if (!disabled && onCellNumberEdit)
                      return (
                        <td {...tdProps}>
                          <TableNumberInput
                            value={stringValue}
                            onEdit={onCellNumberEdit}
                            {...commonProps}
                          />
                        </td>
                      );

                    // Render editable checkbox input
                    if (onCellBooleanEdit)
                      return (
                        <td {...tdProps}>
                          <TableCheckbox
                            value={booleanValue}
                            onEdit={onCellBooleanEdit}
                            {...commonProps}
                          />
                        </td>
                      );

                    // Render editable select input
                    if (selectProps) {
                      return (
                        <td {...tdProps}>
                          <TableSelect
                            value={stringValue}
                            selectProps={{
                              ...selectProps,
                              value: stringValue.length
                                ? stringValue
                                : undefined,
                            }}
                            {...commonProps}
                          />
                        </td>
                      );
                    }

                    // Render plain value
                    return (
                      <td {...tdProps}>
                        {commonProps.showRemark && (
                          <Icon
                            type="ExclamationTriangle"
                            color="error"
                            size={14}
                            className="remark-icon"
                            pullLeft
                          />
                        )}
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        )}
      </StyledTable>
    </>
  );
}

export default Table;
