import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { confirmDelete } from '../../utils';
import { makeClosureProcedure } from '../../views/auditingSections/reporting/closureUtils';
import Divider from '../Divider';
import Icon from '../Icon';
import { Button, Checkbox, InputGroup, TextInput } from '../inputs';
import Popover from '../Popover';

const sectionKey: AuditingSectionKey = 'closure';

const tBase = 'auditing:form.closure';

const DeleteButton = styled.button`
  margin-left: ${p => p.theme.spacing.md};
`;

const CompletedProcedures: React.FC<FormFieldProps<ClosureForm>> = ({
  formState,
  patchFormState,
  store,
}) => {
  const { t } = useTranslation();

  const { auditingLocked, isAuditingSectionFinished } = store.auditingStore;

  const [addNewName, setAddNewName] = useState('');

  const procedures = formState.completedProcedures;

  const handleEdit = ({ id, completed }: { id: UUID; completed: boolean }) => {
    const patch = formState.completedProcedures?.map(row => {
      const idMatch = id !== undefined && id === row.id;
      if (idMatch) return { ...row, completed };
      return row;
    });
    patchFormState({ ...formState, completedProcedures: patch });
  };

  const handleAddNew = (name: string) => {
    const patch = [
      ...formState.completedProcedures,
      makeClosureProcedure({ customName: name }),
    ];
    patchFormState({ ...formState, completedProcedures: patch });
    setAddNewName('');
  };

  const handleDeleteRow = (procedure: CompletedClosureProcedure) => {
    if (confirmDelete(procedure.customName)) {
      const patch = formState.completedProcedures.filter(
        ({ id }) => id !== procedure.id
      );
      patchFormState({ ...formState, completedProcedures: patch });
    }
  };

  const isFormDisabled =
    auditingLocked || isAuditingSectionFinished(sectionKey);

  return (
    <>
      <InputGroup label={t(`${tBase}.completedProcedures`)}>
        {procedures?.map(procedure => {
          const { id, nameKey, customName, completed } = procedure;
          const isCustomItem = !!customName;

          const name = nameKey
            ? t(`${tBase}.completedProceduresOptions.${nameKey}`)
            : customName;

          return (
            <div key={id} className="flex-row justify-start">
              <Checkbox
                checked={completed}
                label={name}
                onChange={e =>
                  handleEdit({ id, completed: e.currentTarget.checked })
                }
                variant="light"
                disabled={isFormDisabled}
              />
              {isCustomItem && !isFormDisabled && (
                <DeleteButton onClick={() => handleDeleteRow(procedure)}>
                  <Icon type="Delete" color="error" size={14} />
                </DeleteButton>
              )}
            </div>
          );
        })}
      </InputGroup>

      <Divider noBorder size="sm" />

      {!isFormDisabled && (
        <Popover
          button={<Button text={'+ ' + t('action:addNew')} variant="link" />}
        >
          {({ close }) => (
            <div>
              <TextInput
                placeholder={t('common:placeholder.name')}
                value={addNewName}
                setValue={setAddNewName}
                fullWidth
                autoFocus
              />
              <Button
                text={t('action:add')}
                onClick={() => {
                  handleAddNew(addNewName);
                  close();
                }}
                variant="filled"
                disabled={!addNewName.length}
                fullWidth
              />
            </div>
          )}
        </Popover>
      )}
    </>
  );
};

export default CompletedProcedures;
