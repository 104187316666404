import { useEffect, useState } from "react";
import { useStore } from "../store/RootContext";
import { useTranslation } from 'react-i18next';

// This interface is needed for props to work correctly.
interface CommentFieldProps {
  sectionKey: AuditingSectionKey;
}

export function CommentField({ sectionKey }: CommentFieldProps) {
  const [text, setText] = useState<undefined | string>("");
  const [numberOfLetterTyped, setNumberOfLetterTyped] = useState(0)
  const { t } = useTranslation()

  const { // hakee AuditingStoresta (src/store/modules/AuditingStore.ts) tarvittavat muuttujat ja funktiot
    auditingStore: {
      getCommentFieldText,
      updateCommentField
    } 
  } = useStore();

  async function getDbText() {
    // ottaa tietokannasta osion kommenttitekstin, ja päivittää tekstin kommenttikenttään.
    const commentTextFromDb = await getCommentFieldText(sectionKey)
    setText(commentTextFromDb === undefined ? '' : commentTextFromDb)
  }


  async function onType(text: string) {
    // Päivitetään data aina 10 kirjaimen välein, siten lagi vähenee, ja kirjoittaja ei menetä työtään ainakaan 10 kirjainta enempää
    // Hiiren liiketunnistimen pitäisi hoitaa loput.
    setText(text);
    setNumberOfLetterTyped(numberOfLetterTyped+1)
    if(numberOfLetterTyped === 10){
      await updateCommentField(sectionKey, text, false)
      setNumberOfLetterTyped(0)
    }
  }

  async function onLeaveMouse() {
    // Kun käyttäjä läh
    text !== undefined && await updateCommentField(sectionKey, text, true)
  }


  // Tämä triggeroityy aina osion vaihtuessa ja vaihtaa sen perusteella kommenttikentän tekstin.
  useEffect(() => {
    async function run() {
      await getDbText();
    }
    run();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sectionKey]);

  return (
    <div style={{ marginBottom: 50 }}>
      <p
        style={{
          display: "block",
          color: "#333333",
          fontSize: "0.875rem",
          textAlign: "left",
          fontWeight: "bold",
          position: "relative",
        }}
      >{t('auditing:commentTitle')}</p>
      <textarea
        onBlurCapture={onLeaveMouse}
        style={{
          width: "auto",
          height: "auto",
          minHeight: '250px',
          maxHeight: '300px',
          overflow: 'auto',
          borderWidth: 1,
          borderStyle: "solid",
          borderColor: "#22025E",
          borderRadius: "0.25rem",
          backgroundColor: "#FFFFFF",
          padding: "0.625rem",
          paddingRight: 14,
          position: "relative",
          margin: "0 0.5rem 0 0",
        }}
        value={text}
        onInput={(input) => {
          const inputClean = input.target as HTMLInputElement; // datatyyppi pitää kertoa tsx:n syntaksin takia.
          onType(inputClean.value);
        }}
        draggable="false"
        placeholder={t('auditing:commentPlaceHolderText')}
      />
    </div>
  );
}
