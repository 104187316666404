import { GroupedGeneralLedgerTable } from '../../../components/scoping/GeneralLedgerScoping.GroupedTable';
import { UploadGeneralLedger } from '../../../components/scoping/GeneralLedgerScoping.UploadGeneralLedger';
import { ParseMethod } from './generalLedgerScopingUtils';
import { FormFieldBuilder } from '../FormFieldBuilder';
import { Button } from '../../../components/inputs';
import { useParams } from '../../../utils';
import { UploadNoGeneralLedgerFunction } from '../../../components/scoping/GeneralLedgerScoping.UploadGeneralLedger';
import InstructionBox from '../../../components/InstructionBox';
import { t } from '../../../i18n';

type SectionFormType = GeneralLedgerScopingForm;

const sectionKey: AuditingSectionKey = 'generalLedgerScoping';

const formBuilder = new FormFieldBuilder<SectionFormType>({ sectionKey });

const defaultFormState: DefaultFormState<SectionFormType> = {
  parseMethod: ParseMethod.groupedAccounts,
  groupedGeneralLedger: [],
  generalLedgerInfo: '',
};

function handleUseNoGeneralLedgerClick(props: any) {
  UploadNoGeneralLedgerFunction({ ...props });
}

const formFields: FormFields<SectionFormType> = [
  formBuilder.custom(
    <InstructionBox
      buttonText='Katso ohje!'
      title='Ohje'>
      <div style={{whiteSpace: 'pre-line'}}>
        {t('auditing:form.generalLedgerScoping.generalLedgerInfoInfo')}
      </div>
    </InstructionBox>
  ),

  formBuilder.custom(props => {
    const { isAuditingSectionFinished } = props.store.auditingStore;
    const hideButton = isAuditingSectionFinished('generalLedgerScoping');
    const id = useParams();
    return !hideButton ? (
      <Button
        onClick={() => handleUseNoGeneralLedgerClick({ ...props, id: id })}
        text="Lukuja ei tuoda erillisenä tiedostona."
      />
    ) : null;
  }),
  formBuilder.custom(props => <UploadGeneralLedger {...props} />),
  formBuilder.custom(props => <GroupedGeneralLedgerTable {...props} />),
];

const section: AuditingSectionDefinition<SectionFormType> = {
  sectionKey,
  defaultFormState,
  formFields,
  depending: ['accountMapScoping'],
};

export default section;
