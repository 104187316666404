import React from 'react';
import { useTranslation } from 'react-i18next';
import Icon from '../Icon';
import { Button, TextInput } from '../inputs';

interface Props {
  title?: string | JSX.Element;
  onAddNew?: (() => void) | false;
  showGlobalFilter?: boolean;
  globalFilter: string;
  setGlobalFilter: React.Dispatch<React.SetStateAction<string>>;
}

const TableToolbar: React.FC<Props> = ({
  title,
  onAddNew,
  showGlobalFilter,
  globalFilter,
  setGlobalFilter,
}) => {
  const { t } = useTranslation();

  return (
    <div className="flex-row">
      <div className="flex-row">
        {title && <h5>{title}</h5>}

        {showGlobalFilter && (
          <TextInput
            value={globalFilter}
            setValue={setGlobalFilter}
            placeholder={`${t('common:label.filter')}...`}
          />
        )}
      </div>

      {onAddNew && (
        <Button
          icon={<Icon type="Plus" color="background" size={12} />}
          text={t('action:addNew')}
          onClick={onAddNew}
          minWidth
        />
      )}
    </div>
  );
};

export default TableToolbar;
