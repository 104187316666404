import { MeetingTopics } from '../../../components/auditing/AuditingPlanning.MeetingTopics';
import auditingTranslations from '../../../i18n/locales/fi/auditing.json';
import { uuid } from '../../../utils';
import {
  ControlsToBeTested,
  IsSpecialistNeeded,
  showAnalyticalRiskAssessmentMeasuresDetails,
  showUnpredictabilityContext,
  showUnpredictabilityDetails,
  Unpredictability,
} from './auditingPlanningUtils';
import { FormFieldBuilder } from '../FormFieldBuilder';

type SectionFormType = AuditingPlanningForm;

const sectionKey: AuditingSectionKey = 'auditingPlanning';

const formBuilder = new FormFieldBuilder<SectionFormType>({ sectionKey });

const getMeetingTopics = (): MeetingTopic[] => {
  const topicLabels = auditingTranslations.form.auditingPlanning.meetingTopic;
  const common = { covered: null, details: null };
  return [
    { id: uuid(), label: topicLabels.topic1, ...common },
    { id: uuid(), label: topicLabels.topic2, ...common },
    { id: uuid(), label: topicLabels.topic3, ...common },
    { id: uuid(), label: topicLabels.topic4, ...common },
    { id: uuid(), label: topicLabels.topic5, ...common },
    { id: uuid(), label: topicLabels.topic6, ...common },
    { id: uuid(), label: topicLabels.topic7, ...common },
    { id: uuid(), label: topicLabels.topic8, ...common },
    { id: uuid(), label: topicLabels.topic9, ...common },
    { id: uuid(), label: topicLabels.topic10, ...common },
  ];
};

const defaultFormState: DefaultFormState<SectionFormType> = {
  meetingDate: null,
  meetingParticipantDetails: '',
  meetingTopics: getMeetingTopics(),
  isSpecialistNeeded: null,
  whySpecialistIsNeeded: '',
  isExternalHelpNeeded: null,
  whyExternalHelpIsNeeded: '',
  unpredictability: Unpredictability.nothing,
  unpredictabilityDetails: '',
  analyticalRiskAssessmentMeasuresDone: null,
  analyticalRiskAssessmentMeasuresDetails: '',
  isControlsToBeTested: null,
  controlsToBeTestedDetails: '',
  communicatedToManagement: null,
  communicatedDate: null,
};

const formFields: FormFields<SectionFormType> = ({ formState }) => [
  formBuilder.group({
    items: [
      formBuilder.dateField({ accessor: 'meetingDate' }),
      formBuilder.textArea({
        accessor: 'meetingParticipantDetails',
        showContextInfo: 'top-right',
      }),
      formBuilder.custom(props => <MeetingTopics {...props} />),
    ],
  }),

  formBuilder.radioGroup({
    accessor: 'isSpecialistNeeded',
    options: Object.values(IsSpecialistNeeded),
    detailsAccessor: 'whySpecialistIsNeeded',
    detailsHidden: formState.isSpecialistNeeded !== IsSpecialistNeeded.yes,
    showDetailsContextInfo: 'top-right',
  }),

  formBuilder.boolean({
    accessor: 'isExternalHelpNeeded',
    options: ['no', 'yes'],
    detailsAccessor: 'whyExternalHelpIsNeeded',
    detailsHidden: !formState.isExternalHelpNeeded,
    showDetailsContextInfo: 'top-right',
  }),

  formBuilder.radioGroup({
    accessor: 'unpredictability',
    options: Object.values(Unpredictability),
    showContextInfo: 'top-left',
    detailsAccessor: 'unpredictabilityDetails',
    detailsHidden: showUnpredictabilityDetails(formState),
    showDetailsContextInfo: showUnpredictabilityContext(formState)
      ? 'top-right'
      : undefined,
  }),

  formBuilder.boolean({
    accessor: 'analyticalRiskAssessmentMeasuresDone',
    options: ['no', 'yes'],
    detailsAccessor: 'analyticalRiskAssessmentMeasuresDetails',
    detailsHidden: showAnalyticalRiskAssessmentMeasuresDetails(formState),
    showDetailsContextInfo: 'top-right',
  }),

  formBuilder.radioGroup({
    accessor: 'isControlsToBeTested',
    options: Object.values(ControlsToBeTested),
    detailsAccessor: 'controlsToBeTestedDetails',
    detailsHidden: formState.isControlsToBeTested !== ControlsToBeTested.other,
  }),

  formBuilder.group({
    items: [
      formBuilder.boolean({
        accessor: 'communicatedToManagement',
      }),
      formBuilder.dateField({
        accessor: 'communicatedDate',
        hidden: !formState.communicatedToManagement,
      }),
    ],
  }),
];

const sectionDefinition: AuditingSectionDefinition<SectionFormType> = {
  sectionKey,
  defaultFormState,
  formFields,
};

export default sectionDefinition;
