import { Api } from '../RootApi';
import { ModuleBaseApi } from './ModuleBaseApi';

const URL = {
  base: 'freezingDecisions',
  info: 'freezingDecisions/uploadInfo',
};

export class FreezingApi extends ModuleBaseApi<FreezingDecisions> {
  constructor(rootApi: Api) {
    super(rootApi, URL.base);
  }
}

// saveFreezingNamesToDB function, which saves names to the database.
export async function saveFreezingNamesToDB(
  rootApi: Api,
  names: string[]
): Promise<void> {
  const response = await new FreezingApi(rootApi).addEntity({
    data: { names },
  });
  if (response instanceof Object) {
    return response.data;
  } else {
    throw new Error('Names not saved');
  }
}

// getFreezingNamesFromDB function, which retrieves names from the database.
export async function getFreezingNamesFromDB(rootApi: Api): Promise<string[]> {
  const response = await new FreezingApi(rootApi).getAllEntities();
  if (response instanceof Object) {
    return response.data;
  } else {
    throw new Error('Names not retrieved');
  }
}

// deleteAllFreezingNamesFromDB function, which deletes all names from the database.
export async function deleteAllFreezingNamesFromDB(
  rootApi: Api
): Promise<void> {
  const response = await new FreezingApi(rootApi).deleteAllEntities();
  if (response instanceof Object) {
    return response.data;
  } else {
    throw new Error('Names not deleted');
  }
}

// getFreezingUploadInfo function, which retrieves the upload date from the database.
export async function getFreezingUploadInfo(rootApi: Api): Promise<string> {
  const response = await rootApi.get(URL.info);
  const uploadDate = response.data;
  if (uploadDate) {
    return uploadDate;
  } else {
    return '';
  }
}
