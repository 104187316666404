import { FormFieldBuilder } from '../FormFieldBuilder';
import auditingJson from '../../../i18n/locales/fi/auditing.json';

type SectionFormType = ContinuityOfOperationForm;
const sectionKey: AuditingSectionKey = 'continuityOfOperation';
export const tBase = `auditing:form.${sectionKey}`;

const formBuilder = new FormFieldBuilder<SectionFormType>({ sectionKey });
const vocabulary = auditingJson.form.continuityOfOperation;

const defaultFormState: DefaultFormState<SectionFormType> = ({ auditing }) => ({
  descriptionOfIdentifiedComment:
    'Kuvaa tunnistetut tapahtumat ja olosuhteet, jotka saattavat antaa aihetta epäillä yhteisön kykyä jatkaa toimintaansa.',
  companyManagement: null,
  companyManagementComment:
    'Kuvaus kenen kanssa on käyty keskustelut, johdon näkemys tilanteesta ja suunnitelma tilanteeseen vastaamiseksi.',
  assessmentOfTheFeasibility:
    'Arvio, ovatko johdon suunnitelmat toteutuskelpoisia ja millainen vaikutus toimenpiteillä on tilanteeseen, viittaa mahdollisuuksien mukaan alkaneen kauden raportteihin.',
  cashFlowBoolean: null,
  basedOnTheAssessment: null,
  checkingAndReviewingComment:
    'Kuvaa suoritetut tarkastustoimenpitee ja tarkastuskommentit.',
  moreFactsOrInformationBoolean: null,
  moreFactsOrInformationComment:
    'Kuvaa tässä esiin tulleet lisätiedot ja niiden vaikutus.',
  otherPerformedInspectionComment:
    'Kuvaa tässä soveltuvin osin suoritetut tarkastustoimenpiteet, mahdolliset muiden raporttien ja sopimusten läpikäynnit, keskustelut aiheeseen liittyen, mahdolliset kirjalliset vahvistukset',
  basedOnTheAcquiredBoolean: null,
  basedOnTheAcquiredComment: '',
  basedOnTheAuditBoolean: null,
  doTheFinancialStatementsBoolean: null,
  doTheFinancialStatementsComment: '',
  shouldInformationAboutEventsBoolean: null,
  shouldInformationAboutEventsComment: 'Anna lisätietoa',
  impactOnReportingComment:
    'Yhtiön toiminnan jatkuvuuteen liittyy epävarmuutta. Lisätään kertomukselle kappale toiminnan jatkuvuuteen liittyvästä olennaisesta epävarmuudesta',
});

const formFields: FormFields<SectionFormType> = ({ formState, auditing }) => [
  formBuilder.group({
    items: [
      formBuilder.group({
        items: [
          formBuilder.text({ text: vocabulary?.descriptionOfIdentified }),
          formBuilder.textArea({ accessor: 'descriptionOfIdentifiedComment' }),
        ],
      }),

      formBuilder.group({
        items: [
          formBuilder.text({ text: vocabulary?.inquiredFromTheCompany }),
          formBuilder.checkboxGroup({
            accessor: 'companyManagement',
            options: Object.keys(vocabulary?.companyManagementOptions || {}),
          }),
          formBuilder.textArea({
            accessor: 'companyManagementComment',
          }),
          formBuilder.textArea({
            accessor: 'assessmentOfTheFeasibility',
          }),
        ],
      }),

      formBuilder.group({
        items: [
          formBuilder.text({ text: vocabulary?.cashFlow }),
          formBuilder.boolean({
            accessor: 'cashFlowBoolean',
            options: ['no', 'yes'],
          }),
          formBuilder.group({
            hidden: !formState.cashFlowBoolean,
            items: [
              formBuilder.subtitle({ title: vocabulary?.basedOnTheCompleted }),
              formBuilder.checkboxGroup({
                accessor: 'basedOnTheAssessment',
                options: Object.keys(
                  vocabulary?.basedOnTheAssessmentOptions || {}
                ),
              }),
              formBuilder.subtitle({ title: vocabulary?.checkingAndReviewing }),
              formBuilder.textArea({ accessor: 'checkingAndReviewingComment' }),
            ],
          }),
        ],
      }),

      formBuilder.group({
        items: [
          formBuilder.text({ text: vocabulary?.moreFactsOrInformation }),
          formBuilder.boolean({
            accessor: 'moreFactsOrInformationBoolean',
            options: Object.keys(
              vocabulary?.moreFactsOrInformationBooleanOptions || {}
            ),
          }),
          formBuilder.textArea({
            accessor: 'moreFactsOrInformationComment',
            hidden: !formState.moreFactsOrInformationBoolean,
          }),
        ],
      }),

      formBuilder.group({
        items: [
          formBuilder.text({ text: vocabulary?.otherPerformedInspection }),
          formBuilder.textArea({ accessor: 'otherPerformedInspectionComment' }),
        ],
      }),

      formBuilder.group({
        items: [
          formBuilder.text({ text: vocabulary?.basedOnTheAcquired }),
          formBuilder.checkboxGroup({
            accessor: 'basedOnTheAcquiredBoolean',
            options: Object.keys(
              vocabulary?.basedOnTheAcquiredBooleanOptions || {}
            ),
          }),
          // If basedOnTheAcquiredBoolean option four ("Lisätietoa") is selected, show the following: formBuilder.textArea({accessor: 'basedOnTheAcquiredComment'}),
          formBuilder.textArea({
            accessor: 'basedOnTheAcquiredComment',
            hidden: formState.basedOnTheAcquiredBoolean
              ? formState.basedOnTheAcquiredBoolean.four === false
              : true,
          }),
        ],
      }),

      formBuilder.group({
        items: [
          formBuilder.text({ text: vocabulary?.basedOnTheAudit }),
          formBuilder.boolean({
            accessor: 'basedOnTheAuditBoolean',
            options: ['no', 'yes'],
          }),
          formBuilder.group({
            hidden: !formState.basedOnTheAuditBoolean,
            items: [
              formBuilder.text({ text: vocabulary?.doTheFinancialStatements }),
              formBuilder.checkboxGroup({
                accessor: 'doTheFinancialStatementsBoolean',
                options: Object.keys(
                  vocabulary?.doTheFinancialStatementsBooleanOptions || {}
                ),
              }),
              formBuilder.textArea({
                accessor: 'doTheFinancialStatementsComment',
                hidden: formState.doTheFinancialStatementsBoolean
                  ? formState.doTheFinancialStatementsBoolean.three === false
                  : true,
              }),
            ],
          }),
          formBuilder.group({
            hidden: formState.basedOnTheAuditBoolean === true,
            items: [
              formBuilder.text({
                text: vocabulary?.shouldInformationAboutEvents,
              }),
              formBuilder.checkboxGroup({
                accessor: 'shouldInformationAboutEventsBoolean',
                options: Object.keys(
                  vocabulary?.shouldInformationAboutEventsBooleanOptions || {}
                ),
              }),
              formBuilder.textArea({
                accessor: 'shouldInformationAboutEventsComment',
                hidden: formState.shouldInformationAboutEventsBoolean
                  ? formState.shouldInformationAboutEventsBoolean.three ===
                    false
                  : true,
              }),
            ],
          }),
          formBuilder.group({
            items: [
              formBuilder.text({ text: vocabulary?.impactOnReporting }),
              formBuilder.textArea({ accessor: 'impactOnReportingComment' }),
            ],
          }),
        ],
      }),
    ],
  }),
];

const section: AuditingSectionDefinition<SectionFormType> = {
  sectionKey,
  defaultFormState,
  formFields,
};

export default section;
