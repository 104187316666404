import { useTranslation } from 'react-i18next';
import { useStore } from '../../store/RootContext';
import { confirmDelete } from '../../utils';
import {
  emptyOtherSignificantRiskStatement,
  getDefaultRiskValues,
  OtherSignificantRiskStatementKind,
  Risk,
} from '../../views/auditingSections/planning/risksUtils';
import AuditingTable from '../table/AuditingTable';
import { OnEditProps, TableColumnDefs } from '../table/Table';

const sectionKey: AuditingSectionKey = 'risks';

const tBase = `auditing:form.${sectionKey}.otherSignificantRiskStatements`;

interface Props {
  data: OtherSignificantRiskStatement[];
  onEdit: (data: OtherSignificantRiskStatement[]) => void;
}

export const OtherSignificantRiskStatements = ({ data, onEdit }: Props) => {
  const { t } = useTranslation();

  const { auditingStore } = useStore();

  function handleEdit<T>({
    itemId,
    rowIndex,
    columnId,
    value,
  }: OnEditProps<T>) {
    const statements = data.map((row, index) => {
      const idMatch = itemId !== undefined && itemId === row.id;
      const rowIndexMatch = itemId === undefined && rowIndex === index;
      if (idMatch || rowIndexMatch) return { ...row, [columnId]: value };
      return row;
    });
    onEdit(statements);
  }

  const composeRiskSelectColumn = (
    key: 'typicalRisk' | 'controlRisk' | 'totalRisk'
  ) => ({
    accessorKey: key,
    className: 'text-center width-min',
    header: () => t(`auditing:form.${sectionKey}.${key}`),
    selectProps: {
      options: Object.values(Risk),
      displayValue: (option: string) =>
        t(`auditing:form.${sectionKey}.riskOptions.${option}`),
      setValue: (editProps: OnEditProps<string>) =>
        handleEdit<string>(editProps),
    },
  });

  const tableColumns: TableColumnDefs<OtherSignificantRiskStatement> = [
    {
      accessorKey: 'kind',
      header: () => t(`${tBase}.kind`),
      selectProps: {
        options: Object.values(OtherSignificantRiskStatementKind),
        displayValue: (option: string) => t(`${tBase}.kindOptions.${option}`),
        setValue: (editProps: OnEditProps<string>) =>
          handleEdit<string>(editProps),
      },
    },
    composeRiskSelectColumn('typicalRisk'),
    composeRiskSelectColumn('controlRisk'),
    composeRiskSelectColumn('totalRisk'),
  ];

  const handleAddNewRow = () => {
    const risks = getDefaultRiskValues(auditingStore.selectedEntity);
    onEdit([...data, emptyOtherSignificantRiskStatement({ risks })]);
  };

  const handleDeleteRow = ({ id, kind }: OtherSignificantRiskStatement) => {
    if (confirmDelete(t(`${tBase}.kindOptions.${kind}`) ?? '')) {
      onEdit(data.filter(row => row.id !== id));
    }
  };

  return (
    <AuditingTable<OtherSignificantRiskStatement>
      sectionKey={sectionKey}
      onAddNewRow={handleAddNewRow}
      data={data}
      columns={tableColumns}
      onRowDelete={handleDeleteRow}
      showGlobalFilter={false}
      disableSort
    />
  );
};
