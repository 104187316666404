import React, { useState } from 'react';
import { Combobox } from '@headlessui/react';
import Icon from '../Icon';
import { InputContainer } from './InputContainer';
import { useTranslation } from 'react-i18next';
import { Label } from './Label';
import { SelectProps } from './Select';
import { SelectContainer } from './SelectContainer';

export function SelectAutocomplete<T>({
  id,
  label,
  options,
  value,
  setValue,
  displayValue = (option: T) => `${option}`,
  placeholder,
  disabled,
  required,
  fullWidth,
  nullable,
}: SelectProps<T>) {
  const { t } = useTranslation('common');

  const [query, setQuery] = useState('');

  const filteredOptions =
    query === ''
      ? options
      : options.filter(option => {
          return displayValue(option)
            ?.toLowerCase()
            .includes(query.toLowerCase());
        });

  const noResultsOption = (
    <div className="select-option nothing-found">
      <Icon type="Unhappy" color="grey400" />
      {t('common:nothingFound')}
    </div>
  );

  const noOptionsOption = (
    <div className="select-option nothing-found">
      <Icon type="Unhappy" color="grey400" />
      {t('common:noOptions')}
    </div>
  );

  return (
    <SelectContainer fullWidth={fullWidth} id={id} data-testid={id}>
      <Combobox
        value={value}
        onChange={setValue}
        disabled={disabled}
        nullable={nullable}
      >
        <InputContainer fullWidth={fullWidth}>
          {label && (
            <Combobox.Label>
              <Label
                disabled={disabled}
                className={required ? 'is-required' : ''}
              >
                {label}
              </Label>
            </Combobox.Label>
          )}

          <Combobox.Button as="div">
            <Combobox.Input
              onChange={event => setQuery(event.target.value)}
              displayValue={(option: T) =>
                option ? displayValue(option) ?? '' : ''
              }
              placeholder={placeholder ?? `${t('action:select')}...`}
              required={required}
              disabled={disabled}
            />
          </Combobox.Button>

          {!disabled && (
            <Combobox.Button className="input-action-button">
              {({ open }) => (
                <Icon
                  type={open ? 'ChevronUp' : 'ChevronDown'}
                  color="secondary"
                />
              )}
            </Combobox.Button>
          )}
        </InputContainer>

        <Combobox.Options className="select-options">
          {!filteredOptions.length && query !== '' && noResultsOption}
          {!filteredOptions.length && query === '' && noOptionsOption}

          {filteredOptions.map((option, i) => (
            <Combobox.Option
              key={(option as any).id ?? i}
              value={option}
              className={({ selected }) =>
                `select-option ${selected ? 'is-selected' : ''}`
              }
            >
              {({ selected }) => (
                <>
                  {selected && <Icon type="Selected" color="primary" />}
                  {displayValue(option)}
                </>
              )}
            </Combobox.Option>
          ))}
        </Combobox.Options>
      </Combobox>
    </SelectContainer>
  );
}

export default SelectAutocomplete;
