import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useStore } from '../../store/RootContext';
import { AuditingTemplate } from '../../utils';
import { Select } from '../inputs';

const AuditingTemplateSelect: React.FC = () => {
  const { t } = useTranslation();

  const {
    auditingStore: { auditing, auditingTemplate, auditingLocked, editEntity },
  } = useStore();

  const [template, setTemplate] = useState<AuditingTemplate | undefined>();

  useEffect(() => {
    if (auditingTemplate) setTemplate(auditingTemplate);
  }, [auditingTemplate]);

  const handleStatusChange = (
    newTemplate: React.SetStateAction<AuditingTemplate | undefined>
  ) => {
    setTemplate(newTemplate);
    if (auditing?.id && typeof newTemplate === 'string') {
      editEntity({ id: auditing.id, data: { template: newTemplate } });
    }
  };

  return (
    <Select<AuditingTemplate>
      label={t('auditing:template.label')}
      options={Object.values(AuditingTemplate)}
      value={template}
      setValue={handleStatusChange}
      displayValue={(template?: AuditingTemplate) =>
        template ? t(`auditing:template.${template}`) : ''
      }
      disabled={process.env.NODE_ENV !== 'development' || auditingLocked}
    />
  );
};

export default AuditingTemplateSelect;
