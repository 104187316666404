import { GroupedGeneralLedgerTable } from '../../../components/analyzes/GeneralLedger.GroupedTable';
import {
  UploadGeneralLedger,
  UploadNoGeneralLedgerFunction,
} from '../../../components/analyzes/GeneralLedger.UploadGeneralLedger';
import { ParseMethod } from './generalLedgerUtils';
import { FormFieldBuilder } from '../FormFieldBuilder';
import { Button } from '../../../components/inputs';
import { useParams } from '../../../utils';
import InstructionBox from '../../../components/InstructionBox';
import { t } from '../../../i18n';

type SectionFormType = GeneralLedgerForm;

const sectionKey: AuditingSectionKey = 'generalLedger';

const formBuilder = new FormFieldBuilder<SectionFormType>({ sectionKey });

const defaultFormState: DefaultFormState<SectionFormType> = {
  parseMethod: ParseMethod.groupedAccounts,
  groupedGeneralLedger: [],
  useGeneralLedger: false,
  generalLedgerInfo: '',
};

function handleUseNoGeneralLedgerClick(props: any) {
  UploadNoGeneralLedgerFunction({ ...props });
}

const formFields: FormFields<SectionFormType> = [
  formBuilder.custom(
    <InstructionBox
      buttonText='Katso ohje!'
      title='Ohje'>
      <div style={{whiteSpace: 'pre-line'}}>
        {t('auditing:form.generalLedger.generalLedgerInfoInfo')}
      </div>
    </InstructionBox>
  ),
  formBuilder.custom(props => {
    const { isAuditingSectionFinished } = props.store.auditingStore;
    const hideButton = isAuditingSectionFinished('generalLedger');
    const id = useParams();
    return !hideButton ? (
      <Button
        onClick={() => handleUseNoGeneralLedgerClick({ ...props, id: id })}
        text="Lukuja ei tuoda erillisenä tiedostona."
      />
    ) : null;
  }),
  formBuilder.custom(props => <UploadGeneralLedger {...props} />),
  formBuilder.custom(props => <GroupedGeneralLedgerTable {...props} />),
];

const section: AuditingSectionDefinition<SectionFormType> = {
  sectionKey,
  defaultFormState,
  formFields,
  depending: ['accountMap'],
};

export default section;
