import { uuid } from '../../../utils';

export const makeEmptyBalanceComparison = (
  balanceComparison?: Partial<BalanceComparison>
) => ({
  id: uuid(),
  label: null,
  balanceInLedgerOrSeparateAccounting: null,
  balanceInGeneralLedger: null,
  ...balanceComparison,
});
