import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { useStore } from '../store/RootContext';
import { formatDateTime } from '../utils';
import Icon from './Icon';

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;

  > p {
    margin: ${p => p.theme.spacing.xsm} 0;
    :not(:last-child) {
      margin-right: ${p => p.theme.spacing.lg};
    }

    position: relative;
    ${p => p.theme.font.size[14]};

    .timestamp,
    .username {
      margin-left: 20px;
    }

    svg {
      position: absolute;
      margin-right: ${p => p.theme.spacing.sm};
      top: 2px;
    }

    .timestamp {
      display: flex;
      align-items: center;
      font-weight: bold;
      color: ${p => p.theme.color.grey500};
    }

    .username {
      ${p => p.theme.font.size[12]};
      color: ${p => p.theme.color.grey400};
    }
  }
`;

type UserIdOrUser = number | User;

export enum StatusType {
  updated = 'updated',
  finished = 'finished',
  approved = 'approved',
}

interface StatusItem {
  key: StatusType;
  date: string | undefined;
  user: UserIdOrUser | undefined;
  label: (date: string) => string;
  icon: JSX.Element;
}

interface Props {
  testId?: string;
  updatedAt?: string; // ISO string
  updatedBy?: UserIdOrUser;
  finishedAt?: string; // ISO string
  finishedBy?: UserIdOrUser;
  approvedAt?: string; // ISO string
  approvedBy?: UserIdOrUser;
  hidden?: StatusType[];
}

const StatusHistory: React.FC<Props> = ({
  testId,
  updatedAt,
  updatedBy,
  finishedAt,
  finishedBy,
  approvedAt,
  approvedBy,
  hidden,
}) => {
  const { t } = useTranslation();

  const {
    userStore: { getUserName },
  } = useStore();

  const commonIconProps = { size: 14 };

  const formatUserName = (userIdOrUser?: UserIdOrUser) => {
    return typeof userIdOrUser === 'number'
      ? userIdOrUser
      : getUserName(userIdOrUser);
  };

  const items: StatusItem[] = [
    {
      key: StatusType.updated,
      date: updatedAt,
      user: updatedBy,
      label: (date: string) => t('common:label.editedAt', { date }),
      icon: <Icon type="Hourglass" {...commonIconProps} />,
    },
    {
      key: StatusType.finished,
      date: finishedAt,
      user: finishedBy,
      label: (date: string) => t('common:label.finishedAt', { date }),
      icon: <Icon type="Check" color="success" {...commonIconProps} />,
    },
    {
      key: StatusType.approved,
      date: approvedAt,
      user: approvedBy,
      label: (date: string) => t('common:label.approvedAt', { date }),
      icon: <Icon type="Approved" color="success" {...commonIconProps} />,
    },
  ].filter(({ key }) => !hidden?.includes(key));

  return (
    <Container data-testid={testId}>
      {items.map(({ key, date, user, label, icon }) => {
        if (!date) return null;
        return (
          <p key={key} data-testid={`status-${key}`}>
            {icon}
            <span className="timestamp">{label(formatDateTime(date))}</span>
            <span className="username">{formatUserName(user)}</span>
          </p>
        );
      })}
    </Container>
  );
};

export default StatusHistory;
