import { observer } from 'mobx-react-lite';
import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import { useStore } from '../../store/RootContext';
import { AuditingTemplate, useForm } from '../../utils';
import { useAnimation } from '../../utils/hooks';
import Accordion from '../Accordion';
import FormElement from './FormElement';

const StyledForm = styled.form`
  > *:not(table),
  .form-element-container:not(table) {
    display: block;
  }
  > *:not(hr),
  .form-element-container:not(hr) {
    padding: ${p => p.theme.spacing.lg} 0;
  }

  .group {
    margin: ${p => p.theme.spacing.lg} 0;
    > *:not(hr) {
      padding: ${p => p.theme.spacing.xsm} 0;
    }
  }

  .group-row {
    display: flex;
    flex-wrap: wrap;
    > * {
      flex: 1;
      :not(:first-child) {
        margin-left: ${p => p.theme.spacing.md};
      }
    }

    @media ${p => p.theme.breakpoint.laptop} {
      flex-direction: column;
      > * {
        :not(:first-child) {
          margin-left: 0;
        }
      }
    }
  }

  .group-accordion {
    padding: ${p => p.theme.spacing.md};
    padding-top: ${p => p.theme.spacing.xl};
  }

  h2 {
    ${p => p.theme.font.h4};
  }
  h3 {
    ${p => p.theme.font.h5};
  }
  h4 {
    ${p => p.theme.font.h6};
  }
`;

interface Props<T> {
  formId: string;
  initialState: T;
  onSubmit: (formState: T) => Promise<boolean | void>;
  fields: FormFields<T>;
  translationBase: string;
  onFormSaveStateChange?: (saveState: FormSaveState) => void;
  sectionKey?: AuditingSectionKey;
  auditingTemplate?: AuditingTemplate;
  disabled?: boolean;
}

function Form<T>({
  formId,
  initialState,
  onSubmit,
  fields,
  translationBase,
  onFormSaveStateChange,
  sectionKey,
  auditingTemplate,
  disabled,
}: Props<T>) {
  const store = useStore();

  const animationParent = useAnimation();

  const formIdRef = useRef<string>();

  const {
    formState,
    formSaveState,
    unsavedChanges,
    initializeForm,
    replaceFormState,
    patchFormState,
    formChangeHandlers,
  } = useForm<T>(initialState, onSubmit);

  useEffect(() => {
    const formChanged = formIdRef.current !== formId;
    if (formChanged) {
      formIdRef.current = formId;
      initializeForm(initialState);
    }
  }, [formId, initialState, initializeForm]);

  useEffect(() => {
    if (onFormSaveStateChange) {
      onFormSaveStateChange(!!unsavedChanges ? 'UNSAVED' : formSaveState);
    }
  }, [formSaveState, onFormSaveStateChange, unsavedChanges]);

  if (formIdRef.current !== formId) return null;

  const formFields =
    typeof fields === 'function'
      ? fields({
          formState,
          auditing: store.auditingStore.selectedEntity,
          store,
        })
      : fields;

  const formFieldProps = {
    formState,
    store,
    patchFormState,
    replaceFormState,
  };

  const remarks = store.auditingStore.getAuditingRemarks();

  // Check if at least some fields in a group are not hidden
  const someFieldsAreVisible = (formItems?: FormField<T>[]) =>
    !!formItems?.some(({ hidden }) => !hidden);

  // Check if (maybe) given "custom" remark triggers match to detected remarks
  const hasCustomRemarks = (formField: FormField<T>) =>
    remarks.some(remark =>
      formField.customRemarkTriggers?.some(
        trigger => trigger === remark.accessor
      )
    );

  // Recusively check if any of the group items has triggered some remarks values
  const groupHasRemarks = (groupItems?: FormField<T>[]): boolean => {
    return !!groupItems?.reduce(
      (hasAnyRemarks: boolean, item: FormField<T>) => {
        if (['group', 'groupRow', 'groupAccordion'].includes(item.type)) {
          return hasAnyRemarks || groupHasRemarks(item.groupItems);
        } else {
          const isMatchingRemark = (remark: AuditingRemark) =>
            remark.sectionKey === sectionKey &&
            remark.accessor === item.accessor;

          return hasAnyRemarks || remarks.some(isMatchingRemark);
        }
      },
      false
    );
  };

  // Check if form field should be hidden based on auditing template
  const isHidden = (formField: FormField<T>) =>
    formField.hidden ||
    (auditingTemplate &&
      formField.hiddenInTemplate?.includes(auditingTemplate));

  const renderFormField = (formField: FormField<T>, i: number) => {
    const isMatchingRemark = (remark: AuditingRemark) =>
      remark.sectionKey === sectionKey &&
      remark.accessor === formField.accessor;

    return (
      <div
        key={`${formField.type}-${String(formField.accessor)}-${i}`}
        className="form-element-container"
      >
        <FormElement<T>
          translationBase={translationBase}
          formField={formField}
          formState={formState}
          formFieldProps={formFieldProps}
          showRemark={remarks.some(isMatchingRemark)}
          disabled={disabled}
          {...formChangeHandlers}
        />
      </div>
    );
  };

  /**
   * Recursively render form fields.
   *
   * Given form field might be a group having property 'groupItems', which contains more form fields.
   * In above case, this function is called recursively.
   */
  const renderFormFields = (formFields: FormField<T>[]) =>
    formFields.map((formField, i) => {
      const { type, variant, accessor, title, groupItems } = formField;

      const hidden = isHidden(formField);

      // Try to form a unique key that still should always be the same for the same element.
      // (e.g. uuid cannot be used as a key since it would change between re-renders!)
      const groupKey = `${type}-${String(accessor)}-${title}-${
        groupItems?.length
      }-${i}`;

      switch (type) {
        case 'group':
          if (hidden || !someFieldsAreVisible(groupItems)) return null;
          return (
            <div key={groupKey} className="group" ref={animationParent}>
              {renderFormFields(groupItems ?? [])}
            </div>
          );

        case 'groupRow':
          if (hidden || !someFieldsAreVisible(groupItems)) return null;
          return (
            <div key={groupKey} className="group-row" ref={animationParent}>
              {renderFormFields(groupItems ?? [])}
            </div>
          );

        case 'groupAccordion':
          if (hidden || !someFieldsAreVisible(groupItems)) return null;

          const isOpen = store.appStore.getComponentState(groupKey)?.isOpen;

          const showRemark =
            hasCustomRemarks(formField) || groupHasRemarks(groupItems);

          return (
            <Accordion
              key={groupKey}
              className="group-accordion"
              title={<h2>{title ?? '-'}</h2>}
              variant={variant}
              defaultOpen={isOpen ?? formField.open ?? false}
              onClick={isOpen =>
                store.appStore.setComponentState({ key: groupKey, isOpen })
              }
              showRemark={showRemark}
            >
              {renderFormFields(groupItems ?? [])}
            </Accordion>
          );
        default:
          return hidden ? null : renderFormField(formField, i);
      }
    });

  return <StyledForm>{renderFormFields(formFields)}</StyledForm>;
}

export default observer(Form);
