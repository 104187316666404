import { DateTime } from 'luxon';
import {
  AlternativeMaterialities,
  MaterialityManualTable,
  MaterialitySecondTable,
  MaterialityTable,
} from '../../../components/auditing/Materiality.AlternativeMaterialities';
import {
  MaterialitiesSecondTable,
  MaterialitiesTable,
  MaterialitiesThirdTable,
} from '../../../components/auditing/Materiality.MaterialitiesTable';
import {
  SeparateMaterialities,
  SeparateSecondMaterialities,
  SeparateThirdMaterialities,
} from '../../../components/auditing/Materiality.SeparateMaterialities';
import UpdateMaterialityBaseNumberButton, {
  UpdateMaterialitySecondBaseNumberButton,
  UpdateMaterialityThirdBaseNumberButton,
} from '../../../components/auditing/Materiality.UpdateBaseNumberButton';
import auditingJson from '../../../i18n/locales/fi/auditing.json';
import { AuditingTemplate } from '../../../utils';
import { FormFieldBuilder } from '../FormFieldBuilder';
import { utilsForSecond } from './forMateriality/materialityUtilsSecond';
import { utilsForThird } from './forMateriality/materialityUtilsThird';
import {
  getDefaultSeparateMaterialities,
  getMaterialityBasisOptions,
  handleMaterialityBasedInChange,
  handleMaterialityBasisChange,
  isSameMaterialityForAllItems,
  MaterialityBasedIn,
} from './materialityUtils';
import InstructionBox from '../../../components/InstructionBox';
import { t } from '../../../i18n';

type SectionFormType = MaterialityForm;
const sectionKey: AuditingSectionKey = 'materiality';
const formBuilder = new FormFieldBuilder<SectionFormType>({ sectionKey });

const vocabulary = auditingJson?.form?.materiality;

const defaultFormState: DefaultFormState<SectionFormType> = ({ auditing }) => {
  const isPublicTemplate = auditing.template === AuditingTemplate.public;
  return {
    materialityTableColumn: [
      {
        label: 'Liikevaihto',
        id: '1',
        materialityBasedOn: 0,
        materialityPercent: 0,
        materialityEuro: 0,
      },
      {
        label: 'Tulos ennen tilinpäätös siirtoja ja veroja',
        id: '2',
        materialityBasedOn: 0,
        materialityPercent: 0,
        materialityEuro: 0,
      },
      {
        label: 'Oma pääoma',
        id: '3',
        materialityBasedOn: 0,
        materialityPercent: 0,
        materialityEuro: 0,
      },
      {
        label: 'Vastattavaa (Taseen loppusumma)',
        id: '4',
        materialityBasedOn: 0,
        materialityPercent: 0,
        materialityEuro: 0,
      },
    ],
    materialitySecondTableColumn: [
      {
        label: 'Liikevaihto',
        id: '1',
        materialityBasedOn: 0,
        materialityPercent: 0,
        materialityEuro: 0,
      },
      {
        label: 'Tulos ennen tilinpäätös siirtoja ja veroja',
        id: '2',
        materialityBasedOn: 0,
        materialityPercent: 0,
        materialityEuro: 0,
      },
      {
        label: 'Oma pääoma',
        id: '3',
        materialityBasedOn: 0,
        materialityPercent: 0,
        materialityEuro: 0,
      },
      {
        label: 'Vastattavaa (Taseen loppusumma)',
        id: '4',
        materialityBasedOn: 0,
        materialityPercent: 0,
        materialityEuro: 0,
      },
    ],
    materialityManualTableColumn: [
      {
        label: 'Liikevaihto',
        id: '1',
        materialityBasedOn: 0,
        materialityPercent: 0,
        materialityEuro: 0,
      },
      {
        label: 'Tulos ennen tilinpäätös siirtoja ja veroja',
        id: '2',
        materialityBasedOn: 0,
        materialityPercent: 0,
        materialityEuro: 0,
      },
      {
        label: 'Oma pääoma',
        id: '3',
        materialityBasedOn: 0,
        materialityPercent: 0,
        materialityEuro: 0,
      },
      {
        label: 'Vastattavaa (Taseen loppusumma)',
        id: '4',
        materialityBasedOn: 0,
        materialityPercent: 0,
        materialityEuro: 0,
      },
    ],
    useManualTable: false,

    materialityBasedIn: null,
    materialityBasis: null,
    materialityBasisText: '',
    materialityBaseNumber: null,
    materialityPercent: isPublicTemplate ? 2 : null,
    workingMaterialityPercent: 75,
    singleErrorMaterialityPercent: 5,
    reasonsOfMateriality: '',
    isSameMaterialityForAllItems: isSameMaterialityForAllItems(
      auditing.template
    ),
    separateMaterialities: getDefaultSeparateMaterialities(auditing.template),
    alternatives: {
      turnoverMaterialityPercent: 3,
      profitOrLossBeforeTransfersAndTaxesMaterialityPercent: 10,
      equityMaterialityPercent: 10,
      liabilitiesMaterialityPercent:
        auditing.template === AuditingTemplate.public ? 2 : 3,
      combinedRevenuesMaterialityPercent: 2,
      averageOfLiabilitiesAndRevenuesMaterialityPercent: 2,
      annualContributionMarginMaterialityPercent: 5,
    },
    // New fields
    addMaterialityCalculation: null,
    materialityTextArea:
      'Kuvaa tässä, miltä ajalta olennaisuus on laskettu / anna muut lisätiedot. Esimerkiksi olennaisuus on laskettu kauden aikaiseen tarkastukseen ja olennaisuus on määritelty alla kuvatulla tavalla.',
    whenMaterialityCalculated: 'Tilinpäätöshetkeltä',
    // Auditing's endDate need to change from string to Date
    whenMaterialityCalculatedDate: auditing.endDate
      ? DateTime.fromISO(auditing.endDate).toFormat('yyyy-MM-dd')
      : null,

    materialitySecondBasedIn: null,
    materialitySecondBasis: null,
    materialitySecondBasisText: '',
    materialitySecondBaseNumber: null,
    materialitySecondPercent: isPublicTemplate ? 2 : null,
    workingMaterialitySecondPercent: 34,
    singleErrorMaterialitySecondPercent: 15,
    reasonsOfMaterialitySecond: '',
    separateSecondMaterialities:
      utilsForSecond.getDefaultSeparateMaterialitiesSecond(auditing.template),
    isSameMaterialitySecondForAllItems:
      utilsForSecond.isSameMaterialitySecondForAllItems(auditing.template),
    addMaterialitySecondCalculation: null,
    materialitySecondTextArea:
      'Kuvaa tässä, miltä ajalta olennaisuus on laskettu / anna muut lisätiedot. Esimerkiksi olennaisuus on laskettu kauden aikaiseen tarkastukseen ja olennaisuus on määritelty alla kuvatulla tavalla.',
    whenMaterialitySecondCalculated: 'Tilinpäätöshetkeltä',
    whenMaterialitySecondCalculatedInfo: 'esim. 1-9/202X',

    materialityThirdBasedIn: null,
    materialityThirdBasis: null,
    materialityThirdBasisText: '',
    materialityThirdBaseNumber: null,
    materialityThirdPercent: isPublicTemplate ? 2 : null,
    workingMaterialityThirdPercent: 5,
    singleErrorMaterialityThirdPercent: 65,
    reasonsOfMaterialityThird: '',
    separateThirdMaterialities:
      utilsForThird.getDefaultSeparateMaterialitiesThird(auditing.template),
    isSameMaterialityThirdForAllItems:
      utilsForThird.isSameMaterialityThirdForAllItems(auditing.template),
    addMaterialityThirdCalculation: null,
    materialityThirdTextArea:
      'Kuvaa tässä, miltä ajalta olennaisuus on laskettu / anna muut lisätiedot. Esimerkiksi olennaisuus on laskettu kauden aikaiseen tarkastukseen ja olennaisuus on määritelty alla kuvatulla tavalla.',
    whenMaterialityThirdCalculated: 'Tilinpäätöshetkeltä',
    whenMaterialityThirdCalculatedInfo: 'esim. 1-9/202X',

    materialityHelp: false,
  };
};

const formFields: FormFields<SectionFormType> = ({ formState, auditing }) => [
  formBuilder.custom(
    <InstructionBox
      buttonText='Katso ohje!'
      title='Ohje'>
      <div style={{whiteSpace: 'pre-line'}}>
        {t('auditing:form.materiality.materialityHelpInfo')}
      </div>
    </InstructionBox>
  ),

  formBuilder.group({
    items: [
      formBuilder.groupRow({
        items: [
          formBuilder.textInput({
            accessor: 'whenMaterialityCalculated',
            plainLabel: 'Miltä ajankohdalta olennaisuus on laskettu?',
          }),
          formBuilder.dateField({
            accessor: 'whenMaterialityCalculatedDate',
            plainLabel: 'Päiväys',
          }),
        ],
      }),
      formBuilder.groupRow({
        items: [
          formBuilder.select({
            accessor: 'materialityBasedIn',
            options: Object.values(MaterialityBasedIn),
            customOnChange: handleMaterialityBasedInChange,
          }),
          formBuilder.select({
            accessor: 'materialityBasis',
            options: getMaterialityBasisOptions(auditing?.template),
            customOnChange: handleMaterialityBasisChange,
          }),
          formBuilder.numberInput({
            accessor: 'materialityBaseNumber',
          }),
        ],
      }),

      formBuilder.custom(props => (
        <UpdateMaterialityBaseNumberButton {...props} />
      )),

      // If selected "Muu"
      formBuilder.textArea({
        accessor: 'materialityBasisText',
        hidden: formState.materialityBasis !== 'other',
      }),

      formBuilder.group({
        items: [
          formBuilder.custom(props => <MaterialitiesTable {...props} />),
          formBuilder.textArea({ accessor: 'reasonsOfMateriality' }),
        ],
      }),

      formBuilder.group({
        items: [
          ...(formState.useManualTable
            ? [
                formBuilder.custom(props => (
                  <MaterialityManualTable {...props} />
                )),
              ]
            : [
                formBuilder.custom(props => (
                  <AlternativeMaterialities {...props} />
                )),
              ]),
          formBuilder.boolean({
            accessor: 'useManualTable',
            plainLabel: 'Lisää vaihtoehtoiset olennaisuudet manuaalisesti',
          }),
        ],
      }),

      formBuilder.group({
        items: [
          formBuilder.boolean({
            accessor: 'isSameMaterialityForAllItems',
            options: ['no', 'yes'],
          }),
          formBuilder.custom(props => <SeparateMaterialities {...props} />, {
            hidden:
              !!formState.isSameMaterialityForAllItems ||
              formState.isSameMaterialityForAllItems === null,
          }),
          formBuilder.boolean({
            accessor: 'addMaterialityCalculation',
            options: Object.keys(vocabulary?.addMaterialityCalculation),
          }),
          formBuilder.textArea({
            accessor: 'materialityTextArea',
            hidden: !formState.addMaterialityCalculation,
          }),
        ],
      }),
    ],
  }),
  // Second materiality
  formBuilder.accordionGroup({
    title: 'Olennaisuuden laskenta',
    hidden: !formState.addMaterialityCalculation,
    open: true,
    items: [
      formBuilder.group({
        items: [
          formBuilder.groupRow({
            items: [
              formBuilder.textInput({
                accessor: 'whenMaterialitySecondCalculated',
                plainLabel: 'Miltä ajankohdalta olennaisuus on laskettu?',
              }),
              formBuilder.textInput({
                accessor: 'whenMaterialitySecondCalculatedInfo',
                plainLabel: 'Ajankohta',
              }),
            ],
          }),
          formBuilder.groupRow({
            items: [
              formBuilder.select({
                accessor: 'materialitySecondBasedIn',
                plainLabel: vocabulary.materialityBasedIn,
                options: Object.values(utilsForSecond.MaterialityBasedInSecond),
                optionDisplayValue: (key: string) =>
                  vocabulary.materialityBasedInOptions[
                    key as keyof typeof vocabulary.materialityBasedInOptions
                  ],
                customOnChange:
                  utilsForSecond.handleMaterialitySecondBasedInChange,
              }),
              formBuilder.select({
                accessor: 'materialitySecondBasis',
                plainLabel: vocabulary.materialityBasis,
                options: utilsForSecond.getMaterialityBasisOptionsSecond(
                  auditing?.template
                ),
                optionDisplayValue: (key: string) =>
                  vocabulary.materialityBasisOptions[
                    key as keyof typeof vocabulary.materialityBasisOptions
                  ],
                customOnChange:
                  utilsForSecond.handleMaterialitySecondBasisChange,
              }),
              formBuilder.numberInput({
                accessor: 'materialitySecondBaseNumber',
                plainLabel: vocabulary.materialityBaseNumber,
              }),
            ],
          }),

          formBuilder.custom(props => (
            <UpdateMaterialitySecondBaseNumberButton {...props} />
          )),

          // If selected "Muu"
          formBuilder.textArea({
            accessor: 'materialitySecondBasisText',
            plainLabel: vocabulary.materialityBasisText,
            hidden: formState.materialitySecondBasis !== 'other',
          }),

          // Taulukko?
          formBuilder.group({
            items: [
              formBuilder.custom(props => (
                <MaterialitiesSecondTable {...props} />
              )),
              formBuilder.textArea({
                accessor: 'reasonsOfMaterialitySecond',
                plainLabel: vocabulary.reasonsOfMateriality,
              }),
              formBuilder.custom(props => <MaterialityTable {...props} />),
            ],
          }),

          formBuilder.group({
            items: [
              formBuilder.boolean({
                accessor: 'isSameMaterialitySecondForAllItems',
                plainLabel: vocabulary.isSameMaterialityForAllItems,
                options: ['no', 'yes'],
              }),
              formBuilder.custom(
                props => <SeparateSecondMaterialities {...props} />,
                {
                  hidden:
                    !!formState.isSameMaterialitySecondForAllItems ||
                    formState.isSameMaterialitySecondForAllItems === null,
                }
              ),
              formBuilder.boolean({
                accessor: 'addMaterialitySecondCalculation',
                plainLabel: vocabulary.addMaterialityCalculation,
                options: Object.keys(vocabulary?.addMaterialityCalculation),
              }),
              formBuilder.textArea({
                accessor: 'materialitySecondTextArea',
                plainLabel: vocabulary.materialityTextArea,
                hidden: !formState.addMaterialitySecondCalculation,
              }),
            ],
          }),
        ],
      }),
    ],
  }),
  // Third materiality
  formBuilder.accordionGroup({
    title: 'Olennaisuuden laskenta',
    hidden: !formState.addMaterialitySecondCalculation,
    open: true,
    items: [
      formBuilder.group({
        items: [
          formBuilder.groupRow({
            items: [
              formBuilder.textInput({
                accessor: 'whenMaterialityThirdCalculated',
                plainLabel: 'Miltä ajankohdalta olennaisuus on laskettu?',
              }),
              formBuilder.textInput({
                accessor: 'whenMaterialityThirdCalculatedInfo',
                plainLabel: 'Ajankohta',
              }),
            ],
          }),
          formBuilder.groupRow({
            items: [
              formBuilder.select({
                accessor: 'materialityThirdBasedIn',
                plainLabel: vocabulary.materialityBasedIn,
                options: Object.values(utilsForThird.MaterialityBasedInThird),
                optionDisplayValue: (key: string) =>
                  vocabulary.materialityBasedInOptions[
                    key as keyof typeof vocabulary.materialityBasedInOptions
                  ],
                customOnChange:
                  utilsForThird.handleMaterialityThirdBasedInChange,
              }),
              formBuilder.select({
                accessor: 'materialityThirdBasis',
                plainLabel: vocabulary.materialityBasis,
                options: utilsForThird.getMaterialityBasisOptionsThird(
                  auditing?.template
                ),
                optionDisplayValue: (key: string) =>
                  vocabulary.materialityBasisOptions[
                    key as keyof typeof vocabulary.materialityBasisOptions
                  ],
                customOnChange: utilsForThird.handleMaterialityThirdBasisChange,
              }),
              formBuilder.numberInput({
                accessor: 'materialityThirdBaseNumber',
                plainLabel: vocabulary.materialityBaseNumber,
              }),
            ],
          }),

          formBuilder.custom(props => (
            <UpdateMaterialityThirdBaseNumberButton {...props} />
          )),

          // If selected "Muu"
          formBuilder.textArea({
            accessor: 'materialityThirdBasisText',
            plainLabel: vocabulary.materialityBasisText,
            hidden: formState.materialityThirdBasis !== 'other',
          }),

          // Taulukko?
          formBuilder.group({
            items: [
              formBuilder.custom(props => (
                <MaterialitiesThirdTable {...props} />
              )),
              formBuilder.textArea({
                accessor: 'reasonsOfMaterialityThird',
                plainLabel: vocabulary.reasonsOfMateriality,
              }),
              formBuilder.custom(props => (
                <MaterialitySecondTable {...props} />
              )),
            ],
          }),

          formBuilder.group({
            items: [
              formBuilder.boolean({
                accessor: 'isSameMaterialityThirdForAllItems',
                plainLabel: vocabulary.isSameMaterialityForAllItems,
                options: ['no', 'yes'],
              }),
              formBuilder.custom(
                props => <SeparateThirdMaterialities {...props} />,
                {
                  hidden:
                    !!formState.isSameMaterialityThirdForAllItems ||
                    formState.isSameMaterialityThirdForAllItems === null,
                }
              ),
              formBuilder.boolean({
                accessor: 'addMaterialityThirdCalculation',
                plainLabel: vocabulary.addMaterialityCalculation,
                options: Object.keys(vocabulary?.addMaterialityCalculation),
              }),
              formBuilder.textArea({
                accessor: 'materialityThirdTextArea',
                plainLabel: vocabulary.materialityTextArea,
                hidden: !formState.addMaterialityThirdCalculation,
              }),
            ],
          }),
        ],
      }),
    ],
  }),
];

const section: AuditingSectionDefinition<SectionFormType> = {
  sectionKey,
  defaultFormState,
  formFields,
};

export default section;
