import auditingJson from '../../../../../src/i18n/locales/fi/auditing.json';
import { FormFieldBuilder } from '../../FormFieldBuilder';
export const innerCircleElementDFS: InnerCircle = {
  spreadSheetIC: [
    {
      column: 15,
      row: 15,
      cellData: [],
    },
  ],
  useSheetIC: null,
  relationsSelect: null,
  relationsSelect2: null,
  relationsSelect3: null,
  relationsSelect4: null,
  reference5: auditingJson.referenceValue3,
  ideasGoalsAndCompletedWork6:
    auditingJson.ideasGoalsAndCompletedWorkValueRelations,
  findingsAndComments6: auditingJson.defaultFindingsAndCommentsValue,
};
export function innerCircleElement<T extends InnerCircle>(
  formBuilder: FormFieldBuilder<T>
): FormField<T>[] {
  return [
    formBuilder.radioGroup({
      plainLabel: auditingJson.innerCircleSelectTitles.one,
      accessor: 'relationsSelect',
      options: Object.keys(auditingJson.relationsSelect),
      optionDisplayValue: (option: string) =>
        auditingJson.relationsSelect[
          option as keyof typeof auditingJson.relationsSelect
        ],
    }),
    formBuilder.checkboxGroup({
      plainLabel: auditingJson.innerCircleSelectTitles.two,
      accessor: 'relationsSelect2',
      options: Object.keys(auditingJson.relationsSelect2),
      optionDisplayValue: (option: string) =>
        auditingJson.relationsSelect2[
          option as keyof typeof auditingJson.relationsSelect2
        ],
    }),
    formBuilder.radioGroup({
      plainLabel: auditingJson.innerCircleSelectTitles.three,
      accessor: 'relationsSelect3',
      options: Object.keys(auditingJson.relationsSelect3),
      optionDisplayValue: (option: string) =>
        auditingJson.relationsSelect3[
          option as keyof typeof auditingJson.relationsSelect3
        ],
    }),
    formBuilder.radioGroup({
      plainLabel: auditingJson.innerCircleSelectTitles.four,
      accessor: 'relationsSelect4',
      options: Object.keys(auditingJson.relationsSelect4),
      optionDisplayValue: (option: string) =>
        auditingJson.relationsSelect4[
          option as keyof typeof auditingJson.relationsSelect4
        ],
    }),
  ];
}
